export default {
    setStudentInfo(state, payload) {
        state.studentInfo = payload;
    },
    setStatusChange(state, payload) {
        state.statusChange = payload;
    },
    setMajorChange(state, payload) {
        state.majorChange = payload;
    },
    setReqQuota(state, payload) {
        state.reqQuota = payload;
    },
    setQuota(state, payload) {
        state.quota = payload;
    },
    setStudent(state, payload) {
        state.students = payload;
    },
    updateStudentMajor(state, payload) {
        const stdId = payload.stdId;
        const toMajor = payload.toMajor;
        const toMajorNo = payload.toMajorNo;

        let theStudent = state.students.find((std) => std.id === stdId);
        if (toMajorNo == 1) {
            theStudent.major = toMajor;
        } else {
            theStudent[`major${toMajorNo}`] = toMajor;
        }
    },
    updateStudentStatus(state, payload) {
        const stdId = payload.stdId;
        const toStatus = payload.toStatus;

        let theStudent = state.students.find((std) => std.id === stdId);
        theStudent.status = toStatus;
    },
    setStdPetitions(state, payload) {
        state.stdPetitions = payload;
    },
};