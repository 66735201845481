import { auth } from "../js/firebaseDb.js";
export default {
    methods: {
        async logoutStudent() {
            try {
                await auth.signOut();
                this.$router.replace({
                    name: "login",
                });
                this.$store.dispatch("clearUser");
            } catch (error) {
                this.error = error.message;
            }
        },
    },
};