export default {
    students(state) {
        return state.students;
    },
    getStudentByCode: (state, getters) => (stdCode) => {
        const students = getters.students;
        const filtStd = students.find((s) => s.stdCode == stdCode);
        return filtStd;
    },
    status(state) {
        return state.status;
    },
    getStatusById: (state, getters) => (statusId) => {
        const status = getters.status;
        const selectedStatus = status.find((s) => s.id == statusId);
        if (!selectedStatus) {
            return { id: statusId, name: statusId };
        }
        return selectedStatus;
    },
};