import { createStore } from "vuex";
import subjectsModule from "./modules/subjects/index.js";
import userModule from "./modules/user/index.js";
import InstructorsModule from "./modules/instructors/index.js";
import sectionsModule from "./modules/sections/index.js";
import QuotaModule from "./modules/quota/index.js";
import MajorsModule from "./modules/majors/index.js";
import ProgramsModule from "./modules/programs/index.js";
import YearsModule from "./modules/years/index.js";
import ScheduleModule from "./modules/schedule/index.js";
import StudentsModule from "./modules/students/index.js";
import ConditionsModule from "./modules/conditions/index.js";
import adminStudentsModule from "./modules/adminStudents/index.js";
import staffsModule from "./modules/staffs/index.js";
import BasketsModule from "./modules/baskets/index.js";
import FAQsModule from "./modules/FAQ/index.js";
import stdQuotaPetitionsModule from "./modules/stdQuotaPetition/index.js";
import QuotaPetitionsModule from "./modules/quotaPetition/index.js";

export default createStore({
    modules: {
        subjects: subjectsModule,
        user: userModule,
        instructors: InstructorsModule,
        sections: sectionsModule,
        quota: QuotaModule,
        majors: MajorsModule,
        programs: ProgramsModule,
        years: YearsModule,
        schedule: ScheduleModule,
        students: StudentsModule,
        conditions: ConditionsModule,
        adminStudents: adminStudentsModule,
        staffs: staffsModule,
        baskets: BasketsModule,
        faqs: FAQsModule,
        stdQuotaPetitions: stdQuotaPetitionsModule,
        quotaPetitions: QuotaPetitionsModule,
    },
    // state() {},
    // mutations: {},
    // actions: {},
});