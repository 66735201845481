<template>
  <!-- Accept -->
  <div class=" flex justify-center items-center" v-if="isLoading">
    <!-- <base-spinner class="my-10"></base-spinner> -->
    Loading ...
  </div>
  <div v-else>
    <div
      class="flex space-x-2 text-green-500"
      v-if="reqQuota.reqResult === 'accept'"
    >
      <svg
        class="w-6 h-6"
        data-darkreader-inline-fill=""
        data-darkreader-inline-stroke=""
        fill="none"
        stroke="currentColor"
        style="--darkreader-inline-stroke:currentColor; --darkreader-inline-fill:none;"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        ></path>
      </svg>
      <div class="font-bold text-left">
        Accept<span v-if="reqQuota.byAdmin && mode === 'admin'"> By Admin</span>
      </div>
    </div>

    <!-- Reject -->
    <div
      class="flex space-x-2 text-red-500"
      v-else-if="reqQuota.reqResult === 'reject'"
    >
      <svg
        class="w-6 h-6"
        data-darkreader-inline-fill=""
        data-darkreader-inline-stroke=""
        fill="none"
        stroke="currentColor"
        style="--darkreader-inline-fill:none; --darkreader-inline-stroke:currentColor;"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        ></path>
      </svg>
      <div class="font-bold text-left">
        Reject<span v-if="reqQuota.byAdmin && mode === 'admin'"> By Admin</span>
      </div>
    </div>

    <!-- Pending -->
    <div
      class="flex space-x-2 text-blue-500"
      v-else-if="reqQuota.reqResult === 'pending'"
    >
      <svg
        class="w-6 h-6"
        data-darkreader-inline-fill=""
        data-darkreader-inline-stroke=""
        fill="none"
        stroke="currentColor"
        style="--darkreader-inline-fill:none; --darkreader-inline-stroke:currentColor;"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
        ></path>
      </svg>
      <div class="font-bold text-left">
        Pending<span v-if="reqQuota.byAdmin && mode === 'admin'">
          By Admin</span
        >
      </div>
    </div>

    <!-- reserve in admin page -->
    <!-- <div
      class="flex space-x-2 text-gray-500"
      v-else-if="reqQuota.reqResult === 'reserve' && mode === 'admin'"
    >
      <svg
        class="w-6 h-6"
        data-darkreader-inline-fill=""
        data-darkreader-inline-stroke=""
        fill="none"
        stroke="currentColor"
        style="--darkreader-inline-fill:none; --darkreader-inline-stroke:currentColor;"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
        ></path>
      </svg>
      <div class="font-bold text-left">
        Pending
      </div>
    </div> -->

    <!-- reserve in student -->
    <div class="flex space-x-2 " v-else-if="reqQuota.reqResult === 'reserve'">
      <div v-if="resPosition === 0">
        <div class="flex space-x-2 text-green-500">
          <svg
            class="w-6 h-6"
            data-darkreader-inline-fill=""
            data-darkreader-inline-stroke=""
            fill="none"
            stroke="currentColor"
            style="--darkreader-inline-stroke:currentColor; --darkreader-inline-fill:none;"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div class="font-bold text-left">
            Accept
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex space-x-2 text-yellow-500">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div class="font-bold text-left">Reserve No.{{ resPosition }}</div>
        </div>
      </div>
    </div>

    <!-- Cancel -->
    <div
      class="flex space-x-2 text-gray-500"
      v-else-if="reqQuota.reqResult === 'cancel'"
    >
      <svg
        class="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
        ></path>
      </svg>
      <div class="font-bold text-left">
        Canceled<span v-if="reqQuota.byAdmin && mode === 'admin'">
          By Admin</span
        >
      </div>
    </div>
    <div v-else>Quota Result Error</div>
  </div>
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: true,
    },
    reqQuota: {
      required: true,
    },
  },
  data() {
    return {
      error: null,
      isLoading: false,
      resPosition: 99999,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      // if (!this.mode === "student") {
      this.isLoading = true;
      await this.getResStatus();
      this.isLoading = false;
      // }
    },
    closeError() {
      this.error = null;
    },
    async getResStatus() {
      if (!this.reqQuota || this.reqQuota.reqResult !== "reserve") {
        return;
      }
      try {
        const resPosition = await this.$store.dispatch("quota/getResStatus", {
          quotaId: this.reqQuota.quota.id,
          reqId: this.reqQuota.id,
          reqRound: this.reqQuota.reqQutTime,
        });
        this.resPosition = resPosition.resPosition;
      } catch (e) {
        this.error = e.message;
        this.resPosition = "N/A"
      }
    },
  },
};
</script>

<style scoped></style>
