import getters from "./getters.js";
// import actions from "./actions.js.js";
// import mutations from "./mutations.js.js";

export default {
    namespaced: true,
    state() {
        return {
            programs: [{
                    id: "p1",
                    name: "หลักสูตร 5 ปี",
                    code: "ibmp",
                    camCode: "T",
                    camName: "ท่าพระจันทร์",
                    quotaMode1: "batch", // batch || transaction
                    quotaMode2: "batch", // batch || transaction
                    quotaMode3: "transaction", // batch || transaction
                    quotaMode4: "batch", // batch || transaction
                    quotaMode5: "transaction", // batch || transaction
                },
                {
                    id: "p2",
                    name: "หลักสูตร 4 ปี",
                    code: "undergrad",
                    camCode: "R",
                    camName: "รังสิต",
                    quotaMode1: "batch", // batch || transaction
                    quotaMode2: "batch", // batch || transaction
                    quotaMode3: "transaction", // batch || transaction
                    quotaMode4: "batch", // batch || transaction
                    quotaMode5: "transaction", // batch || transaction
                },
            ],
        };
    },
    getters,
    // actions,
    // mutations,
};