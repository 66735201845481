import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

export default {
    namespaced: true,
    state() {
        return {
            reqQuota: [],
            // offLink "aavvyhsfpoknpq0rpx0kq2ebmqt3u"
            // quotaId " IXXssgQbvUDR6MJPFetR "
            // reqDate "4/20/2021, 4:21:51 PM"
            // reqQutTime "2"
            // reqResult "pending"
            // stdCode "s1"
            // subId "aTcRRSI2SDlffg8nctzk"
            // instId "i6"
            // secId "0100"

            bskQuota: [],

            quota: [
                // {
                //   id: "q1",
                //   subId: "s2",
                //   secId: "0100",
                //   group: "01",
                //   campus: "R",
                //   quota: 150,
                //   instId: "i2",
                //   //   condition,
                //   //   q1Accept,
                //   //   q1Offer,
                //   //   q1Request
                //   //   regNo
                //   //   remark
                //   //   majorId
                //   updateBy: "u1",
                //   updateDate: "2020-04-13 14:38:38.333",
                //   // round1_request:"120"
                //   // quotaRound: [1, 2, 3],
                // },
            ],
        };
    },
    getters,
    actions,
    mutations,
};