<template>
  <div class="flex flex-col w-full">
    <div
      class="text-white bg-primary-200 flex flex-shrink-0 flex-col pl-4 py-4"
    >
      <div class="flex relative  items-center p-4 h-12">
        <span class="text-2xl tracking-wide font-semibold">Section</span>
      </div>
    </div>
  </div>

  <div class="flex justify-center pt-20" v-if="isLoading">
    <base-spinner></base-spinner>
  </div>

  <div class="px-60" v-else>
    <base-card class="flex justify-end">
      <base-button @click="addSec()">
        + เพิ่ม section
      </base-button>
    </base-card>

    <div class="flex items-center justify-center">
      <table
        class="flex flex-row flex-no-wrap md:block md:bg-white rounded-lg overflow-hidden md:shadow-lg my-5 mx-auto w-full"
      >
        <thead class=" text-white">
          <tr
            class="bg-teal-400 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-2 "
          >
            <th class="p-3 text-left">Section</th>
            <th class="p-3 text-left">Time</th>
            <th class="p-3 text-left">Campus</th>
            <th class="p-3 border-b-1  text-left ">แก้ไข</th>
          </tr>
        </thead>
        <tbody class="flex-1 md:flex-none">
          <tr
            v-for="section in sections"
            :key="section"
            class="flex flex-col flex-no wrap md:table-row mb-2 md:mb-0 "
          >
            <td class="border-grey-light border hover:bg-gray-100 p-3">
              {{ section.code }}
            </td>
            <td class="border-grey-light border hover:bg-gray-100 p-3">
              {{ section.time }}
            </td>
            <td class="border-grey-light border hover:bg-gray-100 p-3">
              {{ section.camCode }}
            </td>
            <td
              class="border-grey-light border hover:bg-gray-100 p-3  text-blue-550 hover:text-blue-600 hover:font-medium cursor-pointer"
              @click="editSec(section)"
            >
              แก้ไข
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Edit Dialog -->
  <DataModal
    :show="showDialog"
    :cancelBtn="true"
    :deleteBtn="mode == 'Edit'"
    title="เพิ่ม Section"
    @close="showDialog = false"
    @delete="deleteDialog = true"
    @submit="submitSection()"
  >
    <div class="form-row  w-full">
      <div>
        <label> Section </label>
        <div class="col-span-2">
          <input
            class=" form-control"
            type="text"
            v-model="sectionCode"
            @blur="v$.sectionCode.$touch"
          />
          <span
            class="errors"
            v-for="error of v$.sectionCode.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div v-if="logVisible">{{ sectionCode }}</div>
      </div>
      <div>
        <label> เวลา </label>
        <div class="col-span-2">
          <input
            class=" form-control"
            type="text"
            v-model="sectionTime"
            @blur="v$.sectionTime.$touch"
          />
          <span
            class="errors"
            v-for="error of v$.sectionTime.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div v-if="logVisible">{{ sectionTime }}</div>
      </div>
    </div>
  </DataModal>
  <toast :show="!!error" mode="error" @close="error = null" :message="error">
  </toast>

  <confirm-modal
    :show="deleteDialog"
    title="ยืนยันลบ Section ออกจากระบบ"
    @close="deleteDialog = false"
    @confirm="deleteSection()"
  >
  </confirm-modal>
</template>

<script>
import LoadDataMixin from "../../mixins/loadData.js";

import ConfirmModal from "../../components/UI/ConfirmModal.vue";
import DataModal from "../../components/UI/DataModal.vue";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { isLogVisible } from "../../js/debug.js";

import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mixins: [LoadDataMixin], // loadSection

  components: { DataModal, ConfirmModal },
  data() {
    return {
      showDialog: false,
      sectionCode: null,
      sectionTime: null,
      mode: null,
      sectionId: null,
      error: null,
      isLoading: false,
      deleteDialog: false,
    };
  },
  validations() {
    return {
      sectionCode: { required, minLength: minLength(4) },
      sectionTime: { required },
    };
  },
  async created() {
    this.isLoading = true;
    await this.loadSection();
    this.isLoading = false;
  },
  computed: {
    sections() {
      return this.$store.getters["sections/sections"];
    },
    logVisible() {
      return isLogVisible();
    },
  },
  methods: {
    addSec() {
      this.showDialog = true;
      this.mode = "Add";
      this.sectionCode = null;
      this.sectionTime = null;
    },
    async submitSection() {
      const matchSection = this.$store.getters["sections/getSecById"](
        this.sectionCode
      );
      this.v$.$touch();
      if (this.v$.$error) {
        this.error = "ข้อมูลที่คุณกรอกผิดพลาด โปรดตรวจสอบอีกครั้ง";
        return;
      }
      if (this.mode == "Add" && matchSection) {
        this.error = "Section ซ้ำกับข้อมูลในระบบ โปรดลองใหม่อีกครั้ง";
        return;
      }

      if (this.mode == "Add") {
        const submitData = { code: this.sectionCode, time: this.sectionTime };
        await this.$store.dispatch("sections/addSection", submitData);
      } else if (this.mode == "Edit") {
        const submitData = {
          code: this.sectionCode,
          time: this.sectionTime,
          id: this.sectionId,
        };
        await this.$store.dispatch("sections/editSection", submitData);
      }

      this.showDialog = false;
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "บันทึกสำเร็จ",
      });
    },
    editSec(section) {
      this.showDialog = true;
      this.sectionCode = section.code;
      this.sectionTime = section.time;
      this.sectionId = section.id;
      this.mode = "Edit";
    },
    async deleteSection() {
      await this.$store.dispatch("sections/deleteSection", {
        id: this.sectionId,
      });
      this.deleteDialog = false;
      this.showDialog = false;
      this.$store.dispatch("updateToast", {
        mode: "info",
        show: true,
        message: "ลบ section สำเร็จ",
      });
    },
  },
};
</script>

<style scoped>
.thepClass {
  @apply bg-yellow-200 rounded-full;
}
.form-row {
  @apply pb-3;
}
.std-info-grid {
  @apply grid grid-cols-4 py-6;
}

.std-info-grid h3 {
  @apply col-span-4 md:col-span-1 pb-4;
}

.std-info-data {
  @apply col-span-4 md:col-span-3;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
