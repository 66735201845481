<template>
  <base-card class="mb-10" v-if="isSudo">
    <div class="flex justify-center py-8 ">
      <base-button mode="outline" class="mx-2" @click="cancelVisible = true"
        >ยกเลิกผลการประมวล</base-button
      >
      <base-button class="mx-2" mode="outline" @click="clickConfirmProcess()"
        >ยืนยันผลการประมวล</base-button
      >

      <base-button @click="processQuota()" class=" px-16 mx-2"
        >ประมวลผล</base-button
      >
    </div>

    <div class="flex justify-center pb-8 ">
      <div class="w-60">
        <input type="file" @change="fileUpload" />
      </div>

      <base-button mode="outline" class="mx-2" @click="selectRoundModal = true"
        >Export รายชื่อ</base-button
      >
    </div>
    <!-- <div class="space-x-4">
      <router-link :to="{ name: 'quota-process' }">
        <base-button mode="gray">List</base-button>
      </router-link>
    </div> -->
  </base-card>
  <div v-if="logVisible">
    <base-button @click="nextSchedule()" class=" px-16 mx-2"
      >Next Schedule</base-button
    >
    <base-button @click="checkQuotaStatus()" class=" px-16 mx-2"
      >Check Quota Process</base-button
    >
    <div>
      {{ schedule }}
    </div>
    <hr />
    <div>{{ file }}</div>
  </div>

  <div class="px-2">
    <!-- <h3>รังสิต</h3> -->

    <div>
      <div class="text-xl font-semi-bold py-4 text-center" v-if="!hasQuota">
        ไม่มีข้อมูลโควตาในฐานข้อมูล โปรด<router-link
          :to="{ name: 'quota-add' }"
          class="text-primary-400"
        >
          เพิ่มโควตา </router-link
        >ใหม่
      </div>
      <table style="table-fixed " v-else>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />

          <col span="3" class="bg-blue-50" />
          <col span="3" class="" />
          <col span="3" class="bg-blue-50" />
          <col span="3" class="" />
          <col span="3" class="bg-blue-50" />
          <col />
          <col class="bg-blue-50" />
          <col />
          <col class="bg-blue-50" />
        </colgroup>
        <thead>
          <tr>
            <th colspan="4" class="text-center px-1 w-1/12">
              <h4>รหัสวิชา/ชื่อวิชา</h4>
            </th>
            <th rowspan="2" class="text-center px-1 w-0.5/12">
              <h4>Sec</h4>
            </th>
            <th rowspan="2" class="text-center px-1 w-0.5/12">
              <h4>Grp</h4>
            </th>
            <th colspan="3" class="text-center px-1 w-1/12">
              <h4>ครั้งที่ 1</h4>
            </th>
            <th colspan="3" class="text-center px-1 w-1/12">
              <h4>ครั้งที่ 2</h4>
            </th>
            <th colspan="3" class="text-center px-1 w-1/12">
              <h4>ครั้งที่ 3</h4>
            </th>
            <th colspan="3" class="text-center px-1 w-1/12">
              <h4>ครั้งที่ 4.1</h4>
            </th>
            <th colspan="3" class="text-center px-1 w-1/12">
              <h4>ครั้งที่ 4.2</h4>
            </th>
            <th rowspan="2" class="text-center px-1 w-0.5/12">
              <h4>
                รวม<br />ได้<br />
                1+2
              </h4>
            </th>
            <th rowspan="2" class="text-center px-1 w-0.5/12">
              <h4>ลง<br />จริง</h4>
            </th>
            <th rowspan="2" class="text-center px-1 w-0.5/12">
              <h4>ลง<br />จริง<br />+3</h4>
            </th>
            <th rowspan="2" class="text-center px-1 w-0.5/12">
              <h4>ลง<br />จริง<br />+3<br />+4.1<br />+4.2</h4>
            </th>
            <th rowspan="2" class="text-center px-1 w-2/12">
              <h4>อาจารย์</h4>
            </th>
            <th rowspan="2" class="text-center px-1 w-2/12">
              <h4>หมายเหตุ</h4>
            </th>
            <th rowspan="2" class="text-center px-1 w-0.5/12">
              <h4>เงื่อน<br />ไข</h4>
            </th>
            <th rowspan="2" class="text-center px-1 w-0.5/12">
              <h4>Detail</h4>
            </th>
          </tr>
          <tr class="text-center">
            <th v-for="(the_year, index) in last4Years" :key="index">
              {{ the_year.name }}
            </th>
            <th>เปิด</th>
            <th>ขอ</th>
            <th>ได้</th>
            <th>เปิด</th>
            <th>ขอ</th>
            <th>ได้</th>
            <th>เปิด</th>
            <th>ขอ</th>
            <th>ได้</th>
            <th>เปิด</th>
            <th>ขอ</th>
            <th>ได้</th>
            <th>เปิด</th>
            <th>ขอ</th>
            <th>ได้</th>
          </tr>
        </thead>

        <!-- send Major instead of Offlink -->
        <quota-item
          :yearNo="yearNo"
          ref="quotaItem"
          :secDetail="true"
        ></quota-item>
      </table>
    </div>
  </div>
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
  <spinner-dialog :show="isLoading"> </spinner-dialog>
  <spinner-dialog
    :show="isQuotaLoading"
    :text="`complete: ${quotaDone.length}/${quota.length}`"
  >
  </spinner-dialog>
  <div v-if="logVisible">
    <div>{{ quota }}</div>
    <hr />
    <hr />
    {{ years }}
    <hr />
    {{ last4Years }}
    <hr />
    {{ subjects }}
  </div>
  <ScrollToTop></ScrollToTop>
  <ConfirmModal
    title="คุณต้องการ ยกเลิกประมวลผล หรือไม่"
    :show="cancelVisible"
    @confirm="undoProcessQuota()"
    @close="cancelVisible = false"
    mode="success"
  ></ConfirmModal>

  <ConfirmModal
    title="การยืนยันผลการประมวล เป็นการยืนยันทั้ง หลักสูตร 4 ปี และหลักสูตร 5 ปี คุณต้องการ ยืนยันประมวลผล และเลื่อนรอบโควตา หรือไม่"
    :show="confirmVisible"
    @confirm="confirmProcess()"
    @close="confirmVisible = false"
    mode="success"
  ></ConfirmModal>

  <DataModal
    title="นำเข้าข้อมูลจำนวนลงทะเบียน"
    :show="importRegVisible"
    @close="importRegVisible = false"
    @submit="importRegNo"
  >
    <div class="grid-table-main">
      <div
        class="
              grid grid-cols-5
              grid-table-header
            "
      >
        <div class="w-full h-auto">รหัสวิชา</div>
        <div class="w-full h-auto">ปีการศึกษา</div>
        <div class="w-full h-auto">section</div>
        <div class="w-full h-auto">สถานะ</div>
        <div class="w-full h-auto">ลงทะเบียน</div>
      </div>

      <div class="grid-table-body">
        <div
          class="grid grid-cols-5 grid-table-row"
          v-for="(quotaData, index) in sortQuotaAndRegNo(quotaAndRegNo)"
          :key="index"
        >
          <div class="text-center my-auto ">
            {{ quotaData.quota.subCode }}
          </div>
          <div class="text-center my-auto ">
            {{ quotaData.quota.subYear }}
          </div>
          <div class="text-center my-auto ">
            {{ quotaData.quota.secId }}/{{ quotaData.quota.group }}
          </div>
          <div class="text-center my-auto ">
            <div
              class="flex justify-center space-x-2 text-green-500"
              v-if="quotaData.isMatch"
            >
              <svg
                class="w-6 h-6"
                data-darkreader-inline-fill=""
                data-darkreader-inline-stroke=""
                fill="none"
                stroke="currentColor"
                style="--darkreader-inline-stroke:currentColor; --darkreader-inline-fill:none;"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <div class="font-bold">
                OK
              </div>
            </div>
            <div class="flex justify-center space-x-2 text-red-500" v-else>
              <svg
                class="w-6 h-6"
                data-darkreader-inline-fill=""
                data-darkreader-inline-stroke=""
                fill="none"
                stroke="currentColor"
                style="--darkreader-inline-fill:none; --darkreader-inline-stroke:currentColor;"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <div class="font-bold">
                Error
              </div>
            </div>
          </div>
          <div class="text-center my-auto ">
            <input
              type="text"
              class="form-control"
              @input="quotaAndRegNo[index]['error'] = false"
              :class="{ errors: quotaAndRegNo[index]['error'] }"
              placeholder=""
              v-model.number="quotaAndRegNo[index]['regNo']"
            />
          </div>
        </div>
      </div>
    </div>
  </DataModal>

  <DataModal
    title="Export รายชื่อนักศึกษาในแต่ละวิชา"
    :show="selectRoundModal"
    @close="selectRoundModal = false"
    @submit="onExport()"
    submitText="Export"
  >
    <div class="flex space-x-10">
      <label class="flex items-center">
        <input
          type="checkbox"
          class="form-checkbox"
          :value="1"
          v-model="exportRound"
        />
        <span class="ml-2">รอบที่ 1 </span>
      </label>
      <label class="flex items-center">
        <input
          type="checkbox"
          class="form-checkbox"
          :value="2"
          v-model="exportRound"
        />
        <span class="ml-2">รอบที่ 2 </span>
      </label>
      <label class="flex items-center">
        <input
          type="checkbox"
          class="form-checkbox"
          :value="3"
          v-model="exportRound"
        />
        <span class="ml-2">รอบที่ 3 </span>
      </label>
      <label class="flex items-center">
        <input
          type="checkbox"
          class="form-checkbox"
          :value="4"
          v-model="exportRound"
        />
        <span class="ml-2">รอบที่ 4.1 </span>
      </label>
      <label class="flex items-center">
        <input
          type="checkbox"
          class="form-checkbox"
          :value="5"
          v-model="exportRound"
        />
        <span class="ml-2">รอบที่ 4.2 </span>
      </label>
    </div>
    <!-- {{ exportRound }} -->
  </DataModal>
  <DataModal
    title="เลื่อนรอบโควตา"
    :show="nextRoundModal"
    @close="nextRoundModal = false"
    @submit="selectNextRound()"
    submitText="เลือกรอบ"
  >
    <div class="">
      <div>โปรดเลือกรอบที่ท่านต้องการใช้งาน</div>

      <div>
        <input
          class="form-radio mr-1"
          @blur="v$.pickedForceSem.$touch"
          :class="{
            errors: v$.pickedForceSem.$error,
          }"
          type="radio"
          id="a"
          value="sem"
          v-model="pickedForceSem"
        />
        <label for="a">เลื่อนไปเทอมถัดไป (สำหรับนักศึกษาทั่วไป)</label>
      </div>

      <div>
        <input
          class="form-radio mr-1"
          @blur="v$.pickedForceSem.$touch"
          :class="{
            errors: v$.pickedForceSem.$error,
          }"
          type="radio"
          id="b"
          value="round"
          v-model="pickedForceSem"
        />
        <label for="b">เลื่อนไปรอบที่ 4 (สำหรับนักศึกษาปี 1)</label>
      </div>
      <span
        class="errors"
        v-for="error of v$.pickedForceSem.$errors"
        :key="error.$uid"
        >{{ error.$message }}<br />
      </span>
    </div>
    <!-- {{ exportRound }} -->
  </DataModal>
</template>

<script>
import XLSX from "xlsx"; // import xlsx
import QuotaItem from "../../components/Quota/QuotaItem.vue";
import SpinnerDialog from "../../components/UI/SpinnerDialog.vue";
import ScrollToTop from "../../components/UI/ScrollToTop.vue";
import ConfirmModal from "../../components/UI/ConfirmModal.vue";
import DataModal from "../../components/UI/DataModal.vue";
import LoadDataMixin from "../../mixins/loadData.js";
import { isLogVisible } from "../../js/debug.js";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  mixins: [LoadDataMixin], // loadSubject
  components: {
    QuotaItem,
    SpinnerDialog,
    ScrollToTop,
    ConfirmModal,
    DataModal,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      yearNo: 4,
      error: null,
      isLoading: false,
      isQuotaLoading: false,
      exportJson: null,
      file: null,
      quotaAndRegNo: null,

      // Visible
      cancelVisible: false,
      confirmVisible: false,
      importRegVisible: false,

      // export excel
      selectRoundModal: false,
      exportRound: [],

      pickedForceSem: null,
      nextRoundModal: false,
    };
  },
  validations() {
    return {
      pickedForceSem: {
        required,
      },
    };
  },

  created() {
    this.loadData();
  },
  computed: {
    forceNextSem() {
      if (this.pickedForceSem === "sem") {
        return true;
      } else {
        return false;
      }
    },
    schedule() {
      return this.$store.getters["schedule"];
    },
    quotaDone() {
      return this.quota.filter(
        (q) => q[`status${this.schedule.qRound}`] === "done"
      );
    },
    quotaNotDone() {
      return this.quota.filter(
        (q) => q[`status${this.schedule.qRound}`] !== "done"
      );
    },

    // get OffLink
    subjects() {
      return this.$store.getters["subjects/subjects"];
    },
    quota() {
      return this.$store.getters["quota/quota"];
    },
    reqQuota() {
      return this.$store.getters["quota/reqQuota"];
    },
    hasQuota() {
      return this.$store.getters["quota/hasQuota"];
    },
    last4Years() {
      return this.$store.getters["years/getLast4Years"];
    },
    years() {
      return this.$store.getters["subjects/years"];
    },
    logVisible() {
      return isLogVisible();
    },
    editSuccess() {
      if (this.logVisible) {
        console.log(this.$refs.quotaItem);
      }

      if (this.$refs.quotaItem !== undefined) {
        return this.$refs.quotaItem.editSuccess;
      } else {
        return false;
      }
    },
    userInfo() {
      return this.$store.getters["userInfo"];
    },
    isSudo() {
      return this.userInfo.role === "A";
    },
  },
  methods: {
    clickConfirmProcess() {
      if (this.schedule.qRound === 3) {
        this.nextRoundModal = true;
      } else {
        this.confirmVisible = true;
      }
    },
    selectNextRound() {
      this.v$.pickedForceSem.$touch();
      if (this.v$.pickedForceSem.$error) return;
      // open confirm modal
      this.nextRoundModal = false;
      this.confirmVisible = true;
    },
    async loadData() {
      if (this.isSudo) {
        await this.loadSubject();
      }
    },
    fileUpload(event) {
      if (this.logVisible) {
        console.log("start upload file");
      }

      this.isLoading = true;
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          if (this.logVisible) {
            console.log("uploading...");
          }

          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          if (this.logVisible) {
            console.log("opening file ...");
          }

          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          if (this.logVisible) {
            console.log("formatting data...");
          }

          this.submitRegNo(data);
        };

        reader.readAsBinaryString(this.file);
      }
    },
    submitRegNo(excelData) {
      const res = this.formatRegister(excelData);
      const tuQuotaList = this.updateSubject(res);
      if (this.logVisible) {
        console.log("matching data with quota...");
        console.log(tuQuotaList);
      }

      this.quotaAndRegNo = this.matchQuotaAndRegExcel(tuQuotaList);
      this.isLoading = false;
      this.importRegVisible = true;
      if (this.logVisible) {
        console.log("upload success");
      }
    },
    formatRegister(regArr) {
      // format Reg Arr
      const resArr = [];
      for (const reg of regArr) {
        if (!reg[0] || !/\d{3}-\d{3}/.test(reg[0])) {
          continue;
        }

        const sCode = reg[0];
        const subData = sCode.split("-");
        const subCode = subData[0];
        const subYear = subData[1].substring(0, 2);
        const section = reg[16];
        const regNo = reg[19];

        const res = {
          subCode: subCode,
          subYear: subYear,
          section: section,
          regNo: parseInt(regNo) || 0,
        };
        resArr.push(res);
      }

      return resArr;
    },
    updateSubject(quotaArr) {
      // [{regNo: "1",
      // section: "870001",,
      // subCode: "AC311",
      // subYear: "56"}  ]

      // find subject of quota
      const quotaOffLinkList = [];
      for (let quota of quotaArr) {
        const the_sub = this.$store.getters["subjects/getSubjectByCodeAndYear"](
          {
            code: quota.subCode,
            year: quota.subYear,
            yearDigit: 2,
          }
        );
        if (this.logVisible) {
          console.log(the_sub);
        }

        if (!the_sub) {
          this.error = `Import file Failed : Subject ${quota.subCode} (${quota.subYear}) can't find in db`;
          this.isLoading = false;
          return;
        }

        quota["offLink"] = the_sub.offLink;
        quotaOffLinkList.push(quota);
      }

      // [{offLink: "", section:"",regNo:""}  ]
      // sum regNo
      let regNoDict = [];
      for (const theQuota of quotaOffLinkList) {
        if (this.logVisible) {
          console.log(theQuota);
        }

        const matchedObject = regNoDict.find((doc) => {
          return (
            doc.offLink === theQuota["offLink"] &&
            doc.section === theQuota["section"]
          );
        });
        const index = regNoDict.indexOf(matchedObject);
        if (index !== -1) {
          regNoDict.splice(index, 1, {
            ...regNoDict[index],
            regNo: regNoDict[index]["regNo"] + theQuota["regNo"],
          });
        } else {
          regNoDict.push({
            offLink: theQuota["offLink"],
            section: theQuota["section"],
            regNo: theQuota["regNo"],
          });
        }
      }
      if (this.logVisible) {
        console.log(regNoDict);
      }

      return regNoDict;
    },
    async importRegNo() {
      // check all quotaAndRegNo is Number
      for (const i in this.quotaAndRegNo) {
        this.quotaAndRegNo[i].error = false;
        if (
          (!this.quotaAndRegNo[i]["regNo"] &&
            this.quotaAndRegNo[i]["regNo"] !== 0) ||
          !Number.isInteger(this.quotaAndRegNo[i]["regNo"])
        ) {
          this.quotaAndRegNo[i].error = true;
          this.error = "กรอกเลขลงทะเบียนไม่ถูกต้อง";
          return;
        }
      }

      // ok ... pass all validator
      try {
        this.isLoading = true;
        await this.$store.dispatch(
          "quota/updateQuotaRegNo",
          this.quotaAndRegNo
        );
        this.isLoading = false;

        this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "อัพเดตข้อมูลเสร็จสิ้น ",
        });

        this.quotaAndRegNo = null;
        this.importRegVisible = false;
      } catch (e) {
        this.isLoading = false;
        this.error = e;
      }
    },

    matchQuotaAndRegExcel(tuQuotaList) {
      const allQuota = this.$store.getters["quota/quota"];

      const newQuotaList = [];
      for (let theQuota of allQuota) {
        let res = {};

        const matchedQuota = tuQuotaList.find((tuQuota) => {
          const fullSection = String(theQuota.secId) + String(theQuota.group);
          return (
            fullSection === tuQuota.section &&
            theQuota.offLink === tuQuota.offLink
          );
        });
        if (matchedQuota) {
          res = matchedQuota;
          res["regNo"] = parseInt(res.regNo);
          res["isMatch"] = true;
        } else {
          res["regNo"] = null;
          res["isMatch"] = false;
        }
        res["quota"] = theQuota;
        newQuotaList.push(res);
      }
      return newQuotaList;
    },

    async getReqQuotaJson() {
      try {
        const allReqQuota = await this.$store.dispatch("quota/getAllReqQuota", {
          roundArray: this.exportRound,
        });

        const resReqQuota = allReqQuota.map((doc) => {
          return {
            รหัสวิชา: doc.subCode,
            หลักสูตร: doc.subYear,
            Section: doc.secId,
            Group: doc.group,
            Campus: doc.campus,
            เลขทะเบียน: doc.stdCode,
            ชื่อนักศึกษา:
              doc.stdTName + " " + doc.stdFName + " " + doc.stdLName,
          };
        });
        return resReqQuota;
      } catch (e) {
        this.error = e;
        throw e;
      }
    },

    async onExport() {
      this.isLoading = true;
      const exportJson = await this.getReqQuotaJson();
      if (this.logVisible) {
        console.log(exportJson);
      }
      const dataWS = XLSX.utils.json_to_sheet(exportJson);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
      this.isLoading = false;
    },
    async processQuota() {
      if (this.logVisible) {
        console.log("button process pressed");
      }

      try {
        this.isQuotaLoading = true;
        await this.$store.dispatch("quota/processQuota");
        this.isQuotaLoading = false;
      } catch (e) {
        this.isQuotaLoading = false;
        this.error = e;
      }

      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "ประมวลผลสำเร็จ ",
      });
    },

    async confirmProcess() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("quota/confirmProcess");
        await this.$store.dispatch("nextSchedule", {
          forceNextSem: this.forceNextSem,
        });
        await this.loadQuota("admin");
        this.isLoading = false;

        this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "ยืนยันประมวลผลสำเร็จ ",
        });
      } catch (e) {
        this.isLoading = false;
        this.error = e;
      }
    },

    async undoProcessQuota() {
      try {
        this.isQuotaLoading = true;
        await this.$store.dispatch("quota/undoProcessQuota");
        this.isQuotaLoading = false;
      } catch (e) {
        this.isQuotaLoading = false;
        this.error = e;
      }

      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "ยกเลิกการประมวลผลสำเร็จ ",
      });
    },
    async nextSchedule() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("nextSchedule", { forceNextSem: true });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.error = e;
      }
    },
    async checkQuotaStatus() {
      try {
        this.isLoading = true;
        const status = await this.$store.dispatch("quota/checkQuotaStatus");
        if (this.logVisible) {
          console.log(status);
        }

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.error = e;
      }
    },

    closeError() {
      this.error = null;
    },
    sortQuotaAndRegNo(payload) {
      return payload.sort(function(a, b) {
        if (a.quota.subCode < b.quota.subCode) {
          return -1;
        } else if (a.quota.subCode > b.quota.subCode) {
          return 1;
        } else {
          if (a.quota.secId < b.quota.secId) {
            return -1;
          } else if (a.quota.secId > b.quota.secId) {
            return 1;
          } else {
            if (a.group < b.group) {
              return -1;
            } else if (a.group > b.group) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      });
    },
  },
};
</script>

<style scoped>
td {
  /* width: 150px;
  text-align: center;
  border: 1px solid black; */
  @apply py-2;
}

th {
  /* width: 150px;
  text-align: center;
  border: 1px solid black; */
  @apply py-2 border-2 border-blue-200;
}
</style>
