<template>
  <div
    class="bg-gray-50 py-4 pl-6  flex justify-start items-center font-kanit "
    v-if="!isLogin"
  >
    <div class="flex items-center">
      <router-link :to="{ name: 'login' }"
        ><img class="w-48 mr-10 " src="../../assets/tbs_logo.png"
      /></router-link>
    </div>
  </div>
</template>

<script>
import { auth } from "../../js/firebaseDb.js";

export default {
  data() {
    return { isLogin: true };
  },
  computed: {
    // isLogin() {
    //   auth.onAuthStateChanged((user) => {
    //     return user;
    //   });
    //   return false;
    // },
  },
  mounted() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    });
  },
  created() {
    // auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     this.isLogin = true;
    //   } else {
    //     this.isLogin = false;
    //   }
    // });
  },
};
</script>
