import { sortText } from "../../../js/utility.js";

export default {
    sections(state) {
        // return state.sections;
        return sortText(state.sections, "code"); //sort text by code
    },
    // sections(state) {
    //     const fltSec = state.sections.filter(
    //         (sec) =>
    //         sec.status == "active" ||
    //         sec.status === undefined ||
    //         sec.status === null
    //     );
    //     return sortText(fltSec, "code");
    // },
    getSecById: (state, getters) => (id) => {
        const sections = getters.sections;
        return sections.find((sec) => sec.code == id);
    },
    fullName(state, getters) {
        let secArr = [];
        const sections = getters.sections;
        for (const sec of sections) {
            secArr.push({
                ...sec,
                fullName: sec.code + " - " + sec.time,
            });
        }
        return secArr;
    },
};