export default {
    //   addStudent(state, payload) {
    //     state.students.push(payload);
    //   },
    setSchedule(state, payload) {
        state.schedule = payload;
    },
    setScheduleRule(state, payload) {
        state.scheduleRule = payload;
    },
    //   clearStudent(state) {
    //     state.students = [];
    //   },
    setScheduleList(state, payload) {
        state.scheduleList = payload;
    },
    setScheduleCustomRule(state, payload) {
        state.customRule = payload;
    },
    setServerTime(state, time) {
        state.serverTime = time;
    },
};