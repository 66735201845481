<template>
  <div class="flex flex-col w-full">
    <div
      class="text-white bg-primary-200 flex flex-shrink-0 flex-col pl-4 py-4"
    >
      <div class="flex relative  items-center p-4 h-12">
        <span class="text-2xl tracking-wide font-semibold">เงื่อนไข</span>
      </div>
    </div>
  </div>

  <div class="flex justify-center pt-20" v-if="isLoading">
    <base-spinner></base-spinner>
  </div>

  <div class="px-60 pt-10" v-else>
    <div class="flex items-center justify-center">
      <table
        class="flex flex-row flex-no-wrap md:block md:bg-white rounded-lg overflow-hidden md:shadow-lg my-5 mx-auto w-full"
      >
        <thead class=" text-white">
          <tr
            class="bg-teal-400 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-2 "
          >
            <th class="p-3 text-left">เงื่อนไข</th>
            <th class="p-3 text-left">ลำดับ,รายละเอียด</th>
          </tr>
        </thead>
        <tbody class="flex-1 md:flex-none">
          <tr
            v-for="condition in conditions"
            :key="condition"
            class="flex flex-col flex-no wrap md:table-row mb-2 md:mb-0 "
          >
            <td class="border-grey-light border hover:bg-gray-100 p-3">
              {{ condition.condCode }}
            </td>
            <td class="border-grey-light border hover:bg-gray-100 p-3">
              {{ condition.block }}. {{ getMajorCrit(condition.majorCrit) }},
              {{ getYearCrit(condition.yearCrit) }},
              {{ getOrderCrit(condition.orderCrit) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import LoadDataMixin from "../../mixins/loadData.js";

import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  mixins: [LoadDataMixin], // loadConditon

  data() {
    return {
      conditon: {},
      yearCrit: {
        1: "ทุกชั้นปี",
        2: "ปีที่ 2 หรือสูงกว่า",
        3: "ปีที่ 3 หรือสูงกว่า",
        4: "ปีที่ 4 หรือสูงกว่า",
      },
      majorCrit: {
        1: "นักศึกษาเอกที่ 1 ภาคเดียวกับรายวิชา",
        2: "นักศึกษา Integrated in Business (BI), Integrated in Accounting (AI)",
        3: "นักศึกษาในคณะ",
        4: "นักศึกษาทั่วไป",
        5: "นักศึกษาเอกที่ 1 และนักศึกษาเอกที่ 2 ภาคเดียวกับรายวิชา (เท่ากัน)",
        6: "นักศึกษาเอกที่ 2 ภาคเดียวกับรายวิชา",
      },
      orderCrit: {
        1: "เรียงตามชั้นปี+เลขสุ่ม",
        2: "เรียงตามเลขสุ่ม",
        3: "โควตาที่ได้จัดสรร+เรียงตามเลขสุ่ม",
      },
      isLoading: false,
    };
  },
  async created() {
    this.isLoading = true;
    await this.loadConditions();
    this.isLoading = false;
  },
  computed: {
    conditions() {
      return this.$store.getters["conditions/conditions"];
    },
  },
  methods: {
    getMajorCrit(major) {
      return this.majorCrit[major];
    },
    getYearCrit(year) {
      return this.yearCrit[year];
    },
    getOrderCrit(order) {
      return this.orderCrit[order];
    },
  },
};
</script>

<style scoped>
.form-row {
  @apply pb-3;
}
.std-info-grid {
  @apply grid grid-cols-4 py-6;
}

.std-info-grid h3 {
  @apply col-span-4 md:col-span-1 pb-4;
}

.std-info-data {
  @apply col-span-4 md:col-span-3;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
