export default {
    years(state) {
        return state.years;
    },

    maxYear(state, getters) {
        const years = getters.years;
        return years.slice(-1)[0];
    },
    getAroundYear: (state, getters) => (yearNo, position) => {
        const years = getters.years;
        const selectedYear = years.find((y) => y.name == yearNo);
        var index = years.indexOf(selectedYear);
        return years.slice(index + position)[0];
    },
    getMaxYears: (state, getters) => (yearNo) => {
        const years = getters.years;
        return years.slice(yearNo * -1);
    },
    getYearByYear: (state, getters) => (year) => {
        const years = getters.years;
        const selectedYear = years.find(
            (theyear) => theyear.name === parseInt(year)
        );
        return selectedYear;
    },
    getYearByStdCode: (state, getters) => (stdCode) => {
        const yearStdCode = stdCode.slice(0, 2);
        const years = getters.years;
        const selectedYear = years.filter(
            (theyear) => theyear.effectEst <= parseInt(yearStdCode)
        );
        return selectedYear.slice(-1)[0];
    },
    getLast4Years(state, getters) {
        const years = getters.years;
        return years.slice(years.length - 4, years.length);
    },
};