<template>
  <!-- <tr class=" text-center font-semibold" v-if="">
    <td colspan="12">Test</td>
  </tr> -->
  <tr>
    <td class="w-16 px-1">
      <select
        class="form-control px-1"
        :class="{ errors: !condition.isValid }"
        placeholder=""
        v-model.number="condition.val"
        @input="[hasChanged1, hasChanged2] = [true, true]"
      >
        <option v-for="cond in condCode" :value="cond" :key="cond">
          {{ cond }}
        </option>
      </select>
    </td>
    <td class="w-20">
      <select
        class="form-control px-1"
        :class="{ errors: !majorId.isValid }"
        placeholder=""
        v-model="majorId.val"
        @input="[hasChanged1, hasChanged2] = [true, true]"
      >
        <option v-for="major in majors" :value="major.id" :key="major.id">
          {{ major.code }}
        </option>
      </select>
      <!-- {{ majorId }} -->
    </td>

    <!-- หลักสูตร 1  -->
    <td class="pr-2 w-20">
      <input
        type="text"
        class="form-control"
        :class="{ errors: !code1.isValid }"
        placeholder="Code"
        v-model="code1.val"
        @input="hasChanged1 = true"
      />
      <!-- {{ subject1.code }} -->
    </td>
    <td class="pr-2 w-52">
      <input
        type="text"
        class="form-control"
        :class="{ errors: !name1.isValid }"
        placeholder="ชื่อวิชา"
        v-model="name1.val"
        @input="hasChanged1 = true"
      />
      <!-- {{ subject1.name }} -->
    </td>
    <td class="pr-2 w-24">
      <div class="relative inline-block w-full text-gray-700">
        <select
          class="form-control"
          placeholder="Regular input"
          v-model="type1.val"
          :class="{
            errors: !type1.isValid,
          }"
          @input="hasChanged1 = true"
        >
          <!-- :disabled="!subject1.type" -->
          <option value="0" disabled>โปรดเลือก</option>
          <option value="core">แกน</option>
          <option value="require">เอกบังคับ</option>
          <option value="elective">เอกเลือก</option>
          <option value=""></option>
        </select>
      </div>
      <!-- {{ subject1.type }} -->
    </td>
    <td class="pr-2 w-16">
      <input
        type="number"
        class="form-control"
        :class="{ errors: !unit1.isValid }"
        placeholder="Unit"
        v-model.number="unit1.val"
        @input="hasChanged1 = true"
      />
      <!-- {{ subject1.unit }} -->
    </td>

    <!-- checkbox used for send data to parent and delete data in DB -->
    <!-- <td class="pr-2 w-1 text-center">
      <input
        type="checkbox"
        :value="subject1.id"
        @input="$emit('selectSubject', $event.target.value)"
      />
    </td> -->

    <td class="w-10 px-2 text-center">
      <div class="w-10">
        <base-button class="w-full h-10" mode="outline" @click="copySubject()">
          <div class="flex justify-center items-center">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 5l7 7-7 7M5 5l7 7-7 7"
              ></path>
            </svg>
          </div>
        </base-button>
      </div>
    </td>

    <!-- หลักสูตร 2  -->
    <td class="pr-2 w-20">
      <input
        type="text"
        class="form-control"
        :class="{ errors: !code2.isValid }"
        placeholder="Code"
        v-model="code2.val"
        @input="hasChanged2 = true"
      />
      <!-- {{ subject2.code }} -->
    </td>

    <td class="pr-2 w-52">
      <input
        type="text"
        class="form-control"
        :class="{ errors: !name2.isValid }"
        placeholder="ชื่อวิชา"
        v-model="name2.val"
        @input="hasChanged2 = true"
      />
      <!-- {{ subject2.name }} -->
    </td>
    <td class="pr-2 w-24">
      <div class="relative inline-block w-full text-gray-700">
        <select
          class="form-control"
          placeholder="Regular input"
          v-model="type2.val"
          :class="{
            errors: !type2.isValid,
          }"
          @input="hasChanged2 = true"
        >
          <option value="0" disabled>โปรดเลือก</option>
          <option value="core">แกน</option>
          <option value="require">เอกบังคับ</option>
          <option value="elective">เอกเลือก</option>
          <option value=""></option>
        </select>
      </div>
      <!-- {{ subject2.type }} -->
    </td>
    <td class="pr-2 w-16">
      <input
        type="number"
        class="form-control"
        :class="{ errors: !unit2.isValid }"
        placeholder="Unit"
        v-model.number="unit2.val"
        @input="hasChanged2 = true"
      />
      <!-- {{ subject2.unit }} -->
    </td>
    <td class="pr-2 w-4">
      <base-button mode="gray" class="w-full" @click="tryDeleteSubject()"
        >ลบ</base-button
      >
    </td>

    <!-- checkbox used for send data to parent and delete data in DB -->
    <!-- <td class="pr-2 w-1 text-center">
      <input
        type="checkbox"
        :value="subject2.id"
        @input="$emit('selectSubject', $event.target.value)"
      />
    </td> -->
  </tr>
  <confirm-modal
    conBtnText="ลบ"
    message="คุณต้องการลบข้อมูลใช่หรือไม่"
    @confirm="deleteSubject"
    @close="delSubBoxVsb = false"
    :show="delSubBoxVsb"
    title="ลบข้อมูล"
  ></confirm-modal>
</template>

<script>
import ConfirmModal from "../UI/ConfirmModal.vue";
import { isLogVisible } from "../../js/debug.js";

export default {
  components: { ConfirmModal },
  props: {
    id: {
      type: String,
      required: true,
    },
    offLink: {
      required: true,
    },
    yearArr: {
      type: Array,
      required: true,
    },
    program: {
      type: String,
      required: true,
    },
  },
  emits: ["selectSubject", "subjectStatus"],
  created() {
    this.setupForm();
  },
  // updated() {
  //   this.setupForm();
  // },

  data() {
    return {
      // Subject 1
      id1: null,
      code1: {
        val: null,
        isValid: true,
      },
      name1: {
        val: null,
        isValid: true,
      },
      type1: {
        val: null,
        isValid: true,
      },
      unit1: {
        val: null,
        isValid: true,
      },
      hasChanged1: false,
      validEmpty1: false,

      // Subject 2
      id2: null,
      code2: {
        val: null,
        isValid: true,
      },
      name2: {
        val: null,
        isValid: true,
      },
      type2: {
        val: null,
        isValid: true,
      },
      unit2: {
        val: null,
        isValid: true,
      },
      hasChanged2: false,
      validEmpty2: false,

      // Item Data
      condition: {
        val: null,
        isValid: true,
      },
      majorId: {
        val: null,
        isValid: true,
      },

      // component data
      delSubBoxVsb: false,
      formValid: true,
    };
  },
  computed: {
    logVisible() {
      return isLogVisible();
    },
    majors() {
      return this.$store.getters["majors/majors"];
    },
    maxYear() {
      return this.yearArr[1];
    },
    minYear() {
      return this.yearArr[0];
    },
    condCode() {
      return this.$store.getters["conditions/condCode"];
    },
  },
  watch: {
    yearArr: {
      deep: true,
      handler() {
        this.setupForm();
      },
    },
  },

  methods: {
    copySubject() {
      this.code2.val = this.code1.val;
      this.name2.val = this.name1.val;
      this.type2.val = this.type1.val;
      this.unit2.val = this.unit1.val;
      this.hasChanged2 = true;
    },
    setupForm() {
      const subject1 = this.$store.getters[
        "subjects/getSubjectByYearAndOffLink"
      ]({ year: this.minYear, offLink: this.offLink });

      if (subject1) {
        // UserInput
        this.id1 = subject1.id;
        this.code1.val = subject1.code;
        this.name1.val = subject1.name;
        this.type1.val = subject1.type;
        this.unit1.val = subject1.unit;
        this.condition.val = subject1.condition;
        this.majorId.val = subject1.majorId;
      } else {
        this.id1 = null;
        this.code1.val = null;
        this.name1.val = null;
        this.type1.val = null;
        this.unit1.val = null;
      }

      const subject2 = this.$store.getters[
        "subjects/getSubjectByYearAndOffLink"
      ]({ year: this.maxYear, offLink: this.offLink });

      if (subject2) {
        // UserInput
        this.id2 = subject2.id;
        this.code2.val = subject2.code;
        this.name2.val = subject2.name;
        this.type2.val = subject2.type;
        this.unit2.val = subject2.unit;
        this.condition.val = subject2.condition;
        this.majorId.val = subject2.majorId;
      } else {
        this.id2 = null;
        this.code2.val = null;
        this.name2.val = null;
        this.type2.val = null;
        this.unit2.val = null;
      }

      if (!subject1 && !subject2) {
        this.condition.val = null;
        this.majorId.val = null;
      }
    },

    async submitData() {
      // IF Data1 has changed
      if (this.hasChanged1 && !this.validEmpty1) {
        const submittedData1 = {
          majorId: this.majorId.val,
          type: this.type1.val,
          name: this.name1.val,
          code: this.code1.val,
          unit: this.unit1.val,
          year: this.minYear,
          offLink: this.offLink,
          condition: this.condition.val,
        };
        // If the subject is exist in DB
        if (this.id1) {
          submittedData1.id = this.id1;
          try {
            if (this.logVisible) {
              console.log("update1");
            }

            await this.$store.dispatch(
              "subjects/updateSubject",
              submittedData1
            );
          } catch (e) {
            this.error = e.message;
          }
        } else {
          // If the subject is new
          try {
            if (this.logVisible) {
              console.log("add1");
            }

            await this.$store.dispatch("subjects/addSubject", submittedData1);
          } catch (e) {
            this.error = e.message;
          }
        }
      }

      // IF Data2 has changed
      if (this.hasChanged2 && !this.validEmpty2) {
        const submittedData2 = {
          majorId: this.majorId.val,
          type: this.type2.val,
          name: this.name2.val,
          code: this.code2.val,
          unit: this.unit2.val,
          year: this.maxYear,
          offLink: this.offLink,
          condition: this.condition.val,
        };
        // If the subject is exist in DB
        if (this.id2) {
          if (this.logVisible) {
            console.log("update2");
          }

          submittedData2.id = this.id2;
          try {
            await this.$store.dispatch(
              "subjects/updateSubject",
              submittedData2
            );
          } catch (e) {
            this.error = e.message;
          }
        } else {
          // If the subject is new
          if (this.logVisible) {
            console.log("add2");
          }

          try {
            await this.$store.dispatch("subjects/addSubject", submittedData2);
          } catch (e) {
            this.error = e.message;
          }
        }
      }

      this.hasChanged1 = false;
      this.hasChanged2 = false;
    },

    isFormValid() {
      this.formValid = true;
      this.condition.isValid = true;
      this.majorId.isValid = true;

      this.code1.isValid = true;
      this.name1.isValid = true;
      this.type1.isValid = true;
      this.unit1.isValid = true;

      this.code2.isValid = true;
      this.name2.isValid = true;
      this.type2.isValid = true;
      this.unit2.isValid = true;

      // 1
      if (
        !this.code1.val &&
        !this.name1.val &&
        !this.type1.val &&
        !this.unit1.val
      ) {
        this.validEmpty1 = true;
      } else {
        if (!this.code1.val) {
          this.formValid = false;
          this.code1.isValid = false;
        }
        if (!this.name1.val) {
          this.formValid = false;
          this.name1.isValid = false;
        }
        if (!this.type1.val) {
          this.formValid = false;
          this.type1.isValid = false;
        }
        if (!this.unit1.val) {
          this.formValid = false;
          this.unit1.isValid = false;
        }
        // console.log(Number.isInteger(this.unit1.val));
        // console.log(this.unit1.val);
        if (!Number.isInteger(this.unit1.val) || this.unit1.val < 0) {
          this.formValid = false;
          this.unit1.isValid = false;
        }
      }

      // 2

      if (
        !this.code2.val &&
        !this.name2.val &&
        !this.type2.val &&
        !this.unit2.val
      ) {
        this.validEmpty2 = true;
      } else {
        if (!this.code2.val) {
          this.formValid = false;
          this.code2.isValid = false;
        }
        if (!this.name2.val) {
          this.formValid = false;
          this.name2.isValid = false;
        }
        if (!this.type2.val) {
          this.formValid = false;
          this.type2.isValid = false;
        }
        if (!this.unit2.val) {
          this.formValid = false;
          this.unit2.isValid = false;
        }
        if (!Number.isInteger(this.unit2.val) || this.unit2.val < 0) {
          this.formValid = false;
          this.unit2.isValid = false;
        }
      }

      if (!this.validEmpty2 || !this.validEmpty1) {
        if (
          !this.condition.val ||
          !Number.isInteger(this.condition.val) ||
          this.condition.val < 0
        ) {
          this.formValid = false;
          this.condition.isValid = false;
        }
        if (!this.majorId.val && this.majorId.val != 0) {
          this.formValid = false;
          this.majorId.isValid = false;
        }
      }
    },
    tryDeleteSubject() {
      if (this.id2) {
        this.delSubBoxVsb = true;
      } else {
        this.code2.val = null;
        this.name2.val = null;
        this.type2.val = null;
        this.unit2.val = null;
      }
    },

    async deleteSubject() {
      try {
        await this.$store.dispatch("subjects/deleteSubject", {
          id: this.id2,
        });
        this.code2.val = null;
        this.name2.val = null;
        this.type2.val = null;
        this.unit2.val = null;
        this.id2 = null;
      } catch (e) {
        this.error = e.message;
      }
    },
  },
};
</script>
