export default {
  setFaq(state, payload) {
    state.faqs = payload;
  },
  addFaq(state, payload) {
    state.faqs.push(payload);
  },
  updateFaq(state, newFaq) {
    const faqs = state.faqs;
    const selectedFaq = faqs.find((s) => s.id === newFaq.id);
    const index = faqs.indexOf(selectedFaq);

    if (index !== -1) {
      faqs.splice(index, 1, newFaq);
    }
  },
  deleteFaq(state, payload) {
    const faqs = state.faqs;
    const selectedFaq = faqs.find((s) => s.id === payload.id);
    const index = faqs.indexOf(selectedFaq);

    if (index !== -1) {
      faqs.splice(index, 1);
    }
  },
};
