<template>
  <!-- This is an example component -->
  <div class="h-screen font-kanit login bg-cover">
    <div
      class="container mx-auto h-full flex flex-1 justify-center items-center"
    >
      <div class="w-full max-w-lg">
        <div class="flex justify-center">
          <form
            class="w-11/12 sm:w-4/5  m-4 p-10 bg-white bg-opacity-75 rounded shadow-xl"
          >
            <p class="text-gray-500 font-medium text-center text-xl ">
              ระบบโควตาสำหรับนักศึกษา
            </p>
            <div class="mt-4">
              <label class="block text-sm text-gray-500 " for="Username"
                >เลขทะเบียน นศ.</label
              >
              <input
                class="form-control"
                type="text"
                id="email"
                placeholder="70XXXXXXXX"
                @blur="v$.username.$touch"
                v-model="username"
                required
              />
            </div>
            <span
              class="errors"
              v-for="error of v$.username.$errors"
              :key="error.$uid"
              ><div>{{ error.$message }}</div>
            </span>
            <div class="mt-4">
              <label class="block  text-sm text-gray-500 ">Password</label>
              <input
                class="form-control"
                type="password"
                id="password"
                placeholder="Password"
                @blur="v$.password.$touch"
                v-model="password"
                required
              />
            </div>
            <span
              class="errors"
              v-for="error of v$.password.$errors"
              :key="error.$uid"
              ><div>{{ error.$message }}</div>
            </span>

            <div class="my-8 items-center flex justify-center">
              <!-- <base-button
                type="submit"
                class="px-8 font-normal text-xl py-1.5"
              >
                Login
              </base-button> -->
              <!-- <router-link :to="{ name: 'std-quota-request' }"></router-link> -->
              <base-button
                type="submit"
                class="px-8 font-normal text-xl py-1.5"
                @click.prevent="stdLogin()"
              >
                Login
              </base-button>
            </div>
            <div class="flex justify-center ">
              <div class="border-solid  border-b-1 border-gray-300 w-2/3"></div>
            </div>

            <!-- <div class="text-center">
              <a
                class="inline-block right-0 align-baseline font-light text-sm text-500 hover:text-red-400"
              >
                Criar uma conta
              </a>
            </div> -->
            <div
              class="flex pt-2 space-x-4 justify-center font-light text-sm text-blue-550"
            >
              <router-link :to="{ name: 'register' }">
                <p>สมัครสมาชิก</p>
              </router-link>
              <span>|</span>
              <router-link :to="{ name: 'forget-password' }">
                <p>ลืมรหัสผ่าน</p>
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <toast
    :show="!!error"
    mode="error"
    :duration="5000"
    @close="closeError"
    :message="error"
  >
  </toast>
  <spinner-dialog :show="isLoading"></spinner-dialog>
</template>

<script>
import { db, auth } from "../../js/firebaseDb.js";
import SpinnerDialog from "../../components/UI/SpinnerDialog.vue";
import FormValidateMixin from "../../mixins/formValidation.js";
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  helpers,
  integer,
} from "@vuelidate/validators";

export default {
  mixins: [FormValidateMixin], // validStdNo() ,
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  components: { SpinnerDialog },
  data() {
    return {
      username: null,
      password: null,

      error: null,
      isLoading: false,

      formValid: true,
    };
  },
  validations() {
    return {
      username: {
        integer,
        required,
        validStdNo: helpers.withMessage(
          "รูปแบบเลขนักศึกษาไม่ถูกต้อง",
          this.validStdNo
        ),
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      password: { required, minLengthValue: minLength(6) },
    };
  },
  // computed: {
  //   uuid() {
  //     return uuidv4();
  //   },
  // },
  methods: {
    // not use
    isFormValid() {
      this.formValid = true;

      this.checkUsername();

      this.checkPassword();
    },

    // not use
    checkUsername() {
      this.username.error = null;
      if (!this.username.val || this.username.val == "") {
        this.formValid = false;
        this.username.error = "เลขทะเบียนนศ./Username ไม่สามารถเว้นว่างได้";
      } else if (!this.validStdNo(this.username.val)) {
        this.username.error = "รูปแบบเลขนักศึกษาไม่ถูกต้อง ";
        this.formValid = false;
      }
    },
    // not use
    checkPassword() {
      this.password.error = null;
      if (!this.password.val || this.password.val == "") {
        this.formValid = false;
        this.password.error = "password ไม่สามารถเว้นว่างได้";
      }
    },

    async stdLogin() {
      // // not use
      // this.isFormValid();
      // if (!this.formValid) {
      //   return;
      // }

      this.v$.$touch();
      if (this.v$.$error) return;

      this.isLoading = true;

      // clear basket data
      await this.$store.dispatch("quota/clearBskQuota");

      // check if ... is system allowed student to login
      const scheduleRef = db.collection("schedule");
      const scheduleDocs = await scheduleRef
        .where("isEnd", "==", false)
        .get()
        .catch((error) => {
          this.isLoading = false;
          console.error("Error getting documents: ", error);
        });

      if (scheduleDocs.size > 1) {
        this.isLoading = false;
        this.error = "found more than 1 open schedule in database";
        return;
      }

      const scheduleArr = scheduleDocs.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      const scheduleDoc = scheduleArr[0];

      if (!scheduleDoc.studentLogin) {
        this.isLoading = false;
        this.error =
          "ณ เวลานี้ยังไม่เปิดนักศึกษาเข้าใช้ระบบ โปรดเข้าระบบใหม่อีกครั้ง ในเวลาที่ทางคณะกำหนด";
        return;
      }

      // mapping user by users collection
      const userRef = db.collection("users");
      const userDocs = await userRef
        .where("username", "==", this.username)
        .get()
        .catch((error) => {
          this.isLoading = false;
          console.error("Error getting documents: ", error);
        });

      if (userDocs.size === 0) {
        this.isLoading = false;
        this.error = "Username not found";
        return;
      }

      if (userDocs.size > 1) {
        this.isLoading = false;
        this.error = "found user more than 1 in Database";
        return;
      }

      const userArr = userDocs.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      const userDoc = userArr[0];

      // is user is admin?
      if (userDoc.isAdmin) {
        this.isLoading = false;
        this.error = "username is not allowed in student page";
        return;
      }

      // everything ok .. let log user in to the system
      auth
        .signInWithEmailAndPassword(userDoc.email, this.password)
        .then(async () => {
          // set User Login ID
          this.$store.dispatch("setLoginId", userDoc.id);

          // route user to student quota page
          this.$router.replace({
            name: "std-quota-request",
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.error = error.message;
        });
    },
    closeError() {
      this.error = null;
    },
  },
};
</script>

<style>
.login {
  /*
    background: url('https://tailwindadmin.netlify.app/dist/images/login-new.jpeg');
  */
  /* background: url("../../assets/ship.png");
  background-repeat: no-repeat;
  background-size: cover; */
  @apply bg-blue-100;
}
</style>
