import { sortNumber } from "../../../js/utility.js";
export default {
  schedule(state) {
    //schedule
    return state.schedule;
  },
  scheduleList(state) {
    let scheduleList = sortNumber(state.scheduleList, "semester");
    scheduleList = sortNumber(scheduleList, "year");
    return scheduleList;
  },
  scheduleYearList(state) {
    const theSet = new Set();

    for (const schedule of state.scheduleList) {
      theSet.add(schedule.year);
    }

    return [...theSet];
  },
  scheduleRule(state) {
    return state.scheduleRule;
  },
  // not usd
  // currentScheduleRuleBy: (state, getters) => (payload) => {
  //     const allSchedule = getters.currentScheduleRule;
  //     const mode = payload.mode || "all";
  //     const value = payload.value;

  //     if (!allSchedule) {
  //         return null;
  //     }
  //     if (mode === "userRole") {
  //         return allSchedule.find((schedule) => schedule.effectUser === value);
  //     } else if (mode === "all") {
  //         return allSchedule;
  //     } else {
  //         console.error(`this ${mode} mode is not support`);
  //     }
  // },

  currentTime(state) {
    // return new Date().getTime();
    return state.serverTime;
  },
  currentScheduleRuleStudent(state, getters) {
    const allRule = getters.scheduleRule;
    const currentTime = getters.currentTime;
    const currentRule = allRule.find(
      (theRule) =>
        theRule.close.seconds * 1000 > currentTime &&
        currentTime > theRule.open.seconds * 1000 &&
        theRule.effectUser === "student"
    );
    return currentRule;
  },
  currentScheduleRuleStaff(state, getters) {
    const allRule = getters.scheduleRule;
    const currentTime = getters.currentTime;
    const currentRule = allRule.find(
      (theRule) =>
        theRule.close.seconds * 1000 > currentTime &&
        currentTime > theRule.open.seconds * 1000 &&
        theRule.effectUser === "staff"
    );
    return currentRule;
  },
  currentScheduleRule(state, getters) {
    const allRule = getters.scheduleRule;
    const currentTime = getters.currentTime;
    const currentRule = allRule.filter(
      (theRule) =>
        theRule.close.seconds * 1000 > currentTime &&
        currentTime > theRule.open.seconds * 1000
    );
    return currentRule;
  },

  fSemYear(state, getters) {
    //fSemYear
    const formattedStr =
      getters.schedule.semester.toString() +
      "_" +
      getters.schedule.year.toString();
    return formattedStr;
  },
  scheduleCustomRule(state) {
    return state.customRule;
  },
};
