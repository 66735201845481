<template>
  this is index mgmt
  <base-button @click="genData()">gen</base-button>
  <base-button @click="getData()">get</base-button>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import { db } from "../../js/firebaseDb.js";
import { v4 as uuidv4 } from "uuid";
import _ from "underscore";
export default {
  methods: {
    async genData() {
      for (let i = 0; i < 10; i++) {
        console.log(i + "/10");
        const batch = db.batch();
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        for (let i = 0; i < 400; i++) {
          const reqQuotaRef = db.collection(`quota_request_1_2565`).doc();
          const quotaId = uuidv4();
          // const secId = uuidv4();
          const userId = uuidv4();
          const stdCode = uuidv4();
          const offLink = uuidv4();

          batch.set(reqQuotaRef, {
            userId: userId,
            stdCode: i % 10 === 0 ? "59026666666" : stdCode,
            stdFName: uuidv4(),
            stdLName: uuidv4(),
            stdTName: uuidv4(),
            stdMajor: Math.floor(Math.random() * 10),
            stdProgram: "undergrad",
            secId: i % 10 === 0 ? "0010" : Math.floor(Math.random() * 10000),
            //
            subCode: uuidv4(),
            subName: uuidv4(),
            subYear: 2558,
            group: Math.floor(Math.random() * 10),
            campus: _.sample(["R", "T"]),
            //
            offLink: i % 10 === 0 ? "ooooooooooooo" : offLink,
            quotaId: i % 10 === 0 ? "qqqqqqqqqqqqq" : quotaId,
            reqDate: timestamp,
            reqQutTime: Math.floor(Math.random() * 6),
            reqResult: _.sample(["pending", "accept", "reject", "reserve"]),
          });
        }

        await batch.commit();
      }
      alert("gen success");
    },
    async getDataBak() {
      // query 1
      const quotaReqRef = db.collection(`quota_request_1_2565`);
      const quotaReqDoc = await quotaReqRef
        .where("quotaId", "==", "qqqqqqqqqqqqq")
        .where("reqQutTime", "==", 2)
        .where("reqResult", "in", ["pending"])
        .get()
        .catch((error) => {
          throw error;
        });
      console.log(quotaReqDoc);
      console.log("get 1 ok");
      const theArray = quotaReqDoc.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      console.log(theArray);

      // query 2
      const quotaReqRef2 = db.collection(`quota_request_1_2565`);
      const quotaReqDoc2 = await quotaReqRef2
        .where("secId", "==", "0010")
        .where("stdCode", "==", "59026666666")
        .where("reqResult", "in", ["accept"])
        .get()
        .catch((error) => {
          throw error;
        });
      const theArray2 = quotaReqDoc2.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      console.log(quotaReqDoc2);
      console.log("get 2 ok");
      console.log(theArray2);

      // query 3
      const quotaReqRef3 = db.collection(`quota_request_1_2565`);
      const quotaReqDoc3 = await quotaReqRef3
        .where("offLink", "==", "ooooooooooooo")
        .where("stdCode", "==", "59026666666")
        .where("reqResult", "in", ["pending"])
        .get()
        .catch((error) => {
          throw error;
        });
      const theArray3 = quotaReqDoc3.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      console.log(quotaReqDoc3);
      console.log("get 3 ok");
      console.log(theArray3);

      // query 4

      const quotaReqRef4 = db.collection(`quota_request_1_2565`);
      const quotaReqDocs4 = await quotaReqRef4
        .where("quotaId", "==", "ruULFNikaejJbHNrE1Gv")
        .orderBy("reqDate")
        .get()
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });
      const theArray4 = quotaReqDocs4.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      console.log(quotaReqDocs4);
      console.log("get 4 ok");
      console.log(theArray4);

      // query 5
      // quota_2_2565
      const quotaRef5 = db.collection(`quota_1_2565`);
      const quotaDocs5 = await quotaRef5.get().catch((error) => {
        console.error("Error getting documents: ", error);
      });
      const theArray5_1 = quotaDocs5.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      for (const theQuota of theArray5_1) {
        const quotaReqRef5 = db.collection(`quota_request_1_2565`);
        const quotaReqDocs5 = await quotaReqRef5
          .where("quotaId", "==", theQuota.id)
          .where("reqQutTime", "==", 5)
          .where("reqResult", "in", ["reserve", "accept", "reject"])
          .orderBy("reqDate")
          .get()
          .catch((error) => {
            console.error("Error getting documents: ", error);
          });
        const theArray5 = quotaReqDocs5.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
        console.log(quotaReqDocs5);
        console.log("get 5 ok");
        console.log(theArray5);
      }
    },

    async getData() {
      // query 4 - OK (getResStatus)
      const quotaReqRef4 = db.collection(`quota_request_1_2565`);
      const quotaReqDocs4 = await quotaReqRef4
        .where("quotaId", "==", "111bb522-aeed-4f57-955e-6c08f18cf1a1")
        // .orderBy("reqDate")
        .get()
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });
      let theArray4 = quotaReqDocs4.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      theArray4 = theArray4.sort(
        (objA, objB) =>
          Number(objA.reqDate.toDate()) - Number(objB.reqDate.toDate())
      );
      console.log(quotaReqDocs4);
      console.log("get 4 ok");
      console.log(theArray4);

      // // OK (processOnlineQuota)
      // const quotaReqRef5 = db.collection(`quota_request_1_2565`);
      // const quotaReqDocs5 = await quotaReqRef5
      //   .where("quotaId", "==", "1d01c69e-ad71-40e8-8992-91bbfcb8ee68")
      //   .where("reqQutTime", "==", 2)
      //   .where("reqResult", "in", ["reserve", "accept", "reject"])
      //   .orderBy("reqDate")
      //   .get()
      //   .catch((error) => {
      //     console.error("Error getting documents: ", error);
      //   });
      // const theArray5 = quotaReqDocs5.docs.map((doc) => {
      //   return {
      //     ...doc.data(),
      //     id: doc.id,
      //   };
      // });
      // console.log(quotaReqDocs5);
      // console.log("get 5 ok");
      // console.log(theArray5);
      // // NOT ERROR (getAllReqQuota)
      // const quotaRef = db.collection(`quota_request_1_2565`);
      // const quotaDocs = await quotaRef
      //   .where("reqResult", "==", "accept")
      //   .where("campus", "==", "T")
      //   .where("reqQutTime", "in", [1, 2, 3])
      //   .get()
      //   .catch((error) => {
      //     console.error("Error getting documents: ", error);
      //   });
      // const theArray = quotaDocs.docs.map((doc) => {
      //   return {
      //     ...doc.data(),
      //     id: doc.id,
      //   };
      // });
      // console.log(quotaDocs);
      // console.log("get 1 ok");
      // console.log(theArray);
      // // NOT ERROR 5 processBatchQuota,undoProcessQuota
      // const quotaReqRef5 = db.collection(`quota_request_1_2565`);
      // const quotaReqDoc5 = await quotaReqRef5
      //   .where("quotaId", "==", "1d01c69e-ad71-40e8-8992-91bbfcb8ee68")
      //   .where("reqQutTime", "==", 2)
      //   .where("reqResult", "in", ["pending", "accept", "reject"])
      //   // // .orderBy("reqResult")
      //   .get()
      //   .catch((error) => {
      //     console.error("Error getting documents: ", error);
      //   });
      // const theArray5 = quotaReqDoc5.docs.map((doc) => {
      //   return {
      //     ...doc.data(),
      //     id: doc.id,
      //   };
      // });
      // console.log(quotaReqDoc5);
      // console.log("get 5 ok");
      // console.log(theArray5);
      // // NOT ERROR ProcessQuotaByAcceptedQuota
      // const quotaReqRef6 = db.collection(`quota_request_1_2565`);
      // const quotaReqDocs6 = await quotaReqRef6
      //   .where("stdCode", "==", "59026666666")
      //   .where("reqResult", "==", "accept")
      //   .get()
      //   .catch((error) => {
      //     console.error("Error getting documents: ", error);
      //   });
      // const theArray6 = quotaReqDocs6.docs.map((doc) => {
      //   return {
      //     ...doc.data(),
      //     id: doc.id,
      //   };
      // });
      // console.log(quotaReqDocs6);
      // console.log("get 6 ok");
      // console.log(theArray6);
    },
  },
};
</script>
