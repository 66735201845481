import { isLogVisible } from "../../../js/debug.js";
import { db } from "../../../js/firebaseDb";
// import firebase from "firebase/app";
// import "firebase/firestore";

export default {
    async setBasket(context) {
        // prepare data
        const basketsRef = db.collection(`baskets`);
        const basketDocs = await basketsRef.get().catch((error) => {
            if (isLogVisible()) {
                console.error("Error getting documents: ", error);
            }
        });

        if (basketDocs.empty) {
            context.commit("setStaff", []);
            const error = new Error("No such document!");
            if (isLogVisible()) {
                console.error(error);
            }

            throw error;
        }

        const basketArr = basketDocs.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
            };
        });

        context.commit("setStaff", basketArr);
    },
};