<template>
  <div class="flex justify-center" v-if="!userInfo">
    <base-spinner></base-spinner>
  </div>
  <div
    class="bg-gray-50 py-4 px-6 flex justify-between items-center font-body no-print"
    v-else
  >
    <div class="flex justify-start items-center">
      <img class="w-48 mr-5 lg:mr-10" src="../../../assets/tbs_logo.png" />
      <div class="flex space-x-4 lg:space-x-10">
        <div class="relative ">
          <button
            @click="dropdownOpen = dropdownOpen === 'quota' ? null : 'quota'"
            class="text-blue-550"
          >
            จัดการโควตา
          </button>
          <div
            v-if="dropdownOpen === 'quota'"
            @click="dropdownOpen = null"
            class="fixed inset-0 h-full w-full z-10"
          ></div>
          <div
            v-if="dropdownOpen === 'quota'"
            class="absolute mt-2 bg-white rounded-md shadow-lg overflow-hidden z-20 w-64"
          >
            <div class="py-2" @click="dropdownOpen = null">
              <router-link
                v-if="isSudo"
                :to="{ name: 'schedule-detail' }"
                class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  กำหนดการ
                </p>
              </router-link>

              <router-link
                v-if="isSudo"
                :to="{ name: 'subjects-list' }"
                class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  รายวิชา
                </p>
              </router-link>
              <router-link
                :to="{ name: 'quota-list' }"
                class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  จำนวนรับ
                </p>
              </router-link>
              <router-link
                v-if="staffCanEditPetition"
                :to="{ name: 'petition' }"
                class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  จัดการคำร้อง
                </p>
              </router-link>
              <!-- <router-link
                :to="{ name: 'basket-list' }"
                class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  ตะกร้าวิชา
                </p>
              </router-link> -->
              <router-link
                v-if="isSudo"
                :to="{ name: 'section' }"
                class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  Section
                </p>
              </router-link>

              <router-link
                v-if="isSudo"
                :to="{ name: 'condition' }"
                class="flex items-center px-4 py-3 hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  เงื่อนไข
                </p>
              </router-link>
            </div>
            <!-- <a
              href="#"
              class="block bg-gray-800 text-white text-center font-bold py-2"
              >อื่นๆ</a
            > -->
          </div>
        </div>
        <div>
          <button
            @click="
              dropdownOpen = dropdownOpen === 'dataMgmt' ? null : 'dataMgmt'
            "
            class="text-blue-550"
          >
            จัดการข้อมูล
          </button>
          <div
            v-if="dropdownOpen === 'dataMgmt'"
            @click="dropdownOpen = null"
            class="fixed inset-0 h-full w-full z-10"
          ></div>
          <div
            v-if="dropdownOpen === 'dataMgmt'"
            class="absolute mt-2 bg-white rounded-md shadow-lg overflow-hidden z-20 w-64"
          >
            <div class="py-2" @click="dropdownOpen = null">
              <router-link
                :to="{ name: 'admin-std-search' }"
                class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  นักศึกษา
                </p>
              </router-link>

              <router-link
                v-if="isSudo"
                :to="{ name: 'staff-list' }"
                class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  เจ้าหน้าที่
                </p>
              </router-link>

              <router-link
                v-if="isSudo"
                :to="{ name: 'instructor' }"
                class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  อาจารย์
                </p>
              </router-link>
              <router-link
                v-if="isSudo"
                :to="{ name: 'major-list' }"
                class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  ภาควิชา
                </p>
              </router-link>
              <router-link
                v-if="isSudo"
                :to="{ name: 'faq' }"
                class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  FAQ
                </p>
              </router-link>
              <router-link
                v-if="isSudo"
                :to="{ name: 'status-list' }"
                class="flex items-center px-4 py-3 hover:bg-gray-100 -mx-2"
              >
                <p class="text-gray-500 text-base mx-2">
                  สถานภาพ
                </p>
              </router-link>
            </div>
            <!-- <a
              href="#"
              class="block bg-gray-800 text-white text-center font-bold py-2"
              >อื่นๆ</a
            > -->
          </div>
        </div>
        <div>
          <router-link :to="{ name: 'quota-process' }" class="text-blue-550">
            ประมวลผล
          </router-link>
        </div>
        <div>
          <router-link
            :to="{ name: 'report-student-info' }"
            class="text-blue-550"
          >
            รายงาน
          </router-link>
        </div>
        <!-- <div v-if="isSudo">
          <button @click="dropdownOpen = !dropdownOpen" class="text-blue-550">
            Log Book
          </button>
        </div> -->
      </div>
    </div>
    <div class="flex ">
      <div class="relative">
        <button @click="dropdownOpen = dropdownOpen === 'user' ? null : 'user'">
          <div class="flex text-gray-500 items-center">
            <span>{{ userInfo.username }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 ml-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </button>
        <div
          v-if="dropdownOpen === 'user'"
          @click="dropdownOpen = null"
          class="fixed inset-0 h-full w-full z-10"
        ></div>
        <div
          v-if="dropdownOpen === 'user'"
          class="absolute right-0 mt-2   bg-white rounded-md shadow-lg overflow-hidden z-20 w-64"
        >
          <div class="py-2 max-w-64">
            <a
              href="#"
              class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clip-rule="evenodd"
                />
              </svg>
              <p class="text-gray-500 text-base mx-2">
                {{ userInfo.username }} | {{ userInfo.role }}
              </p>
            </a>
            <router-link
              :to="{ name: 'select-program' }"
              class="flex items-center px-4 py-3  hover:bg-gray-100 -mx-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z"
                />
              </svg>
              <p class="text-gray-500 text-base mx-2">
                {{ program.name }}
              </p>
            </router-link>
            <a
              href="#"
              class="flex items-center px-4 py-3 hover:bg-gray-100 -mx-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"
                />
              </svg>
              <p class="text-gray-500 text-base mx-2">
                รอบ {{ qRoundToText(schedule.qRound) }} เทอม
                {{ schedule.semester }} ปี
                {{ schedule.year }}
              </p>
            </a>
            <div
              class="flex items-center px-4 py-3 hover:bg-gray-100 -mx-2"
              @click="logoutAdmin()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              <button @click.prevent>
                <p class="text-gray-500 text-base mx-2">
                  ออกจากระบบ
                </p>
              </button>
            </div>
          </div>
          <!-- <a
            href="#"
            class="block bg-gray-800 text-white text-center font-bold py-2"
            >อื่นๆ</a
          > -->
        </div>
      </div>
    </div>
  </div>
  <toast
    :show="!!error"
    mode="error"
    @close="error = null"
    fixed
    :message="error"
  >
  </toast>
</template>

<script>
import AdminMixin from "../../../mixins/admin.js";
import StaffRuleMixin from "../../../mixins/staffRule.js";
import TextConverterMixin from "../../../mixins/textConverter.js";
export default {
  mixins: [AdminMixin, StaffRuleMixin, TextConverterMixin], //logoutAdmin,staffCanEditPetition
  data() {
    return {
      dropdownOpen: null,
      error: null,
    };
  },
  computed: {
    schedule() {
      return this.$store.getters["schedule"];
    },
    program() {
      return this.$store.getters["programs/getUserProgram"];
    },
    username() {
      return this.$store.getters["userId"];
    },
    userInfo() {
      return this.$store.getters["userInfo"];
    },
    isSudo() {
      return this.$store.getters["isSudo"];
    },
  },
  methods: {},
};
</script>
