<template>
  <teleport to="body">
    <div v-if="show" @click="tryClose" class="backdrop"></div>
    <dialog
      open
      v-if="show"
      class="font-body flex flex-col justify-center items-center"
    >
      <div><base-spinner></base-spinner></div>
      <div class="pt-4">
        <p class="text-2xl text-blue-500 pl-4">Loading . . .</p>
        <p class="text-xl pt-2 text-blue-500 pl-4" v-if="text">{{ text }}</p>
      </div>
    </dialog>
  </teleport>
</template>

<script>
import BaseSpinner from "./BaseSpinner.vue";
export default {
  components: { "base-spinner": BaseSpinner },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: String,
      required: false,
    },
  },
  emits: ["close"],
  methods: {
    tryClose() {
      if (this.fixed) {
        return;
      }
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  @apply bg-white opacity-80;
  z-index: 10;
}

dialog {
  position: fixed;
  top: 40vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: transparent;
}

header {
  @apply bg-primary-100 text-gray-800 w-full p-3;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
