<template>
  <tbody
    class="pt-10"
    v-for="majorOffLink in majorOffLinks"
    :key="majorOffLink.mId"
  >
    <tr class="bg-primary-200 text-white ">
      <th colspan="29" class="py-2 ">
        {{ majorOffLink.mName }}
        <!-- {{ majorOffLink }} -->
      </th>
    </tr>
    <!-- Quota Row -->
    <quota-row
      v-for="offLink in majorOffLink.offLinks"
      :secDetail="secDetail"
      :key="offLink"
      :offLink="offLink"
      :yearNo="yearNo"
      @edit="editQuota"
    ></quota-row>
  </tbody>
  <tr>
    <td colspan="28">
      <hr class="my-2" />
    </td>
  </tr>

  <!-- Edit Dialog -->
  <data-modal
    :show="editQuotaDia"
    :deleteBtn="true"
    title="แก้ไขข้อมูลโควตา"
    @close="closeQuotaDia"
    @delete="trydeleteQuota()"
    @submit="submitData"
  >
    <form @submit.prevent="submitData">
      <p class="text-sm leading-5 text-gray-500 pt-4 pb-2">
        ชื่อวิชา/รหัสวิชา
      </p>
      <p
        class="text-sm leading-5 text-gray-700"
        v-for="(subItem, index) in subNameCodeArr"
        :key="index"
      >
        {{ subItem.year }} - {{ subItem.code }} ({{ subItem.name }})
      </p>
      <div class=" mt-4" v-if="instructors">
        <label>
          อาจารย์
        </label>
        <Multiselect
          v-model="instructors"
          mode="tags"
          :close-on-select="false"
          :searchable="true"
          :create-option="false"
          :options="instructorsOption"
        />
      </div>
      <div class="grid grid-cols-4 mt-4">
        <div class="pr-2 col-span-2">
          <label>
            Sec
          </label>
          <search-input
            :options="sections"
            placeHolder=""
            :setValue="sec"
            @selectedValue="selectSec"
            textField="fullName"
            valueField="code"
            @blur="v$.sec.$touch"
            :disabled="hasQRequest"
            @mouseover="hasQRequest ? (showNotEditSec = true) : false"
            @mouseleave="showNotEditSec = false"
          ></search-input>
          <span class="errors" v-if="showNotEditSec"
            >ไม่สามารถแก้ไข section เนื่องจากมีนักศึกษา มาลงทะเบียน section
            ดังกล่าวแล้ว <br />
          </span>
          <span class="errors" v-for="error of v$.sec.$errors" :key="error.$uid"
            >{{ error.$message }} <br />
          </span>
        </div>
        <div class="pr-2 col-span-1">
          <label>
            Group
          </label>
          <select
            class="form-control"
            v-model="group"
            @blur="v$.group.$touch"
            :disabled="hasQRequest"
            @mouseover="hasQRequest ? (showNotEditGr = true) : false"
            @mouseleave="showNotEditGr = false"
          >
            <option disabled value="">Please select one</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
          </select>
          <span
            class="errors"
            v-for="error of v$.group.$errors"
            :key="error.$uid"
            >{{ error.$message }} <br />
          </span>
          <span class="errors" v-if="showNotEditGr"
            >ไม่สามารถแก้ไข group เนื่องจากมีนักศึกษา มาลงทะเบียน section
            ดังกล่าวแล้ว <br />
          </span>
        </div>
        <div class="col-span-1">
          <label>
            Campus
          </label>
          <input
            class="form-control"
            type="text"
            v-model.trim="campus"
            @blur="v$.campus.$touch"
            disabled
          />
        </div>
      </div>

      <!-- ครั้งที่ 1 -->
      <div class="mt-4">
        <div>
          <label class="text-base text-gray-600 font-bold">ครั้งที่ 1</label>
        </div>
        <div class="flex">
          <div class="pr-2">
            <label>
              จำนวนเปิดรับ
            </label>
            <input
              class="form-control"
              type="text"
              @blur="v$.q1Offer.$touch"
              v-model.number="q1Offer"
              :disabled="schedule.qRound !== 1"
            />
            <span
              class="errors"
              v-for="error of v$.q1Offer.$errors"
              :key="error.$uid"
              >{{ error.$message }} <br />
            </span>
          </div>
          <div class="pr-2">
            <label>
              จำนวนขอ
            </label>
            <input
              class="form-control"
              type="number"
              v-model.number="q1Request"
              disabled
            />
          </div>
          <div>
            <label>
              จำนวนที่ได้
            </label>
            <input
              class="form-control"
              type="number"
              v-model.number="q1Accept"
              disabled
            />
          </div>
        </div>
      </div>

      <!-- ครั้งที่ 2 -->
      <div class="mt-4">
        <div>
          <label class="text-base text-gray-600 font-bold">ครั้งที่ 2</label>
        </div>
        <div class="flex">
          <div class="pr-2">
            <label>
              จำนวนเปิดรับ
            </label>
            <input
              class="form-control"
              type="text"
              @blur="v$.q2Offer.$touch"
              v-model.number="q2Offer"
              :disabled="schedule.qRound !== 2"
            />
            <span
              class="errors"
              v-for="error of v$.q2Offer.$errors"
              :key="error.$uid"
              >{{ error.$message }} <br />
            </span>
          </div>
          <div class="pr-2">
            <label>
              จำนวนขอ
            </label>
            <input
              class="form-control"
              type="number"
              v-model.number="q2Request"
              disabled
            />
          </div>
          <div>
            <label>
              จำนวนที่ได้
            </label>
            <input
              class="form-control"
              type="number"
              v-model.number="q2Accept"
              disabled
            />
          </div>
        </div>
      </div>

      <!-- ครั้งที่ 3 -->
      <div class="mt-4">
        <div>
          <label class="text-base text-gray-600 font-bold">ครั้งที่ 3</label>
        </div>
        <div class="flex">
          <div class="pr-2">
            <label>
              จำนวนเปิดรับ
            </label>
            <input
              class="form-control"
              type="text"
              @blur="v$.q3Offer.$touch"
              v-model.number="q3Offer"
              :disabled="schedule.qRound !== 3"
            />
            <span
              class="errors"
              v-for="error of v$.q3Offer.$errors"
              :key="error.$uid"
              >{{ error.$message }} <br />
            </span>
          </div>
          <div class="pr-2">
            <label>
              จำนวนขอ
            </label>
            <input
              class="form-control"
              type="number"
              v-model.number="q3Request"
              disabled
            />
          </div>
          <div>
            <label>
              จำนวนที่ได้
            </label>
            <input
              class="form-control"
              type="number"
              v-model.number="q3Accept"
              disabled
            />
          </div>
        </div>
      </div>

      <!-- ครั้งที่ 4 -->
      <div class="mt-4">
        <div>
          <label class="text-base text-gray-600 font-bold">ครั้งที่ 4.1</label>
        </div>
        <div class="flex">
          <div class="pr-2">
            <label>
              จำนวนเปิดรับ
            </label>
            <input
              class="form-control"
              type="text"
              @blur="v$.q4Offer.$touch"
              v-model.number="q4Offer"
              :disabled="schedule.qRound !== 4"
            />
            <span
              class="errors"
              v-for="error of v$.q4Offer.$errors"
              :key="error.$uid"
              >{{ error.$message }} <br />
            </span>
          </div>
          <div class="pr-2">
            <label>
              จำนวนขอ
            </label>
            <input
              class="form-control"
              type="number"
              v-model.number="q4Request"
              disabled
            />
          </div>
          <div>
            <label>
              จำนวนที่ได้
            </label>
            <input
              class="form-control"
              type="number"
              v-model.number="q4Accept"
              disabled
            />
          </div>
        </div>
      </div>

      <!-- ครั้งที่ 5 -->
      <div class="mt-4">
        <div>
          <label class="text-base text-gray-600 font-bold">ครั้งที่ 4.2</label>
        </div>
        <div class="flex">
          <div class="pr-2">
            <label>
              จำนวนเปิดรับ
            </label>
            <input
              class="form-control"
              type="text"
              @blur="v$.q5Offer.$touch"
              v-model.number="q5Offer"
              :disabled="schedule.qRound !== 5"
            />
            <span
              class="errors"
              v-for="error of v$.q5Offer.$errors"
              :key="error.$uid"
              >{{ error.$message }} <br />
            </span>
          </div>
          <div class="pr-2">
            <label>
              จำนวนขอ
            </label>
            <input
              class="form-control"
              type="number"
              v-model.number="q5Request"
              disabled
            />
          </div>
          <div>
            <label>
              จำนวนที่ได้
            </label>
            <input
              class="form-control"
              type="number"
              v-model.number="q5Accept"
              disabled
            />
          </div>
        </div>
      </div>

      <div class="flex mt-4">
        <div class="pr-2">
          <label>
            ลงจริง
          </label>
          <input
            class="form-control"
            type="text"
            v-model.number="register"
            :disabled="!isSudo"
            @blur="v$.register.$touch"
          />
          <span
            class="errors"
            v-for="error of v$.register.$errors"
            :key="error.$uid"
            >{{ error.$message }} <br />
          </span>
        </div>
        <div class="pr-2 ">
          <label>
            หมายเหตุ
          </label>
          <input
            class="form-control"
            type="text"
            v-model.trim="remark"
            @blur="v$.remark.$touch"
          />
          <span
            class="errors"
            v-for="error of v$.remark.$errors"
            :key="error.$uid"
            >{{ error.$message }} <br />
          </span>
        </div>
        <div>
          <label>
            เงื่อนไข
          </label>
          <select
            class="form-control px-1"
            placeholder=""
            v-model.number="condition"
          >
            <option disabled value="">Please select one</option>
            <option v-for="cond in condCode" :value="cond" :key="cond">
              {{ cond }}
            </option>
          </select>
          <span
            class="errors"
            v-for="error of v$.condition.$errors"
            :key="error.$uid"
            >{{ error.$message }} <br />
          </span>
        </div>
      </div>
    </form>
  </data-modal>

  <confirm-modal
    :show="deleteQuotaDia"
    title="ยืนยันลบ Section นี้ ออกจากระบบ"
    @close="deleteQuotaDia = false"
    @confirm="deleteQuota()"
  >
  </confirm-modal>

  <toast
    :show="!!error"
    mode="error"
    :message="error"
    :duration="5000"
    @close="closeError"
    fixed
  ></toast>

  <toast
    :show="!formValid"
    mode="error"
    :duration="5000"
    @close="closeError"
    message="คุณกรอกข้อมูลโควตาไม่ครบถ้วน โปรดตรวจสอบและลองใหม่อีกครั้ง"
  ></toast>
  <!-- {{ majorOffLinks }} -->
</template>

<script>
// import SecItem from "./SecItem.vue";
import DataModal from "../../components/UI/DataModal.vue";
import FormValidation from "../../mixins/formValidation.js";
import QuotaRow from "./QuotaRow.vue";
import ConfirmModal from "../../components/UI/ConfirmModal.vue";
import SearchInput from "../../components/UI/SearchInput.vue";
import useVuelidate from "@vuelidate/core";
import { required, integer, minValue, helpers } from "@vuelidate/validators";
import Multiselect from "@vueform/multiselect";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mixins: [FormValidation], // isNumber(),
  components: { DataModal, QuotaRow, ConfirmModal, SearchInput, Multiselect },
  props: {
    yearNo: {
      required: true,
    },
    secDetail: {
      required: false,
      default: false,
    },
  },
  validations() {
    return {
      sec: {
        required,
      },
      group: {
        required,
      },
      q1Offer: {
        required,
        integer,
        minValueValue: minValue(0),
      },
      q2Offer: {
        requireRound2: helpers.withMessage(
          "คุณจำเป็นต้องใส่ข้อมูลรอบ 2 ",
          this.requireRound2
        ),
        integer,
        minValueValue: minValue(0),
      },
      q3Offer: {
        requireRound3: helpers.withMessage(
          "คุณจำเป็นต้องใส่ข้อมูลรอบ 3 ",
          this.requireRound3
        ),
        integer,
        minValueValue: minValue(0),
      },
      q4Offer: {
        requireRound4: helpers.withMessage(
          "คุณจำเป็นต้องใส่ข้อมูลรอบ 4.1 ",
          this.requireRound4
        ),
      },
      q5Offer: {
        requireRound5: helpers.withMessage(
          "คุณจำเป็นต้องใส่ข้อมูลรอบ 4.2 ",
          this.requireRound5
        ),
      },
      register: {
        integer,
        minValueValue: minValue(0),
      },
      remark: {},
      condition: {
        required,
      },
    };
  },

  data() {
    return {
      // UI
      editQuotaDia: false,
      formValid: true,
      editSuccess: false,
      deleteQuotaDia: false,
      error: null,

      // system
      offLink: null,
      quotaId: null,
      subName: null,
      subjectCode: null,
      q1Request: null,
      q1Accept: null,
      q2Request: null,
      q2Accept: null,
      q3Request: null,
      q3Accept: null,
      q4Request: null,
      q4Accept: null,
      q5Request: null,
      q5Accept: null,
      campus: null,
      condition: null,
      subNameCodeArr: [],
      instructors: null,

      // user
      sec: null,
      group: null,
      q1Offer: null,
      q2Offer: null,
      q3Offer: null,
      q4Offer: null,
      q5Offer: null,
      register: null,
      remark: null,

      showNotEditSec: false,
      showNotEditGr: false,
    };
  },
  computed: {
    isSudo() {
      return this.$store.getters["isSudo"];
    },
    condCode() {
      return this.$store.getters["conditions/condCode"];
    },
    sections() {
      return this.$store.getters["sections/fullName"];
    },
    majorOffLinks() {
      return this.$store.getters["quota/getMajorOffLinks"];
    },
    last4Year() {
      return this.$store.getters["years/getLast4Years"];
    },
    schedule() {
      return this.$store.getters.schedule;
    },
    instructorsList() {
      return this.$store.getters["instructors/fullName"];
    },
    instructorsOption() {
      const instructorsArr = this.instructorsList.map((doc) => {
        return {
          label: `${doc.prefix} ${doc.name} ${doc.surname}`,
          value: doc.id,
        };
      });
      return instructorsArr;
    },
    hasQRequest() {
      return (
        this.q1Request ||
        this.q2Request ||
        this.q3Request ||
        this.q4Request ||
        this.q5Request
      );
    },
  },
  methods: {
    requireRound2(value) {
      // apply varidator if round is current round
      if (this.schedule.qRound === 2) {
        return helpers.req(value);
      } else {
        return true;
      }
    },
    requireRound3(value) {
      // apply varidator if round is current round
      if (this.schedule.qRound === 3) {
        return helpers.req(value);
      } else {
        return true;
      }
    },
    requireRound4(value) {
      // apply varidator if round is current round
      if (this.schedule.qRound === 4) {
        return helpers.req(value);
      } else {
        return true;
      }
    },
    requireRound5(value) {
      // apply varidator if round is current round
      if (this.schedule.qRound === 5) {
        return helpers.req(value);
      } else {
        return true;
      }
    },
    editQuota(quotaId, offLink) {
      // set all
      this.offLink = offLink;

      const quotaData = this.$store.getters["quota/getQuotaById"](quotaId);

      // set subject name & code
      this.subNameCodeArr = [];
      for (const theYear of this.last4Year) {
        if (quotaData[`sub${theYear.name}`]) {
          this.subNameCodeArr.push(quotaData[`sub${theYear.name}`]);
        }
      }

      this.sec = quotaData.secId;
      this.group = quotaData.group;
      this.campus = quotaData.campus;
      this.q1Offer = quotaData.q1Offer;
      this.q2Offer = quotaData.q2Offer;
      this.q3Offer = quotaData.q3Offer;
      this.q4Offer = quotaData.q4Offer;
      this.q5Offer = quotaData.q5Offer;
      if (quotaData.instructors) {
        const instructorsArr = quotaData.instructors.map((doc) => {
          return doc.id;
        });
        this.instructors = instructorsArr;
      }

      // 2
      this.quotaId = quotaData.id;
      this.register = quotaData.regNo;
      this.remark = quotaData.remark;
      this.condition = quotaData.condition;
      this.q1Request = quotaData.q1Request;
      this.q1Accept = quotaData.q1Accept;
      this.q2Request = quotaData.q2Request;
      this.q2Accept = quotaData.q2Accept;
      this.q3Request = quotaData.q3Request;
      this.q3Accept = quotaData.q3Accept;

      this.editQuotaDia = true;
    },
    closeQuotaDia() {
      this.editQuotaDia = false;
    },
    trydeleteQuota() {
      if (this.hasQRequest) {
        this.error =
          "ไม่สามารถลบ section นี้ได้ เนื่องจากมีนักศึกษาขอโควตาเข้ามาแล้ว";
        return;
      }
      this.deleteQuotaDia = true;
    },
    async deleteQuota() {
      this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังลบ Section..",
      });
      const submittedData = {
        id: this.quotaId,
      };
      this.closeQuotaDia();
      await this.$store.dispatch("quota/deleteQuota", submittedData);
      await this.$store.dispatch("clearToast");
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "ลบ Section เสร็จสิ้น",
      });
    },

    selectSec(val) {
      this.sec = val;
    },
    closeError() {
      this.error = null;
      this.formValid = true;
    },
    async submitData() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังทำการอัพเดตโควตา",
      });
      const submittedData = {
        id: this.quotaId,
        secId: this.sec,
        group: this.group,
        campus: this.campus,
        q1Offer: this.q1Offer,
        q2Offer: this.q2Offer,
        q3Offer: this.q3Offer,
        q4Offer: this.q4Offer,
        q5Offer: this.q5Offer,
        regNo: this.register,
        remark: this.remark || "-",
        condition: this.condition,
        instructors: this.instructors,
      };

      this.editQuotaDia = false;
      await this.$store.dispatch("quota/updateQuota", submittedData);
      await this.$store.dispatch("clearToast");
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "อัพเดตโควตาสำเร็จ",
      });
    },
  },
};
</script>

<style scoped>
td {
  word-wrap: break-word;
  height: 1rem;
}
</style>
