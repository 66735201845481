import { isLogVisible } from '../js/debug.js';
export default {
  methods: {
    async loadYear(forceUpdate = false) {
      // this.isloading = true;
      try {
        if (isLogVisible()) {
          console.log('try fetch data');
          console.log(`program: ${this.program}`);
        }

        await this.$store.dispatch('years/setYear', {
          forceUpdate: forceUpdate,
          program: this.program,
        });
      } catch (e) {
        if (isLogVisible()) {
          console.log('error in loadYear');
        }

        this.error = e.message;
      }

      // this.isloading = false;
    },
    async loadSubject() {
      // this.isloading = true;
      try {
        await this.$store.dispatch('subjects/setSubject');
      } catch (e) {
        this.error = e.message;
      }

      // this.isloading = false;
    },
    async loadQuota(mode) {
      if (mode === 'student') {
        try {
          await this.$store.dispatch('quota/setQuotaStd');
        } catch (e) {
          console.error(e);
          this.error = e;
        }
      } else if (mode === 'admin') {
        try {
          await this.$store.dispatch('quota/setQuotaAdmin');
        } catch (e) {
          console.error(e);
          this.error = e;
        }
      } else {
        throw new Error('mode is not supported');
      }
    },
    async loadReqQuota(forceUpdate = false) {
      try {
        console.log('try fetch data');
        await this.$store.dispatch('quota/setReqQuota', {
          forceUpdate: forceUpdate,
        });
      } catch (e) {
        console.log('error in ReqQuota');
        this.error = e.message;
      }
    },
    async loadSchedule() {
      try {
        await this.$store.dispatch('setSchedule');
      } catch (e) {
        this.error = e;
      }
    },
    async loadConditions(forceUpdate = false) {
      try {
        await this.$store.dispatch('conditions/setConditions', {
          forceUpdate: forceUpdate,
        });
      } catch (e) {
        console.error('error in loadConditions');
        this.error = e.message;
      }
    },
    async loadSection() {
      try {
        await this.$store.dispatch('sections/setSection');
      } catch (e) {
        this.error = e;
      }
    },
    async loadStaff() {
      try {
        await this.$store.dispatch('staffs/setStaff');
      } catch (e) {
        this.error = e;
      }
    },
    async loadInstructor() {
      try {
        await this.$store.dispatch('instructors/setInstructor');
      } catch (e) {
        this.error = e.message;
      }
    },
    async loadFaq() {
      try {
        await this.$store.dispatch('faqs/setFaq');
      } catch (e) {
        this.error = e.message;
      }
    },
  },
};
