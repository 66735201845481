<template>
  <base-card class="mt-12">
    <div class="flex space-x-2">
      <div class="w-full">
        <label class="block  text-sm text-gray-500 ">สถานภาพดิม</label>
        <select
          class="form-control text-sm md:text-base"
          v-model.number="fromStatus"
        >
          <option :value="null">ทั้งหมด</option>
          <option
            v-for="status in stdStatusList"
            :value="status.id"
            :key="status.id"
          >
            {{ status.name }}
          </option>
        </select>
      </div>
      <div class="w-full">
        <label class="block  text-sm text-gray-500 ">สถานภาพใหม่</label>
        <select
          class="form-control text-sm md:text-base"
          v-model.number="toStatus"
        >
          <option :value="null">ทั้งหมด</option>
          <option
            v-for="status in stdStatusList"
            :value="status.id"
            :key="status.id"
          >
            {{ status.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex space-x-2 pt-4">
      <!-- <div class="w-full">
        <label class="block  text-sm text-gray-500 ">สาขาวิชาเอก</label>
        <select
          class="form-control text-sm md:text-base"
          v-model.number="stdMajor"
        >
          <option :value="null">ทั้งหมด</option>
          <option
            v-for="major in stdMajorList"
            :value="major.id"
            :key="major.id"
          >
            {{ major.name }}
          </option>
        </select>
      </div> -->
      <div class="w-full">
        <label class="block  text-sm text-gray-500 ">ปีการศึกษาที่ย้าย</label>
        <select
          class="form-control px-1"
          placeholder=""
          v-model.number="changeYear"
        >
          <option :value="null">ทั้งหมด</option>
          <option v-for="year in scheduleYearList" :value="year" :key="year">
            {{ year }}
          </option>
        </select>
      </div>
      <div class="w-full">
        <label class="block  text-sm text-gray-500 ">เทอม</label>
        <select
          class="form-control px-1"
          placeholder=""
          v-model.number="changeSemester"
        >
          <option :value="null">ทั้งหมด</option>
          <option :value="1">1</option>
          <option :value="2">2</option>
          <option :value="3">3</option>
        </select>
      </div>
    </div>

    <div class="w-full flex justify-end pt-5">
      <base-button class="px-10" @click="downloadStatusChange()"
        >ดาวน์โหลด</base-button
      >
    </div>
  </base-card>
  <!-- {{ scheduleYearList }} -->
</template>

<script>
import XLSX from "xlsx";
export default {
  computed: {
    scheduleYearList() {
      return this.$store.getters.scheduleYearList;
    },
    stdStatusList() {
      return this.$store.getters["students/status"];
    },
    stdMajorList() {
      return this.$store.getters["majors/majors"];
    },
  },
  data() {
    return {
      fromStatus: null,
      toStatus: null,
      // stdMajor: null,
      changeYear: null,
      changeSemester: null,
    };
  },
  methods: {
    async downloadStatusChange() {
      this.isLoading = true;
      const exportJson = await this.getStatusChangeJson();
      if (this.logVisible) {
        console.log(exportJson);
      }
      const dataWS = XLSX.utils.json_to_sheet(exportJson);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "edit_status_report.xlsx");
      this.isLoading = false;
    },
    async getStatusChangeJson() {
      try {
        let allStatusChange = await this.$store.dispatch(
          "adminStudents/getStatusChange",
          {
            changeYear: this.changeYear,
          }
        );

        if (this.fromStatus) {
          allStatusChange = allStatusChange.filter(
            (item) => item.from == this.fromStatus
          );
        }

        if (this.toStatus) {
          allStatusChange = allStatusChange.filter(
            (item) => item.to == this.toStatus
          );
        }

        if (this.changeSemester) {
          allStatusChange = allStatusChange.filter(
            (item) => item.semester == this.changeSemester
          );
        }

        const resAllStatusChange = allStatusChange.map((doc) => {
          return {
            ภาคการศึกษา: `${doc.semester}/${doc.year}`,
            เลขทะเบียน: doc.stdCode,
            "ชื่อ-นามสกุล": doc.stdName,
            สถานภาพเดิม: doc.fromText,
            สถานภาพใหม่: doc.toText,
            ผู้บันทึกข้อมูล: doc.stfId,
            วันที่บันทึกข้อมูล: doc.time.toDate(),
            หมายเหตุ: doc.remark,
          };
        });
        return resAllStatusChange;
      } catch (e) {
        this.error = e;
      }
    },
  },
};
</script>
