import { isLogVisible } from "../../../js/debug.js";
import { db } from "../../../js/firebaseDb";

export default {
    // this method is not used yet
    async setStudent(context) {
        console.log("start setStudent");

        const stdRef = db.collection(`students`);
        const response = await stdRef.get().catch((error) => {
            console.error("Error getting documents: ", error);
        });
        if (isLogVisible()) {
            console.log(response);
        }
        if (response.empty) {
            context.commit("clearStudent");
            const error = new Error("No such document!");
            if (isLogVisible()) {
                console.error(error);
            }
            throw error;
        }

        const students = [];
        response.forEach((doc) => {
            const newStd = {
                id: doc.id,
                birthday: doc.data().birthday,
                conAddress: doc.data().conAddress,
                email: doc.data().email,
                firstName: doc.data().firstName,
                gender: doc.data().gender,
                homAddress: doc.data().homAddress,
                lastName: doc.data().lastName,
                major: doc.data().major,
                nameTitle: doc.data().nameTitle,
                program: doc.data().program,
                stdCode: doc.data().stdCode,
                tel: doc.data().tel,
            };
            students.push(newStd);
        });

        if (isLogVisible()) {
            console.log(students);
        }

        context.commit("setStudent", students);
    },

    async setStatus(context) {
        // prepare data
        const statusRef = db.collection(`status`);

        await statusRef.onSnapshot((statusDocs) => {
            if (statusDocs.empty) {
                context.commit("setStatus", []);
                const error = new Error("No such document!");
                if (isLogVisible()) {
                    console.error(error);
                }

                throw error;
            }

            const statusArr = statusDocs.docs.map((doc) => {
                const payload = {
                    ...doc.data(),
                };

                // for compatible with old id pattern
                const pattern = /[\d+]/gi;
                const idText = String(doc.id);
                const idInt = idText.match(pattern);
                if (idInt.length === idText.length) {
                    payload["id"] = parseInt(doc.id);
                } else {
                    payload["id"] = doc.id;
                }
                return payload;
            });

            context.commit("setStatus", statusArr);
        });
    },

    async addStatus(context, payload) {
        const statusRef = db.collection(`status`).doc();
        const theData = {
            name: payload.name,
            status: payload.status,
        };
        // sent data to server
        await statusRef.set(theData);
    },

    async updateStatus(context, payload) {
        const statusRef = db.collection(`status`).doc(String(payload.id));
        const theData = {
            name: payload.name,
            status: payload.status,
        };
        await statusRef.update(theData);
    },
};