<template>
  <!-- Desktop -->
  <div
    class="bg-gray-50 py-4 px-6 hidden lg:flex justify-between  items-center font-kanit "
  >
    <!-- left Section -->
    <div class="flex items-center">
      <img class="w-48 mr-10 " src="../../../assets/tbs_logo.png" />

      <div class="flex space-x-10 ">
        <div>
          <router-link
            :to="{ name: 'std-quota-request' }"
            class="text-blue-550"
          >
            แจ้งความจำนง
          </router-link>
        </div>
        <div v-if="true">
          <router-link
            :to="{ name: 'std-quota-petition' }"
            class="text-blue-550"
          >
            ยื่นคำร้อง
          </router-link>
        </div>
      </div>
    </div>

    <!-- Right Section -->
    <div class="flex space-x-2">
      <div class="relative ">
        <button
          @click="dropdownOpen = dropdownOpen === 'stdInfo' ? null : 'stdInfo'"
          class="flex text-blue-550"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 mr-2 text-gray-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="inline">{{ nameNUsername }}</span>
        </button>
        <div
          v-if="dropdownOpen === 'stdInfo'"
          @click="dropdownOpen = null"
          class="fixed inset-0 h-full w-full z-10"
        ></div>
        <div
          v-if="dropdownOpen === 'stdInfo'"
          class="absolute bg-white rounded-md shadow-lg overflow-hidden z-20 w-64"
        >
          <div class="" @click="dropdownOpen = null">
            <router-link
              :to="{ name: 'std-info' }"
              class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
            >
              <p class="text-gray-500 text-base mx-2">
                ข้อมูลส่วนตัว
              </p>
            </router-link>

            <button
              @click="logoutStudent()"
              class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2 w-full"
            >
              <p class="text-gray-500 text-base mx-2">
                ออกจากระบบ
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile -->

  <div class="font-kanit  lg:hidden">
    <div class="bg-gray-50 py-4 px-6 flex  justify-between  items-center ">
      <!-- left Section -->
      <div class="flex items-center">
        <div class="">
          <button
            @click="isOpen = !isOpen"
            type="button"
            class="block text-gray-500 hover:text-white focus:text-white focus:outline-none"
          >
            <svg class="h-8 w-8 fill-current" viewBox="0 0 24 24">
              <path
                v-if="isOpen"
                class="text-gray-500"
                fill-rule="evenodd"
                d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
              />
              <path
                v-if="!isOpen"
                class="text-gray-500"
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div>
        <img
          class="w-48 mr-5 lg:mr-10 lg:hidden"
          src="../../../assets/tbs_logo.png"
        />
      </div>

      <!-- Right Section -->
      <div class="flex space-x-2"></div>
    </div>

    <div class="flex items-center justify-between px-4  "></div>
    <nav :class="isOpen ? 'block' : 'hidden'" class="  bg-gray-50 ">
      <div class=" border-b border-gray-300"></div>
      <div class="px-10 cursor-pointer">
        <div
          @click.prevent="mobilePush({ name: 'std-quota-request' })"
          class="flex items-center  py-3  hover:bg-gray-100 -mx-2"
        >
          <p class="text-gray-500 text-base mx-2">
            แจ้งความจำนง
          </p>
        </div>
        <div class="border-b" v-if="true"></div>
        <div
          v-if="true"
          @click.prevent="mobilePush({ name: 'std-quota-petition' })"
          class="flex items-center  py-3  hover:bg-gray-100 -mx-2 cursor-pointer"
        >
          <p class="text-gray-500 text-base mx-2">
            ยื่นคำร้อง
          </p>
        </div>
      </div>
      <div class="px-10 pt-2 border-t border-gray-300 ">
        <div class="flex items-center py-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-gray-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clip-rule="evenodd"
            />
          </svg>
          <p class="text-gray-500 text-base mx-2">
            {{ nameNUsername }}
          </p>
        </div>
        <div class="">
          <div class="border-b"></div>
          <div
            @click.prevent="mobilePush({ name: 'std-info' })"
            class="flex items-center  py-3  hover:bg-gray-100 -mx-2 cursor-pointer"
          >
            <p class="text-gray-500 text-base mx-2">
              ข้อมูลส่วนตัว
            </p>
          </div>
          <div class="border-b"></div>

          <button
            @click="logoutStudent()"
            class="flex items-center  py-3  hover:bg-gray-100 -mx-2"
          >
            <p class="text-gray-500 text-base mx-2">
              ออกจากระบบ
            </p>
          </button>
        </div>
      </div>
      <div class=" border-b border-gray-300"></div>
    </nav>
  </div>
</template>

<script>
// import { auth } from "../../../js/firebaseDb.js";
import StudentMixin from "../../../mixins/student.js";
import StudentRuleMixin from "../../../mixins/studentRule.js";
export default {
  mixins: [StudentMixin, StudentRuleMixin], // logoutStudent(),canAddQuotaPetition
  data() {
    return {
      dropdownOpen: null,
      isOpen: false,
    };
  },
  computed: {
    nameNUsername() {
      if (this.userInfo) {
        return `${this.userInfo.nameTitle} ${this.userInfo.firstName} ${this.userInfo.lastName} | ${this.username}`;
      } else {
        return "";
      }
    },
    userInfo() {
      return this.$store.getters["userInfo"];
    },
    username() {
      return this.$store.getters["userId"];
    },
  },
  methods: {
    // logoutUser() {
    //   auth
    //     .signOut()
    //     .then(() => {
    //       this.$router.replace({
    //         name: "login",
    //       });
    //       this.$store.dispatch("clearUser");
    //     })
    //     .catch((error) => {
    //       alert(error.message);
    //     });
    // },
    mobilePush(targetName) {
      this.isOpen = false;
      this.$router.push(targetName);
    },
  },
};
</script>
