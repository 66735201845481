import { sortText } from "../../../js/utility.js";
export default {
    // instructors(state) {
    //   return state.instructors;
    // },
    instructors(state) {
        const filteredInstructor = state.instructors.filter(
            (isActive) => isActive.status == "active" || isActive.status == undefined
        );
        return sortText(filteredInstructor, "name");
    },
    fullName(state, getters) {
        let instArr = [];
        const instructors = getters.instructors;
        for (const inst of instructors) {
            instArr.push({
                ...inst,
                fullName: inst.prefix + " " + inst.name + " " + inst.surname,
            });
        }
        return instArr;
    },
    getNameById: (state, getters) => (instId) => {
        const instructors = getters.fullName;
        const filteredInst = instructors.find((inst) => inst.id === instId);
        return filteredInst;
    },
};