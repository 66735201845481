<template>
  <div class="flex flex-col w-full">
    <div
      class="text-white bg-primary-200 flex flex-shrink-0 flex-col pl-4 py-4"
    >
      <div class="flex relative  items-center p-4 h-12">
        <span class="text-2xl tracking-wide font-semibold">ผู้บรรยาย</span>
      </div>
    </div>
  </div>
  <div class="flex justify-center pt-20" v-if="isLoading">
    <base-spinner></base-spinner>
  </div>

  <div class="px-60" v-else>
    <base-card class="flex justify-end">
      <base-button @click="addInstructor()"> + เพิ่มผู้บรรยาย</base-button>
    </base-card>
    <div class="flex items-center justify-center">
      <table
        class="flex flex-row flex-no-wrap md:block md:bg-white rounded-lg overflow-hidden md:shadow-lg my-5 mx-auto w-full"
      >
        <thead class=" text-white">
          <tr
            class="bg-teal-400 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-2 "
          >
            <th class="p-3 text-center">No.</th>
            <th class="p-3 text-left">Prefix</th>
            <th class="p-3 text-left">Name</th>
            <th class="p-3 text-left">Surname</th>
            <th class="p-3 border-b-1  text-left ">แก้ไข</th>
          </tr>
        </thead>
        <tbody class="flex-1 md:flex-none">
          <tr
            v-for="(instructor, index) in instructors"
            :key="instructor"
            class="flex flex-col flex-no wrap md:table-row mb-2 md:mb-0 "
          >
            <td
              class="text-center border-grey-light border hover:bg-gray-100 p-3"
            >
              {{ index + 1 }}
            </td>
            <td class="border-grey-light border hover:bg-gray-100 p-3">
              {{ instructor.prefix }}
            </td>
            <td class="border-grey-light border hover:bg-gray-100 p-3">
              {{ instructor.name }}
            </td>
            <td class="border-grey-light border hover:bg-gray-100 p-3">
              {{ instructor.surname }}
            </td>
            <td
              class="border-grey-light border hover:bg-gray-100 p-3  text-blue-550 hover:text-blue-600 hover:font-medium cursor-pointer"
              @click="editInstructor(instructor)"
            >
              แก้ไข
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Edit Dialog -->
  <data-modal
    :show="showDialog"
    title="เพิ่มผู้บรรยาย"
    @close="showDialog = false"
    @delete="deleteDialog = true"
    :deleteBtn="mode == 'Edit'"
    @submit="submitInstructor()"
  >
    <div class="form-row  w-full">
      <label>
        Prefix
      </label>
      <div class="col-span-2">
        <input
          class=" form-control"
          type="text"
          v-model="instructorPrefix"
          @blur="v$.instructorPrefix.$touch"
        />
        <span
          class="errors"
          v-for="error of v$.instructorPrefix.$errors"
          :key="error.$uid"
        >
          {{ error.$message }} <br />
        </span>
      </div>
      <div v-if="logVisible">{{ instructorPrefix }}</div>
      <label>
        Name
      </label>
      <div class="col-span-2">
        <input
          class=" form-control"
          type="text"
          v-model="instructorName"
          @blur="v$.instructorName.$touch"
        />
        <span
          class="errors"
          v-for="error of v$.instructorName.$errors"
          :key="error.$uid"
        >
          {{ error.$message }} <br />
        </span>
      </div>
      <div v-if="logVisible">{{ instructorName }}</div>
      <label>
        Surname
      </label>
      <div class="col-span-2">
        <input
          class=" form-control"
          type="text"
          v-model="instructorSurname"
          @blur="v$.instructorSurname.$touch"
        />
        <span
          class="errors"
          v-for="error of v$.instructorSurname.$errors"
          :key="error.$uid"
        >
          {{ error.$message }} <br />
        </span>
      </div>
      <div v-if="logVisible">{{ instructorSurname }}</div>
    </div>
  </data-modal>
  <toast :show="!!error" mode="error" @close="error = null" :message="error">
  </toast>
  <confirm-modal
    :show="deleteDialog"
    title="ยืนยันลบอาจารย์ ออกจากระบบ"
    @close="deleteDialog = false"
    @confirm="deleteInstructor()"
  >
  </confirm-modal>
</template>

<script>
import LoadDataMixin from "../../mixins/loadData.js";

import DataModal from "../../components/UI/DataModal.vue";
import ConfirmModal from "../../components/UI/ConfirmModal.vue";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { isLogVisible } from "../../js/debug.js";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mixins: [LoadDataMixin], // loadInstructor

  components: { DataModal, ConfirmModal },
  data() {
    return {
      showDialog: false,
      instructorName: null,
      instructorSurname: null,
      instructorPrefix: null,
      mode: null,
      instructorId: null,
      error: null,
      isLoading: false,
      deleteDialog: false,
    };
  },
  validations() {
    return {
      instructorPrefix: { required },
      instructorName: { required },
      instructorSurname: { required },
    };
  },
  async created() {
    this.isLoading = true;
    await this.loadInstructor();
    this.isLoading = false;
  },
  computed: {
    instructors() {
      return this.$store.getters["instructors/instructors"];
    },
    logVisible() {
      return isLogVisible();
    },
  },
  methods: {
    addInstructor() {
      this.showDialog = true;
      this.mode = "Add";
      this.instructorPrefix = null;
      this.instructorName = null;
      this.instructorSurname = null;
    },

    async submitInstructor() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }
      if (this.mode == "Add") {
        const submitData = {
          name: this.instructorName,
          surname: this.instructorSurname,
          prefix: this.instructorPrefix,
        };
        await this.$store.dispatch("instructors/addInstructor", submitData);
      } else if (this.mode == "Edit") {
        const submitData = {
          name: this.instructorName,
          surname: this.instructorSurname,
          prefix: this.instructorPrefix,
          id: this.instructorId,
        };
        await this.$store.dispatch("instructors/editInstructor", submitData);
      }

      this.showDialog = false;
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "บันทึกสำเร็จ",
      });
    },
    editInstructor(instructors) {
      this.showDialog = true;
      this.instructorPrefix = instructors.prefix;
      this.instructorName = instructors.name;
      this.instructorSurname = instructors.surname;
      this.instructorId = instructors.id;
      this.mode = "Edit";
    },
    async deleteInstructor() {
      await this.$store.dispatch("instructors/deleteInstructor", {
        id: this.instructorId,
      });
      this.deleteDialog = false;
      this.showDialog = false;
      this.$store.dispatch("updateToast", {
        mode: "info",
        show: true,
        message: "ลบรายชื่อสำเร็จ",
      });
    },
  },
};
</script>

<style scoped>
.form-row {
  @apply pb-3;
}
.std-info-grid {
  @apply grid grid-cols-4 py-6;
}

.std-info-grid h3 {
  @apply col-span-4 md:col-span-1 pb-4;
}

.std-info-data {
  @apply col-span-4 md:col-span-3;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
