import { db } from "../../../js/firebaseDb";
import { isLogVisible } from "../../../js/debug.js";
import {
    randNSort,
    sliceIntoChunks,
    sortText,
    QuodSort,
} from "../../../js/utility.js";
import firebase from "firebase/app";
import "firebase/firestore";
import _ from "underscore";

function diffArray(arrayA, arrayB) {
    let array_difference = arrayA.filter(function(x) {
        // checking second array does not contain element "x"
        if (arrayB.indexOf(x) == -1) return true;
        else return false;
    });

    return array_difference;
}

export default {
    // Quota Section
    async addQuota(context, payload) {
        const schedule = context.rootGetters.schedule;
        // get selected subject data
        const subData = context.rootGetters["subjects/getSubjectsById"](
            payload.subId
        );
        // get section data
        const secData = context.rootGetters["sections/getSecById"](payload.secId);

        // get instructor data
        let instructors = [];
        for (const instId of payload.instId) {
            const instName = context.rootGetters["instructors/getNameById"](instId);
            instructors.push({ id: instId, name: instName.fullName });
        }

        const quotaData = {
            subId: payload.subId,
            subCode: subData.code,
            subYear: subData.year,
            subName: subData.name,
            subUnit: subData.unit,
            secTime: secData.time,
            instructors: instructors,
            secId: payload.secId,
            majorId: payload.majorId,
            offLink: payload.offLink,
            group: payload.group,
            campus: payload.campus,
            // [`q${schedule.qRound}Offer`]: payload.quota,
            condition: payload.condition,
            updateBy: context.rootGetters.userId,
            updateDate: firebase.firestore.FieldValue.serverTimestamp(),
        };

        // check round
        if (schedule.qRound === 1) {
            quotaData[`q1Offer`] = payload.quota;
        } else if (schedule.qRound === 2) {
            quotaData[`q1Offer`] = 0;
            quotaData[`q2Offer`] = payload.quota;
        } else if (schedule.qRound === 3) {
            quotaData[`q1Offer`] = 0;
            quotaData[`q2Offer`] = 0;
            quotaData[`q3Offer`] = payload.quota;
        } else if (schedule.qRound === 4) {
            quotaData[`q1Offer`] = 0;
            quotaData[`q2Offer`] = 0;
            quotaData[`q3Offer`] = 0;
            quotaData[`q4Offer`] = payload.quota;
        } else if (schedule.qRound === 5) {
            quotaData[`q1Offer`] = 0;
            quotaData[`q2Offer`] = 0;
            quotaData[`q3Offer`] = 0;
            quotaData[`q4Offer`] = 0;
            quotaData[`q5Offer`] = payload.quota;
        } else {
            throw Error("This Quota Round is not supported");
        }

        // get last 4 years
        const last4Year = context.rootGetters["years/getLast4Years"];

        // get historical subject by offLink and year
        let effectYear = [];
        for (const theYear of last4Year) {
            const yearSubData = context.rootGetters[
                "subjects/getSubjectByYearAndOffLink"
            ]({
                year: theYear.name,
                offLink: payload.offLink,
            });

            if (yearSubData) {
                quotaData[`sub${theYear.name}`] = {
                    year: theYear.name,
                    name: yearSubData.name,
                    code: yearSubData.code,
                };
                effectYear.push(theYear.effectEst);
            }
        }
        if (effectYear.length > 0) {
            quotaData["effectYear"] = effectYear;
        }

        if (isLogVisible()) {
            console.log(quotaData);
        }
        // Update data in Firebase

        // === Data that need to be sent === //
        // const token = context.rootGetters.token;
        const fSemYear = context.rootGetters.fSemYear;

        if (isLogVisible()) {
            console.log("start sending data to firbase");
        }

        // Add new Quota to Database
        const quotColl = db.collection(`quota_${fSemYear}`);
        const response = await quotColl.add(quotaData).catch((error) => {
            if (isLogVisible()) {
                console.error("Error adding document: ", error);
            }
            throw error;
        });
        if (response.empty) {
            const error = new Error("Can't send data to server");
            if (isLogVisible()) {
                console.error(error);
            }
        }

        quotaData.id = response.id;

        // Update data in Vuex
        context.commit("addQuota", {
            ...quotaData,
        });
    },
    async setQuotaAdmin(context) {
        const fSemYear = context.rootGetters.fSemYear;
        const userInfo = context.rootGetters.userInfo;
        const majors = userInfo.majors;

        const quotaRef = db.collection(`quota_${fSemYear}`);
        const userProgram = context.rootGetters["programs/getUserProgram"];
        let quotaDocs = null;
        if (userInfo.role === "A") {
            quotaDocs = await quotaRef
                .where("campus", "==", userProgram.camCode)
                .get()
                .catch((error) => {
                    if (isLogVisible()) {
                        console.error("Error getting documents: ", error);
                    }

                    throw error;
                });
        } else if (userInfo.role === "S") {
            quotaDocs = await quotaRef
                .where("campus", "==", userProgram.camCode)
                .where("majorId", "in", majors)
                .get()
                .catch((error) => {
                    if (isLogVisible()) {
                        console.error("Error getting documents: ", error);
                    }
                    throw error;
                });
        } else {
            throw Error("this admin role is not supported");
        }

        if (quotaDocs.empty || !quotaDocs) {
            context.commit("setQuota", []);
            if (isLogVisible()) {
                const error = new Error("No such document!");
                console.error(error);
            }
        }

        const quotaArr = quotaDocs.docs.map((doc) => {
            return {...doc.data(), id: doc.id };
        });

        context.commit("setQuota", quotaArr);
    },

    async isSectionDuplicate(context, payload) {
        // context, payload
        const offLink = payload.offLink;
        const secId = payload.secId;
        const group = payload.group;

        const quotas = context.getters.quota;
        const found = quotas.some(
            (q) => q.offLink == offLink && q.secId == secId && q.group == group
        );
        return found;
    },

    async setQuotaStd(context) {
        const fSemYear = context.rootGetters.fSemYear;
        const quotaRef = db.collection(`quota_${fSemYear}`);
        const userInfo = context.rootGetters.userInfo;

        const userYear = context.rootGetters["years/getYearByStdCode"](
            userInfo.stdCode
        );

        const quotaDocs = await quotaRef.get().catch((error) => {
            if (isLogVisible()) {
                console.error("Error getting documents: ", error);
            }
            throw error;
        });

        if (quotaDocs.empty) {
            if (isLogVisible()) {
                const error = new Error("No such document!");
                console.error(error);
            }
        }

        const quotaArr = quotaDocs.docs.map((doc) => {
            return {...doc.data(), id: doc.id };
        });
        const quotaInYear = quotaArr.filter((quota) => {
            return quota.effectYear.some((aYear) => aYear === userYear.effectEst);
        });

        const formatQuotaInYear = quotaInYear.map((q) => {
            return {
                ...q,
                subCode: q[`sub${userYear.name}`].code,
                subName: q[`sub${userYear.name}`].name,
                subYear: q[`sub${userYear.name}`].year,
            };
        });

        const sortedQuotaInYear = sortText(formatQuotaInYear, "subCode");

        context.commit("clearQuota");
        context.commit("setQuota", sortedQuotaInYear);
    },
    async updateQuota(context, submittedData) {
        const id = submittedData.id;
        const newQuot = {
            secId: submittedData.secId,
            group: submittedData.group,
            campus: submittedData.campus,
            q1Offer: submittedData.q1Offer,

            condition: submittedData.condition,
            // instId: submittedData.instId,
            updateBy: context.rootGetters.userId,
            updateDate: new Date().toLocaleString(),
        };

        if (submittedData.instructors && submittedData.instructors.length > 0) {
            // get instructor data
            const instructorsArr = [];
            for (const instId of submittedData.instructors) {
                const instName = context.rootGetters["instructors/getNameById"](instId);
                instructorsArr.push({
                    id: instId,
                    name: instName.fullName,
                });
            }
            newQuot["instructors"] = instructorsArr;
        }

        // get sec data
        const secData = context.rootGetters["sections/getSecById"](
            submittedData.secId
        );

        newQuot.secTime = secData.time;

        if (Number.isInteger(submittedData.regNo)) {
            newQuot.regNo = submittedData.regNo;
        }
        if (submittedData.remark) {
            newQuot.remark = submittedData.remark;
        }
        if (Number.isInteger(submittedData.q2Offer)) {
            newQuot.q2Offer = submittedData.q2Offer;
        }
        if (Number.isInteger(submittedData.q3Offer)) {
            newQuot.q3Offer = submittedData.q3Offer;
        }
        if (Number.isInteger(submittedData.q4Offer)) {
            newQuot.q4Offer = submittedData.q4Offer;
        }
        if (Number.isInteger(submittedData.q5Offer)) {
            newQuot.q5Offer = submittedData.q5Offer;
        }

        // === Data that need to be sent === //
        // const token = context.rootGetters.token;
        const fSemYear = context.rootGetters.fSemYear;

        var quotaRef = db.collection(`quota_${fSemYear}`).doc(id);

        // Set all field of the Quota
        await quotaRef.update(newQuot).catch((error) => {
            // The document probably doesn't exist.
            if (isLogVisible()) {
                console.error("Error getting documents: ", error);
            }
            throw error;
        });

        const quota = context.getters.quota;
        const selectedQuota = quota.find((q) => q.id === id);
        var index = quota.indexOf(selectedQuota);

        if (index !== -1) {
            quota.splice(index, 1, {
                ...selectedQuota,
                ...newQuot,
            });
        }

        context.commit("setQuota", quota);
    },

    async deleteQuota(context, payload) {
        const qId = payload.id;
        // === Data that need to be sent === //
        // const token = context.rootGetters.token;
        const fSemYear = context.rootGetters.fSemYear;

        // update data in Firbase

        const subColl = db.collection(`quota_${fSemYear}`);
        subColl
            .doc(qId)
            .delete()
            .catch((error) => {
                if (isLogVisible()) {
                    console.error("Error removing documents: ", error);
                }
                throw error;
            });

        // update data in VueX
        if (isLogVisible()) {
            console.log("start deleteSubject in VueX" + index);
        }

        // update data in VueX
        const selectedQuota = context.getters.getQuotaById(qId);
        const allQuota = context.state.quota;
        var index = allQuota.indexOf(selectedQuota);
        if (index !== -1) {
            allQuota.splice(index, 1);
        }
        context.commit("setQuota", allQuota);
    },

    async checkQuotaStatus(context) {
        // เช็คว่า Quota ในรอบนี้ถูกปิดหมดหรือยัง ถ้าปิดหมดแล้ว ให้ทำการ schedule.next()
        const fSemYear = context.rootGetters.fSemYear;
        const schedule = context.rootGetters.schedule;
        const programs = context.rootGetters["programs/programs"];
        const quotaRef = db.collection(`quota_${fSemYear}`);

        let response = {};

        response[`status`] = true;
        for (const program of programs) {
            // get all quota in campus
            const allQuotaDoc = await quotaRef
                .where("campus", "==", program.camCode)
                .get()
                .catch((error) => {
                    if (isLogVisible()) {
                        console.error("Error getting documents: ", error);
                    }
                    throw error;
                });

            // get done quota in campus
            const doneQuotaDoc = await quotaRef
                .where("campus", "==", program.camCode)
                .where(`status${schedule.qRound}`, "==", "done")
                .get()
                .catch((error) => {
                    if (isLogVisible()) {
                        console.error("Error getting documents: ", error);
                    }
                    throw error;
                });

            // calculate status
            if (allQuotaDoc.size === doneQuotaDoc.size) {
                response[`${program.camCode}`] = true;
            } else {
                response[`${program.camCode}`] = false;
                response[`status`] = false;
            }
        }
        return response;
    },

    async checkQuotaRegNo(context) {
        // เช็คว่า Quota ทุก quota มี regNo หรือยัง
        const fSemYear = context.rootGetters.fSemYear;
        const quotaRef = db.collection(`quota_${fSemYear}`);

        let response = {};

        response[`status`] = true;
        // get all quota
        const allQuotaDoc = await quotaRef.get().catch((error) => {
            if (isLogVisible()) {
                console.error("Error getting documents: ", error);
            }
            throw error;
        });

        const quotaArr = allQuotaDoc.docs.map((doc) => {
            return {...doc.data(), id: doc.id };
        });

        const errorQuota = quotaArr.filter((q) => {
            return !Number.isInteger(q.regNo);
        });

        if (errorQuota.length > 0) {
            response[`status`] = false;
            response["error"] = errorQuota;
        }
        return response;
    },

    async confirmProcess(context) {
        const schedule = context.rootGetters.schedule;
        const fSemYear = context.rootGetters.fSemYear;

        const isQuotaOk = await context.dispatch("checkQuotaStatus");

        if (schedule.qRound === 2) {
            const regNoRes = await context.dispatch("checkQuotaRegNo");

            if (!regNoRes.status) {
                let resStr = "";
                for (const errorSec of regNoRes.error) {
                    resStr = resStr.concat(
                        `${errorSec.subCode} (sec ${errorSec.secId}${errorSec.group}), `
                    );
                }
                throw Error(`Some registered student quantity is not provided : ${resStr}`);
            }
        }

        if (!isQuotaOk.status) {
            throw Error(
                `บางโควตายังไม่ได้ประมวลผลโดยระบบ โปรดประมวลผลโควตาอีกครั้ง : ${JSON.stringify(
          isQuotaOk
        )}`
            );
        }

        // check process status
        const quotaRef = db.collection(`quota_${fSemYear}`);
        const quotaDoc = await quotaRef.get().catch((error) => {
            if (isLogVisible()) {
                console.error("Error getting documents: ", error);
            }
            throw error;
        });

        const quotaArr = quotaDoc.docs.map((doc) => {
            return {...doc.data(), id: doc.id };
        });

        const quotaChunks = sliceIntoChunks(quotaArr, 400);

        for (const quotaArr of quotaChunks) {
            const batch = db.batch();

            // if present period is secode round then calculate offer for thrid round by q1Offer - regNo
            if (schedule.qRound === 2) {
                for (const theQuota of quotaArr) {
                    // loop all Quota
                    if (isLogVisible()) {
                        console.log("send data to firebase");
                    }

                    // calculate remaining quota
                    const theQuotaRef = quotaRef.doc(theQuota.id);
                    let q3OfferRes = 0;
                    if (!theQuota.q1Offer) {
                        q3OfferRes = theQuota.q2Offer - theQuota.regNo;
                    } else {
                        q3OfferRes = theQuota.q1Offer - theQuota.regNo;
                    }
                    batch.update(theQuotaRef, {
                        q3Offer: Math.max(0, q3OfferRes),
                    });

                    // Update Accept  Quota Number in Vuex
                    const quota = context.getters.quota;
                    const selectedQuota = quota.find((q) => q.id === theQuota.id);
                    if (selectedQuota) {
                        selectedQuota.q3Offer = Math.max(0, q3OfferRes);
                    }
                }
            } else {
                // if It's not second round  then calculate offer quota by offer-accept
                for (const theQuota of quotaArr) {
                    let newOffer =
                        theQuota[`q${schedule.qRound}Offer`] -
                        theQuota[`q${schedule.qRound}Accept`];
                    newOffer = Math.max(newOffer, 0);
                    // loop all Quota
                    // calculate remaining quota
                    const theQuotaRef = quotaRef.doc(theQuota.id);
                    batch.update(theQuotaRef, {
                        [`q${schedule.qRound + 1}Offer`]: newOffer,
                    });

                    // Update Accept  Quota Number in Vuex
                    const quota = context.getters.quota;
                    const selectedQuota = quota.find((q) => q.id === theQuota.id);
                    if (selectedQuota) {
                        selectedQuota[`q${schedule.qRound + 1}Offer`] = newOffer;
                    }
                }
            }
            await batch.commit();
        }
    },

    async processQuota(context) {
        // change name to processQuota
        const programCode = context.rootGetters.userProgram;
        const schedule = context.rootGetters.schedule;
        const theProgram = context.rootGetters["programs/getProgramByCode"](
            programCode
        );

        const mode = theProgram[`quotaMode${schedule.qRound}`];

        if (mode === "batch") {
            await context.dispatch("processBatchQuota");
        } else if (mode === "transaction") {
            await context.dispatch("processOnlineQuota");
        } else {
            if (isLogVisible()) {
                console.error("mode error");
            }
            throw Error("mode error");
        }
    },

    async undoProcessQuota(context) {
        const programCode = context.rootGetters.userProgram;
        const schedule = context.rootGetters.schedule;
        const fSemYear = context.rootGetters.fSemYear;
        const theProgram = context.rootGetters["programs/getProgramByCode"](
            programCode
        );
        const mode = theProgram[`quotaMode${schedule.qRound}`];
        const quotaInCampus = await context.dispatch("getQuotaInCampus");
        const quotaRef = db.collection(`quota_${fSemYear}`);

        const doneNprocessingQuota = quotaInCampus.filter((q) => {
            return (
                (q[`status${schedule.qRound}`] &&
                    q[`status${schedule.qRound}`] === "done") ||
                (q[`status${schedule.qRound}`] &&
                    q[`status${schedule.qRound}`] === "processing")
            );
        });

        for (const quotaItem of doneNprocessingQuota) {
            const quotaReqRef = db.collection(`quota_request_${fSemYear}`);
            // batch start
            const batch = db.batch();

            const quotaReqDoc = await quotaReqRef
                .where("quotaId", "==", quotaItem.id)
                .where("reqQutTime", "==", schedule.qRound)
                .where("reqResult", "in", ["reject", "accept"])
                .get()
                .catch((error) => {
                    if (isLogVisible()) {
                        console.error("Error getting documents: ", error);
                    }
                    throw error;
                });

            if (quotaReqDoc.empty) {
                if (isLogVisible()) {
                    console.error("Document not found");
                }
            }

            // Convert Reponse Data to js Array
            const allQuotaReqArr = quotaReqDoc.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });

            if (mode === "batch") {
                for (const reqItem of allQuotaReqArr) {
                    const acceptQuotaRef = quotaReqRef.doc(reqItem.id);
                    batch.update(acceptQuotaRef, {
                        reqResult: "pending",
                    });
                }
            } else if (mode === "transaction") {
                for (const reqItem of allQuotaReqArr) {
                    const acceptQuotaRef = quotaReqRef.doc(reqItem.id);
                    batch.update(acceptQuotaRef, {
                        reqResult: "reserve",
                    });
                }
            } else {
                console.error("mode error");
            }

            // updateCounter in quota collection
            const QuotaDoc = quotaRef.doc(quotaItem.id);
            batch.update(QuotaDoc, {
                [`q${schedule.qRound}Accept`]: 0,
                [`status${schedule.qRound}`]: "undo",
            });

            // Update Accept  Quota Number in Vuex
            const quota = context.getters.quota;
            const selectedQuota = quota.find((q) => q.id === quotaItem.id);
            const index = quota.indexOf(selectedQuota);
            const newQuota = {
                ...selectedQuota,
            };
            newQuota[`q${schedule.qRound}Accept`] = 0;
            newQuota[`status${schedule.qRound}`] = "undo";
            if (index !== -1) {
                quota.splice(index, 1, newQuota);
                if (isLogVisible()) {
                    console.log("updated Quota : " + index);
                }
            }

            // batch commit
            await batch.commit();
        }
    },

    async commitUpdatedQuota(context, payload) {
        const quotaRef = payload.quotaRef;
        const quotaReqRef = payload.quotaReqRef;
        const quotaItem = payload.quotaItem;
        const acceptReqQuota = payload.acceptReqQuota;
        const rejectReqQuota = payload.rejectReqQuota;
        const schedule = context.rootGetters.schedule;

        const batch = db.batch();

        acceptReqQuota.forEach(async(acceptQ) => {
            const acceptQuotaRef = quotaReqRef.doc(acceptQ.id);
            batch.update(acceptQuotaRef, { reqResult: "accept", byAdmin: false });
        });

        rejectReqQuota.forEach(async(rejectQ) => {
            const acceptQuotaRef = quotaReqRef.doc(rejectQ.id);
            batch.update(acceptQuotaRef, {
                reqResult: "reject",
                byAdmin: false,
            });
        });

        // updateCounter in quota collection
        const QuotaDoc = quotaRef.doc(quotaItem.id);
        batch.update(QuotaDoc, {
            [`q${schedule.qRound}Accept`]: acceptReqQuota.length,
        });

        await batch.commit();

        // Update Accept  Quota Number in Vuex
        const quota = context.getters.quota;
        const selectedQuota = quota.find((q) => q.id === quotaItem.id);
        const index = quota.indexOf(selectedQuota);
        const newQuota = {
            ...selectedQuota,
        };
        newQuota[`q${schedule.qRound}Accept`] = acceptReqQuota.length;
        newQuota[`status${schedule.qRound}`] = "done";
        if (newQuota["byAdmin"]) {
            newQuota["byAdmin"] = false;
        }
        if (index !== -1) {
            // edit accept number
            quota.splice(index, 1, newQuota);
            if (isLogVisible()) {
                console.log("updated Quota : " + index);
            }
        }
    },

    async getQuotaInCampus(context) {
        // get data from firebase ONLY
        const fSemYear = context.rootGetters.fSemYear;
        const userProgram = context.rootGetters.userProgram;
        const camCode = context.rootGetters["programs/getProgramByCode"](
            userProgram
        ).camCode;
        if (isLogVisible()) {
            console.log("start getting Quota data from firebase");
        }

        // Get Quota from firebase
        const quotaRef = db.collection(`quota_${fSemYear}`);
        const quotaDoc = await quotaRef
            .where("campus", "==", camCode)
            .get()
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });

        // Convert Quota Response to js Array
        if (isLogVisible()) {
            console.log("Convert Firebase response to js Array");
        }
        const quotaInCampus = quotaDoc.docs.map((doc) => {
            return {...doc.data(), id: doc.id };
        });

        return quotaInCampus;
    },

    async processOnlineQuota(context) {
        const fSemYear = context.rootGetters.fSemYear;
        const schedule = context.rootGetters.schedule;
        const quotaInCampus = await context.dispatch("getQuotaInCampus");
        const quotaRef = db.collection(`quota_${fSemYear}`);

        // Loop all Quota
        if (isLogVisible()) {
            console.log("Start Loop all Quota");
        }

        for (const quotaItem of quotaInCampus) {
            // check does quota is done?
            if (isLogVisible()) {
                console.time("proceesEachQuotaItem");
                console.log(quotaItem[`status${schedule.qRound}`]);
            }

            if (
                quotaItem[`status${schedule.qRound}`] &&
                quotaItem[`status${schedule.qRound}`] === "done"
            ) {
                continue;
            }

            const processRef = db.collection(`quota_${fSemYear}`).doc(quotaItem.id);

            await processRef.update({
                [`status${schedule.qRound}`]: "processing",
            });

            // Get Request Quota that for each Quota
            const quotaReqRef = db.collection(`quota_request_${fSemYear}`);
            const quotaReqDoc = await quotaReqRef
                .where("quotaId", "==", quotaItem.id)
                .where("reqQutTime", "==", schedule.qRound)
                .where("reqResult", "in", ["reserve", "accept", "reject"])
                // .orderBy("reqDate")
                .get()
                .catch((error) => {
                    console.error("Error getting documents: ", error);
                });

            if (isLogVisible()) {
                console.log("Convert Reponse Data to js Array");
            }
            // Convert Reponse Data to js Array
            let allQuotaReqArr = quotaReqDoc.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });
            // sort array by reqDate
            allQuotaReqArr = allQuotaReqArr.sort(
                (objA, objB) =>
                Number(objA.reqDate.toDate()) - Number(objB.reqDate.toDate())
            );

            // find remain quota number
            if (isLogVisible()) {
                console.log("Finding Remain");
            }

            let remain = quotaItem[`q${schedule.qRound}Offer`];

            // not Random but Sort by time
            const acceptReqQuota = allQuotaReqArr.slice(0, remain);
            const rejectReqQuota = allQuotaReqArr.slice(
                remain,
                allQuotaReqArr.length
            );

            // commit data to firebase
            await context.dispatch("commitUpdatedQuota", {
                quotaRef,
                quotaReqRef,
                quotaItem,
                acceptReqQuota,
                rejectReqQuota,
            });

            // write log in quota collection
            await processRef.update({
                [`status${schedule.qRound}`]: "done",
            });

            if (isLogVisible()) {
                console.timeEnd("proceesEachQuotaItem");
            }
        }
        // TODO : IF all quota was processed ? update schedule and remove log collection
    },

    async processBatchQuota(context) {
        const fSemYear = context.rootGetters.fSemYear;
        const schedule = context.rootGetters.schedule;
        const quotaInCampus = await context.dispatch("getQuotaInCampus");
        const quotaRef = db.collection(`quota_${fSemYear}`);

        // Loop all Quota
        if (isLogVisible()) {
            console.log("Start Loop all Quota");
        }

        for (const quotaItem of quotaInCampus) {
            // check does quota is done?
            if (isLogVisible()) {
                console.log(quotaItem[`status${schedule.qRound}`]);
            }

            if (
                quotaItem[`status${schedule.qRound}`] &&
                quotaItem[`status${schedule.qRound}`] === "done"
            ) {
                continue;
            }

            const processRef = db.collection(`quota_${fSemYear}`).doc(quotaItem.id);

            await processRef.update({
                [`status${schedule.qRound}`]: "processing",
            });

            const quotaReqRef = db.collection(`quota_request_${fSemYear}`);
            if (isLogVisible()) {
                console.log(quotaItem.id);
            }

            const quotaReqDoc = await quotaReqRef
                .where("quotaId", "==", quotaItem.id)
                .where("reqQutTime", "==", schedule.qRound)
                .where("reqResult", "in", ["pending", "accept", "reject"])
                .get()
                .catch((error) => {
                    console.error("Error getting documents: ", error);
                });

            const allQuotaReqArr = quotaReqDoc.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });

            const condCode = quotaItem.condition;
            const quotaMajor = quotaItem.majorId;
            const intFirstYear = schedule.year % 100;
            // find remain quota number
            let remain = quotaItem[`q${schedule.qRound}Offer`];

            if (isLogVisible()) {
                console.log(`remain Number: ${remain}`);
            }

            // order conditionArr by block
            // if quota Round is 4 then force use condCode 12 to process quota
            let processingCondCode = condCode;
            const scheduleCustomRule = context.rootGetters.scheduleCustomRule;
            if (scheduleCustomRule.isForceCondition) {
                processingCondCode = scheduleCustomRule.forceCondition;
            }

            const conditionRes = await db
                .collection(`conditions`)
                .where("condCode", "==", processingCondCode)
                .orderBy("block")
                .get();

            const conditionArr = conditionRes.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });

            // quotaReqArr เป็นตระกร้าที่เอาไว้ loop => พอ request ไหนถูก approve => จะถูกนำออกจากตะหร้า
            // => ดังนั้น แม้ว่าการสุ่มจะใส่ key แปลกๆเข้าไปที่ทำให้ object หน้าตาเปลี่ยนไป => ก็ไม่มีผลเพราะว่า
            // การที่สุ่ม = การ loop ครั้งสุดท้าย ไม่ต้องใช้ diffArray ก็ได้
            let quotaReqArr = allQuotaReqArr;
            const qualifiedList = [];
            for (let cond of conditionArr) {
                if (isLogVisible()) {
                    console.log(cond);
                }

                let qualifiedReq = quotaReqArr.filter((reqQuota) => {
                    let majorCrit = false;

                    if (isLogVisible()) {
                        console.log("start MAJOR criteria");
                    }

                    // MAJOR CRITERIA
                    if (cond.majorCrit === 1) {
                        // Same student first major and subject major
                        majorCrit = reqQuota.stdMajor == quotaMajor;
                    } else if (cond.majorCrit === 2) {
                        // In selected major => ibmp first
                        majorCrit = reqQuota.stdProgram == "ibmp";
                    } else if (cond.majorCrit === 3) {
                        //In department
                        majorCrit = reqQuota.stdCode.substring(2, 4) == "02";
                    } else if (cond.majorCrit === 4) {
                        //General student
                        majorCrit = true;
                    } else if (cond.majorCrit === 5) {
                        // treat first and second major as the same level
                        majorCrit =
                            reqQuota.stdMajor == quotaMajor ||
                            reqQuota.stdMajor2 == quotaMajor;
                    } else if (cond.majorCrit === 6) {
                        // second major match subject major
                        majorCrit = reqQuota.stdMajor2 == quotaMajor;
                    }

                    if (isLogVisible()) {
                        console.log("start year criteria");
                    }
                    let yearCrit = false;
                    // YEAR CRITERIA
                    const stdYear = parseInt(reqQuota.stdCode.substring(0, 2));
                    if (cond.yearCrit === 1) {
                        // every year
                        yearCrit = true;
                    } else if (cond.yearCrit === 2) {
                        // >= 2 year
                        yearCrit = stdYear <= intFirstYear - 1;
                    } else if (cond.yearCrit === 3) {
                        // >= 3 year
                        yearCrit = stdYear <= intFirstYear - 2;
                    } else if (cond.yearCrit === 4) {
                        // >= 4 year
                        yearCrit = stdYear <= intFirstYear - 3;
                    }

                    if (isLogVisible()) {
                        console.log(`majorCrit ${majorCrit}`);
                        console.log(`yearCrit ${yearCrit}`);
                    }

                    return majorCrit && yearCrit;
                });

                if (qualifiedList.length + qualifiedReq.length > remain) {
                    let requiredNo = remain - qualifiedList.length;
                    requiredNo = requiredNo < 0 ? 0 : requiredNo;

                    // SORT CRITERIA
                    if (cond.orderCrit === 1) {
                        // Random and Sort by student year
                        qualifiedReq = randNSort(qualifiedReq, "stdCode", requiredNo);
                    } else if (cond.orderCrit === 2) {
                        // Random ONLY
                        qualifiedReq = _.sample(qualifiedReq, requiredNo);
                    } else if (cond.orderCrit === 3) {
                        // Random and Sort by Accepted quota count
                        qualifiedReq = await context.dispatch(
                            "ProcessQuotaByAcceptedQuota", {
                                qualifiedReq: qualifiedReq,
                                fSemYear: fSemYear,
                                qRound: schedule.qRound,
                                qualifiedNo: requiredNo,
                            }
                        );
                    }

                    if (isLogVisible()) {
                        console.log(requiredNo);
                        console.log(qualifiedReq);
                    }
                }
                qualifiedList.push(...qualifiedReq);
                quotaReqArr = diffArray(quotaReqArr, qualifiedReq);

                if (qualifiedList.length >= remain) {
                    break;
                }
            }

            if (isLogVisible()) {
                console.log(qualifiedList);
            }

            const acceptReqQuota = qualifiedList;

            // update accept or reject to firebase
            const rejectReqQuota = diffArray(allQuotaReqArr, qualifiedList);

            // commit data to firebase
            await context.dispatch("commitUpdatedQuota", {
                quotaRef,
                quotaReqRef,
                quotaItem,
                acceptReqQuota,
                rejectReqQuota,
            });

            // write log in quota collection
            await processRef.update({
                [`status${schedule.qRound}`]: "done",
            });
        }
        // TODO : IF all quota was processed (both ibmp and undergrad) ? update schedule and remove log collection
    },

    async ProcessQuotaByAcceptedQuota(context, payload) {
        const qualifiedReq = payload.qualifiedReq;
        const fSemYear = payload.fSemYear;
        // const qRound = payload.qRound;
        const qualifiedNo = payload.qualifiedNo;

        // insert random number
        for (const aQuota of qualifiedReq) {
            aQuota["randNo"] = Math.floor(Math.random() * 1000);

            const quotaReqRef = db.collection(`quota_request_${fSemYear}`);
            const quotaReqDocs = await quotaReqRef
                // .where("reqQutTime", "==", qRound)
                .where("stdCode", "==", aQuota.stdCode)
                .where("reqResult", "==", "accept")
                .get()
                .catch((error) => {
                    console.error("Error getting documents: ", error);
                });

            let quotaReqArr = quotaReqDocs.docs.map((doc) => {
                return {...doc.data(), id: doc.id };
            });
            quotaReqArr = quotaReqArr.filter((q) => q.quotaId != aQuota.quotaId);
            aQuota["countAccept"] = quotaReqArr.length;
        }

        // sort countAccept then random
        qualifiedReq.sort(function(a, b) {
            return a.countAccept - b.countAccept || a.randNo - b.randNo;
        });

        // slice array
        let res = qualifiedReq.slice(0, qualifiedNo);

        // delete random number
        for (const aQuota of res) {
            delete aQuota["randNo"];
            delete aQuota["countAccept"];
        }

        return res;
    },

    async updateQuotaRegNo(context, quotaAndRegNoArr) {
        // initial Reference
        const fSemYear = context.rootGetters.fSemYear;
        // const quotaRef = db.collection(`quota_${fSemYear}`).doc(quotaItem.id);

        // slice in to chunk
        const quotaAndRegNos = sliceIntoChunks(quotaAndRegNoArr, 400);

        // loop all chunk
        for (const quotaAndRegNo of quotaAndRegNos) {
            // start batch
            const batch = db.batch();
            // loop for all quotaAndRegNo
            for (const quotaRegNoItem of quotaAndRegNo) {
                const quotaRef = db
                    .collection(`quota_${fSemYear}`)
                    .doc(quotaRegNoItem.quota.id);
                batch.update(quotaRef, {
                    regNo: quotaRegNoItem.regNo,
                });

                // update vuex
                const allQuota = context.state.quota;
                const selectedQuota = context.getters.getQuotaById(
                    quotaRegNoItem.quota.id
                );
                var index = allQuota.indexOf(selectedQuota);

                if (index !== -1) {
                    allQuota.splice(index, 1, {
                        ...selectedQuota,
                        regNo: quotaRegNoItem.regNo,
                    });
                }
                context.commit("setQuota", allQuota);
            }
            // commit batch
            await batch.commit();
        }

        // commit batch
    },

    // RequestQuota Section
    async setReqQuota(context) {
        const userId = context.rootGetters.userId;

        const fSemYear = context.rootGetters.fSemYear;
        // const schedule = context.rootGetters.schedule;

        const quotaRef = db.collection(`quota_request_${fSemYear}`);
        const response = await quotaRef
            .where("stdCode", "==", userId)
            // .where("reqQutTime", "==", schedule.qRound)
            .get()
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });
        if (isLogVisible()) {
            console.log(response);
        }
        if (response.empty) {
            if (isLogVisible()) {
                const error = new Error("No such document!");
                console.error(error);
            }
        }

        const quota = [];
        response.forEach((doc) => {
            const quotaData = context.getters.getQuotaById(doc.data().quotaId);
            const newQuota = {
                ...doc.data(),
                stdMajor2: doc.data().stdMajor2 || null,
                byAdmin: doc.data().byAdmin || null,
                id: doc.id,
                quota: quotaData,
            };
            quota.push(newQuota);
        });

        if (isLogVisible()) {
            console.log(quota);
        }
        context.commit("clearReqQuota");
        context.commit("setReqQuota", quota);
    },

    async setReqQuotaByQuota(context, submittedData) {
        const fSemYear = context.rootGetters.fSemYear;
        const quotaId = submittedData.quotaId;

        const quotaColl = db.collection(`quota_request_${fSemYear}`);
        const response = await quotaColl
            .where("quotaId", "==", quotaId)
            .get()
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });
        if (isLogVisible()) {
            console.log(response);
            if (response.empty) {
                const error = new Error("No such document!");
                console.error(error);
            }
        }

        const quota = [];
        response.forEach((doc) => {
            const quotaData = context.getters.getQuotaById(doc.data().quotaId);
            const newQuota = {
                ...doc.data(),
                id: doc.id,
                quota: quotaData,
            };
            quota.push(newQuota);
        });

        if (isLogVisible()) {
            console.log(quota);
        }
        context.commit("clearReqQuota");
        context.commit("setReqQuota", quota);
    },

    async findReqQuota(context, payload) {
        const mode = payload.mode; // quotaId,secId,offLink
        const modeVal = payload.modeVal;
        const stdCode = payload.stdCode || "all";
        const result = payload.result || "all";
        const fSemYear = context.rootGetters.fSemYear;

        let quotaReqRef = db.collection(`quota_request_${fSemYear}`);

        // stdCode Filter
        if (stdCode !== "all") {
            quotaReqRef = quotaReqRef.where("stdCode", "==", stdCode);
        }

        // Mode Filter
        if (mode === "quotaId") {
            quotaReqRef = quotaReqRef.where("quotaId", "==", modeVal);
        } else if (mode === "secId") {
            quotaReqRef = quotaReqRef.where("secId", "==", modeVal);
        } else if (mode === "offLink") {
            quotaReqRef = quotaReqRef.where("offLink", "==", modeVal);
        }

        // result Filter
        if (result === "notCancel") {
            quotaReqRef = quotaReqRef.where("reqResult", "!=", "cancel");
        } else if (result === "notCancelNReject") {
            quotaReqRef = quotaReqRef.where("reqResult", "not-in", [
                "cancel",
                "reject",
            ]);
        } else if (result !== "all") {
            quotaReqRef = quotaReqRef.where("reqResult", "==", result);
        }

        const response = await quotaReqRef.get().catch((error) => {
            console.error("Error getting documents: ", error);
        });

        if (response.empty) {
            return [];
        }

        const reqQuota = [];
        response.forEach((doc) => {
            const newQuota = doc.data();
            newQuota.id = doc.id;
            reqQuota.push(newQuota);
        });

        return reqQuota;
    },

    async addReqQuotaByAdmin(context, payload) {
        if (isLogVisible()) {
            console.log("add request quota by admin is started");
        }
        const quotaId = payload.quotaId;
        const the_result = payload.result;
        const stdInfo = payload.stdInfo;
        const fSemYear = context.rootGetters.fSemYear;
        const schedule = context.rootGetters.schedule;

        if (the_result !== "accept") {
            throw Error("result is error");
        }

        const regValid = await context.dispatch("checkReqQuota", {
            quotaId: quotaId,
            stdCode: stdInfo.stdCode,
        });
        if (isLogVisible()) {
            console.log(regValid);
        }

        // get quota collection from firebase
        const quotaRef = db.collection(`quota_${fSemYear}`).doc(quotaId);
        const quotaDoc = await quotaRef.get();
        if (isLogVisible()) {
            if (!quotaDoc.exists) {
                console.error("No such document!");
            }
        }

        const quotaData = {
            ...quotaDoc.data(),
            id: quotaDoc.id,
        };

        // get curriculum year of the students
        const userCurYear = context.rootGetters["years/getYearByStdCode"](
            stdInfo.stdCode
        );
        if (!quotaData[`sub${userCurYear.name}`]) {
            throw Error(
                `ไม่สามารถเพิ่มนักศึกษา ${stdInfo.stdCode} เนื่องไม่มีหลักสูตรที่ตรงกับนักศีกษารายนี้`
            );
        }
        const subCode = quotaData[`sub${userCurYear.name}`].code;
        const subName = quotaData[`sub${userCurYear.name}`].name;
        const subYear = quotaData[`sub${userCurYear.name}`].year;

        // update quota_request_xxx
        const batch = db.batch();
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        const reqQuotaRef = db.collection(`quota_request_${fSemYear}`).doc();
        const reqQuotaData = {
            userId: stdInfo.id,
            stdCode: stdInfo.stdCode,
            stdFName: stdInfo.firstName,
            stdLName: stdInfo.lastName,
            stdTName: stdInfo.nameTitle,
            stdMajor: stdInfo.major,
            stdMajor2: stdInfo.major2 || null,
            stdProgram: stdInfo.program,
            secId: quotaData.secId,
            //
            subCode: subCode,
            subName: subName,
            subYear: subYear,
            group: quotaData.group,
            campus: quotaData.campus,
            //
            offLink: quotaData.offLink,
            quotaId: quotaId,
            reqDate: timestamp,
            reqQutTime: schedule.qRound,
            reqResult: the_result,
            byAdmin: true,
        };
        batch.set(reqQuotaRef, reqQuotaData);

        // update quota
        batch.update(quotaRef, {
            [`q${schedule.qRound}Request`]: firebase.firestore.FieldValue.increment(
                1
            ),
        });

        batch.update(quotaRef, {
            [`q${schedule.qRound}Accept`]: firebase.firestore.FieldValue.increment(1),
        });
        await batch.commit();

        // Update Accept  Quota Number in Vuex
        const quota = context.getters.quota;
        const selectedQuota = quota.find((q) => q.id === quotaId);
        if (selectedQuota) {
            selectedQuota[`q${schedule.qRound}Request`] =
                (selectedQuota[`q${schedule.qRound}Request`] || 0) + 1;
            selectedQuota[`q${schedule.qRound}Accept`] =
                (selectedQuota[`q${schedule.qRound}Accept`] || 0) + 1;
        }

        await context
            .dispatch("setReqQuotaByQuota", { quotaId: quotaId })
            .catch((error) => {
                console.error("Error Set Req Quota By Quota", error);
            });
    },
    async checkReqQuota(context, payload) {
        const quotaId = payload.quotaId;
        const fSemYear = context.rootGetters.fSemYear;
        const stdCode = payload.stdCode;

        if (!quotaId || !stdCode) {
            throw Error("checkReqQuota expected QuotaId and StdCode data ");
        }
        const quotaRef = db.collection(`quota_${fSemYear}`).doc(quotaId);
        const quotaDoc = await quotaRef.get();
        if (isLogVisible()) {
            if (!quotaDoc.exists) {
                console.error("No such document!");
            }
        }
        const quotaData = quotaDoc.data();
        quotaData.id = quotaDoc.id;

        const quotaReqRef = db
            .collection(`quota_request_${fSemYear}`)
            .where("stdCode", "==", stdCode);
        const quotaReqDoc = await quotaReqRef.get();
        if (isLogVisible()) {
            if (!quotaReqDoc.exists) {
                console.error("No such document!");
            }
        }

        const reqQuotaArr = quotaReqDoc.docs.map((doc) => {
            return {...doc.data(), id: doc.id };
        });

        const res = {
            quotaId: true,
            section: true,
            offLink: true,
            subAmt: true,
            // unitAmt: true,
        };

        // get all quota of stdCode that status is accept or pending or reserve
        // check if ... QuotaId is Duplicated
        res.quotaId = !reqQuotaArr.some((req) => {
            return (
                (req.quotaId === quotaData.id) &
                (req.reqResult === "accept" ||
                    req.reqResult === "pending" ||
                    req.reqResult === "reserve")
            );
        });

        // check if ... Section is Duplicated
        res.section = !reqQuotaArr.some((req) => {
            return (
                (req.secId == quotaData.secId) &
                (req.reqResult === "accept" ||
                    req.reqResult === "pending" ||
                    req.reqResult === "reserve")
            );
        });

        // check if ... OffLink is Duplicated
        res.offLink = !reqQuotaArr.some((req) => {
            return (
                (req.offLink === quotaData.offLink) &
                (req.reqResult === "accept" ||
                    req.reqResult === "pending" ||
                    req.reqResult === "reserve")
            );
        });

        // check if ... subject exceed 7 items
        const filteredReq = reqQuotaArr.filter((req) => {
            return (
                req.reqResult === "accept" ||
                req.reqResult === "pending" ||
                req.reqResult === "reserve"
            );
        });
        const isExceed = filteredReq.length >= 7;
        res.subAmt = !isExceed;

        // check if ... unit excedd 35 units
        return res;
    },

    async addReqQuotaBatch(context, quotaArrBatch) {
        // get parameter
        const fSemYear = context.rootGetters.fSemYear;
        const schedule = context.rootGetters.schedule;
        const stdInfo = context.rootGetters.userInfo;

        // if ... new Quota pass all filter

        // batch start

        for (const newQuotaItem of quotaArrBatch) {
            // Collection Reference
            const quotaRef = db.collection(`quota_${fSemYear}`).doc(newQuotaItem.id);
            const newReqQuotaRef = db.collection(`quota_request_${fSemYear}`).doc();

            try {
                let reqQuotaDict;
                await db.runTransaction(async(t) => {
                    // set some data
                    const quota_doc = await t.get(quotaRef);
                    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

                    // update data to quota collection
                    const newRequest =
                        (quota_doc.data()[`q${schedule.qRound}Request`] || 0) + 1;
                    t.update(quotaRef, {
                        [`q${schedule.qRound}Request`]: newRequest,
                    });

                    // write data to quota_request collection
                    reqQuotaDict = {
                        userId: stdInfo.id,
                        stdCode: stdInfo.stdCode,
                        stdFName: stdInfo.firstName,
                        stdLName: stdInfo.lastName,
                        stdTName: stdInfo.nameTitle,
                        stdMajor: stdInfo.major,
                        stdMajor2: stdInfo.major2 || null,
                        stdProgram: stdInfo.program,
                        secId: newQuotaItem.secId,
                        //
                        subCode: newQuotaItem.subCode,
                        subName: newQuotaItem.subName,
                        subYear: newQuotaItem.subYear,
                        group: newQuotaItem.group,
                        campus: newQuotaItem.campus,
                        //
                        offLink: newQuotaItem.offLink,
                        quotaId: newQuotaItem.id,
                        reqDate: timestamp,
                        reqQutTime: schedule.qRound,
                        reqResult: "pending",
                    };
                    t.set(newReqQuotaRef, reqQuotaDict);
                });

                // update Qutoa to Vuex
                const quotaDoc = await quotaRef.get();
                const quota = context.getters.quota;
                const selectedQuota = quota.find((q) => q.id === newQuotaItem.id);
                const index = quota.indexOf(selectedQuota);
                let newQuotaData;
                if (index !== -1) {
                    newQuotaData = { id: quotaDoc.id, ...quotaDoc.data() };
                    quota.splice(index, 1, newQuotaData);
                }
                context.commit("setQuota", quota);

                // add Request Quota to VueX TODO
                context.commit("addReqQuota", {
                    id: newReqQuotaRef.id,
                    ...reqQuotaDict,
                    quota: newQuotaData || newQuotaItem,
                });
            } catch (e) {
                if (isLogVisible()) {
                    console.log("Transaction failure:", e);
                }
            }
        }

        // # Transaction end
    },

    async addReqQuotaOnline(context, quotaArrOnline) {
        // get parameter
        const fSemYear = context.rootGetters.fSemYear;
        const schedule = context.rootGetters.schedule;
        const stdInfo = context.rootGetters.userInfo;

        // if ... new Quota pass all filter

        // batch start

        for (const newQuotaItem of quotaArrOnline) {
            // Collection Reference
            const quotaRef = db.collection(`quota_${fSemYear}`).doc(newQuotaItem.id);
            const newReqQuotaRef = db.collection(`quota_request_${fSemYear}`).doc();

            try {
                let reqQuotaDict;
                await db.runTransaction(async(t) => {
                    // get some data
                    const quota_doc = await t.get(quotaRef);
                    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

                    // update data to quota collection
                    const newRequest =
                        (quota_doc.data()[`q${schedule.qRound}Request`] || 0) + 1;
                    t.update(quotaRef, {
                        [`q${schedule.qRound}Request`]: newRequest,
                    });

                    // write data to quota_request collection
                    reqQuotaDict = {
                        userId: stdInfo.id,
                        stdCode: stdInfo.stdCode,
                        stdFName: stdInfo.firstName,
                        stdLName: stdInfo.lastName,
                        stdTName: stdInfo.nameTitle,
                        stdMajor: stdInfo.major,
                        stdMajor2: stdInfo.major2 || null,
                        stdProgram: stdInfo.program,
                        secId: newQuotaItem.secId,
                        //
                        subCode: newQuotaItem.subCode,
                        subName: newQuotaItem.subName,
                        subYear: newQuotaItem.subYear,
                        group: newQuotaItem.group,
                        campus: newQuotaItem.campus,
                        //
                        offLink: newQuotaItem.offLink,
                        quotaId: newQuotaItem.id,
                        reqDate: timestamp,
                        reqQutTime: schedule.qRound,
                        reqResult: "reserve",
                    };
                    t.set(newReqQuotaRef, reqQuotaDict);
                });

                // update Qutoa to Vuex
                const quotaDoc = await quotaRef.get();
                const quota = context.getters.quota;
                const selectedQuota = quota.find((q) => q.id === newQuotaItem.id);
                const index = quota.indexOf(selectedQuota);
                let newQuotaData;
                if (index !== -1) {
                    newQuotaData = { id: quotaDoc.id, ...quotaDoc.data() };
                    quota.splice(index, 1, newQuotaData);
                }
                context.commit("setQuota", quota);

                // add Request Quota to VueX TODO
                context.commit("addReqQuota", {
                    id: newReqQuotaRef.id,
                    ...reqQuotaDict,
                    quota: newQuotaData || newQuotaItem,
                });
            } catch (e) {
                console.error(e);
                throw e;
            }
        }
    },

    async addReqQuota(context, quotaArr) {
        // get parameter
        const schedule = context.rootGetters.schedule;
        const fSemYear = context.rootGetters.fSemYear;
        const stdInfo = context.rootGetters.userInfo;

        // check if ... user is student  then Restrict user use system from 1 Device
        const isStudent = context.rootGetters.isStudent;

        if (isStudent) {
            const isLastLogin = await context.dispatch("checkLoginId", null, {
                root: true,
            });
            if (!isLastLogin) {
                throw new Error(
                    "ไม่สามารถขอ/แก้ไขโควตา เนื่องจากบัญชีนี้ถูก Login จาก 2 เครื่อง หากต้องดำเนินการต่อบน Device นี้โปรด Logout และ Login ใหม่"
                );
            }
        }

        // Initialize document
        const oldReqQuotaRef = db
            .collection(`quota_request_${fSemYear}`)
            .where("stdCode", "==", stdInfo.stdCode);

        // get all Request Quota of the student quota
        const oldReqQuotaDocs = await oldReqQuotaRef.get();

        // convert firebase response to JSON
        const oldReqQuotaArr = oldReqQuotaDocs.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
            };
        });

        // filter request quota then get only pending,accept,reserve status
        const oldReqQuota = oldReqQuotaArr.filter(
            (q) =>
            q.reqResult === "pending" ||
            q.reqResult === "accept" ||
            q.reqResult === "reserve"
        );

        // check if ... count quotaArr + registerd quota <= 7
        if ((oldReqQuota.length || 0) + (quotaArr.length || 0) > 7) {
            throw new Error(
                "ไม่สามารถยืนยันได้เนื่องจาก โควตาที่คุณได้่ขอโควตาเกิน 7 วิชา"
            );
        }

        for (const newQuotaItem of quotaArr) {
            // check if ... offLink is Duplicated
            const duplicateOfflink = oldReqQuota.some(
                (oldItem) => oldItem.offLink === newQuotaItem.offLink
            );
            if (duplicateOfflink) {
                throw new Error(
                    `ไม่สามารถยืนยันได้เนื่องจาก คุณได้ขอวิชา ${newQuotaItem.subCode} ไปแล้ว`
                );
            }

            // check if ... sec is Duplicated
            const duplicateSec = oldReqQuota.some(
                (oldItem) =>
                oldItem.secId === newQuotaItem.secId && newQuotaItem.secId !== "0000"
            );
            if (duplicateSec) {
                throw new Error(
                    `ไม่สามารถยืนยันได้เนื่องจาก คุณได้ขอ Section ${newQuotaItem.secId} นี้ไปแล้ว`
                );
            }
        }

        // filter quotaArr to Batch and Online
        const onlineQuota = quotaArr.filter((quotaItem) => {
            // Get Quota mode of quota
            const the_program = context.rootGetters["programs/getProgramByCamCode"](
                quotaItem.campus
            );
            const quotaMode = the_program[`quotaMode${schedule.qRound}`];
            return quotaMode === "transaction";
        });

        const batchQuota = quotaArr.filter((quotaItem) => {
            // Get Quota mode of quota
            const the_program = context.rootGetters["programs/getProgramByCamCode"](
                quotaItem.campus
            );
            const quotaMode = the_program[`quotaMode${schedule.qRound}`];
            return quotaMode === "batch";
        });

        if (batchQuota.length > 0) {
            await context.dispatch("addReqQuotaBatch", batchQuota);
        }

        if (onlineQuota.length > 0) {
            await context.dispatch("addReqQuotaOnline", onlineQuota);
        }

        // await context.dispatch("setQuota");
        // await context.dispatch("setReqQuota");
    },

    async updateReqQuotaBatch(context, submittedData) {
        // prepare data
        const quotaId = submittedData.quotaId;
        const field = submittedData.field;
        const theValue = submittedData.value;
        const reqArr = submittedData.reqArr;
        const fSemYear = context.rootGetters.fSemYear;
        const quota = context.getters.quota;
        const storeQuota = quota.find((q) => q.id === quotaId);

        // start batch & loop all Quota Request Array
        const batch = db.batch();
        for (const reqQ of reqArr) {
            const quotaReqRef = db.collection(`quota_request_${fSemYear}`).doc(reqQ);
            const quotaReqRes = await quotaReqRef.get();

            const quotaReqDoc = quotaReqRes.data();
            const reqQuotaRound = quotaReqDoc.reqQutTime;

            if (field !== "result") {
                throw `${field} field doesn't provide in system`;
            }

            // update quota request collection
            // check if ... target value is delete
            if (theValue === "delete") {
                // check if ONLY Request quota by admin can be deleted
                if (!quotaReqDoc.byAdmin) {
                    throw `Can't delete because ONLY Request Quota that is added by admin can be deleted`;
                }
                // if pass ... delete the Request quota
                batch.delete(quotaReqRef);
            } else {
                batch.update(quotaReqRef, {
                    reqResult: theValue,
                    byAdmin: true,
                });
            }

            // update quota collection
            const quotaRef = db.collection(`quota_${fSemYear}`).doc(quotaId);

            if (quotaReqDoc.reqResult === "accept") {
                if (theValue === "reject") {
                    batch.update(quotaRef, {
                        [`q${reqQuotaRound}Accept`]: firebase.firestore.FieldValue.increment(-1),
                    });

                    // update VueX
                    storeQuota[`q${reqQuotaRound}Accept`] =
                        (storeQuota[`q${reqQuotaRound}Accept`] || 0) - 1;
                } else if (theValue === "delete") {
                    batch.update(quotaRef, {
                        [`q${reqQuotaRound}Accept`]: firebase.firestore.FieldValue.increment(-1),
                    });
                    batch.update(quotaRef, {
                        [`q${reqQuotaRound}Request`]: firebase.firestore.FieldValue.increment(-1),
                    });

                    // update VueX
                    storeQuota[`q${reqQuotaRound}Accept`] =
                        (storeQuota[`q${reqQuotaRound}Accept`] || 0) - 1;
                    storeQuota[`q${reqQuotaRound}Request`] =
                        (storeQuota[`q${reqQuotaRound}Request`] || 0) - 1;
                } else if (theValue === "accept") {
                    // pass
                } else {
                    throw `${theValue} field value doesn't provide in system, need to be accept,pending,reject,cancel`;
                }
            }

            if (quotaReqDoc.reqResult === "reject") {
                if (theValue === "accept") {
                    batch.update(quotaRef, {
                        [`q${reqQuotaRound}Accept`]: firebase.firestore.FieldValue.increment(
                            1
                        ),
                    });

                    // update VueX
                    storeQuota[`q${reqQuotaRound}Accept`] =
                        (storeQuota[`q${reqQuotaRound}Accept`] || 0) + 1;
                } else if (theValue === "delete") {
                    batch.update(quotaRef, {
                        [`q${reqQuotaRound}Request`]: firebase.firestore.FieldValue.increment(-1),
                    });

                    // update VueX
                    storeQuota[`q${reqQuotaRound}Request`] =
                        (storeQuota[`q${reqQuotaRound}Request`] || 0) - 1;
                } else if (theValue === "reject") {
                    // pass
                } else {
                    throw `${theValue} field value doesn't provide in system, need to be accept,pending,reject,cancel`;
                }
            }

            if (quotaReqDoc.reqResult === "cancel") {
                throw `student ${quotaReqDoc.stdCode} : cancel status can't update to other status`;
            }
        }

        await batch.commit();

        await context
            .dispatch("setReqQuotaByQuota", { quotaId: quotaId })
            .catch((error) => {
                console.error("Error Set Req Quota By Quota", error);
            });
    },

    async cancelReqQuota(context, formatRequestQuota) {
        const qRound = formatRequestQuota.reqQutTime;
        const quotaId = formatRequestQuota.quota.id;
        const quotaReqId = formatRequestQuota.id;

        const fSemYear = context.rootGetters.fSemYear;
        const isStudent = context.rootGetters.isStudent;

        // check if ... user is student  then Restrict user use system from 1 Device
        if (isStudent) {
            const isLastLogin = await context.dispatch("checkLoginId", null, {
                root: true,
            });
            if (!isLastLogin) {
                throw new Error(
                    "ไม่สามรถขอ/แก้ไขโควตา เนื่องจากบัญชีนี้ถูก Login จาก 2 เครื่อง หากต้องดำเนินการต่อบน Device นี้โปรด Logout และ Login ใหม่"
                );
            }
        }

        // Initialize document
        const reqQuotaRef = db
            .collection(`quota_request_${fSemYear}`)
            .doc(quotaReqId);
        const quotaRef = db.collection(`quota_${fSemYear}`).doc(quotaId);

        // update data to server
        try {
            await db.runTransaction(async(t) => {
                // get request quota docdument then check ... if It's pending status, deleting doc else update req Result to cancel
                const oldReqdoc = await t.get(reqQuotaRef);
                const quotaDoc = await t.get(quotaRef);

                // update request quota
                if (
                    oldReqdoc.data().reqResult === "pending" ||
                    oldReqdoc.data().reqResult === "reserve"
                ) {
                    t.delete(reqQuotaRef);

                    // update quota
                    t.update(quotaRef, {
                        [`q${qRound}Request`]: quotaDoc.data()[`q${qRound}Request`] - 1,
                    });
                } else if (oldReqdoc.data().reqResult === "accept") {
                    // if result is accept set result to cancel and update Accept = -1
                    t.update(reqQuotaRef, {
                        reqResult: "cancel",
                        byAdmin: false,
                    });
                    t.update(quotaRef, {
                        [`q${qRound}Accept`]: quotaDoc.data()[`q${qRound}Accept`] - 1,
                    });
                } else {
                    throw Error(
                        "Cancel Error, This Result In request Quota is not supported"
                    );
                }
            });

            // get new request quota data from DB
            const newReqDoc = await reqQuotaRef.get().catch((error) => {
                console.error("Error getting documents: ", error);
                throw error;
            });
            if (newReqDoc.empty) {
                console.error("No such document!");
                throw Error("can't get this quota from DB");
            }

            // get new quota data from DB
            const quotaDoc = await quotaRef.get().catch((error) => {
                console.error("Error getting documents: ", error);
            });
            if (quotaDoc.empty) {
                console.error("No such document!");
                throw Error("can't get this quota from DB");
            }

            // update Qutoa to Vuex
            const quota = context.getters.quota;
            const selectedQuota = quota.find((q) => q.id === quotaDoc.id);
            const qIndex = quota.indexOf(selectedQuota);
            const newQuotaData = { id: quotaDoc.id, ...quotaDoc.data() };
            if (qIndex !== -1) {
                quota.splice(qIndex, 1, newQuotaData);
            }

            // edit Request Quota to VueX
            const reqQuota = context.getters.reqQuota;
            const selectedReqQuota = reqQuota.find((rq) => rq.id === newReqDoc.id);
            const rqIndex = reqQuota.indexOf(selectedReqQuota);
            if (rqIndex !== -1) {
                reqQuota.splice(rqIndex, 1, {
                    id: newReqDoc.id,
                    ...newReqDoc.data(),
                    quota: newQuotaData,
                });
            }

            context.commit("setQuota", quota);
            context.commit("setReqQuota", reqQuota);
        } catch (e) {
            if (isLogVisible()) {
                console.log("Transaction failure:", e);
            }
        }
    },

    async getAllReqQuota(context, payload) {
        // prepare parameter
        const fSemYear = context.rootGetters.fSemYear;
        const userProgram = context.rootGetters["programs/getUserProgram"];

        const quotaRef = db.collection(`quota_request_${fSemYear}`);
        const quotaDocs = await quotaRef
            .where("reqResult", "==", "accept")
            .where("campus", "==", userProgram.camCode)
            .where("reqQutTime", "in", payload.roundArray)
            .get()
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });

        if (quotaDocs.empty) {
            if (isLogVisible()) {
                const error = new Error("No such document!");
                console.error(error);
            }
        }

        const quota = [];
        quotaDocs.forEach((doc) => {
            const newQuota = {
                ...doc.data(),
                stdMajor2: doc.data().stdMajor2 || null,
                byAdmin: doc.data().byAdmin || null,
                id: doc.id,
            };
            quota.push(newQuota);
        });

        return QuodSort(quota, ["subCode", "secId", "group", "stdCode"]);
    },

    // BasketQuota Section
    trySetBskQuota(context) {
        const stdCode = context.rootGetters.userId;
        const allBskQuota = JSON.parse(localStorage.getItem("bskQuota"));

        if (allBskQuota && allBskQuota[stdCode]) {
            context.commit("setBskQuota", allBskQuota[stdCode]);
        } else {
            context.commit("setBskQuota", []);
        }
    },

    addBskQuota(context, quota) {
        // Update data in Vuex
        context.commit("addBskQuota", quota);
    },

    clearBskQuota(context) {
        context.commit("setBskQuota", []);
    },

    deleteBskQuota(context, quotaId) {
        // update data in VueX
        const uBskQuota = context.state.bskQuota;
        const selectedQ = uBskQuota.find((q) => q.id === quotaId);
        var index = uBskQuota.indexOf(selectedQ);

        if (index !== -1) {
            uBskQuota.splice(index, 1);
        }

        context.commit("setBskQuota", uBskQuota);
    },

    async getResStatus(context, payload) {
        const quotaId = payload.quotaId;
        const reqId = payload.reqId;
        const reqRound = payload.reqRound;
        const fSemYear = context.rootGetters.fSemYear;

        const quotaRef = db.collection(`quota_${fSemYear}`).doc(quotaId);
        const quotaDoc = await quotaRef.get().catch((error) => {
            console.error("Error getting documents: ", error);
            throw new Error(`Your Internet connection is not available: ${error}`);
        });
        if (isLogVisible()) {
            if (quotaDoc.empty) {
                const error = new Error("No such document!");
                console.error(error);
            }
        }

        const quota_data = {
            ...quotaDoc.data(),
            id: quotaDoc.id,
        };

        const quotaReqRef = db.collection(`quota_request_${fSemYear}`);
        const quotaReqDocs = await quotaReqRef
            .where("quotaId", "==", quotaId)
            .where("reqQutTime", "==", reqRound)
            .where("reqResult", "==", "reserve")
            // .orderBy("reqDate")
            .get()
            .catch((error) => {
                console.error("Error getting documents: ", error);
                throw new Error(`Your Internet connection is not available: ${error}`);
            });

        if (isLogVisible()) {
            if (quotaReqDocs.empty) {
                const error = new Error("No such document!");
                console.error(error);
            }
        }

        let reqQuotaArr = quotaReqDocs.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
            };
        });

        // sort array by reqDate
        reqQuotaArr = reqQuotaArr.sort(
            (objA, objB) =>
            Number(objA.reqDate.toDate()) - Number(objB.reqDate.toDate())
        );

        // const reqInRound = reqQuotaArr.filter(
        //     (q) => q.reqQutTime == reqRound && q.reqResult === "reserve"
        // );
        const reqPosition = reqQuotaArr.findIndex((q) => q.id === reqId);

        // recheck array
        if (reqQuotaArr.length === 0 || reqPosition === -1) {
            // return { resPosition: 99999 };
            throw new Error(`Your Internet connection is not available`);
        }

        const qOffer = quota_data[`q${reqRound}Offer`];
        const resPos = reqPosition - qOffer + 1;

        if (resPos <= 0) {
            return { resPosition: 0 };
        } else {
            return { resPosition: resPos };
        }
    },
    async getReqQuota(context, payload) {
        const allowMode = ["stdCode"];
        if (!allowMode.includes(payload.mode)) {
            throw Error(`mode ${payload.mode} isn't supported in getReqQuota`);
        }

        // common varaiable
        const fSemYear = context.rootGetters.fSemYear;
        if (payload.mode === "stdCode") {
            const stdCode = payload.searchValue.stdCode;

            const quotaReqRef = db.collection(`quota_request_${fSemYear}`);
            const quotaReqDocs = await quotaReqRef
                .where("stdCode", "==", stdCode)
                .get()
                .catch((error) => {
                    console.error("Error getting documents: ", error);
                });

            let quotaRequestArray = quotaReqDocs.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });
            const quotaId = payload.searchValue.quotaId;
            quotaRequestArray = quotaRequestArray.filter((q) => q.quotaId == quotaId);

            // filter quotaId
            if ("quotaId" in payload.searchValue && payload.searchValue.quotaId) {
                const quotaId = payload.searchValue.quotaId;
                quotaRequestArray = quotaRequestArray.filter(
                    (q) => q.quotaId == quotaId
                );
            }

            // filter round/reqQutTime
            if (
                "reqQutTime" in payload.searchValue &&
                payload.searchValue.reqQutTime
            ) {
                const reqQutTime = payload.searchValue.reqQutTime;
                quotaRequestArray = quotaRequestArray.filter(
                    (q) => q.reqQutTime == reqQutTime
                );
            }

            // filter status/reqResult
            if ("reqResult" in payload.searchValue && payload.searchValue.reqResult) {
                const reqResult = payload.searchValue.reqResult;
                quotaRequestArray = quotaRequestArray.filter(
                    (q) => q.reqResult == reqResult
                );
            }

            return quotaRequestArray;
        }
    },
};