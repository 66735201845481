// import { isLogVisible } from "../../../js/debug.js";
import { db, storage } from "../../../js/firebaseDb";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
    async setPetitionReason(context) {
        const colRef = db.collection(`quota_petition_reasons`);

        await colRef.onSnapshot((querySnapshot) => {
            if (querySnapshot.empty) {
                context.commit("setPetitionReason", []);
            }
            const resArr = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });

            context.commit("setPetitionReason", resArr);
        });
    },
    async addQuotaPetition(context, payload) {
        const schedule = context.rootGetters.schedule;
        const fSemYear = context.rootGetters.fSemYear;
        const stdCode = payload.studentInfo.stdCode;
        const quotaInfo = payload.quotaInfo;
        const studentInfo = payload.studentInfo;
        const theFile = payload.theFile;

        // alter Subcode => save last subcode to DB
        const last4Year = context.rootGetters["years/getLast4Years"].reverse();
        let subCode = null;
        for (const aYear of last4Year) {
            if (quotaInfo[`sub${aYear.name}`] !== undefined) {
                subCode = quotaInfo[`sub${aYear.name}`]["code"];
                break;
            }
        }
        if (!subCode) {
            throw Error(`can't find subject code ${quotaInfo.subCode}`);
        }

        // upload data
        // prepare data
        // const dt = new Date();
        const storageRef = storage.ref();
        const pdfRef = storageRef.child(
            `${fSemYear}/${schedule.qRound}/${subCode}_${quotaInfo.secId}${quotaInfo.group}_${stdCode}_${studentInfo.firstName}_${studentInfo.lastName}.pdf`
        );

        // upload file to server
        const uploadTask = await pdfRef.put(theFile);
        const downloadURL = await uploadTask.ref.getDownloadURL();
        // alter payload
        delete payload.theFile;
        payload["file"] = downloadURL;
        payload["time"] = firebase.firestore.FieldValue.serverTimestamp();
        payload["stdCode"] = stdCode;
        payload["subCode"] = subCode;
        payload["subMajorId"] = quotaInfo.majorId;

        // save ref file to db
        db.collection("quota_petitions")
            .add(payload)
            .catch((error) => {
                console.error(error);
                throw error;
            });
    },
    async updatePetition(context, payload) {
        const colRef = db.collection(`quota_petitions`).doc(payload.id);
        await colRef.delete();
    },
    async setStdPetition(context) {
        const userInfo = context.rootGetters.userInfo;
        const schedule = context.rootGetters.schedule;
        const fSemYear = context.rootGetters.fSemYear;

        const colRef = db
            .collection(`quota_petitions`)
            .where("fSemYear", "==", fSemYear)
            .where("stdCode", "==", userInfo.stdCode);
        const userYear = context.rootGetters["years/getYearByStdCode"](
            userInfo.stdCode
        );

        await colRef.onSnapshot((querySnapshot) => {
            if (querySnapshot.empty) {
                context.commit("setStdPetitions", []);
            }
            let resArr = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                    stdSubCode: doc.data().quotaInfo[`sub${userYear.name}`].code,
                    stdSubName: doc.data().quotaInfo[`sub${userYear.name}`].name,
                    stdSubYear: doc.data().quotaInfo[`sub${userYear.name}`].year,
                };
            });

            resArr = resArr.filter((q) => {
                return q.qRound === schedule.qRound;
            });

            context.commit("setStdPetitions", resArr);
        });
    },
};