import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

export default {
    namespaced: true,
    state() {
        return {
            students: [],
            status: [
                // {
                //     id: 1,
                //     name: "ปัจจุบัน",
                // },
                // {
                //     id: 2,
                //     name: "จบ",
                // },
                // {
                //     id: 3,
                //     name: "ลาพัก - โทษทางวินัย",
                // },
                // {
                //     id: 4,
                //     name: "เข้าร่วมโครงการแลกเปลี่ยน",
                // },
                // {
                //     id: 5,
                //     name: "ลาพัก - ลาศึกษา",
                // },
                // {
                //     id: 6,
                //     name: "ลาออก",
                // },
                // {
                //     id: 7,
                //     name: "ถูกถอนชื่อ",
                // },
                // {
                //     id: 9,
                //     name: "เสียชีวิต",
                // },
                // {
                //     id: 10,
                //     name: "ทัณฑ์บน-โทษทางวินัย",
                // },
                // {
                //     id: 11,
                //     name: "ไล่ออก",
                // },
                // {
                //     id: 13,
                //     name: "ย้ายหลักสูตร",
                // },
                // {
                //     id: 14,
                //     name: "ทดสอบ",
                // },
            ],
        };
    },
    getters,
    actions,
    mutations,
};