<template>
  <div class="flex flex-col w-full">
    <div
      class="text-white bg-primary-200 flex flex-shrink-0 flex-col pl-4 py-4"
    >
      <div class="flex relative  items-center p-4 h-12">
        <span class="text-2xl tracking-wide font-semibold">กำหนดการ</span>
      </div>
    </div>
  </div>
  <div class="px-4">
    <div class=" flex justify-center items-center" v-if="loadingData">
      <base-spinner class="my-10"></base-spinner>
    </div>
    <div class="px-0 md:px-20" v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingData: false,
    };
  },
};
</script>

<style scoped>
a:active,
a:hover,
a.router-link-active {
  @apply border-b-4 border-white pt-1;
}

a:hover span,
a:active span,
a.router-link-active span {
  @apply font-extrabold;
}
</style>
