export default {
    setSection(state, payload) {
        state.sections = payload;
    },
    //new
    addSection(state, payload) {
        state.sections.push(payload);
    },
    //new
    updateSection(state, newSection) {
        const sections = state.sections;
        const selecedSection = sections.find((s) => s.id === newSection.id);
        const index = sections.indexOf(selecedSection);

        if (index !== -1) {
            sections.splice(index, 1, newSection);
        }
    },
    deleteSection(state, payload) {
        const sections = state.sections;
        const selectedSec = sections.find((s) => s.id === payload.id);
        const index = sections.indexOf(selectedSec);

        if (index !== -1) {
            sections.splice(index, 1);
        }
    },
};