import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

export default {
  namespaced: true,
  state() {
    return {
      years: [
        // { text: "2552", value: 2552, previousYear: 0 },
        // { text: "2556", value: 2556, previousYear: 2552 },
        // { text: "2558", value: 2558, previousYear: 2556 },
        // { text: "2561", value: 2561, previousYear: 2558 },
        // --------- NEW --------- //
        // { id: "y1", name: 2561 },
      ],
      subjects: [],
    };
  },
  getters,
  actions,
  mutations,
};
