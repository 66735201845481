<template>
  <div
    class="border border-gray-200 shadow-lg rounded-xl my-4 p-4 "
    :class="{
      'max-w-4xl': cardW === 'xl',
      'max-w-md': cardW === 'md',
      'max-w-sm': cardW === 'sm',
      'mx-auto': mxAuto,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    cardW: {
      type: String,
      required: false,
      default: "xl",
    },
    mxAuto: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style scoped></style>
