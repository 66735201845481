<template>
  <tr>
    <td class=" px-1">
      <serach-input
        :options="years"
        placeHolder="year"
        :setValue="null"
        @selectedValue="selectYear"
        textField="name"
        valueField="name"
        :error="!year.isValid"
        :reverse="true"
      ></serach-input>
    </td>
    <td class=" px-1">
      <span v-if="logVisible">
        {{ formValid }}
      </span>
      <!-- <input type="text" class="form-control" placeholder="" value="AC201" /> -->
      <serach-input
        :options="subjects"
        placeHolder="code"
        :setValue="null"
        @selectedValue="selectSubjectCode"
        textField="code"
        valueField="code"
        :error="!subjectCode.isValid"
        @input="subjectCode.isValid = true"
        :disabled="subjectCode.disabled"
        :key="subComponentKey"
      ></serach-input>
    </td>
    <td class=" px-1 pt-1">
      <input
        type="text"
        class="form-control "
        placeholder=""
        v-model="subName"
        disabled
      />
    </td>
    <td class=" px-1">
      <serach-input
        :options="sections"
        placeHolder=""
        :setValue="null"
        @selectedValue="selectSec"
        textField="fullName"
        valueField="code"
        :error="!sec.isValid"
      ></serach-input>
    </td>
    <td class="px-1 pt-1">
      <select
        class="form-control"
        @input="group.isValid = true"
        :class="{
          errors: !group.isValid,
        }"
        v-model="group.val"
      >
        <option disabled value="">Please select one</option>
        <option value="01">01</option>
        <option value="02">02</option>
        <option value="03">03</option>
        <option value="04">04</option>
        <option value="05">05</option>
        <option value="06">06</option>
        <option value="07">07</option>
        <option value="08">08</option>
        <option value="09">09</option>
        <option value="10">10</option>
      </select>
    </td>
    <td class=" px-1 pt-1">
      <input
        type="text"
        list="Cam"
        class="form-control"
        placeholder=""
        v-model="campus.val"
        :class="{
          errors: !campus.isValid,
        }"
        @input="campus.isValid = true"
        disabled
      />
      <datalist id="Cam">
        <option value="R">R</option>
        <option value="T">T</option>
      </datalist>
    </td>
    <td class="px-1 pt-1">
      <input
        type="text"
        class="form-control"
        placeholder=""
        v-model.number="quota.val"
        @input="quota.isValid = true"
        :class="{
          errors: !quota.isValid,
        }"
      />
    </td>
    <td class=" px-1 pb-0.5">
      <Multiselect
        v-model="instructor.val"
        mode="tags"
        :close-on-select="false"
        :searchable="true"
        :create-option="false"
        :options="instructorsOption"
        @input="instructor.isValid = true"
        :class="{
          'bg-red-200': !instructor.isValid,
        }"
      />
      <!-- {{ instructor.val }} -->
    </td>
    <td class=" px-1">
      <!-- <select
        class="form-control"
        :class="{
          errors: !condition.isValid,
        }"
        v-model="condition.val"
      >
        <option disabled value="">Please select one</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
      </select> -->
      <select
        class="form-control px-1"
        :class="{ errors: !condition.isValid }"
        placeholder=""
        @input="condition.isValid = true"
        v-model.number="condition.val"
      >
        <option v-for="cond in condCode" :value="cond" :key="cond">
          {{ cond }}
        </option>
      </select>
    </td>
    <td class=" px-1">
      <button @click="$emit('deleteForm', id)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-gray-500 hover:text-gray-700"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </td>
    <!-- <td class=" px-1">
      <input type="text" class="form-control" placeholder="" value="not use" />
    </td>
    <td class=" px-1">
      <input type="text" class="form-control" placeholder="" value="not use" />
    </td>
    <td class=" px-1">
      <input type="text" class="form-control" placeholder="" value="not use" />
    </td> -->
  </tr>
  <tr v-if="logVisible">
    <td>{{ year.val }} | {{ year.isValid }}</td>
    <td>{{ subjectCode.val }} | {{ subjectCode.isValid }}</td>
    <td>{{ subName }}</td>
    <td>{{ sec.val }} | {{ sec.isValid }}</td>
    <td>{{ group.val }} | {{ group.isValid }}</td>
    <td>{{ campus.val }} | {{ campus.isValid }}</td>
    <td>{{ quota.val }} | {{ quota.isValid }}</td>
    <td>{{ instructor.val }} | {{ instructor.isValid }}</td>
    <td>{{ condition.val }} | {{ condition.isValid }}</td>
    <td>{{ program.camCode }}</td>
  </tr>
</template>

<script>
import SerachInput from "../../components/UI/SearchInput.vue";
import { isLogVisible } from "../../js/debug.js";
import Multiselect from "@vueform/multiselect";
export default {
  data() {
    return {
      FilteredSubjectByCode: [],
      formValid: true,
      loading: null,

      // system
      subOffLink: null,
      subName: null,
      subId: null,
      majorId: null,
      subComponentKey: 0,

      // user
      subjectCode: {
        val: null,
        isValid: true,
        disabled: false,
      },
      year: {
        val: null,
        isValid: true,
      },

      sec: {
        val: null,
        isValid: true,
      },
      group: {
        val: null,
        isValid: true,
      },
      campus: {
        val: null,
        isValid: true,
      },
      quota: {
        val: null,
        isValid: true,
      },
      instructor: {
        val: null,
        isValid: true,
      },
      condition: {
        val: null,
        isValid: true,
      },

      selectedInstructors: null,
      // selectedRoom: null,
      // selectedMidTerm: null,
      // selectedFinal: null,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    "serach-input": SerachInput,
    Multiselect,
  },
  emits: ["quotaStatus", "deleteForm"],
  created() {
    this.setDefault();
    this.hasYear();
  },
  watch: {
    year: {
      deep: true,
      handler() {
        this.updateName();
        this.hasYear();
      },
    },
    subjectCode: {
      deep: true,
      handler() {
        this.updateYearData();
        this.updateName();
      },
    },
  },

  computed: {
    condCode() {
      return this.$store.getters["conditions/condCode"];
    },
    years() {
      return this.$store.getters["years/years"];
    },
    program() {
      return this.$store.getters["programs/getUserProgram"];
    },
    logVisible() {
      return isLogVisible();
    },
    subjects() {
      if (this.year.val) {
        return this.$store.getters["subjects/getSubjectByYear"]([
          this.year.val,
        ]);
      } else {
        return [];
      }
    },
    sections() {
      return this.$store.getters["sections/fullName"];
    },
    instructors() {
      return this.$store.getters["instructors/fullName"];
    },
    instructorsOption() {
      const instructorsArr = this.instructors.map((doc) => {
        return {
          label: `${doc.prefix} ${doc.name} ${doc.surname}`,
          value: doc.id,
        };
      });
      return instructorsArr;
    },
  },
  methods: {
    forceRerender() {
      this.subComponentKey += 1;
    },
    hasYear() {
      if (this.year.val) {
        this.subjectCode.disabled = false;
      } else {
        this.subjectCode.disabled = true;
      }
    },
    setDefault() {
      this.campus.val = this.program.camCode;
    },
    test() {
      alert("working.." + this.id);
    },
    async submitData() {
      if (isLogVisible()) {
        console.log("starting submitData in AddQuotaItem");
      }

      const submittedData = {
        subId: this.subId,
        majorId: this.majorId,
        offLink: this.subOffLink,
        secId: this.sec.val,
        group: this.group.val,
        campus: this.campus.val,
        quota: this.quota.val,
        instId: this.instructor.val,
        condition: this.condition.val,
      };

      await this.$store.dispatch("quota/addQuota", submittedData);
      // await new Promise((r) => setTimeout(r, 2000));
      this.$emit("quotaStatus", {
        id: this.id,
        valid: this.formValid,
        loading: "completed",
      });
      // this.$router.replace("/quota");
    },
    async isFormValid() {
      this.formValid = true;
      this.subjectCode.isValid = true;
      this.year.isValid = true;
      this.sec.isValid = true;
      this.group.isValid = true;
      this.campus.isValid = true;
      this.quota.isValid = true;
      this.instructor.isValid = true;
      this.condition.isValid = true;

      if (!this.subjectCode.val) {
        this.formValid = false;
        this.subjectCode.isValid = false;
      }
      if (!this.year.val) {
        this.formValid = false;
        this.year.isValid = false;
      }
      if (!this.sec.val) {
        this.formValid = false;
        this.sec.isValid = false;
      }

      if (!this.group.val) {
        this.formValid = false;
        this.group.isValid = false;
      }
      if (
        !Number.isInteger(Number(this.group.val)) ||
        Number(this.group.val) < 0
      ) {
        this.formValid = false;
        this.group.isValid = false;
      }
      if (!this.campus.val) {
        this.formValid = false;
        this.campus.isValid = false;
      }
      if (!this.quota.val && this.quota.val !== 0) {
        this.formValid = false;
        this.quota.isValid = false;
      }
      if (!Number.isInteger(this.quota.val) || this.quota.val < 0) {
        this.formValid = false;
        this.quota.isValid = false;
      }

      if (!this.instructor.val || this.instructor.val.length === 0) {
        this.formValid = false;
        this.instructor.isValid = false;
      }
      if (!this.condition.val) {
        this.formValid = false;
        this.condition.isValid = false;
      }

      // checl section duplicate
      const isDuplicate = await this.$store.dispatch(
        "quota/isSectionDuplicate",
        {
          offLink: this.subOffLink,
          secId: this.sec.val,
          group: this.group.val,
        }
      );
      if (isDuplicate) {
        this.formValid = false;
        this.sec.isValid = false;
        this.group.isValid = false;
      }
    },
    updateYearData() {
      this.FilteredSubjectByCode = this.$store.getters[
        "subjects/getSubjectByCode"
      ](this.subjectCode.val);
    },
    updateName() {
      const sub = this.$store.getters["subjects/getSubjectByCodeAndYear"]({
        code: this.subjectCode.val,
        year: this.year.val,
      });

      if (sub) {
        this.subName = sub.name;
        this.condition.val = sub.condition;
        this.subId = sub.id;
        this.majorId = sub.majorId;
        this.subOffLink = sub.offLink;
      }
    },
    selectSubjectCode(val) {
      this.subjectCode.isValid = true;
      this.subjectCode.val = val;
    },
    selectYear(val) {
      this.subjectCode.val = null;
      this.forceRerender();
      this.subOffLink = null;
      this.subName = null;
      this.subId = null;
      this.condition.val = null;
      this.year.isValid = true;
      this.year.val = val;
    },
    selectSec(val) {
      this.sec.isValid = true;
      this.sec.val = val;
    },
  },
};
</script>
