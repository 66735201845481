<template>
  <div class="min-h-screen bg-blue-100 p-0 sm:p-12">
    <!-- gray-100 -->
    <div
      class="mx-auto max-w-md lg:max-w-lg px-6 py-12 bg-white border-0 shadow-lg sm:rounded-3xl"
    >
      <div v-if="!isComplete">
        <h1 class="text-2xl font-semi-bold mb-8 text-center">
          มีปัญหาในการลงชื่อเข้าหรือไม่?
        </h1>
        <!-- https://tailwindcomponents.com/component/steps-bar -->
        <!-- https://tailwindcomponents.com/component/floating-form-labels -->

        <form id="form" novalidate v-if="!isComplete">
          <h2 class="text-base font-normal pb-4 text-gray-500">
            Enter the email address associated with your account and we'll send
            you a link to reset your password.
          </h2>
          <div class="relative z-0 w-full mb-6 mt-6">
            <input
              type="email"
              name="email"
              v-model.trim="email.val"
              placeholder=" "
              class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
            />
            <label
              for="email"
              class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
              >name@example.com</label
            >
            <span class="text-sm text-red-500 " v-if="!!email.error">{{
              email.error
            }}</span>
          </div>
          <button
            id="button"
            type="button"
            class="w-full px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-pink-500 hover:bg-pink-600 hover:shadow-lg focus:outline-none"
            @click.prevent="stdSignUp()"
          >
            Continue
          </button>
        </form>
      </div>

      <div v-else>
        <div class="flex justify-center py-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-14 w-14 text-green-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div class="text-base text-center py-6">
          เราได้ส่งคำแนะนำไปยังที่อยู่อีเมลของคุณ <br />
          โปรดตรวจสอบ และทำตามคำแนะนำ
        </div>
        <div class="flex justify-center">
          <router-link
            id="button"
            type="button"
            class="text-center w-1/2 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-blue-500 hover:bg-blue-600 hover:shadow-lg focus:outline-none"
            :to="{ name: 'login' }"
          >
            เข้าสู่ระบบ
          </router-link>
        </div>
      </div>
      <!-- <div v-if="isLogVisible">
        <base-button @click.prevent="regStep = 1">1</base-button>
        <base-button @click.prevent="regStep = 2">2</base-button>
        <base-button @click.prevent="regStep = 3">3</base-button>
        <base-button @click.prevent="regStep = 4">4</base-button>
      </div> -->
    </div>
  </div>
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
</template>

<script>
// หลักสูตรไม่ต้องมี และมีคำนำหน้า
import { auth } from "../../js/firebaseDb.js";
import { isLogVisible } from "../../js/debug.js";
export default {
  data() {
    return {
      form1IsValid: true,
      form2IsValid: true,
      email: {
        val: null,
        error: null,
      },

      // system
      isComplete: false,
      cfPassword: null,
      sucEmail: null,
      error: null,
      formValid: true,
    };
  },
  computed: {
    isLogVisible() {
      return isLogVisible;
    },
  },
  methods: {
    closeError() {
      this.error = null;
    },
    isFormValid() {
      this.formValid = true;
      this.checkEmail();
    },
    checkEmail() {
      this.email.error = null;
      const regex = /^[^\s@]+@[^\s@]+$/g;
      if (!this.email.val || this.email.val == "") {
        this.email.error = "Email ไม่สามารถเว้นว่างได้";
        this.formValid = false;
      } else if (!regex.test(this.email.val)) {
        this.email.error = "รูปแบบ Email ไม่ถูกต้อง ";
        this.formValid = false;
      }
    },
    stdSignUp() {
      // check validation

      this.isFormValid();
      if (!this.formValid) {
        return;
      }

      auth
        .sendPasswordResetEmail(this.email.val)
        .then(() => {
          this.isComplete = true;
          this.email.val = "";
        })
        .catch((error) => {
          this.error = `${error.code} : ${error.message}`;
        });
    },
  },
};
</script>

<style>
.-z-1 {
  z-index: -1;
}

.origin-0 {
  transform-origin: 0%;
}

input:focus ~ label,
input:not(:placeholder-shown) ~ label,
textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label,
select:focus ~ label,
select:not([value=""]):valid ~ label {
  /* @apply transform; scale-75; -translate-y-6; */
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
  --tw-translate-y: -1.5rem;
}

input:focus ~ label,
select:focus ~ label {
  /* @apply text-black; left-0; */
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  left: 0px;
}

.step-bar-active {
  @apply bg-green-500;
}

.step-icon-active {
  @apply bg-green-500 text-white;
}

.step-bar-inactive {
  @apply bg-gray-200;
}
.step-icon-inactive {
  @apply bg-white border-2 border-gray-200 text-gray-600;
}

input.errors {
  @apply border-red-500 bg-red-50;
}
</style>
