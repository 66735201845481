import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles.css";
import BaseCard from "./components/UI/BaseCard.vue";
import BaseButton from "./components/UI/BaseButton.vue";
import BaseDialog from "./components/UI/BaseDialog.vue";
import Toast from "./components/UI/Toast.vue";
import BaseSpinner from "./components/UI/BaseSpinner.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faUserSecret,
    faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faUserSecret, faAngleDoubleUp);

import { auth } from "./js/firebaseDb.js";

let app;
auth.onAuthStateChanged(() => {
    if (!app) {
        app = createApp(App);
        app.component("base-card", BaseCard);
        app.component("base-button", BaseButton);
        app.component("font-awesome-icon", FontAwesomeIcon);
        app.component("base-dialog", BaseDialog);
        app.component("base-spinner", BaseSpinner);
        app.component("toast", Toast);
        app.use(store);
        app.use(router);
        app.mount("#app");
    }
});