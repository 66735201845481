export default {
    computed: {
        staffCanEdit() {
            const inTime = !!this.staffRule && !!this.staffRule.rule && !!this.staffRule.rule.edit;
            return inTime || this.isSudo;
        },
        staffCanLogin() {
            const inTime = !!this.staffRule &&
                !!this.staffRule.rule &&
                !!this.staffRule.rule.login;
            return inTime || this.isSudo;
        },
        isSudo() {
            return (
                this.$store.getters.userInfo &&
                this.$store.getters.userInfo.role &&
                this.$store.getters.userInfo.role === "A"
            );
        },
        staffRule() {
            return this.$store.getters["currentScheduleRuleStaff"];
        },
        staffCanEditPetition() {
            const inTime = !!this.staffRule &&
                !!this.staffRule.rule &&
                !!this.staffRule.rule.petition;
            return inTime || this.isSudo;
        },
    },
};