export default {
    setToast(state, payload) {
        // console.log(payload);
        state.toast = payload;
    },
    setUserProgram(state, payload) {
        // console.log(payload);
        state.userProgram = payload;
    },
    setUserRole(state, payload) {
        state.userRole = payload;
    },
    clearUserInfo(state) {
        state.userInfo = null;
    },
    setUserInfo(state, payload) {
        state.userInfo = payload;
    },
    setUserId(state, payload) {
        state.userId = payload;
    },
    setUserState(state, payload) {
        state.userState = payload;
    },
    setLoginId(state, payload) {
        state.loginId = payload;
    },
    setConsentInfo(state, payload) {
        state.consentInfo = payload;
    },
    clearLoginId(state) {
        state.loginId = null;
    },
    setStatusChange(state, payload) {
        state.statusChange = payload;
    },
    setMajorChange(state, payload) {
        state.majorChange = payload;
    },
};