<template>
  <div class="px-2">
    <base-card>
      <h3 class="text-center pt-6">รายวิชา {{ uProgram.name }}</h3>

      <div class="flex justify-center py-8 ">
        <base-button mode="outline" class="mx-2" @click="toggleAddForm()"
          >เพิ่มหลักสูตร</base-button
        >
        <base-button
          mode="outline"
          class="mx-2"
          @click="
            showForm !== 'deleteCur'
              ? (showForm = 'deleteCur')
              : (showForm = null)
          "
          >ลบหลักสูตร</base-button
        >
        <base-button class="mx-2" mode="outline" @click="addSubject"
          >เพิ่มวิชา</base-button
        >

        <base-button @click="submitData" class=" px-16 mx-2"
          >บันทึกข้อมูล</base-button
        >

        <!-- <base-button @click="deleteSubjects">ลบวิชา</base-button> -->
        <!-- <base-button @click="null">ลบหลักสูตร</base-button> -->
      </div>
      <div></div>
      <div v-if="showForm === 'addCur'" class="flex justify-center">
        <div class="max-w-lg ">
          <h5 class="text-center pt-4">เพิ่มหลักสูตร</h5>
          <form @submit.prevent="addCurriculum()">
            <div class="w-80">
              <div>
                <label>ปีการศึกษา</label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ errors: v$.copyToCur.$error }"
                  @blur="v$.copyToCur.$touch"
                  v-model.trim="copyToCur"
                />
                <span
                  class="errors"
                  v-for="error of v$.copyToCur.$errors"
                  :key="error.$uid"
                  >{{ error.$message }}<br />
                </span>
              </div>
              <div class="pt-2">
                <label>ส่งผลกับนักศึกษารหัส</label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ errors: v$.effectEst.$error }"
                  @blur="v$.effectEst.$touch"
                  v-model.trim="effectEst"
                />
                <span
                  class="errors"
                  v-for="error of v$.effectEst.$errors"
                  :key="error.$uid"
                  >{{ error.$message }}<br />
                </span>
              </div>
              <div class="pt-2">
                <label>คัดลอกจากปี</label>
                <select class="form-control" v-model="copyFormCur">
                  <option
                    :value="year.name"
                    v-for="year in years"
                    :key="year.name"
                    >{{ year.name }}</option
                  >
                </select>
              </div>
              <div class="flex justify-center">
                <base-button mode="outline px-10 mt-2">เพิ่ม</base-button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div v-if="showForm === 'deleteCur'" class="flex justify-center">
        <div class="w-2/5">
          <h5 class="text-center pt-4">ลบหลักสูตร</h5>
          <form @submit.prevent="tryDeleteCur()">
            <label>ปีการศึกษา</label>
            <select class="form-control" v-model="selectedDeleteCur">
              <option
                :value="year.name"
                v-for="year in years"
                :key="year.name"
                >{{ year.name }}</option
              >
            </select>
            <div class="flex justify-center">
              <base-button mode="outline px-10 mt-2">ลบ</base-button>
            </div>
          </form>
        </div>
      </div>
    </base-card>

    <!-- Pagination -->
    <div class=" flex justify-center items-center" v-if="loadingData">
      <base-spinner class="my-10"></base-spinner>
    </div>
    <div v-else>
      <div class="flex justify-end items-center py-4">
        <h5>ปีการศึกษา</h5>
        <div class="flex justify-center">
          <div class="pr-2" v-for="(year, index) in years" :key="year.name">
            <base-button
              mode="gray"
              class="group bg-gray-400 hover:bg-gray-600"
              :class="{ 'bg-primary-300': year.name === selectedYear }"
              v-if="index !== 0 && index < 9"
              @click="pageHandler(year.name)"
              ><span class="text-white group-focus:text-primary-300"
                >{{ years[index - 1].name }}-{{ year.name }}</span
              >
            </base-button>
          </div>
        </div>
      </div>
      <!-- End Pagination -->

      <table style="width:100%">
        <thead>
          <tr>
            <th colspan="2" class="text-center ">
              <h4>วิชา</h4>
            </th>
            <th colspan="5" class="text-center ">
              <div class="flex justify-center">
                <h4 class="pr-2">หลักสูตร {{ yearArr[0] }}</h4>
                <!-- <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                ></path>
              </svg> -->
              </div>
            </th>
            <th colspan="6" class="text-center">
              <h4>หลักสูตร {{ yearArr[1] }}</h4>
            </th>
          </tr>
          <tr class="text-center">
            <th>เงือนไข</th>
            <th>วิชาเอก</th>
            <th>Code</th>
            <th>ชื่อวิชา</th>
            <th>Type</th>
            <th>Unit</th>
            <th>คัดลอก</th>
            <!-- <th>เลือก</th> -->

            <th>Code</th>
            <th>ชื่อวิชา</th>
            <th>Type</th>
            <th>Unit</th>
            <th>ลบวิชา</th>
            <!-- <th>เลือก</th> -->
            <!-- <th></th> -->
          </tr>
        </thead>
        <div class="h-2"></div>

        <tbody>
          <subject-item
            :id="offLink"
            :key="offLink"
            :ref="offLink"
            :offLink="offLink"
            :yearArr="yearArr"
            :program="program"
            @selectSubject="selectSubject"
            v-for="offLink in offLinkList"
          ></subject-item>
        </tbody>
      </table>
    </div>
    <div v-if="offLinkList.length === 0">
      <div class="text-xl font-bold text-center py-10">
        No Subject to Display, Please add some Subject
      </div>
    </div>
    <div class="flex justify-center py-8">
      <base-button @click="addSubject" class="px-6" mode="outline"
        >+ เพิ่มวิชา</base-button
      >
    </div>
    <base-card>
      <div class="flex justify-center py-8 ">
        <base-button mode="outline" class="mx-2" @click="toggleAddForm()"
          >เพิ่มหลักสูตร</base-button
        >
        <base-button
          mode="outline"
          class="mx-2"
          @click="
            showForm !== 'deleteCur'
              ? (showForm = 'deleteCur')
              : (showForm = null)
          "
          >ลบหลักสูตร</base-button
        >
        <base-button class="mx-2" mode="outline" @click="addSubject"
          >เพิ่มวิชา</base-button
        >

        <base-button @click="submitData" class=" px-16 mx-2"
          >บันทึกข้อมูล</base-button
        >

        <!-- <base-button @click="deleteSubjects">ลบวิชา</base-button> -->
        <!-- <base-button @click="null">ลบหลักสูตร</base-button> -->
      </div>
      <div></div>
      <div v-if="showForm === 'addCur'" class="flex justify-center">
        <div class="max-w-lg ">
          <h5 class="text-center pt-4">เพิ่มหลักสูตร</h5>
          <form @submit.prevent="addCurriculum()">
            <div class="w-80">
              <div>
                <label>ปีการศึกษา</label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ errors: v$.copyToCur.$error }"
                  @blur="v$.copyToCur.$touch"
                  v-model.trim="copyToCur"
                />
                <span
                  class="errors"
                  v-for="error of v$.copyToCur.$errors"
                  :key="error.$uid"
                  >{{ error.$message }}<br />
                </span>
              </div>
              <div class="pt-2">
                <label>ส่งผลกับนักศึกษารหัส</label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ errors: v$.effectEst.$error }"
                  @blur="v$.effectEst.$touch"
                  v-model.trim="effectEst"
                />
                <span
                  class="errors"
                  v-for="error of v$.effectEst.$errors"
                  :key="error.$uid"
                  >{{ error.$message }}<br />
                </span>
              </div>
              <div class="pt-2">
                <label>คัดลอกจากปี</label>
                <select class="form-control" v-model="copyFormCur">
                  <option
                    :value="year.name"
                    v-for="year in years"
                    :key="year.name"
                    >{{ year.name }}</option
                  >
                </select>
              </div>
              <div class="flex justify-center">
                <base-button mode="outline px-10 mt-2">เพิ่ม</base-button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div v-if="showForm === 'deleteCur'" class="flex justify-center">
        <div class="w-2/5">
          <h5 class="text-center pt-4">ลบหลักสูตร</h5>
          <form @submit.prevent="tryDeleteCur()">
            <label>ปีการศึกษา</label>
            <select class="form-control" v-model="selectedDeleteCur">
              <option
                :value="year.name"
                v-for="year in years"
                :key="year.name"
                >{{ year.name }}</option
              >
            </select>
            <div class="flex justify-center">
              <base-button mode="outline px-10 mt-2">ลบ</base-button>
            </div>
          </form>
        </div>
      </div>
    </base-card>
  </div>
  <div v-if="logVisible">
    {{ offLinkList }}
    <hr />
    <!-- {{ selectedSubject }}-->
    {{ JSON.stringify(subjectInState) }}

    <!-- <base-spinner></base-spinner> -->

    <br />
    <!-- {{ testParent }}
  {{ testChild }} -->
  </div>

  <scroll-to-top></scroll-to-top>

  <h2 class="text-red-500">{{ error }}</h2>

  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>

  <toast
    :show="!formValid"
    mode="error"
    @close="closeError"
    :duration="5000"
    message="ข้อมูลวิชาไม่ครบถ้วน หรือไม่ถูกต้อง โปรดตรวจสอบและลองใหม่อีกครั้ง"
  >
  </toast>
  <confirm-modal
    conBtnText="ลบ"
    :message="
      'คุณต้องการลบข้อมูลของปีการศึกษา ' + selectedDeleteCur + ' ใช่หรือไม่'
    "
    @confirm="deleteCurriculum"
    @close="delYearBoxVsb = false"
    :show="delYearBoxVsb"
    title="ลบข้อมูล"
  ></confirm-modal>
  <div class="text-center flex justify-center">
    <BaseBadge
      :canClose="true"
      class="fixed top-16 "
      :show="badgeVisible"
      message="โปรดกดปุ่ม บันทึกข้อมูล หากมีการแก้ไขข้อมูลรายวิชา"
      @close="badgeVisible = false"
      mode="warning"
    ></BaseBadge>
  </div>
</template>

<script>
import SubjectItem from "../../components/subjects/SubjectItem.vue";
import ScrollToTop from "../../components/UI/ScrollToTop.vue";
import ConfirmModal from "../../components/UI/ConfirmModal.vue";
import BaseBadge from "../../components/UI/BaseBadge.vue";

import LoadDataMixin from "../../mixins/loadData.js";
import { getId } from "../../js/utility.js";
import { isLogVisible } from "../../js/debug.js";

import useVuelidate from "@vuelidate/core";
import { required, integer, minValue, minLength } from "@vuelidate/validators";

export default {
  mixins: [LoadDataMixin], // loadYear, loadSubject
  components: {
    SubjectItem,
    ScrollToTop,
    ConfirmModal,
    BaseBadge,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      effectEst: {
        required,
        integer,
        minValueValue: minValue(0),
        minLengthValue: minLength(2),
      },
      copyToCur: {
        required,
        integer,
        minValueValue: minValue(0),
        minLengthValue: minLength(4),
      },
    };
  },
  data() {
    return {
      // copy curriculum
      copyFormCur: null,
      copyToCur: null,
      effectEst: null,

      // delete curriculum
      selectedDeleteCur: null,

      // User input
      selectedYear: null,
      offLinkList: [],

      // System Data
      showForm: null,
      formValid: true,
      loadingData: false,
      delYearBoxVsb: false,

      error: null,
      badgeVisible: true,

      // Doesn't use Feature
      selectedSubject: [],

      page: 1,
    };
  },
  computed: {
    program() {
      return this.$store.getters["userProgram"];
    },
    uProgram() {
      return this.$store.getters["programs/getUserProgram"];
    },

    yearArr() {
      const prevYear = this.$store.getters["years/getAroundYear"](
        this.selectedYear,
        -1
      );

      return [prevYear.name, this.selectedYear];
    },
    logVisible() {
      return isLogVisible();
    },

    stateMaxYear() {
      return this.$store.getters["years/maxYear"];
    },
    years() {
      return this.$store.getters["years/years"];
    },
    subjectInState() {
      return this.$store.getters["subjects/subjects"];
    },
  },
  created() {
    this.setDefaultData();
  },
  methods: {
    async loadData() {
      this.loadingData = true;
      await this.loadYear();
      await this.loadSubject();
      this.loadingData = false;
    },
    setDefaultData() {
      this.loadingData = true;
      this.setDefaultYears();
      this.setOffLinkList();
      this.loadingData = false;
    },

    addSubject() {
      this.offLinkList.push(getId());
    },
    deleteSubjects() {
      this.$store.dispatch("subjects/deleteSubjects", {
        id: this.selectedSubject,
      });
      this.setOffLinkList();
    },
    setDefaultYears() {
      this.selectedYear = this.$store.getters["years/maxYear"].name;
      if (this.logVisible) {
        console.log(this.selectedYear);
      }
    },
    setOffLinkList() {
      if (this.logVisible) {
        console.log("start....set subject");
        console.log(this.yearArr);
      }

      const allOffLink = this.$store.getters["subjects/getOffLinkByYear"](
        this.yearArr
      );

      if (this.logVisible) {
        console.log(allOffLink);
      }

      this.offLinkList = allOffLink;
    },

    selectSubject(payload) {
      const index = this.selectedSubject.indexOf(payload);
      if (index > -1) {
        this.selectedSubject.splice(index, 1);
      } else {
        if (payload) {
          this.selectedSubject.push(payload);
        }
      }
    },
    callChildMethod(methodName) {
      for (const offLink of this.offLinkList) {
        this.$refs[offLink][methodName]();
      }
    },
    async submitData() {
      // validation
      this.formValid = true;
      this.isFormValid();
      if (!this.formValid) {
        return;
      }
      this.$store.dispatch("updateToast", {
        show: true,
        message: "กำลังบันทึกข้อมูล..",
        mode: "loading",
      });
      this.callChildMethod("submitData");
      this.$store.dispatch("clearToast");
      this.$store.dispatch("updateToast", {
        show: true,
        message: "บันทึกข้อมูลสำเร็จ",
        mode: "success",
      });
      await this.loadData();
    },
    isFormValid() {
      // callChildMethod
      this.callChildMethod("isFormValid");

      const inValidData = this.offLinkList.some(
        (offLink) => this.$refs[offLink].formValid === false
      );
      this.formValid = !inValidData;
    },
    pageHandler(year) {
      let text =
        "โปรดตรวจสอบว่าคุณบันทึกข้อมูลแล้ว หากเลื่อนปีการศึกษาโดยไม่ได้กดปุ่มบันทึก ข้อมูลจะไม่ถูกบันทึกเข้าระบบ";
      if (!confirm(text)) {
        return;
      }
      this.selectedYear = year;
      this.setOffLinkList();
    },

    toggleAddForm() {
      this.showForm !== "addCur"
        ? (this.showForm = "addCur")
        : (this.showForm = null);

      if (this.logVisible) {
        console.log(this.stateMaxYear);
      }

      this.copyFormCur = this.stateMaxYear.name;
    },

    async addCurriculum() {
      // touch 2 fields
      this.v$.copyToCur.$touch;
      this.v$.effectEst.$touch;

      // validate 2 fields
      if (this.v$.copyToCur.$error || this.v$.effectEst.$error) {
        return;
      }

      this.$store.dispatch("updateToast", {
        mode: "loading",
        message: "กำลังสร้างปีการศึกษาใหม่",
        show: true,
      });
      this.loadingData = true;

      try {
        await this.$store.dispatch("subjects/copyCurriculum", {
          copyFromCur: this.copyFormCur,
          copyToCur: this.copyToCur,
          effectEst: this.effectEst,
        });
      } catch (e) {
        this.error = e.message;
      }

      await this.loadData();
      this.setDefaultData();
      this.$store.dispatch("clearToast");
      this.loadingData = false;
      this.$store.dispatch("updateToast", {
        mode: "loading",
        message: "กำลังสร้างปีการศึกษาใหม่",
        show: true,
      });
      this.$store.dispatch("updateToast", {
        mode: "success",
        message: "หลักสูตรใหม่ถูกบันทึกเสร็จสิ้นแล้ว",
        show: true,
      });
      this.showForm = null;
      this.copyToCur = null;
      this.effectEst = null;
    },
    tryDeleteCur() {
      this.delYearBoxVsb = true;
    },
    async deleteCurriculum() {
      if (this.logVisible) {
        console.log("sending delete data...");
      }

      this.$store.dispatch("updateToast", {
        mode: "loading",
        message: "กำลังลบปีการศึกษา",
        show: true,
      });
      this.loadingData = true;
      try {
        await this.$store.dispatch("subjects/deleteSubjectByYear", {
          year: this.selectedDeleteCur,
        });
      } catch (e) {
        this.error = e.message;
      }

      await this.loadData();
      this.setDefaultData();
      this.$store.dispatch("clearToast");
      this.loadingData = false;
      this.$store.dispatch("updateToast", {
        mode: "success",
        message: "เพิ่มหลักสูตรใหม่เสร็จสิ้น",
        show: true,
      });

      this.showForm = null;
    },
    closeError() {
      this.formValid = true;
      this.error = null;
    },
  },
};
</script>

<style scoped>
.year-card {
  @apply border border-gray-300 shadow-sm rounded-md my-4 py-4 px-2 mx-1;
}

body {
  margin: 0;
  padding: 2rem;
}

/* Position Sticky and Table Headers  */
table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}
th,
td {
  padding: 0.25rem;
}

th {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  padding: 10px 0px;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  z-index: 10;
}
</style>
