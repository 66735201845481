<template>
  <div class="py-10 text-center ">
    <h3>เพิ่มข้อมูลโควตา</h3>
  </div>

  <div class=" flex justify-center items-center" v-if="loadingData">
    <base-spinner class="my-10"></base-spinner>
  </div>

  <div
    class="text-xl font-semibold  pb-20 text-center"
    v-else-if="!formIdArr || formIdArr.length === 0"
  >
    ไม่มีแถวที่จะแสดง โปรดเพิ่มแถวเพื่อเพิ่มข้อมูลโควตา
  </div>

  <div
    class="text-xl font-semibold  pb-20 text-center"
    v-else-if="!staffCanEdit"
  >
    ระบบไม่อนุญาตให้เพิ่มโควตาในขณะนี้
  </div>

  <table style="table-fixed" v-else>
    <thead>
      <tr class="text-center">
        <th class="w-0.5/12">หลักสูตร</th>
        <th class="w-1/12">รหัสวิชา</th>
        <th class="w-3/12">ชื่อวิชา</th>
        <th class="w-2/12">Sec</th>
        <th class="w-0.5/12">Gr</th>
        <th class="w-0.5/12">Cam</th>
        <th class="w-0.5/12">จำนวนรับ</th>
        <th class="w-2/12">อาจารย์</th>
        <th class="w-0.5/12">เงื่อนไข</th>
        <th class="w-0.5/12">ลบ</th>
        <!-- <th class="w-0.5/12">ห้องเรียน</th>
        <th class="w-0.5/12">Midterm</th>
        <th class="w-0.5/12">Final</th> -->
      </tr>
    </thead>
    <div class="h-2"></div>

    <tbody>
      <add-quota-item
        v-for="formId in formIdArr"
        @deleteForm="deleteForm"
        :ref="formId"
        :key="formId"
        :id="formId"
      ></add-quota-item>
    </tbody>
  </table>
  <div class="px-2 py-2">จำนวน {{ formIdArr.length }} แถว</div>

  <div>
    <base-card>
      <div class="flex justify-center py-4">
        <base-button
          :mode="staffCanEdit ? 'flat' : 'disabled'"
          @click="addRowVisible = !addRowVisible"
          class=" px-8"
          >+ เพิ่มแถว</base-button
        >
        <base-button
          v-if="!addRowVisible"
          @click="submitData()"
          :mode="staffCanEdit ? 'main' : 'disabled'"
          class=" px-8"
          >บันทึกข้อมูล</base-button
        >
      </div>
      <div v-if="addRowVisible" class="flex justify-center">
        <div class="max-w-lg ">
          <form @submit.prevent="addRow()">
            <label>จำนวนแถวที่ต้องการ</label>
            <input type="text" class="form-control" v-model.trim="addRowNo" />
            <div class="flex justify-center">
              <base-button mode="outline px-10 mt-2">เพิ่ม</base-button>
            </div>
          </form>
        </div>
      </div>
    </base-card>
  </div>
  <!-- <div class="flex justify-center py-4">
    <base-button @click="null" class="bg-primary-300 px-8"
      >บันทึกข้อมูล</base-button
    >
  </div> -->
  <div v-if="logVisible">
    {{ userProgram }}
    <hr />
    {{ formIdArr }}
    <hr />
    <h4 class="text-red-500 text-center" v-if="!!error">{{ error }}</h4>
    <hr />
    {{ quota }}
    <hr />
    {{ sections }}
    <hr />
    {{ formIdArr }}
    <button @click="test()">TEST</button>
  </div>
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    :duration="5000"
    :message="error"
  >
  </toast>
</template>

<script>
import AddQuotaItem from "../../components/Quota/AddQuotaItem.vue";
import { getId } from "../../js/utility.js";
import { isLogVisible } from "../../js/debug.js";
import LoadDataMixin from "../../mixins/loadData.js";
import StaffRuleMixin from "../../mixins/staffRule.js";

export default {
  mixins: [LoadDataMixin, StaffRuleMixin], //loadYear,staffCanEdit,
  components: { "add-quota-item": AddQuotaItem },
  data() {
    return {
      formIdArr: [],
      formValid: true,

      loadingData: false,
      addRowNo: 1,
      addRowVisible: false,
      defaultFormNo: 1,
      error: null,
      program: "ibmp",
    };
  },
  computed: {
    // logVisible() {
    //   return process.env.VUE_APP_SHOW_LOGS === "1";
    // },
    logVisible() {
      return isLogVisible();
    },
    quota() {
      return this.$store.getters["quota/quota"];
    },
    userProgram() {
      return this.$store.getters["programs/getUserProgram"];
    },
    sections() {
      return this.$store.getters["sections/sections"];
    },
  },
  watch: {},
  created() {
    this.addRow();
    this.loadData();
  },
  methods: {
    test() {
      for (const formId of this.formIdArr) {
        const x = "test";
        this.$refs[formId][x]();
      }
    },
    checkSectionOK() {
      let isSecOK = true;
      for (const formId of this.formIdArr) {
        const theOffLink = this.$refs[formId]["subOffLink"];
        const theSec = this.$refs[formId]["sec"];
        const theGroup = this.$refs[formId]["group"];
        const duplicate = this.formIdArr.some((idItem) => {
          return (
            this.$refs[idItem]["subOffLink"] &&
            this.$refs[idItem]["sec"] &&
            this.$refs[idItem]["group"] &&
            this.$refs[idItem]["subOffLink"] == theOffLink &&
            this.$refs[idItem]["sec"].val == theSec.val &&
            this.$refs[idItem]["group"].val == theGroup.val &&
            formId != idItem
          );
        });
        if (duplicate) {
          isSecOK = false;
          break;
        }
      }
      return isSecOK;
    },
    async callChildMethod(methodName) {
      for (const formId of this.formIdArr) {
        await this.$refs[formId][methodName]();
      }
    },
    async loadData() {
      this.loadingData = true;
      await this.loadYear();
      await this.loadSubject();

      this.loadingData = false;
    },
    addRow() {
      for (let i = 0; i < this.addRowNo; i++) {
        this.formIdArr.push(getId());
      }
      this.addRowVisible = false;

      this.$store.dispatch("clearToast");
    },
    async submitData() {
      if (!this.staffCanEdit) {
        this.error = "ระบบไม่อนุญาตให้แก้ไขโควตาในขณะนี้";
        return;
      }

      if (!this.checkSectionOK()) {
        this.error =
          "Section ที่คุณกำลังเพิ่มมี วิชา section และ group ที่ซ้ำกัน โปรดตรวจสอบและลองใหม่อีกครั้ง";
        return;
      }

      // เช็ค input ทั้งหมด ว่ามีปัญหาไหม
      this.formValid = true;
      await this.isFormValid();
      if (!this.formValid) {
        this.error =
          "ข้อมูลโควตาไม่ครบถ้วน,ไม่ถูกต้อง หรือ section+group ซ้ำ โปรดตรวจสอบและลองใหม่อีกครั้ง";
        return;
      }
      this.$store.dispatch("updateToast", {
        show: true,
        message: "กำลังบันทึกข้อมูล..",
        mode: "loading",
      });
      await this.callChildMethod("submitData");
      this.$store.dispatch("clearToast");
      this.$router.replace({
        name: "quota-list",
        params: { success: true },
      });
    },

    async isFormValid() {
      // callChildMethod
      await this.callChildMethod("isFormValid");

      const validArr = [];
      for (const formId of this.formIdArr) {
        validArr.push(this.$refs[formId].formValid);
      }
      if (this.logVisible) {
        console.log(validArr);
      }

      const foundInValid = validArr.some((itemValid) => itemValid === false);
      this.formValid = !foundInValid;
    },
    closeError() {
      this.error = null;
    },
    deleteForm(formId) {
      const selectedForm = this.formIdArr.find((fId) => fId === formId);
      var index = this.formIdArr.indexOf(selectedForm);

      if (index !== -1) {
        this.formIdArr.splice(index, 1);
      }
    },
  },
};
</script>
