import { createRouter, createWebHistory } from "vue-router";

// STD
import StdQuotaMain from "../pages/Student/QuotaMain.vue";
import StdQuotaRequest from "../pages/Student/QuotaRequest.vue";
// import StdQuotaReSult from "../pages/Student/QRs.vue";
import StudentLogin from "../pages/Student/Login.vue";
import StudentRegister from "../pages/Student/Register.vue";
import StdInfo from "../pages/Student/StdInfo.vue";
import StdQuotaPetition from "../pages/Student/QuotaPetition.vue";

// ADMIN
import AdminMain from "../pages/Admin/AdminMain.vue";
import AdminLogin from "../pages/Admin/Login.vue";
import SubjectsList from "../pages/Subjects/SubjectsList.vue";
import SubjectsMain from "../pages/Subjects/SubjectsMain.vue";
import QuotaMain from "../pages/Quota/QuotaMain.vue";
import AddQuota from "../pages/Quota/AddQuota.vue";
import QuotaList from "../pages/Quota/QuotaList.vue";
import QuotaProcess from "../pages/Quota/QuotaProcess.vue";
import QuotaProcessMain from "../pages/Quota/QuotaProcessMain.vue";
import QuotaSec from "../pages/Quota/QuotaSec.vue";

// import HomeMain from "../pages/Home/HomeMain.vue";
// import HomeForm from "../pages/Home/HomeForm.vue";
import ProgramMain from "../pages/Programs/ProgramMain.vue";
import SelectProgram from "../pages/Programs/SelectProgram.vue";
import store from "../store/index.js";

import ScheduleMain from "../pages/Schedule/ScheduleMain.vue";
import ScheduleDetail from "../pages/Schedule/ScheduleDetail.vue";
import ScheduleEdit from "../pages/Schedule/ScheduleEdit.vue";

import StdAdminDetail from "../pages/StudentAdmin/StdDetail.vue";
import StdAdminInfo from "../pages/StudentAdmin/StdInfo.vue";
import StdAdminQuota from "../pages/StudentAdmin/StdQuota.vue";
import StdAdminSearch from "../pages/StudentAdmin/StdSearch.vue";
import StdAdminMain from "../pages/StudentAdmin/StudentMain.vue";
import StdAdminPetition from "../pages/StudentAdmin/StdPetition.vue";

import StaffMain from "../pages/Staff/StaffMain.vue";
import StaffList from "../pages/Staff/StaffList.vue";

import MajorMain from "../pages/Major/MajorMain.vue";
import MajorList from "../pages/Major/MajorList.vue";

import StatusMain from "../pages/Status/StatusMain.vue";
import StatusList from "../pages/Status/StatusList.vue";

import BasketMain from "../pages/Basket/BasketMain.vue";
import BasketList from "../pages/Basket/BasketList.vue";

import FirebaseIndex from "../pages/FirebaseIndex/IndexMain.vue";

// Thep
import SectionList from "../pages/Section/SectionList.vue";
import ConditionList from "../pages/Condition/Condition.vue";
import InstructorList from "../pages/Instructor/InstructorList.vue";
import FaqList from "../pages/FAQ/FAQList.vue";
import PetitionList from "../pages/Petition/petitionList.vue";

import ReportMain from "../pages/Report/ReportMain.vue";
import StudentOverview from "../pages/Report/StudentOverview.vue";
import StudentInfo from "../pages/Report/StudentInfo.vue";
import StudentMajor from "../pages/Report/StudentMajor.vue";
import StudentStatus from "../pages/Report/StudentStatus.vue";

import ForgetPassword from "../pages/Auth/ForgetPassword.vue";

import PrivacyPolicy from "../pages/Student/PrivacyPolicy.vue";
import FAQPage from "../pages/Student/FAQ.vue";

import NotFoundComponent from "../pages/NotFound.vue";

import { auth } from "../js/firebaseDb.js";
import { mapUser } from "../js/utility.js";
import { isLogVisible } from "../js/debug.js";
import firebase from "firebase/app";

// set Persistence to SESSION
auth
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then()
    .catch((error) => {
        console.error(error);
    });

store.dispatch("tryUserProgram");

const routes = [{
        path: "/",
        name: "home",
        redirect: "login",
    },
    {
        path: "/login",
        name: "login",
        component: StudentLogin,
        beforeEnter: (to, from, next) => {
            const user = auth.currentUser;
            if (user) {
                mapUser(user).then((userData) => {
                    // check if .. user is admin
                    if (userData.isAdmin) {
                        auth.signOut();
                        next();
                    } else {
                        next({
                            name: "std-quota-request",
                        });
                    }
                });
            } else {
                next();
            }
        },
    },
    {
        path: "/register",
        name: "register",
        component: StudentRegister,
        beforeEnter: (to, from, next) => {
            const user = auth.currentUser;
            if (user) {
                mapUser(user).then((userData) => {
                    // check if .. user is admin
                    if (userData.isAdmin) {
                        auth.signOut();
                        next();
                    } else {
                        next({
                            name: "std-quota-request",
                        });
                    }
                });
            } else {
                next();
            }
        },
    },

    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: PrivacyPolicy,
    },
    {
        path: "/faq",
        name: "FAQ",
        component: FAQPage,
    },
    {
        path: "/forget-password",
        name: "forget-password",
        component: ForgetPassword,
    },
    {
        path: "/login-admin",
        name: "admin-login",
        component: AdminLogin,
    },
    // Student Area
    {
        path: "/quota",
        name: "student-quota",
        component: StdQuotaMain,
        meta: { requiresAuth: true, requiresStudent: true },
        children: [{
                path: "",
                redirect: { name: "std-quota-request" },
            },
            {
                path: "/quota-request",
                name: "std-quota-request",
                component: StdQuotaRequest,
            },
            {
                path: "/student-info",
                name: "std-info",
                component: StdInfo,
            },
            {
                path: "/quota-petition",
                name: "std-quota-petition",
                component: StdQuotaPetition,
            },
        ],
    },

    // Admin Area

    {
        path: "/admin",
        name: "admin",
        component: AdminMain,
        meta: { requiresAuth: true, requiresAdmin: true },
        children: [{
                path: "",
                redirect: { name: "subjects-list" },
            },
            {
                path: "program",
                name: "program",
                component: ProgramMain,
                children: [{
                    path: "",
                    name: "select-program",
                    component: SelectProgram,
                }, ],
            },
            {
                path: "schedule",
                name: "schedule",
                component: ScheduleMain,
                meta: { requireProgram: true, requireSudo: true },
                children: [{
                        path: "",
                        redirect: { name: "schedule-detail" },
                    },
                    {
                        path: "detail",
                        name: "schedule-detail",
                        component: ScheduleDetail,
                    },
                    {
                        path: "detail",
                        name: "schedule-edit",
                        component: ScheduleEdit,
                    },
                ],
            },
            {
                path: "staff",
                name: "staff",
                component: StaffMain,
                meta: { requireProgram: true, requireSudo: true },
                children: [{
                        path: "",
                        redirect: { name: "staff-list" },
                    },
                    {
                        path: "list",
                        name: "staff-list",
                        component: StaffList,
                    },
                ],
            },
            {
                path: "major",
                name: "major",
                component: MajorMain,
                meta: { requireProgram: true, requireSudo: true },
                children: [{
                        path: "",
                        redirect: { name: "major-list" },
                    },
                    {
                        path: "list",
                        name: "major-list",
                        component: MajorList,
                    },
                ],
            },
            {
                path: "status",
                name: "status",
                component: StatusMain,
                meta: { requireProgram: true, requireSudo: true },
                children: [{
                        path: "",
                        redirect: { name: "status-list" },
                    },
                    {
                        path: "list",
                        name: "status-list",
                        component: StatusList,
                    },
                ],
            },
            {
                path: "basket",
                name: "basket",
                component: BasketMain,
                meta: { requireProgram: true, requireSudo: true },
                children: [{
                        path: "",
                        redirect: { name: "basket-list" },
                    },
                    {
                        path: "list",
                        name: "basket-list",
                        component: BasketList,
                    },
                ],
            },
            {
                path: "petition",
                name: "petition",
                component: PetitionList,
                meta: { requireProgram: true, requireSudo: true },
            },
            {
                path: "section",
                name: "section",
                component: SectionList,
                meta: { requireProgram: true, requireSudo: true },
            },
            {
                path: "condition",
                name: "condition",
                component: ConditionList,
                meta: { requireProgram: true, requireSudo: true },
            },
            {
                path: "instructor",
                name: "instructor",
                component: InstructorList,
                meta: { requireProgram: true, requireSudo: true },
            },
            {
                path: "firebase-index",
                name: "firebase-index",
                component: FirebaseIndex,
                meta: { requireProgram: true, requireSudo: true },
            },
            {
                path: "faq",
                name: "faq",
                component: FaqList,
                meta: { requireProgram: true, requireSudo: true },
            },
            {
                path: "student",
                name: "student-admin",
                component: StdAdminMain,
                meta: { requireProgram: true },
                children: [{
                        path: "",
                        redirect: { name: "admin-std-search" },
                    },
                    {
                        path: "search",
                        name: "admin-std-search",
                        component: StdAdminSearch,
                    },

                    {
                        path: ":stdId",
                        props: true,
                        name: "admin-std-detail",
                        component: StdAdminDetail,
                        children: [{
                                path: "info",
                                name: "admin-std-info",
                                component: StdAdminInfo,
                                meta: { requireSudo: true },
                            },
                            {
                                path: "quota",
                                name: "admin-std-quota",
                                component: StdAdminQuota,
                            },
                            {
                                path: "petition",
                                name: "admin-std-petition",
                                component: StdAdminPetition,
                            },
                        ],
                    },
                    // {
                    //     path: ":stdId",
                    //     props: true,
                    //     name: "admin-std-quota",
                    //     component: StdAdminQuota,
                    //     meta: { requireSudo: false },
                    // },
                ],
            },
            {
                path: "subjects",
                name: "subjects",
                component: SubjectsMain,
                meta: { requireProgram: true, requireSudo: true },
                children: [{
                        path: "",
                        redirect: { name: "subjects-list" },
                    },
                    {
                        path: "list",
                        name: "subjects-list",
                        component: SubjectsList,
                    },
                ],
            },
            {
                path: "report",
                name: "report",
                component: ReportMain,
                meta: { requireProgram: true, requireSudo: false },
                children: [{
                        path: "",
                        redirect: { name: "report-student-info" },
                    },
                    {
                        path: "student-overview",
                        name: "report-student-overview",
                        component: StudentOverview,
                    },
                    {
                        path: "student-info",
                        name: "report-student-info",
                        component: StudentInfo,
                    },
                    {
                        path: "student-major",
                        name: "report-student-major",
                        component: StudentMajor,
                    },
                    {
                        path: "student-status",
                        name: "report-student-status",
                        component: StudentStatus,
                    },
                ],
            },
            {
                path: "quota",
                name: "quota",
                component: QuotaMain,
                meta: { requireProgram: true },
                children: [{
                        path: "",
                        redirect: "list",
                    },
                    {
                        path: "list",
                        name: "quota-list",
                        component: QuotaList,
                    },
                    {
                        path: "process",
                        name: "quota-process-main",
                        component: QuotaProcessMain,
                        children: [{
                                path: "",
                                name: "quota-process",
                                component: QuotaProcess,
                            },
                            {
                                path: ":id",
                                props: true,
                                name: "quota-sec",
                                component: QuotaSec,
                            },
                        ],
                    },
                    {
                        path: "add",
                        name: "quota-add",
                        component: AddQuota,
                    },
                ],
            },
            // { path: "*", component: NotFoundComponent },
        ],
    },
    // { path: "*", component: NotFoundComponent },
    {
        path: "/:pathMatch(.*)*",
        name: "bad-not-found",
        component: NotFoundComponent,
    },
];

const router = createRouter({
    mode: "history",
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    // // get currentUser
    // let currentUser = auth.currentUser;

    // // if currentUser is not exist then onAuthStateChanged
    // if (!currentUser) {
    //     auth.onAuthStateChanged((user) => {
    //         console.log("onAuthStateChanged called");
    //         currentUser = user;
    //     });
    // }

    // check if ... is route require Authentication
    if (to.meta.requiresAuth) {
        if (isLogVisible()) {
            console.log("global 1");
        }
        const currentUser = auth.currentUser;
        if (currentUser) {
            mapUser(currentUser).then((userData) => {
                // Student Section
                if (to.meta.requiresStudent) {
                    if (!userData.isAdmin) {
                        if (to.name === "login") {
                            next({ name: "std-quota-request" });
                        } else {
                            next();
                        }
                    } else {
                        auth.signOut();
                        next({ name: "login" });
                    }
                } else if (to.meta.requiresAdmin) {
                    // Admin Section
                    if (userData.isAdmin) {
                        // check if .. user is Admin
                        if (isLogVisible()) {
                            console.log("this is Admin route");
                        }

                        if (to.meta.requireProgram && !store.getters.hasUserProgram) {
                            if (isLogVisible()) {
                                console.log("this is Not select program route");
                            }

                            // check selected program
                            next({
                                name: "select-program",
                            });
                        } else {
                            if (isLogVisible()) {
                                console.log("this is select program route");
                            }

                            if (to.name === "admin-login") {
                                next(false);
                            } else {
                                // check Admin Role
                                if (isLogVisible()) {
                                    console.log(userData);
                                }

                                if (to.meta.requireSudo && userData.role === "A") {
                                    if (isLogVisible()) {
                                        console.log("admin role route 1");
                                    }

                                    next();
                                } else if (to.meta.requireSudo && userData.role !== "A") {
                                    if (isLogVisible()) {
                                        console.log("admin role route 2");
                                    }

                                    // alert('สิทธิ์ของคุณ ไม่สามารถเข้าถึงหน้านี้ได้');
                                    next();
                                    // next({ name: "quota-list" });
                                } else {
                                    if (isLogVisible()) {
                                        console.log("admin role route 3");
                                    }

                                    next();
                                }
                            }
                        }
                    } else {
                        auth.signOut();
                        next({ name: "admin-login" });
                    }
                } else {
                    next();
                }
            });
        } else {
            next({ name: "login" });
        }
    } else {
        if (isLogVisible()) {
            console.log("global 3");
        }

        next();
    }
});

export default router;