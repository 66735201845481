import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

export default {
    namespaced: true,
    state() {
        return {
            conditions: [
                // {
                //     id: "sefsfsefsefesf",
                //     condCode: 2,
                //     majorCrit: 1,
                //     yearCrit: 1,
                //     orderCrit: 1,
                //     block: 1,
                // },
                // {
                //     id: "gukgjgnfgjn",
                //     condCode: 2,
                //     majorCrit: 3,
                //     yearCrit: 1,
                //     orderCrit: 1,
                //     block: 2,
                // },
                // {
                //     id: "awdrgdrgsadw",
                //     condCode: 2,
                //     majorCrit: 4,
                //     yearCrit: 1,
                //     orderCrit: 1,
                //     block: 3,
                // },
            ],
        };
    },
    getters,
    actions,
    mutations,
};