<template>
  <div class=" flex justify-center items-center" v-if="loadingData">
    <base-spinner class="my-10"></base-spinner>
  </div>
  <div
    class=" flex justify-center items-center"
    v-else-if="!schedule.studentLogin"
  >
    <h4>ไม่สามารถแสดงข้อมูลในขณะนี้</h4>
  </div>
  <div v-else>
    <div
      class="text-base md:text-xl font-semibold  pb-20 text-center"
      v-if="!reqQuotaResultStatus || reqQuotaResultStatus.length === 0"
    >
      ไม่มีข้อมูลโควตาที่ต้องแสดง
    </div>

    <div class="grid-table-main" v-else>
      <div class="pb-2" v-if="userInfo">
        {{ userInfo.nameTitle }} {{ userInfo.firstName }}
        {{ userInfo.lastName }} ({{ userInfo.stdCode }})
      </div>
      <div
        class="
              grid grid-cols-3
              md:grid-cols-12
              grid-table-header
            "
      >
        <div class="col-span-2 md:hidden w-full h-auto">วิชา</div>
        <div class="md:hidden w-full h-auto">สถานะ</div>
        <div class="hidden md:block w-full h-auto">รหัสวิชา</div>
        <div class="hidden md:block w-full h-auto md:col-span-2 ">
          ชื่อวิชา
        </div>
        <div class="hidden md:block w-full h-auto">Campus</div>
        <div class="hidden md:block w-full h-auto">Sec-Group</div>
        <div class="hidden md:block w-full h-auto md:col-span-2">วันเวลา</div>
        <div class="hidden md:block w-full h-auto md:col-span-2 ">
          ผู้บรรยาย
        </div>
        <div class="hidden md:block w-full h-auto">รอบ</div>
        <div class="hidden md:block w-full h-auto">สถานะ</div>
        <div class="hidden md:block w-full h-auto">ยกเลิก</div>
      </div>

      <div class="grid-table-body">
        <div
          class="grid grid-cols-3 md:grid-cols-12 grid-table-row"
          v-for="q in reqQuotaResultStatus"
          :key="q.id"
        >
          <div class="col-span-2 md:col-span-10 md:grid md:grid-cols-10 pl-2">
            <div
              class="md:col-span-4 md:grid md:grid-cols-4 md:text-center md:my-auto"
            >
              <span class="font-bold">
                {{ q.subCode }}<span class="md:hidden"> - </span></span
              >
              <span class="md:col-span-2">{{ q.subName }} </span>
              <span class="">
                <span class=" md:hidden">(</span>
                {{ q.campus }}</span
              >
              <span class=" md:hidden">)</span>
            </div>
            <div
              class="md:col-span-3 md:grid md:grid-cols-3 md:text-center md:my-auto"
            >
              <span>
                <span class="md:hidden">sec </span>{{ q.quota.secId }}-{{
                  q.quota.group
                }}</span
              >
              <span class="md:col-span-2"> ({{ q.quota.secTime }})</span>
            </div>

            <div
              class="md:col-span-2  md:text-center md:my-auto"
              v-if="q.quota.instructors"
            >
              <span
                v-for="instructor in q.quota.instructors"
                :key="instructor.id"
              >
                {{ instructor.name }}
                <br />
              </span>
            </div>
            <div class="md:col-span-2  md:text-center md:my-auto" v-else>
              <span>{{ q.quota.instName }}</span>
            </div>
            <div class="md:text-center pt-3 md:pt-0 md:my-auto">
              <span>
                <span class="md:hidden">รอบ: </span>
                {{ qRoundToText(q.reqQutTime) }}
              </span>
            </div>
          </div>
          <div
            class="col-span-1 md:col-span-2 md:grid md:grid-cols-2 text-left md:text-center my-auto "
          >
            <div
              class="text-blue-500 mx-auto flex justify-left md:justify-center md:my-auto pb-4 md:pb-0"
            >
              <div class="flex items-center">
                <ResultIcon :reqQuota="q" mode="student"></ResultIcon>
              </div>
            </div>

            <div class="">
              <span
                class=" hover:bg-gray-100 p-3   hover:font-medium"
                :class="{
                  'cursor-pointer text-red-500 hover:text-red-600': checkCancel(
                    q
                  ),
                  'cursor-not-allowed text-gray-500 hover:text-gray-600': !checkCancel(
                    q
                  ),
                }"
                @click="showCancelDialog(q)"
              >
                ยกเลิก
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLogVisible"></div>
  <SpinnerDialog :show="loadingDialog"></SpinnerDialog>
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
  <confirm-modal
    :show="cancelDialog"
    title="ยืนยันยกเลิกโควตา"
    @close="
      () => {
        cancelDialog = false;
        cancelQuota = null;
      }
    "
    @confirm="ccReqQuota()"
  >
  </confirm-modal>
</template>

<script>
import { isLogVisible } from "../../js/debug.js";
import ResultIcon from "../UI/ResultIcon.vue";
import StudentRule from "../../mixins/studentRule.js";
import LoadDataMixin from "../../mixins/loadData.js";
import SpinnerDialog from "../../components/UI/SpinnerDialog.vue";
import ConfirmModal from "../../components/UI/ConfirmModal.vue";
import TextConverterMixin from "../../mixins/textConverter.js";
export default {
  mixins: [StudentRule, LoadDataMixin, TextConverterMixin], // canCancel,loadSchedule,qRoundToText()

  components: { ResultIcon, SpinnerDialog, ConfirmModal },
  data() {
    return {
      loadingData: false,
      loadingDialog: false,
      error: null,

      // cancel
      cancelDialog: false,
      cancelQuota: null,
    };
  },
  computed: {
    isLogVisible() {
      return isLogVisible();
    },
    reqQuotaResultStatus() {
      return this.$store.getters["quota/reqQuotaResultStatus"];
    },
    reserveReqQuota() {
      return this.$store.getters["quota/getReqQuotaByResult"]("reserve");
    },
    schedule() {
      return this.$store.getters["schedule"];
    },
    currentRound() {
      return this.schedule.qRound;
    },
    userInfo() {
      return this.$store.getters["userInfo"];
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loadingData = true;
      await this.loadSchedule();
      this.loadingData = false;
    },
    checkCancel(q) {
      let response = true;
      if (q.reqResult === "reject") {
        response = false;
      }

      if (
        this.currentRound === 3 &&
        (q.reqQutTime === 1 || q.reqQutTime === 2)
      ) {
        response = false;
      }

      if (!this.canCancel) {
        response = false;
      }

      if (this.currentRound === 4 || this.currentRound === 5) {
        if (this.currentRound !== q.reqQutTime) {
          response = false;
        }
      }

      return response;
    },
    showCancelDialog(q) {
      this.cancelDialog = true;
      this.cancelQuota = q;
    },
    async ccReqQuota() {
      this.cancelDialog = false;
      const q = this.cancelQuota;

      if (this.confirmCancel) {
        return;
      }

      this.loadingDialog = true;

      if (!this.checkCancel(q)) {
        this.loadingDialog = false;
        this.error = "ไม่สามารถยกเลิกวิชานี้ได้";
        return;
      }

      // If Request Quota Round is 1 and students receive all quota that they has requested then prevent student to cancel request quota
      let rejectedQuota = null;
      if (q.reqResult !== "reserve" && q.reqQutTime === 1) {
        rejectedQuota = this.reqQuotaResultStatus.filter(
          (quota) => quota.reqQutTime === 1 && quota.reqResult === "reject"
        );
      }

      if (rejectedQuota && rejectedQuota.length === 0) {
        this.loadingDialog = false;
        this.error =
          "ไม่สามารถยกเลิกวิชาในรอบที่ 1 ได้ เนื่องจากคุณได้รับจัดสรรโควตาในรอบที่ 1 ครบถ้วนแล้ว";
        return;
      }

      // If Request Quota status is reject then prevent student to cancel request quota
      if (q.reqResult === "reject") {
        this.loadingDialog = false;
        this.error = "ไม่สามารถยกเลิกวิชาที่อยู่ในสถานะ Reject ได้";
        return;
      }

      try {
        await this.$store.dispatch("quota/cancelReqQuota", q);
      } catch (e) {
        this.error = e.message;
      }
      this.cancelQuota = null;
      this.loadingDialog = false;
    },
    closeError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
