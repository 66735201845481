<template>
  <!-- Dialog (full screen) -->
  <teleport to="body">
    <transition name="data-modal">
      <div
        v-if="show"
        class="font-kanit bg-black bg-opacity-50 focus-within:absolute fixed top-0 left-0 flex items-center justify-center w-full h-full "
      >
        <!-- A basic modal dialog with title, body and one button to close -->
        <div
          class="  h-auto p-6 pt-4 mx-2 text-left bg-white rounded-xl shadow-xl min-w-4.5/10 md:max-w-xl md:mx-0 z-30"
        >
          <div class="mt-3 text-left  ">
            <div class="flex justify-between">
              <h3 class="text-xl font-medium leading-6 text-gray-900">
                {{ title }}
              </h3>
              <button
                @click="tryClose()"
                class="flex items-start"
                v-if="closeIcon"
              >
                <div>
                  <svg
                    class="w-6 h-6 "
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </div>
              </button>
            </div>

            <div
              class="mt-4 text-gray-600 modal-body"
              :class="{ 'modal-body': canScroll }"
            >
              <slot></slot>
              <!-- <p class="text-sm leading-5 text-gray-500 pt-4 pb-2">
              Data location
            </p>
            <p class="text-sm leading-5 text-gray-700">
              /
            </p>
            <div class="mt-4">
              <p class="text-sm leading-5 text-gray-500">
                Data (JSON)
              </p>
              <input class="form-control" type="text" />
            </div> -->
            </div>
          </div>

          <!-- One big close button.  --->
          <div class="mt-5 sm:mt-6 flex justify-between">
            <div>
              <base-button
                v-if="deleteBtn"
                @click="tryDelete()"
                class="px-4 border-2 border-gray-300 text-red-500 hover:bg-red-400 hover:text-white"
                mode="outline"
              >
                Delete
              </base-button>
            </div>

            <div>
              <base-button
                v-if="cancelBtn"
                @click="tryClose()"
                class="px-4 text-gray-500 hover:bg-gray-200"
                mode="flat"
              >
                Cancel
              </base-button>
              <base-button class="px-6 " @click="trySubmit()" v-if="submitBtn">
                {{ submitText }}
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancelBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    submitBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    submitText: {
      type: String,
      required: false,
      default: "Save",
    },
    canScroll: {
      type: Boolean,
      required: false,
      default: true,
    },
    closeIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxWidth: {
      type: String,
      required: false,
      default: "true",
    },
  },
  emits: ["close", "delete", "submit"],
  methods: {
    tryClose() {
      this.$emit("close");
    },
    tryDelete() {
      this.$emit("delete");
    },
    trySubmit() {
      this.$emit("submit");
    },
  },
};
</script>

<style>
/* enter transitions */
.data-modal-enter-from {
  opacity: 0;
  transform: translateY(-60px);
}
.data-modal-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.data-modal-enter-active {
  transition: all 0.3s ease;
}
/* leave transitions */
.data-modal-leave-from {
  opacity: 1;
  transform: translateY(0);
}
.data-modal-leave-to {
  opacity: 0;
  transform: translateY(-60px);
}
.data-modal-leave-active {
  transition: all 0.3s ease;
}

/* Important part */
.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
