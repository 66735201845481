import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

export default {
    namespaced: true,
    state() {
        return {
            majors: [
                // {
                //     id: 0,
                //     name: "Others",
                //     code: "Others",
                // },
                // {
                //     id: 9,
                //     name: "Accounting",
                //     code: "AC",
                // },
                // {
                //     id: 13,
                //     name: "Integrated in Accounting",
                //     code: "AI",
                // },
                // {
                //     id: 10,
                //     name: "Business Administration",
                //     code: "BA",
                // },
                // {
                //     id: 12,
                //     name: "Integrated in Business",
                //     code: "BI",
                // },
                // {
                //     id: 22,
                //     name: "Master of Financial (IBMP)",
                //     code: "FM",
                // },
                // {
                //     id: 2,
                //     name: "Finance",
                //     code: "FN",
                // },
                // {
                //     id: 5,
                //     name: "Human Resource and Organization Management",
                //     code: "HO",
                // },
                // {
                //     id: 26,
                //     name: "Integrated",
                //     code: "IBMP",
                // },
                // {
                //     id: 4,
                //     name: "Management Information System",
                //     code: "IS",
                // },
                // {
                //     id: 7,
                //     name: "International Business, Logistics&Transport", // International Business
                //     code: "IBLT", // Logistics&Transport
                // },
                // {
                //     id: 24,
                //     name: "Master of Accounting (IBMP)",
                //     code: "MA",
                // },
                // {
                //     id: 3,
                //     name: "Marketing",
                //     code: "MK",
                // },
                // {
                //     id: 23,
                //     name: "Master of Marketing (IBMP)",
                //     code: "MM",
                // },
                // {
                //     id: 6,
                //     name: "Operations Management",
                //     code: "OM",
                // },
                // {
                //     id: 1,
                //     name: "Real Estate Business",
                //     code: "RB",
                // },
                // {
                //     id: 25,
                //     name: "Integrated in Accounting",
                //     code: "Integrated in Business",
                // },
            ],
        };
    },
    getters,
    actions,
    mutations,
};