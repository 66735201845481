<template>
  <teleport to="body">
    <transition name="confirm-modal">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div
        class="fixed z-50 inset-0 overflow-y-auto font-kanit"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        v-if="show"
      >
        <div
          @click="tryClose()"
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
          <div
            class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
            aria-hidden="true"
          ></div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >

          <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                  :class="{ 'bg-red-100': isDanger, 'bg-green-100': isSuccess }"
                >
                  <!-- Heroicon name: outline/exclamation -->
                  <svg
                    v-if="isSuccess"
                    class="h-6 w-6 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <svg
                    v-else
                    class="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    class="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    {{ title }}
                  </h3>
                  <div class="mt-2">
                    <p class="text-base text-gray-500">
                      {{ message }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
            >
              <button
                type="button"
                @click="tryConfirm()"
                :class="{
                  'bg-red-600 hover:bg-red-700 focus:ring-red-500 ': isDanger,
                  'bg-green-500 hover:bg-green-600 focus:ring-green-400 ': isSuccess,
                }"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {{ conBtnText }}
              </button>
              <button
                type="button"
                @click="tryClose()"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
    },
    conBtnText: {
      type: String,
      required: false,
      default: "ยืนยัน",
    },
    mode: {
      type: String,
      required: false,
      default: "danger",
    },
  },
  emits: ["confirm", "close"],
  computed: {
    isDanger() {
      return this.mode === "danger";
    },
    isSuccess() {
      return this.mode === "success";
    },
  },

  methods: {
    tryClose() {
      this.$emit("close");
    },
    tryConfirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style>
/* enter transitions */
.confirm-modal-enter-from {
  opacity: 0;
  transform: translateY(-60px);
}
.confirm-modal-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.confirm-modal-enter-active {
  transition: all 0.3s ease;
}
/* leave transitions */
.confirm-modal-leave-from {
  opacity: 1;
  transform: translateY(0);
}
.confirm-modal-leave-to {
  opacity: 0;
  transform: translateY(-60px);
}
.confirm-modal-leave-active {
  transition: all 0.3s ease;
}
</style>
