<template>
  <base-card class="mt-12">
    <div class="flex space-x-2">
      <div class=" w-full">
        <label class="block  text-sm text-gray-500 "
          >เลขทะเบียน (ใส่ได้มากกว่า 1 ค่า)</label
        >
        <Multiselect
          v-model="stdCodeArray"
          mode="tags"
          :closeOnSelect="false"
          :searchable="true"
          :createTag="true"
        />
      </div>
    </div>
    <div class="py-2">Filter</div>
    <div class="flex space-x-2">
      <div class="w-full">
        <label class="block  text-sm text-gray-500 ">สาขาวิชาเอก</label>
        <select
          class="form-control text-sm md:text-base"
          v-model.number="stdMajor"
        >
          <option :value="null" v-if="isSudo">ทั้งหมด</option>
          <option
            v-for="major in stdMajorList"
            :value="major.id"
            :key="major.id"
          >
            {{ major.name }}
          </option>
        </select>
      </div>
      <div class="w-full">
        <label class="block  text-sm text-gray-500 ">ในคณะ/นอกคณะ</label>
        <select class="form-control text-sm md:text-base" v-model="stdSchool">
          <option :value="null">ทั้งหมด</option>
          <option value="inSchool">น.ศ. ในคณะ</option>
          <option value="outSchool">น.ศ. นอกคณะ</option>
        </select>
      </div>
      <div class="w-full">
        <label class="block  text-sm text-gray-500 ">สถานภาพ</label>
        <!-- <select
          class="form-control text-sm md:text-base"
          v-model.number="stdStatus"
        >
          <option :value="null">ทั้งหมด</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select> -->
        <select
          class="form-control px-1"
          placeholder=""
          v-model.number="stdStatus"
        >
          <option :value="null">ทั้งหมด</option>
          <option
            v-for="status in stdStatusList"
            :value="status.id"
            :key="status.id"
          >
            {{ status.name }}
          </option>
        </select>
      </div>
      <div class="w-full">
        <label class="block  text-sm text-gray-500 ">หลักสูตร</label>
        <select
          class="form-control text-sm md:text-base"
          v-model.number="stdProgram"
        >
          <option :value="null">ทั้งหมด</option>
          <option
            v-for="program in stdProgramList"
            :value="program.code"
            :key="program.id"
          >
            {{ program.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="w-full flex justify-end pt-5">
      <base-button class="px-10" @click="downloadStd()">ดาวน์โหลด</base-button>
    </div>
  </base-card>
  <toast
    :show="!!error"
    mode="error"
    @close="error = null"
    fixed
    :message="error"
  >
  </toast>
</template>

<script>
import XLSX from "xlsx";
import Multiselect from "@vueform/multiselect";
export default {
  components: { Multiselect },
  data() {
    return {
      stdCodeArray: [],
      stdSchool: null,
      stdStatus: null,
      stdProgram: null,
      stdMajor: null,
      error: null,
    };
  },
  computed: {
    students() {
      return this.$store.getters["adminStudents/students"];
    },
    stdStatusList() {
      return this.$store.getters["students/status"];
    },
    stdProgramList() {
      return this.$store.getters["programs/programs"];
    },
    stdMajorList() {
      if (this.isSudo) {
        return this.$store.getters["majors/majors"];
      } else {
        const majors = this.$store.getters["majors/majors"];
        return majors.filter((major) => {
          return this.staffMajors.includes(major.id);
        });
      }
    },
    isSudo() {
      return this.$store.getters["isSudo"];
    },
    staffMajors() {
      return this.$store.getters["userInfo"].majors || [];
    },
  },
  created() {
    this.staffLoadData();
  },
  methods: {
    // async downloadStd() {
    //   // set
    //   try {
    //     await this.$store.dispatch("adminStudents/setStudent", {
    //       mode: this.searchMode,
    //       queryText: this.searchValue,
    //     });
    //     this.page = 1;
    //   } catch (e) {
    //     this.error = e.message;
    //   }
    // },
    staffLoadData() {
      if (!this.isSudo) {
        this.stdMajor = this.staffMajors[0];
      }
    },
    async downloadStd() {
      if (!this.stdCodeArray || this.stdCodeArray.length == 0) {
        this.error = "ต้องใส่ค่าในการค้นหา";
        return;
      }
      this.isLoading = true;
      const exportJson = await this.getStudentInfoJson();
      if (this.logVisible) {
        console.log(exportJson);
      }
      const dataWS = XLSX.utils.json_to_sheet(exportJson);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "student_info.xlsx");
      this.isLoading = false;
    },
    getDMY(seconds) {
      const dt = new Date(seconds * 1000);
      return dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate();
    },
    async getStudentInfoJson() {
      try {
        let allStudentInfo = await this.$store.dispatch(
          "adminStudents/getStudentInfo",
          {
            stdCodeArray: this.stdCodeArray,
          }
        );

        if (this.stdMajor) {
          allStudentInfo = allStudentInfo.filter(
            (item) =>
              item.major == this.stdMajor || item.major2 == this.stdMajor
          );
        }

        if (this.stdSchool) {
          if (this.stdSchool === "inSchool") {
            allStudentInfo = allStudentInfo.filter(
              (item) => item.stdCode.slice(2, 4) == "02"
            );
          } else if (this.stdSchool === "outSchool") {
            allStudentInfo = allStudentInfo.filter(
              (item) => item.stdCode.slice(2, 4) !== "02"
            );
          } else {
            this.error = "stdSchool is invalid value";
            return;
          }
        }

        if (this.stdStatus) {
          allStudentInfo = allStudentInfo.filter(
            (item) => item.status == this.stdStatus
          );
        }

        if (this.stdProgram) {
          allStudentInfo = allStudentInfo.filter(
            (item) => item.program == this.stdProgram
          );
        }

        const resAllStudentInfo = allStudentInfo.map((doc) => {
          return {
            เลขทะเบียน: doc.stdCode,
            "ชื่อ-สกุล":
              doc.nameTitle + " " + doc.firstName + " " + doc.lastName,
            สถานภาพปัจจุบัน: doc.statusText,
            หลักสูตร: doc.programText,
            เอก: doc.majorText,
            เอก2: doc.major2Text,
            เพศ: doc.gender === "M" ? "ชาย" : doc.gender === "F" ? "หญิง" : "-",
            วันเกิด: doc.birthday ? this.getDMY(doc.birthday.seconds) : "-",
            ที่อยู่ปัจจุบัน: doc.conAddress,
            ที่อยู่ตามทะเบียนบ้าน: doc.homAddress,
            หมายเลขโทรศัพท์: doc.tel,
            "E-mail": doc.email,
            เลขบัตรประชาชน: doc.stdIdCard ? doc.stdIdCard : "-",
            เบอร์โทรฉุกเฉิน: doc.emergTel ? doc.emergTel : "-",
            ชื่อ: doc.emergName ? doc.emergName : "-",
            เกี่ยวข้องเป็น: doc.emergRelation ? doc.emergRelation : "-",
            "โรงเรียนม.ปลาย": doc.stdHighSchool,
            "เกรดม.ปลาย": doc.stdHighSchoolGrade,
            "หลักสูตรม.ปลาย": doc.stdHighSchoolSchool,
          };
        });
        return resAllStudentInfo;
      } catch (e) {
        this.error = e;
      }
    },
  },
};
</script>
