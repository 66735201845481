<template>
  <!-- component -->

  <teleport to="body">
    <transition name="banner">
      <section
        class="w-full p-5 lg:px-24 fixed bottom-0 bg-gray-600 font-kanit"
        v-if="isVisible"
        x-show="showCookieBanner"
      >
        <div class="md:flex items-center -mx-3">
          <div class="md:flex-1 px-3 mb-5 md:mb-0">
            <p
              class="text-center md:text-left text-white text-sm leading-tight md:pr-12"
            >
              เว็บไซต์ TBS Quota มีการใช้เทคโนโลยี เช่น คุกกี้ (cookies)
              และเทคโนโลยีที่คล้ายคลึงกันบนเว็บไซต์
              เพื่อสร้างประสบการณ์การใช้งานเว็บไซต์ของท่านให้ดียิ่งขึ้น
            </p>
          </div>
          <div class="px-3 text-center">
            <router-link :to="{ name: 'privacy-policy' }" v-if="false">
              <button
                id="btn"
                class="py-2 px-8 bg-gray-800 hover:bg-gray-900 text-white rounded font-bold text-sm shadow-xl mr-3"
              >
                รายละเอียด
              </button>
            </router-link>
            <button
              id="btn"
              class="py-2 px-8 bg-blue-500 hover:bg-blue-600 text-white rounded font-bold text-sm shadow-xl"
              @click.prevent="consent()"
            >
              ยอมรับ
            </button>
          </div>
        </div>
      </section>
    </transition>
  </teleport>
</template>

<script>
// import { v4 as uuidv4 } from "uuid";
import { db } from "../../js/firebaseDb.js";
export default {
  data() {
    return {
      expireDateDuration: 180,

      isVisible: false,
    };
  },
  // mounted() {
  //   this.checkConsent();
  // },
  computed: {},
  methods: {
    checkConsent() {
      if (localStorage.consentTime) {
        const consentTime = new Date(localStorage.consentTime);
        // consentTime.setMinutes(
        //   consentTime.getMinutes() + this.expireDateDuration
        // );
        consentTime.setDate(consentTime.getDate() + this.expireDateDuration);
        const currentTime = new Date();
        if (consentTime && currentTime) {
          if (currentTime < consentTime) {
            this.isVisible = false;
          } else {
            this.isVisible = true;
          }
        } else {
          this.isVisible = true;
        }
      } else {
        this.isVisible = true;
      }
    },
    async consent() {
      this.isVisible = false;
      const consentRef = db.collection("consents").doc();
      const consentTime = new Date();
      localStorage.setItem("consentKey", consentRef.id);
      localStorage.setItem("consentTime", consentTime);
      // update consent data to firebase
      await consentRef.set({ time: consentTime }).catch((error) => {
        console.error("Error updating document: ", error);
      });
    },
  },
};
</script>

<style scoped>
/* enter transitions */
.banner-enter-from {
  opacity: 0;
  transform: translateY(60px);
}
/* .banner-enter-to {
    opacity: 1;
    transform: translateY(0);
  } */
.banner-enter-active {
  transition: all 0.3s ease;
}
/* leave transitions */
/* .banner-leave-from {
    opacity: 1;
    transform: translateY(0);
  } */
.banner-leave-to {
  opacity: 0;
  transform: translateY(60px);
}
.banner-leave-active {
  transition: all 0.3s ease;
}
</style>
