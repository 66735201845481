<template>
  <div class="py-10">
    <div v-if="reqQuotaList.length === 0" class="text-center py-5">
      <h4>นักศึกษารายนี้ยังไม่มีการขอโควตา</h4>
    </div>
    <div v-else class="grid-table-main ">
      <div
        class="
              grid grid-cols-12
              grid-table-header
            "
      >
        <div class="w-full h-auto  flex items-center justify-center">
          รหัสวิชา
        </div>
        <div class="w-full h-auto col-span-2 flex items-center justify-center">
          ชื่อวิชา
        </div>
        <div class="w-full h-auto  flex items-center justify-center">
          Campus
        </div>
        <div class="w-full h-auto flex items-center justify-center">
          Sec-Group
        </div>
        <div class="w-full h-auto col-span-2 flex items-center justify-center">
          วันเวลา
        </div>
        <div class="w-full h-auto col-span-2 flex items-center justify-center">
          ผู้บรรยาย
        </div>
        <div class="w-full h-auto flex items-center justify-center">
          รอบ
        </div>
        <div
          class="w-full h-auto col-span-2   flex items-center justify-center"
        >
          สถานะ
        </div>
      </div>

      <div class="grid-table-body">
        <div
          class="grid grid-cols-12 grid-table-row"
          v-for="q in reqQuotaList"
          :key="q.id"
        >
          <div class="text-center my-auto">
            <base-button
              link
              mode="super-flat"
              :to="{ name: 'quota-sec', params: { id: q.quota.id } }"
              >{{ q.subCode }}</base-button
            >
          </div>
          <div class="col-span-2 text-center my-auto ">
            {{ q.subName }}
          </div>
          <div class=" text-center my-auto">
            {{ q.campus }}
          </div>
          <div class=" text-center my-auto">
            {{ q.quota.secId }}{{ q.quota.group }}
          </div>
          <div class="col-span-2  text-center my-auto">
            {{ q.quota.secTime }}
          </div>
          <div
            class="col-span-2  text-center my-auto"
            v-if="q.quota.instructors"
          >
            <span
              v-for="instructor in q.quota.instructors"
              :key="instructor.id"
            >
              {{ instructor.name }}
              <br />
            </span>
          </div>
          <div class="col-span-2 text-center my-auto" v-else>
            <span>{{ q.quota.instName }}</span>
          </div>
          <div class=" text-center my-auto">
            {{ qRoundToText(q.reqQutTime) }}
          </div>
          <div class="col-span-2 text-center my-auto">
            <div class="flex items-center">
              <ResultIcon :reqQuota="q" mode="admin"></ResultIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResultIcon from "../../components/UI/ResultIcon.vue";
import TextConverterMixin from "../../mixins/textConverter.js";
export default {
  mixins: [TextConverterMixin], //qRoundToText()
  components: { ResultIcon },
  computed: {
    reqQuotaList() {
      return this.$store.getters["adminStudents/reqQuotaAndQuota"];
    },
  },
};
</script>
