import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

export default {
    namespaced: true,
    state() {
        return {
            sections: [],
            // sections: [{
            //         code: "0000",
            //         time: "ติดต่ออาจารย์",
            //     },
            //     {
            //         code: "0100",
            //         time: "อ./ พฤ. 8:00-9:30",
            //     },
            //     {
            //         code: "0200",
            //         time: "อ./ พฤ. 9:30-11:00",
            //     },
            //     {
            //         code: "0300",
            //         time: "อ./ พฤ. 11:00-12:30",
            //     },
            //     {
            //         code: "0400",
            //         time: "อ./ พฤ 13:30-15:00",
            //     },
            //     {
            //         code: "0500",
            //         time: "อ./ พฤ. 15:00-16:30",
            //     },
            //     {
            //         code: "0600",
            //         time: "พ./ ศ. 8:00-9:30",
            //     },
            //     {
            //         code: "0700",
            //         time: "พ./ ศ. 9:30-11:00",
            //     },
            //     {
            //         code: "0800",
            //         time: "พ./ ศ. 11:00-12:30",
            //     },
            //     {
            //         code: "0900",
            //         time: "พ./ ศ. 13:30-15:00",
            //     },
            //     {
            //         code: "1000",
            //         time: "พ./ ศ. 15:00-16:30",
            //     },
            //     {
            //         code: "1100",
            //         time: "จ./ ส. 8.00-9.30",
            //     },
            //     {
            //         code: "1200",
            //         time: "อ. 8.00-11.00",
            //     },
            //     {
            //         code: "1300",
            //         time: "จ./ ส. 9.30-11.00",
            //     },
            //     {
            //         code: "1400",
            //         time: "จ./ ส. 11.00-12.30",
            //     },
            //     {
            //         code: "1500",
            //         time: "จ./ ส. 13.30-15.00",
            //     },
            //     {
            //         code: "2000",
            //         time: "อ./ พฤ. 12:30-14:00",
            //     },
            //     {
            //         code: "2100",
            //         time: "พฤ. 8.00-11.00",
            //     },
            //     {
            //         code: "2300",
            //         time: "อ. 9.30-12.30",
            //     },
            //     {
            //         code: "2400",
            //         time: "จ./ ส. 15.00-16.30",
            //     },
            //     {
            //         code: "2500",
            //         time: "จ./ ส. 16.30-18.00",
            //     },
            //     {
            //         code: "3200",
            //         time: "พฤ. 9.30-12.30",
            //     },
            //     {
            //         code: "3800", // thaprachan only
            //         time: "พฤ, ศ, ส. 9:00-12:00",
            //     },
            //     {
            //         code: "4000",
            //         time: "พ./ ศ. 12:30-14:00",
            //     },
            //     {
            //         code: "4100", // thaprachan only
            //         time: "จ, พ. 8:00-9:30",
            //     },
            //     {
            //         code: "4500",
            //         time: "อ. 13:30-16:30",
            //     },
            //     {
            //         code: "4900",
            //         time: "พ. 12:30-15:30",
            //     },
            //     {
            //         code: "5400",
            //         time: "พฤ. 13:30-16:30",
            //     },
            //     {
            //         code: "5900", // thaprachan only
            //         time: "พฤ, ศ, ส. 13:30-16:30",
            //     },
            //     {
            //         code: "6700",
            //         time: "พ. 8:00-11:00",
            //     },
            //     {
            //         code: "6800",
            //         time: "อ. 16.30 - 19.30 น.",
            //     },
            //     {
            //         code: "7600",
            //         time: "ศ. 8:00-11:00",
            //     },
            //     {
            //         code: "7800",
            //         time: "พ. 9:30-12:30",
            //     },
            //     {
            //         code: "8100",
            //         time: "จ. 9:30-12:30",
            //     },
            //     {
            //         code: "8200",
            //         time: "จ. 13:30-16:30",
            //     },
            //     {
            //         code: "8300",
            //         time: "ส. 9:00-12:00",
            //     },
            //     {
            //         code: "8400",
            //         time: "ส. 13:00-16:00",
            //     },
            //     {
            //         code: "8500",
            //         time: "ส. 16:00 - 19:00",
            //     },
            //     {
            //         code: "8600",
            //         time: "อ./ พฤ. 16:30-18:00",
            //     },
            //     {
            //         code: "8700",
            //         time: "ศ. 9:30-12:30",
            //     },
            //     {
            //         code: "8800",
            //         time: "พ./ ศ. 16:30-18:00",
            //     },
            //     {
            //         code: "8900",
            //         time: "จ. 16:30 - 19:30",
            //     },
            //     {
            //         code: "9000",
            //         time: "พ. 13:30-16:30",
            //     },
            //     {
            //         code: "9100",
            //         time: "ศ. 13:30-16:30",
            //     },
            // ],
        };
    },
    getters,
    actions,
    mutations,
};