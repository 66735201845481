import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

let firebaseConfig = null;
if (process.env.VUE_APP_DB === "tbs-webquota-test") {
    firebaseConfig = {
        apiKey: "AIzaSyDHXIxOgpxD9GLjPh3WNin5RPsMgPx3NcM",
        authDomain: "tbs-webquota-test-23479.firebaseapp.com",
        projectId: "tbs-webquota-test-23479",
        storageBucket: "tbs-webquota-test-23479.appspot.com",
        messagingSenderId: "464755513416",
        appId: "1:464755513416:web:6bb129f0da08892d75a07d",
        // measurementId: "G-2F5VSMYCT5",
    };
} else if (process.env.VUE_APP_DB === "tbs-webquota-staging") {
    // TBS_DB_DEV
    firebaseConfig = {
        apiKey: "AIzaSyB4Qr8JF1bbULCqUd-H6ZfpiQJhmd8l4rA",
        authDomain: "tbs-webquota-staging.firebaseapp.com",
        projectId: "tbs-webquota-staging",
        storageBucket: "tbs-webquota-staging.appspot.com",
        messagingSenderId: "1049694719800",
        appId: "1:1049694719800:web:d55ae829abacc10d533bcd",
        //   measurementId: "",
    };
} else if (process.env.VUE_APP_DB === "tbs-webquota-prod") {
    // TBS_DB_DEV
    firebaseConfig = {
        apiKey: "AIzaSyB-lV5Y-cfyLvRqafOPvyEBv5lEIP5Icfo",
        authDomain: "tbs-webquota-prod-cf8a3.firebaseapp.com",
        projectId: "tbs-webquota-prod-cf8a3",
        storageBucket: "tbs-webquota-prod-cf8a3.appspot.com",
        messagingSenderId: "856710973745",
        appId: "1:856710973745:web:94b30b5ef918a50f7b4810",
        measurementId: "G-7ZJR5GJTS2",
    };
} else {
    // TBS_DB_DEV

    // firebaseConfig = {
    //     apiKey: "AIzaSyAN0l3_MBrOfcIbiWrXLu2dEXxy9j5s5SU",
    //     authDomain: "tbs-webquota-dev.firebaseapp.com",
    //     projectId: "tbs-webquota-dev",
    //     storageBucket: "tbs-webquota-dev.appspot.com",
    //     messagingSenderId: "461277387543",
    //     appId: "1:461277387543:web:71d75b88082a48aabef552",
    //     measurementId: "G-2F5VSMYCT5",
    // };

    firebaseConfig = {
        apiKey: "AIzaSyClEgpzZRNFFpvER-yygKUEYv8maMML93k",
        authDomain: "tbs-webquota-dev-5c2e1.firebaseapp.com",
        projectId: "tbs-webquota-dev-5c2e1",
        storageBucket: "tbs-webquota-dev-5c2e1.appspot.com",
        messagingSenderId: "594061320253",
        appId: "1:594061320253:web:6ee5188d76f3e191d14a54",
        //   measurementId: "G-2F5VSMYCT5",
    };
}

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
// const functions = firebase.functions("asia-southeast1");
const functions = firebase.app().functions("asia-southeast1");
const storage = firebase.storage();

db.settings({ timestampInSnapshots: true });

export { db, auth, firebaseConfig, functions, storage };