export default {
    addQuota(state, quotaData) {
        state.quota.push(quotaData);
    },
    setQuota(state, payload) {
        state.quota = payload;
    },
    clearQuota(state) {
        state.quota = [];
    },
    setReqQuota(state, payload) {
        state.reqQuota = payload;
    },
    addReqQuota(state, reqQuotaData) {
        state.reqQuota.push(reqQuotaData);
    },
    clearReqQuota(state) {
        state.reqQuota = [];
    },

    setBskQuota(state, payload) {
        state.bskQuota = payload;
    },
    addBskQuota(state, bskQuotaData) {
        state.bskQuota.push(bskQuotaData);
    },
};