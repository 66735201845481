<template>
  <div class="flex flex-col w-full ">
    <div
      class="text-white bg-primary-200 flex flex-shrink-0 flex-col pl-4 pt-4"
    >
      <div class="flex relative  items-center p-4 h-12">
        <span class="text-2xl tracking-wide font-semibold"
          >จำนวนรับ (Quota)</span
        >
      </div>
    </div>

    <div class="text-white bg-blue-400 flex w-full pl-4">
      <div class="flex overflow-hidden h-12 ml-2">
        <router-link :to="{ name: 'quota-list' }" class="flex items-center">
          <button class="mx-3 hover:border-b-2 border-white">
            <span>ดูข้อมูล/แก้ไข</span>
          </button></router-link
        >

        <!-- <button class="mx-3 hover:border-b-2 border-white">
          <span>แก้ไข</span>
        </button> -->

        <router-link :to="{ name: 'quota-add' }" class="flex items-center">
          <button class="mx-3 hover:border-b-2 border-white">
            <span>เพิ่ม</span>
          </button></router-link
        >
        <router-link :to="{ name: 'quota-process' }" class="flex items-center">
          <button class="mx-3 hover:border-b-2 border-white">
            <span>ประมวลผล</span>
          </button></router-link
        >
        <!-- 
        <button class="mx-3 hover:border-b-2 border-white">
          <span>นำเข้า</span>
        </button> -->
      </div>
    </div>
  </div>
  <div class="px-4">
    <div class=" flex justify-center items-center" v-if="loadingData">
      <base-spinner class="my-10"></base-spinner>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
  <toast
    :show="isSuccess"
    mode="success"
    message="บันทึกเสร็จสิ้นแล้ว"
    @close="closeSuccess()"
  />
</template>

<script>
import LoadDataMixin from "../../mixins/loadData.js";
export default {
  mixins: [LoadDataMixin], // loadYear(), loadQuota(), loadConditions(),loadSection
  data() {
    return {
      isSuccess: false,
      loadingData: false,
    };
  },
  created() {
    this.loadData();
  },

  watch: {
    $route: "fetchData",
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  methods: {
    async loadData() {
      this.loadingData = true;
      await this.loadYear();
      await this.loadQuota("admin");
      await this.loadConditions();
      await this.loadSection();
      await this.loadInst();
      this.loadingData = false;
    },
    closeSuccess() {
      this.isSuccess = false;
    },
    fetchData() {
      this.isSuccess = this.$route.params.success == "true" ? true : false;
    },
    async loadInst() {
      try {
        await this.$store.dispatch("instructors/setInstructor");
      } catch (e) {
        this.error = e;
      }
    },
  },
};
</script>

<style scoped>
a:active,
a:hover,
a.router-link-active {
  @apply border-b-4 border-white pt-1;
}

a:hover span,
a:active span,
a.router-link-active span {
  @apply font-extrabold;
}
</style>
