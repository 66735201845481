<template>
  <!-- component -->

  <teleport to="body">
    <transition name="banner">
      <section
        class="w-full p-5 lg:px-24 fixed bottom-0 bg-gray-600 font-kanit"
        v-if="isShowBanner && forceVisible"
        x-show="showCookieBanner"
      >
        <div class="md:flex items-center -mx-3">
          <div class="md:flex-1 px-3 mb-5 md:mb-0">
            <p
              class="text-center md:text-left text-white text-sm leading-tight md:pr-12"
            >
              เบอร์โทร {{ userInfo.tel }} เป็นเบอร์ที่สามารถติดต่อคุณได้
              ถูกต้องหรือไม่
            </p>
          </div>
          <div class="px-3 text-center">
            <router-link :to="{ name: 'std-info' }">
              <button
                id="btn"
                class="py-2 px-8 bg-gray-800 hover:bg-gray-900 text-white rounded font-bold text-sm shadow-xl mr-3"
              >
                แก้ไขเบอร์
              </button>
            </router-link>
            <button
              id="btn"
              class="py-2 px-8 bg-blue-500 hover:bg-blue-600 text-white rounded font-bold text-sm shadow-xl"
              @click.prevent="confirmInfo()"
            >
              เบอร์ถูกต้อง
            </button>
          </div>
        </div>
      </section>
    </transition>
  </teleport>
</template>

<script>
// import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import "firebase/firestore";
import { db } from "../../js/firebaseDb.js";
export default {
  data() {
    return {
      expDuration: 180,

      forceVisible: true,
    };
  },
  // mounted() {
  //   this.checkConsent();
  // },
  computed: {
    userInfo() {
      return this.$store.getters["userInfo"];
    },
    isShowBanner() {
      const currentTime = new Date();
      if (!this.userInfo) {
        return false;
      }
      if (!this.userInfo.updateInfoTime) {
        return true;
      }
      const expDate = this.userInfo.updateInfoTime.toDate();
      expDate.setDate(expDate.getDate() + this.expDuration);
      if (expDate && currentTime) {
        if (currentTime < expDate) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    async confirmInfo() {
      this.forceVisible = false;
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      const studentRef = db.collection(`students`).doc(this.userInfo.id);

      await studentRef.update({ updateInfoTime: timestamp }).catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });

      await this.$store
        .dispatch("setUser", { mode: "student" })
        .catch((error) => {
          console.error("Error setUser", error);
        });
    },
  },
};
</script>

<style scoped>
/* enter transitions */
.banner-enter-from {
  opacity: 0;
  transform: translateY(60px);
}
/* .banner-enter-to {
    opacity: 1;
    transform: translateY(0);
  } */
.banner-enter-active {
  transition: all 0.3s ease;
}
/* leave transitions */
/* .banner-leave-from {
    opacity: 1;
    transform: translateY(0);
  } */
.banner-leave-to {
  opacity: 0;
  transform: translateY(60px);
}
.banner-leave-active {
  transition: all 0.3s ease;
}
</style>
