import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

export default {
    namespaced: true,
    state() {
        return {
            students: [], // used for search student
            reqQuota: [], // used for quota of student
            quota: [], // used for quota of student
            studentInfo: null, // used for StdInfoForm of student
            statusChange: [],
            majorChange: [],
            stdPetitions: [],
        };
    },
    getters,
    actions,
    mutations,
};