<template>
  <div class="text-center py-10">
    <h2>
      กำหนดการแจ้งความจำนงครั้งที่ {{ qRoundToText(schedule.qRound) }} เทอม
      {{ schedule.semester }} ปีการศึกษา {{ schedule.year }}
    </h2>
  </div>
  <base-card class="flex justify-between items-center">
    <div class="flex items-center">
      <div class=" mr-4">อนุญาตสู่เข้าระบบ</div>
      <div class="w-32">
        <label class="form-control">
          นักศึกษา
        </label>
        <div class="flex  space-x-2" v-if="schedule.studentLogin">
          <svg
            class="w-6 h-6 text-green-500 "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div>
            เปิด
          </div>
        </div>
        <div class="flex space-x-2" v-else>
          <svg
            class="w-6 h-6 text-red-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div>
            ปิด
          </div>
        </div>
      </div>

      <div class="w-32">
        <label class="form-control">
          เลขาสาขาวิชาเอก
        </label>
        <div class="flex  space-x-2" v-if="schedule.adminLogin">
          <svg
            class="w-6 h-6 text-green-500 "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div>
            เปิด
          </div>
        </div>
        <div class="flex space-x-2" v-else>
          <svg
            class="w-6 h-6 text-red-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div>
            ปิด
          </div>
        </div>
      </div>

      <div class="cursor-pointer" @click="openGrantDia()">
        <svg
          class="w-6 h-6 text-blue-500 hover:text-blue-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          ></path>
        </svg>
      </div>
    </div>
    <div>
      <base-button class="h-full" @click="addScheduleRule()">
        + เพิ่มข้อมูลกำหนดเวลา</base-button
      >
    </div>
  </base-card>
  <div v-if="scheduleRule.length === 0" class="text-center my-20">
    <h3>ยังไม่มีกำหนดการ</h3>
  </div>
  <div v-else class="std-info-grid col-span-4">
    <h3 class="text-gray-500 font-semibold  ">
      กำหนดการปีการศึกษา {{ schedule.semester }}/{{ schedule.year }}
    </h3>

    <div class="std-info-data space-y-2 col-span-4">
      <div class="flex items-center justify-center">
        <table
          class="flex flex-row flex-no-wrap md:block md:bg-white rounded-lg overflow-hidden md:shadow-lg my-5 mx-auto w-full"
        >
          <thead class=" text-white">
            <tr
              class="bg-teal-400 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-2 "
            >
              <th class="p-3 text-left">ลำดับ</th>
              <th class="p-3 text-left ">ชื่อ</th>
              <th class="p-3 text-left ">ผู้ใช้</th>
              <th class="p-3 text-left ">เปิด</th>
              <th class="p-3 text-left ">ปิด</th>
              <th class="p-3 text-left ">Rule</th>
              <th class="p-3 text-left ">แก้ไข</th>
            </tr>
          </thead>
          <tbody class="flex-1 md:flex-none">
            <tr
              class="flex flex-col flex-no wrap md:table-row mb-2 md:mb-0 "
              v-for="(schedule, index) in scheduleRule"
              :key="index"
            >
              <td
                class="border-grey-light border text-center hover:bg-gray-100 p-3"
              >
                {{ index + 1 }}
                <div
                  class="flex  justify-center   "
                  v-if="isRuleActive(schedule.open, schedule.close)"
                >
                  <svg
                    class="w-6 h-6  text-green-500 "
                    data-darkreader-inline-fill=""
                    data-darkreader-inline-stroke=""
                    fill="none"
                    stroke="currentColor"
                    style="--darkreader-inline-stroke:currentColor; --darkreader-inline-fill:none;"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <div class="text-green-500">Active</div>
                </div>
                <div class="flex justify-center  " v-else>
                  <svg
                    class="w-6 h-6 text-gray-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <div class="text-gray-500">Inactive</div>
                </div>
              </td>
              <td class="border-grey-light border hover:bg-gray-100 p-3 ">
                {{ schedule.name }}
              </td>
              <td class="border-grey-light border hover:bg-gray-100 p-3 ">
                {{ schedule.effectUser }}
              </td>
              <td class="border-grey-light border hover:bg-gray-100 p-3 ">
                <div>
                  {{ schedule.open.toDate().toLocaleString() }}
                </div>
              </td>
              <td
                class="border-grey-light border hover:bg-gray-100 p-3 py-3.5 "
              >
                <div>
                  {{ schedule.close.toDate().toLocaleString() }}
                </div>
              </td>
              <td class="border-grey-light border hover:bg-gray-100 p-3">
                <div v-if="schedule.effectUser == 'student'">
                  ลงทะเบียน
                  {{ schedule.rule.register ? `เปิด` : "ปิด" }}
                </div>
                <div v-if="schedule.effectUser == 'student'">
                  ยกเลิกโควตา
                  {{ schedule.rule.cancel ? `เปิด` : "ปิด" }}
                </div>
                <div v-if="schedule.effectUser == 'student'">
                  ยื่นคำร้อง
                  {{ schedule.rule.petition ? `เปิด` : "ปิด" }}
                </div>
                <div v-if="schedule.effectUser == 'staff'">
                  แก้ไขข้อมูล
                  {{ schedule.rule.edit ? `เปิด` : "ปิด" }}
                </div>
                <div v-if="schedule.effectUser == 'staff'">
                  จัดการคำร้อง
                  {{ schedule.rule.petition ? `เปิด` : "ปิด" }}
                </div>

                <div>
                  {{
                    schedule.allowStdInternal
                      ? `นักศึกษาในคณะ ${schedule.allowStdInternal}`
                      : ""
                  }}
                </div>
                <div>
                  {{
                    schedule.allowStdExternal
                      ? `นักศึกษานอกคณะ ${schedule.allowStdExternal}`
                      : ""
                  }}
                </div>
              </td>
              <td
                class="border-grey-light border hover:bg-gray-100 p-3  text-blue-550 hover:text-blue-600 hover:font-medium cursor-pointer"
                @click="editScheduleRule(schedule.id)"
              >
                แก้ไข
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Edit Rule Dialog -->
  <data-modal
    :show="dialog === 'editSchedule'"
    :deleteBtn="scheduleRuleMode == 'edit'"
    :title="scheduleRuleMode == 'edit' ? 'แก้ไขกำหนดการ' : 'เพิ่มกำหนดการ'"
    @close="dialog = null"
    @delete="showDeleteRule = true"
    @submit="saveScheduleRule"
  >
    <!-- {{ editedScheduleRule }} -->
    <form @submit.prevent>
      <!-- #################### -->
      <div class="grid grid-cols-3 gap-4">
        <div class="col-span-1 text-right font-semibold">ชื่อ</div>
        <div class="col-span-2">
          <input
            type="text"
            class="form-control "
            placeholder=""
            v-model="editedScheduleRule.name"
          />
        </div>
        <div class="col-span-1 text-right font-semibold">เทอม/ปีการศึกษา</div>
        <div class="col-span-2">{{ editedScheduleRule.semYear }}</div>
        <div class="col-span-1 text-right font-semibold">มีผลต่อผู้ใช้</div>
        <div class="col-span-2">
          <select
            class="form-control text-sm md:text-base"
            v-model="editedScheduleRule.effectUser"
            @change="setRule(editedScheduleRule.effectUser)"
            :disabled="scheduleRuleMode === 'edit'"
          >
            <option value="student">นักเรียน</option>
            <option value="staff">เลขาภาค/เจ้าหน้าที่</option>
          </select>
        </div>
        <div class="col-span-1 text-right font-semibold">
          เวลาเปิด - ปิด
        </div>
        <div class="col-span-2">
          <!-- {{ range }} -->
          <v-date-picker
            v-model="range"
            @blur="v$.range.$touch"
            mode="dateTime"
            :masks="masks"
            is-range
          >
            <template v-slot="{ inputValue, inputEvents, isDragging }">
              <div class="flex flex-col justify-start ">
                <div class="relative flex-grow">
                  <svg
                    class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                  </svg>
                  <input
                    class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full"
                    :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                    :value="inputValue.start"
                    v-on="inputEvents.start"
                  />
                </div>
                <span class="flex-shrink-0 m-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 stroke-current text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 14l-7 7m0 0l-7-7m7 7V3"
                    />
                  </svg>
                </span>
                <div class="relative flex-grow">
                  <svg
                    class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                  </svg>
                  <input
                    class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full"
                    :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                    :value="inputValue.end"
                    v-on="inputEvents.end"
                  />
                </div>
              </div>
            </template>
          </v-date-picker>
          <span
            class="errors"
            v-for="error of v$.range.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="col-span-1 text-right font-semibold">Rule</div>
        <div
          class="col-span-2 "
          v-if="editedScheduleRule.effectUser === 'student'"
        >
          <!-- {{ editedScheduleRule.rule }} -->
          <div class="mb-2">
            <span>ยกเลิกโควตา</span>
            <div class="flex">
              <input type="checkbox" v-model="editedScheduleRule.rule.cancel" />
              <p class="pl-2">เปิด</p>
            </div>
          </div>
          <div class="mb-2">
            <span>ลงชื่อเข้าใช้</span>
            <div class="flex">
              <input
                type="checkbox"
                v-model="editedScheduleRule.rule.login"
                disabled="disabled"
              />
              <p class="pl-2">เปิด</p>
            </div>
          </div>
          <div class="mb-2">
            <span>ขอโควตา</span>
            <div class="flex">
              <input
                type="checkbox"
                v-model="editedScheduleRule.rule.register"
              />
              <p class="pl-2">เปิด</p>
            </div>
          </div>
          <div class="mb-2">
            <span>ยื่นคำร้อง</span>
            <div class="flex">
              <input
                type="checkbox"
                v-model="editedScheduleRule.rule.petition"
              />
              <p class="pl-2">เปิด</p>
            </div>
          </div>
        </div>
        <div
          class="col-span-2 "
          v-if="editedScheduleRule.effectUser === 'staff'"
        >
          <!-- {{ editedScheduleRule.rule }} -->
          <div class="mb-2">
            <span>แก้ไขข้อมูล</span>
            <div class="flex">
              <input type="checkbox" v-model="editedScheduleRule.rule.edit" />
              <p class="pl-2">เปิด</p>
            </div>
          </div>
          <div class="mb-2">
            <span>ลงชื่อเข้าใช้</span>
            <div class="flex">
              <input
                type="checkbox"
                v-model="editedScheduleRule.rule.login"
                disabled="disabled"
              />
              <p class="pl-2">เปิด</p>
            </div>
          </div>
          <div class="mb-2">
            <span>จัดการคำร้อง</span>
            <div class="flex">
              <input
                type="checkbox"
                v-model="editedScheduleRule.rule.petition"
              />
              <p class="pl-2">เปิด</p>
            </div>
          </div>
        </div>

        <div
          class="col-span-1 text-right font-semibold"
          v-if="editedScheduleRule.effectUser === 'student'"
        >
          นักศึกษาในคณะ
        </div>
        <div
          class="col-span-2"
          v-if="editedScheduleRule.effectUser === 'student'"
        >
          <Multiselect
            v-model="editedScheduleRule.allowStdInternal"
            mode="tags"
            :closeOnSelect="false"
            :searchable="true"
            :createTag="true"
            label="value"
            :options="arrayToObject(editedScheduleRule.allowStdInternal)"
          />

          <!-- {{ editedScheduleRule.allowStdInternal }} -->
        </div>
        <div
          class="col-span-1 text-right font-semibold"
          v-if="editedScheduleRule.effectUser === 'student'"
        >
          นักศึกษานอกคณะ
        </div>
        <div
          class="col-span-2 pb-16"
          v-if="editedScheduleRule.effectUser === 'student'"
        >
          <Multiselect
            v-model="editedScheduleRule.allowStdExternal"
            mode="tags"
            :closeOnSelect="false"
            :searchable="true"
            :createTag="true"
            label="value"
            :options="arrayToObject(editedScheduleRule.allowStdExternal)"
          />
          <!-- {{ editedScheduleRule.allowStdExternal }} -->
        </div>
      </div>
    </form>
  </data-modal>

  <!-- Grant Login Dialog -->
  <data-modal
    :show="dialog === 'grantLogin'"
    :deleteBtn="false"
    title="แก้ไขสิทธิ์เข้าระบบ"
    @close="dialog = null"
    @submit="saveGrant"
  >
    <div class="pr-2 w-30 mt-5">
      <label class="form-control">นักศึกษาเข้าระบบ</label>
      <div class="pt-2 flex">
        <div>
          <input
            type="checkbox"
            v-model="studentLogin"
            @blur="v$.studentLogin.$touch"
          />
          <span
            class="errors"
            v-for="error of v$.studentLogin.$errors"
            :key="error.$uid"
            >{{ error.$message }} <br />
          </span>
        </div>
        <p class="pl-2">เปิด</p>
      </div>
    </div>
    <div class="pr-2 w-30 mt-5">
      <label class="form-control">เลขาสาขาวิชาเอกเข้าระบบ</label>
      <div class="pt-2 flex">
        <div>
          <input
            type="checkbox"
            v-model="adminLogin"
            @blur="v$.adminLogin.$touch"
          />
          <span
            class="errors"
            v-for="error of v$.adminLogin.$errors"
            :key="error.$uid"
            >{{ error.$message }} <br />
          </span>
        </div>
        <p class="pl-2">เปิด</p>
      </div>
    </div>
  </data-modal>
  <!-- {{ schedule }}
  <hr />
  {{ scheduleRule }} -->

  <toast
    :show="!!error"
    mode="error"
    @close="error = null"
    fixed
    :message="error"
  >
  </toast>

  <confirm-modal
    :show="showDeleteRule"
    title="ยืนยันลบ Schedule Rule นี้ ออกจากระบบ"
    @close="showDeleteRule = false"
    @confirm="deteleScheduleRule()"
  >
  </confirm-modal>
</template>

<script>
import DataModal from "../../components/UI/DataModal.vue";
import { DatePicker } from "v-calendar";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import Multiselect from "@vueform/multiselect";
import ConfirmModal from "../../components/UI/ConfirmModal.vue";
import TextConverterMixin from "../../mixins/textConverter.js";

const mustHaveDate = (dateRange) =>
  helpers.req(dateRange.start) && helpers.req(dateRange.end);

export default {
  mixins: [TextConverterMixin], //qRoundToText()
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  components: {
    DataModal,
    "v-date-picker": DatePicker,
    Multiselect,
    ConfirmModal,
  },

  data() {
    return {
      // system
      dialog: null,

      date: null,
      studentLogin: null,
      adminLogin: null,
      error: null,
      range: {
        start: null, //new Date(2020, 0, 6)
        end: null, //new Date(2020, 0, 23)
      },
      masks: {
        input: "YYYY-MM-DD h:mm A",
      },

      // edit
      scheduleRuleMode: null,
      editedScheduleRule: {},

      // confirm modal
      showDeleteRule: false,
    };
  },
  validations() {
    return {
      range: {
        mustHaveDate: helpers.withMessage(
          "This field cannot be empty",
          mustHaveDate
        ),
      },
      studentLogin: {
        required,
      },
      adminLogin: {
        required,
      },
    };
  },
  computed: {
    schedule() {
      return this.$store.getters["schedule"];
    },
    scheduleRule() {
      return this.$store.getters["scheduleRule"];
    },
    systemStudentLogin() {
      return this.schedule.studentLogin;
    },
    systemAdminLogin() {
      return this.schedule.adminLogin;
    },
    fSemYear() {
      return this.$store.getters["fSemYear"];
    },
    defaultStudentRule() {
      return {
        cancel: true,
        login: true,
        register: true,
        petition: false,
      };
    },
    defaultStaffRule() {
      return {
        edit: false,
        login: true,
        petition: false,
      };
    },
  },
  created() {},
  methods: {
    // getRuleText(schedule) {
    //   let theText = ''
    //   if(schedule.effectUser === 'student'){
    //     if(schedule.rule){
    //       if(schedule.rule.register){

    //       }
    //     }

    //   }else if (schedule.effectUser === 'staff'){

    //   }else{

    //   }
    // },
    clearRange() {
      this.range = {
        start: null,
        end: null,
      };
    },
    setRule(user) {
      if (user === "student") {
        this.editedScheduleRule.rule = this.defaultStudentRule;
      } else if (user === "staff") {
        this.editedScheduleRule.rule = this.defaultStaffRule;
      } else {
        this.error = "this user in not supported";
      }
    },
    arrayToObject(array) {
      if (array) {
        return array.map((x) => {
          return { value: x };
        });
      } else {
        return {};
      }
    },
    isRuleActive(open, close) {
      const currentTime = this.$store.getters.currentTime;
      return (
        close.seconds * 1000 > currentTime && currentTime > open.seconds * 1000
      );
    },
    addScheduleRule() {
      this.scheduleRuleMode = "add";
      this.editedScheduleRule = null;
      this.clearRange();
      // set some default value
      this.editedScheduleRule = {
        effectUser: "student",
        semYear: this.fSemYear,
        rule: this.defaultStudentRule,
      };
      this.dialog = "editSchedule";
    },
    editScheduleRule(scheduleId) {
      this.scheduleRuleMode = "edit";
      this.editedScheduleRule = null;
      this.clearRange();
      const theScheduleRule = this.scheduleRule.find(
        (item) => scheduleId === item.id
      );
      this.editedScheduleRule = { ...theScheduleRule };
      this.editedScheduleRule.rule = { ...theScheduleRule.rule };
      this.range.start = this.editedScheduleRule.open.toDate();
      this.range.end = this.editedScheduleRule.close.toDate();
      this.dialog = "editSchedule";
    },
    async deteleScheduleRule() {
      try {
        await this.$store.dispatch(
          "deleteScheduleRule",
          this.editedScheduleRule
        );
        this.dialog = null;
        this.$store.dispatch("updateToast", {
          mode: "success",
          message: "บันทึกข้อมูลสำเร็จ",
          show: true,
        });
        this.clearRange();
      } catch (e) {
        this.error = e;
      }
    },
    async saveScheduleRule() {
      // validating submitted data
      this.v$.range.$touch();
      // checking open and close date
      if (this.v$.range.$error) {
        this.isLoading = false;
        await this.$store.dispatch("clearToast");
        return;
      }

      // prepare data
      const payload = {
        ...this.editedScheduleRule,
        open: this.range.start,
        close: this.range.end,
      };

      const effectUser = this.editedScheduleRule.effectUser;
      // get filtered Schedule Rule by effectUser
      const filteredRule = this.scheduleRule.filter(
        (rule) => rule.effectUser === effectUser && rule.id !== payload.id
      );

      // check new end < every start && new start < every start OR
      // check new start > every end && new end > evert start
      for (const theRule of filteredRule) {
        const oldOpen = theRule.open.toDate();
        const oldClose = theRule.close.toDate();
        if (
          (payload.open < oldOpen && payload.close < oldOpen) ||
          (payload.open > oldClose && payload.close > oldClose)
        ) {
          // OK
        } else {
          this.error =
            "ไม่สามารถเพิ่มได้ เนื่องจากเวลาเปิด-ปิดทับซ้อนกับ Rule ก่อนหน้านี้";
          return;
        }
      }

      if (this.scheduleRuleMode === "edit") {
        try {
          await this.$store.dispatch("updateScheduleRule", payload);
          this.dialog = null;
          this.$store.dispatch("updateToast", {
            mode: "success",
            message: "บันทึกข้อมูลสำเร็จ",
            show: true,
          });
        } catch (e) {
          this.error = e;
        }
      } else if (this.scheduleRuleMode === "add") {
        try {
          await this.$store.dispatch("addScheduleRule", payload);
          this.dialog = null;
          this.$store.dispatch("updateToast", {
            mode: "success",
            message: "บันทึกข้อมูลสำเร็จ",
            show: true,
          });
        } catch (e) {
          this.error = e;
        }
      } else {
        this.error = `${this.scheduleRuleMode} : this scheduleRuleMode is not supported`;
      }
    },
    async saveGrant() {
      try {
        await this.$store.dispatch("updateSchedule", {
          scheduleId: this.schedule.id,
          studentLogin: this.studentLogin,
          adminLogin: this.adminLogin,
        });
        this.dialog = null;
        this.$store.dispatch("updateToast", {
          mode: "success",
          message: "บันทึกข้อมูลสำเร็จ",
          show: true,
        });
      } catch (e) {
        this.error = e;
      }
    },
    openGrantDia() {
      this.studentLogin = this.schedule.studentLogin;
      this.adminLogin = this.schedule.adminLogin;
      this.dialog = "grantLogin";
    },
  },
};
</script>

<style scoped>
.std-info-grid {
  @apply grid grid-cols-4 py-6;
}

.std-info-grid h3 {
  @apply col-span-4 md:col-span-1 pb-4;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
