<template>
  <!-- this is QuotaClass -->
  <!-- {{ quotaInfo }} -->
  <div class=" flex justify-center items-center" v-if="quotaInfo === undefined">
    <base-spinner class="my-10"></base-spinner>
  </div>
  <div class=" flex justify-center items-center" v-else-if="!quotaInfo"></div>
  <base-card class="mb-10" v-else>
    <div class="space-x-4">
      <router-link :to="getPrevRoute">
        <base-button mode="gray">
          <div class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <span>ย้อนกลับ</span>
          </div>
        </base-button>
      </router-link>
    </div>
    <div class="flex space-x-10 justify-center py-8 ">
      <div>
        <p class="text-sm leading-5 text-gray-500 pt-4 pb-2">
          ชื่อวิชา/รหัสวิชา
        </p>
        <div
          class=" text-gray-700"
          v-for="(yearItem, index) of last4Year"
          :key="index"
        >
          {{
            quotaInfo[`sub${yearItem.name}`]
              ? quotaInfo[`sub${yearItem.name}`].year +
                " - " +
                quotaInfo[`sub${yearItem.name}`].code +
                " (" +
                quotaInfo[`sub${yearItem.name}`].name +
                ")"
              : "-"
          }}
        </div>
      </div>

      <div>
        <p class="text-sm leading-5 text-gray-500 pt-4 pb-2">
          sec
        </p>
        <p class="5 text-gray-700">
          {{ quotaInfo.secId }}
        </p>
      </div>
      <div>
        <p class="text-sm leading-5 text-gray-500 pt-4 pb-2">
          group
        </p>
        <p class=" text-gray-700">
          {{ quotaInfo.group }}
        </p>
      </div>
      <div>
        <p class="text-sm leading-5 text-gray-500 pt-4 pb-2">
          campus
        </p>
        <p class=" text-gray-700">
          {{ quotaInfo.campus }}
        </p>
      </div>

      <div>
        <p class="text-sm leading-5 text-gray-500 pt-4 pb-2">
          เวลา
        </p>
        <p class=" text-gray-700">
          {{ quotaInfo.secTime }}
        </p>
      </div>
    </div>

    <div class="flex space-x-10 justify-center pb-8 ">
      <div>
        <p class="text-sm leading-5 text-gray-500 pt-4 pb-2">
          อาจารย์
        </p>
        <span class="text-gray-700" v-if="quotaInfo.instructors">
          <span
            v-for="instructor in quotaInfo.instructors"
            :key="instructor.id"
          >
            {{ instructor.name }}
            <br />
          </span>
        </span>
        <span class="text-gray-700" v-else>
          {{ quotaInfo.instName }}
        </span>
      </div>
      <div>
        <p class="text-sm leading-5 text-gray-500 pt-4 pb-2">
          จำนวนรับ/ขอ/ได้
        </p>
        <p
          class="text-gray-700"
          :class="{ 'text-red-500': quotaInfo.q1Request > quotaInfo.q1Offer }"
        >
          รอบ 1 : {{ quotaInfo.q1Offer || 0 }} /
          {{ quotaInfo.q1Request || 0 }} /
          {{ quotaInfo.q1Accept || 0 }}
        </p>
        <p
          class="text-gray-700"
          :class="{ 'text-red-500': quotaInfo.q1Request > quotaInfo.q1Offer }"
        >
          รอบ 2 : {{ quotaInfo.q2Offer || 0 }} /
          {{ quotaInfo.q2Request || 0 }} /
          {{ quotaInfo.q2Accept || 0 }}
        </p>
        <p
          class="text-gray-700"
          :class="{ 'text-red-500': quotaInfo.q1Request > quotaInfo.q1Offer }"
        >
          รอบ 3 : {{ quotaInfo.q3Offer || 0 }} /
          {{ quotaInfo.q3Request || 0 }} /
          {{ quotaInfo.q3Accept || 0 }}
        </p>
      </div>
      <div>
        <p class="text-sm leading-5 text-gray-500 pt-4 pb-2 invisible">
          จำนวนรับ/ขอ/ได้
        </p>
        <p
          class="text-gray-700"
          :class="{ 'text-red-500': quotaInfo.q1Request > quotaInfo.q1Offer }"
        >
          รอบ 4.1 : {{ quotaInfo.q4Offer || 0 }} /
          {{ quotaInfo.q4Request || 0 }} /
          {{ quotaInfo.q4Accept || 0 }}
        </p>
        <p
          class="text-gray-700"
          :class="{ 'text-red-500': quotaInfo.q1Request > quotaInfo.q1Offer }"
        >
          รอบ 4.2 : {{ quotaInfo.q5Offer || 0 }} /
          {{ quotaInfo.q5Request || 0 }} /
          {{ quotaInfo.q5Accept || 0 }}
        </p>
      </div>
    </div>
  </base-card>

  <div class=" flex justify-center items-center" v-if="isLoading">
    <base-spinner class="my-10"></base-spinner>
  </div>
  <div class=" flex justify-center items-center my-20" v-else-if="!quotaInfo">
    <h3>คุณไม่มีสิทธิ์เข้าถึงโควตานี้ หรือ ข้อมูลโควตานี้ไม่พบในระบบ</h3>
  </div>
  <div v-else>
    <div class="py-4 flex justify-between">
      <div>
        <h3>รายชื่อนักศึกษาที่แจ้งความจำนง</h3>
      </div>
    </div>

    <div class="flex justify-end items-center space-x-12 pb-4">
      <div class="">
        <h4 class="invisible">-</h4>
        <base-button class="flex space-x-2 " mode="gray" @click="openSecSum"
          ><svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            ></path>
          </svg>
          <span>สรุปจำนวน</span>
        </base-button>
      </div>
      <div class="">
        <h4 class="invisible">-</h4>
        <base-button
          class="flex space-x-2 "
          mode="gray"
          @click="downloadSection()"
          ><svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            ></path>
          </svg>
          <span>ดาวน์โหลดรายชื่อ</span>
        </base-button>
      </div>

      <div>
        <h4>รอบที่</h4>
        <div class="space-x-4">
          <base-button
            :class="{ 'bg-gray-300': isRound(selectedRound, 1) }"
            mode="gray"
            @click="selectedRound = 1"
            >1</base-button
          >
          <base-button
            :class="{ 'bg-gray-300': isRound(selectedRound, 2) }"
            mode="gray"
            @click="selectedRound = 2"
            >2</base-button
          >
          <base-button
            :class="{ 'bg-gray-300': isRound(selectedRound, 3) }"
            mode="gray"
            @click="selectedRound = 3"
            >3</base-button
          >
          <base-button
            :class="{ 'bg-gray-300': isRound(selectedRound, 4) }"
            mode="gray"
            @click="selectedRound = 4"
            >4.1</base-button
          >
          <base-button
            :class="{ 'bg-gray-300': isRound(selectedRound, 5) }"
            mode="gray"
            @click="selectedRound = 5"
            >4.2</base-button
          >
        </div>
      </div>
    </div>

    <div class="std-info-data space-b-2 col-span-4">
      <div
        class="flex justify-end bg-gray-100 rounded-t-lg py-2 "
        v-if="menuVisible"
      >
        <base-button
          mode="flat"
          @click="addReqVisible = true"
          v-if="
            (isRound(selectedRound, presentRound) && userInfo.role === 'A') ||
              (isRound(selectedRound, 1) &&
                isRound(selectedRound, presentRound)) ||
              (isRound(selectedRound, 2) &&
                isRound(selectedRound, presentRound)) ||
              (isRound(selectedRound, 3) &&
                isRound(selectedRound, presentRound)) ||
              (isRound(selectedRound, 4) &&
                isRound(selectedRound, presentRound)) ||
              (isRound(selectedRound, 5) &&
                isRound(selectedRound, presentRound))
          "
          >Add Student</base-button
        >
        <base-button mode="flat" @click="submitResult('accept')"
          >Accept</base-button
        >
        <base-button
          mode="flat"
          v-if="userInfo.role === 'A'"
          @click="submitResult('reject')"
          >Reject</base-button
        >
        <!-- <base-button mode="flat">Move</base-button> -->
        <!-- <base-button mode="flat">Cancel</base-button> -->
        <base-button
          mode="flat"
          v-if="userInfo.role === 'A'"
          @click="submitResult('delete')"
          >Delete</base-button
        >
      </div>

      <div class="grid-table-main">
        <div
          class="
              grid grid-cols-11
              grid-table-header
            "
        >
          <div class="w-full h-auto">ลำดับ</div>
          <div class="w-full h-auto">เลือก</div>
          <div class="w-full h-auto col-span-2">เลขทะเบียน</div>
          <div class="w-full h-auto col-span-2">ชื่อ-นามสกุล</div>
          <div class="w-full h-auto col-span-2">สาขาวิชาเอก</div>
          <div class="w-full h-auto">รอบที่</div>
          <div class="w-full h-auto">ผลลัพธ์</div>
        </div>

        <div class="grid-table-body">
          <div
            class="grid grid-cols-11 grid-table-row"
            v-for="(theQ, index) in reqQuota"
            :key="index"
          >
            <div class="text-center my-auto ">
              {{ index + 1 }}
            </div>
            <div class="text-center my-auto">
              <input
                type="checkbox"
                class="form-checkbox"
                v-if="
                  isRound(selectedRound, presentRound) || userInfo.role === 'A'
                "
                :id="theQ.id"
                :value="theQ.id"
                v-model="checkedReq"
              />
              <span v-else>-</span>
            </div>
            <div class="col-span-2 text-center my-auto ">
              <router-link
                class="text-blue-550 hover:text-blue-600 hover:font-medium cursor-pointer"
                :to="{
                  name: 'admin-std-quota',
                  params: { stdId: theQ.userId },
                }"
              >
                {{ theQ.stdCode }}
              </router-link>
            </div>
            <div class="col-span-2 text-center my-auto">
              {{ theQ.stdTName + " " + theQ.stdFName + " " + theQ.stdLName }}
            </div>
            <div class="col-span-2 text-center my-auto">
              {{ theQ.stdMajor ? getMajor(theQ.stdMajor).name : "-" }}
            </div>
            <div class=" text-center my-auto">
              {{ qRoundToText(theQ.reqQutTime) }}
            </div>
            <div class="col-span-2 text-center my-auto">
              <result-icon :reqQuota="theQ" mode="admin"></result-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
  <div v-if="logVisible">
    {{ checkedReq }}
    <hr />
    {{ id }}
    <hr />
    {{ reqQuota }}
    <hr />
    {{ students }}

    <hr />
    {{ quotaInfo }}
  </div>
  <spinner-dialog :show="dialogLoading"></spinner-dialog>

  <!-- add student to section modal -->
  <data-modal
    :cancelBtn="false"
    :show="addReqVisible"
    :submitBtn="formValid"
    title="เพิ่มนักศึกษาในโควตา"
    @close="closeAddReqDia"
    @submit="addStd()"
    submitText="เพิ่ม"
  >
    <div class="flex mt-4">
      <div class=" flex space-x-6 pr-2 w-full">
        <div class=" flex-grow">
          <label class="form-control">รหัสนักศึกษา</label>
          <input
            class=" form-control"
            :class="{
              errors: !stdCode.isValid,
            }"
            type="text"
            v-model="stdCode.val"
          />
        </div>
        <div class="flex items-end justify-end pb-2">
          <button
            class="w-14 h-10 text-gray-500 hover:text-gray-700 "
            @click="findStd()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8 mx-auto"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div>
      <div class="flex justify-center py-6" v-if="addStdLoading">
        <base-spinner></base-spinner>
      </div>
      <div v-else-if="!stdInfo"></div>
      <div v-else>
        <h3 class="py-4">ข้อมูลนักศึกษา</h3>
        <label class="form-control">ชื่อ</label>
        <div>
          {{ stdInfo.nameTitle }} {{ stdInfo.firstName }} {{ stdInfo.lastName }}
        </div>

        <!-- {{ stdInfo }} -->
      </div>
    </div>
  </data-modal>

  <!-- section summary modal -->
  <data-modal
    :cancelBtn="false"
    :show="secSumVisible"
    :submitBtn="false"
    title="สรุปจำนวนนักศึกษา"
    @close="closeSecSum"
  >
    <!-- {{ secSumData }} -->
    <div class="flex justify-end">
      <h4 class="invisible">-</h4>
      <base-button class="flex space-x-2 " mode="gray" @click="downloadSecSum()"
        ><svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          ></path>
        </svg>
        <span>Excel</span>
      </base-button>
    </div>

    <table class="w-full text-center">
      <thead class="text-white">
        <tr>
          <th class="px-4">สาขา\รหัส</th>
          <th
            class="px-4"
            v-for="(year, index) in secSumData['yearList']"
            :key="index"
          >
            {{ year }}
          </th>
          <th class="px-4">รวม</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(secData, index) in secSumData['inSchool']" :key="index">
          <td>{{ getMajor(index).code }}</td>
          <td v-for="(year, index) in secSumData['yearList']" :key="index">
            {{ secData[year] ? secData[year] : 0 }}
          </td>
          <td>{{ secSumData["sumMajor"][index] }}</td>
        </tr>
        <td>นอกคณะ</td>
        <td v-for="(year, yearIdx) in secSumData['yearList']" :key="yearIdx">
          {{
            secSumData["outSchool"][year] ? secSumData["outSchool"][year] : 0
          }}
        </td>
        <td>{{ secSumData["sumOut"] }}</td>
        <tr>
          <td>รวม</td>
          <td v-for="aYear in secSumData['sumYear']" :key="aYear">
            {{ aYear }}
          </td>
          <td>{{ secSumData["sumAll"] }}</td>
        </tr>
      </tbody>
    </table>

    <div>
      <div class="flex justify-center py-6" v-if="addStdLoading">
        <base-spinner></base-spinner>
      </div>
      <div v-else-if="!stdInfo"></div>
      <div v-else>
        <h3 class="py-4">ข้อมูลนักศึกษา</h3>
        <label class="form-control">ชื่อ</label>
        <div>
          {{ stdInfo.nameTitle }} {{ stdInfo.firstName }} {{ stdInfo.lastName }}
        </div>

        <!-- {{ stdInfo }} -->
      </div>
    </div>
  </data-modal>
</template>

<script>
// import AlertCard from "../../components/UI/AlertCard.vue";
import { db } from "../../js/firebaseDb.js";
import DataModal from "../../components/UI/DataModal.vue";
import SpinnerDialog from "../../components/UI/SpinnerDialog.vue";
import ResultIcon from "../../components/UI/ResultIcon.vue";
import { isLogVisible } from "../../js/debug.js";
import { sortNumber } from "../../js/utility.js";
import StaffRuleMixin from "../../mixins/staffRule.js";
import XLSX from "xlsx";
import TextConverterMixin from "../../mixins/textConverter.js";

export default {
  mixins: [StaffRuleMixin, TextConverterMixin], //staffCanEdit,qRoundToText()
  components: { SpinnerDialog, DataModal, ResultIcon },
  props: ["id"],
  data() {
    return {
      checkedReq: [],
      error: null,
      isLoading: false,
      dialogLoading: false,

      // yearNo: 4,
      stdInfo: null,
      selectedRound: 1,

      stdCode: {
        val: null,
        isValid: true,
      },
      formValid: false,

      addReqVisible: false,
      addStdLoading: false,
      prevRoute: null,
      // quotaId: "nwzca3jxEbstJbFBZ1KM",

      secSumVisible: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((ev) => {
      ev.prevRoute = from;
    });
  },
  created() {
    this.loadData();
    this.setRound();
  },
  computed: {
    getPrevRoute() {
      if (this.prevRoute && this.prevRoute.name !== undefined) {
        return {
          name: this.prevRoute.name,
          params: this.prevRoute.params,
        };
      } else {
        return { name: "quota-process" };
      }
    },
    matchMajor() {
      if (this.quotaInfo && this.userInfo) {
        return this.userInfo.majors.some((m) => m === this.quotaInfo.majorId);
      } else {
        return false;
      }
    },
    menuVisible() {
      return (
        this.userInfo.role === "A" ||
        (this.isRound(this.selectedRound, this.presentRound) &&
          this.staffCanEdit)
      );
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    logVisible() {
      return isLogVisible();
    },
    schedule() {
      return this.$store.getters["schedule"];
    },
    presentRound() {
      return this.schedule.qRound;
    },
    quotaInfo() {
      return this.$store.getters["quota/getQuotaById"](this.id);
    },
    reqQuota() {
      const allReqQuota = this.$store.getters["quota/reqQuota"];
      const filteredReq = allReqQuota.filter(
        (q) => q.reqQutTime === this.selectedRound
      );
      return sortNumber(filteredReq, "stdCode");
    },
    students() {
      return this.$store.getters["students/students"];
    },
    last4Year() {
      return this.$store.getters["years/getLast4Years"];
    },
    headerData() {
      const theInst = [];
      if (this.quotaInfo.instructors) {
        this.quotaInfo.instructors.forEach((instructor, i) => {
          if (i == 0) {
            theInst.push({ A: "อาจารย์", B: instructor.name });
          } else {
            theInst.push({ A: "", B: instructor.name });
          }
        });
      } else {
        theInst.push({ A: "อาจารย์", B: this.quotaInfo.instName });
      }

      return {
        left: [
          {
            A: "ชื่อวิชา",
            B: `${this.quotaInfo.subCode} (${this.quotaInfo.subName})`,
          },
          {
            A: "sec/group",
            B: `${this.quotaInfo.secId}${this.quotaInfo.group}(${this.quotaInfo.secTime})`,
          },
          {
            A: "campus",
            B: this.quotaInfo.campus,
          },
          {
            A: "จำนวนรับ/ขอ/ได้ รอบ 1",
            B:
              (this.quotaInfo.q1Offer || 0) +
              "/" +
              (this.quotaInfo.q1Request || 0) +
              "/" +
              (this.quotaInfo.q1Accept || 0),
          },
          {
            A: "จำนวนรับ/ขอ/ได้ รอบ 2",
            B:
              (this.quotaInfo.q2Offer || 0) +
              "/" +
              (this.quotaInfo.q2Request || 0) +
              "/" +
              (this.quotaInfo.q2Accept || 0),
          },
          {
            A: "จำนวนรับ/ขอ/ได้ รอบ 3",
            B:
              (this.quotaInfo.q3Offer || 0) +
              "/" +
              (this.quotaInfo.q3Request || 0) +
              "/" +
              (this.quotaInfo.q3Accept || 0),
          },
          {
            A: "จำนวนรับ/ขอ/ได้ รอบ 4.1",
            B:
              (this.quotaInfo.q4Offer || 0) +
              "/" +
              (this.quotaInfo.q4Request || 0) +
              "/" +
              (this.quotaInfo.q4Accept || 0),
          },
          {
            A: "จำนวนรับ/ขอ/ได้ รอบ 4.2",
            B:
              (this.quotaInfo.q5Offer || 0) +
              "/" +
              (this.quotaInfo.q5Request || 0) +
              "/" +
              (this.quotaInfo.q5Accept || 0),
          },
        ],
        right: theInst,
        head: [{ A: "รายชื่อนักศึกษาที่แจ้งความจำนง" }],
      };
    },
    secSumData() {
      var groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      const yearListIn = new Set();
      const yearListOut = new Set();
      const reqQuotaInMajor = groupBy(this.reqQuota, "stdMajor");
      const reqQuotaSum = { inSchool: {}, outSchool: {} };
      for (const major in reqQuotaInMajor) {
        reqQuotaSum["inSchool"][major] = {};
        for (const std of reqQuotaInMajor[major]) {
          const stdYear = std.stdCode.substring(0, 2);
          const stdSchool = std.stdCode.substring(2, 4);
          if (stdSchool == "02") {
            yearListIn.add(stdYear);
            reqQuotaSum["inSchool"][major][stdYear] = reqQuotaSum["inSchool"][
              major
            ][stdYear]
              ? reqQuotaSum["inSchool"][major][stdYear] + 1
              : 1;
          } else {
            yearListOut.add(stdYear);
            reqQuotaSum["outSchool"][stdYear] = reqQuotaSum["outSchool"][
              stdYear
            ]
              ? reqQuotaSum["outSchool"][stdYear] + 1
              : 1;
          }
        }

        if (Object.keys(reqQuotaSum["inSchool"][major]).length === 0) {
          delete reqQuotaSum["inSchool"][major];
        }
      }
      reqQuotaSum["yearListIn"] = Array.from(yearListIn).sort();
      reqQuotaSum["yearListOut"] = Array.from(yearListOut).sort();
      const allList = new Set([...yearListIn, ...yearListOut]);
      reqQuotaSum["yearList"] = Array.from(allList).sort();

      const sumMajor = {};
      const sumYear = {};
      for (const aYear of reqQuotaSum["yearList"]) {
        sumYear[aYear] = 0;
      }

      for (const mIdx in reqQuotaSum["inSchool"]) {
        sumMajor[mIdx] = 0;
        for (const yIdx in reqQuotaSum["inSchool"][mIdx]) {
          sumMajor[mIdx] += reqQuotaSum["inSchool"][mIdx][yIdx];
          sumYear[yIdx] += reqQuotaSum["inSchool"][mIdx][yIdx];
        }
      }

      let sumOut = 0;
      for (const yIdx in reqQuotaSum["outSchool"]) {
        sumYear[yIdx] += reqQuotaSum["outSchool"][yIdx];
        sumOut = sumOut + reqQuotaSum["outSchool"][yIdx];
      }

      let sumAll = sumOut;
      for (const mIdx in sumMajor) {
        sumAll += sumMajor[mIdx];
      }

      reqQuotaSum["sumYear"] = sumYear;
      reqQuotaSum["sumMajor"] = sumMajor;
      reqQuotaSum["sumOut"] = sumOut;
      reqQuotaSum["sumAll"] = sumAll;
      return reqQuotaSum;
    },
  },
  methods: {
    async downloadSection() {
      this.isLoading = true;
      const exportJson = await this.getQuotaSecInfo();
      if (this.logVisible) {
        console.log(exportJson);
      }

      const dataWS = XLSX.utils.json_to_sheet(exportJson || [{}], {
        skipHeader: false,
        origin: "A12",
      });
      XLSX.utils.sheet_add_json(dataWS, this.headerData.left || [{}], {
        skipHeader: true,
        origin: "A2",
      });
      XLSX.utils.sheet_add_json(dataWS, this.headerData.right || [{}], {
        skipHeader: true,
        origin: "E2",
      });
      XLSX.utils.sheet_add_json(dataWS, this.headerData.head || [{}], {
        skipHeader: true,
        origin: "C1",
      });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(
        wb,
        `${this.quotaInfo.subCode}-${this.quotaInfo.secId}${this.quotaInfo.group}.xlsx`
      );
      this.isLoading = false;
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "ดาวน์โหลดไฟล์สำเร็จ",
      });
    },

    downloadSecSum() {
      const inSchoolPayload = [];
      const majorList = [];
      for (const property in this.secSumData["inSchool"]) {
        const payload = {};
        for (const aYear of this.secSumData["yearList"]) {
          payload[aYear] = this.secSumData["inSchool"][property][aYear] || 0;
        }
        inSchoolPayload.push(payload);
        majorList.push({ สาขา: property ? this.getMajor(property).code : "-" });
      }

      const outPayload = {};
      for (const aYear of this.secSumData["yearList"]) {
        outPayload[aYear] = this.secSumData["outSchool"][aYear] || 0;
      }
      const outSchoolPayload = [outPayload];
      const majorLen = Object.keys(this.secSumData["inSchool"]).length;
      const yearLen = Object.keys(this.secSumData["yearList"]).length;

      // init worksheet
      const dataWS = XLSX.utils.json_to_sheet([{}]);

      // adddata to worksheet (Header)
      const headCol = String.fromCharCode(yearLen / 2 + 1 + 64);
      XLSX.utils.sheet_add_json(dataWS, [{ A: "สรุปจำนวนนักศึกษา" }], {
        skipHeader: true,
        origin: `${headCol}1`,
      });

      // adddata to worksheet (inSchool)
      XLSX.utils.sheet_add_json(dataWS, majorList || [{}], {
        skipHeader: false,
        origin: "A3",
      });
      XLSX.utils.sheet_add_json(dataWS, inSchoolPayload || [{}], {
        skipHeader: false,
        origin: "B3",
      });

      // adddata to worksheet (outSchool)
      XLSX.utils.sheet_add_json(dataWS, [{ A: "นอกคณะ" }], {
        skipHeader: true,
        origin: `A${majorLen + 4}`,
      });
      XLSX.utils.sheet_add_json(dataWS, outSchoolPayload || [{}], {
        skipHeader: true,
        origin: `B${majorLen + 4}`,
      });

      // Total
      XLSX.utils.sheet_add_json(dataWS, [{ A: "รวมรหัส" }], {
        skipHeader: true,
        origin: `A${majorLen + 5}`,
      });
      // row
      XLSX.utils.sheet_add_json(dataWS, [this.secSumData["sumYear"]] || [{}], {
        skipHeader: true,
        origin: `B${majorLen + 5}`,
      });
      // column
      const totalCol = String.fromCharCode(yearLen + 2 + 64);
      const sumRowPayload = [];
      for (const aMajor in this.secSumData["sumMajor"]) {
        sumRowPayload.push({
          aMajor: this.secSumData["sumMajor"][aMajor] || 0,
        });
      }
      XLSX.utils.sheet_add_json(dataWS, [{ total: "รวมสาขา" }], {
        skipHeader: true,
        origin: `${totalCol}3`,
      });
      XLSX.utils.sheet_add_json(dataWS, sumRowPayload || [{}], {
        skipHeader: true,
        origin: `${totalCol}4`,
      });
      XLSX.utils.sheet_add_json(
        dataWS,
        [{ sumOut: this.secSumData["sumOut"] }] || [{}],
        {
          skipHeader: true,
          origin: `${totalCol}${majorLen + 4}`,
        }
      );
      XLSX.utils.sheet_add_json(
        dataWS,
        [{ sumAll: this.secSumData["sumAll"] }] || [{}],
        {
          skipHeader: true,
          origin: `${totalCol}${majorLen + 5}`,
        }
      );

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(
        wb,
        `sum-${this.quotaInfo.subCode}-${this.quotaInfo.secId}${this.quotaInfo.group}.xlsx`
      );
      this.isLoading = false;
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "ดาวน์โหลดไฟล์สำเร็จ",
      });
    },
    async getQuotaSecInfo() {
      try {
        let resAllStudentInfo = [];
        for (const [index, doc] of this.reqQuota.entries()) {
          const stdInfo = await this.$store.dispatch(
            "adminStudents/getStudentInfoById",
            {
              id: doc.userId,
            }
          );
          const payload = {
            ลำดับ: index + 1,
            เลขทะเบียน: doc.stdCode,
            "ชื่อ-นามสกุล":
              doc.stdTName + " " + doc.stdFName + " " + doc.stdLName,
            สาขาวิชาเอก: doc.stdMajor ? this.getMajor(doc.stdMajor).name : "-",
            สาขาวิชาเอก2: "",
            เบอร์โทร: stdInfo.tel,
            Email: stdInfo.email,
            รอบที่: this.qRoundToText(doc.reqQutTime),
            ผลลัพธ์: doc.reqResult,
          };
          if (doc.stdMajor2) {
            payload["สาขาวิชาเอก2"] = this.getMajor(doc.stdMajor2).name;
          }
          resAllStudentInfo.push(payload);
        }

        return resAllStudentInfo;
      } catch (e) {
        this.error = e;
      }
    },
    isRound(no, targetNo) {
      return targetNo === no;
    },
    setRound() {
      this.selectedRound = this.schedule.qRound;
    },
    clearStdModal() {
      this.stdInfo = null;
      this.stdCode.val = null;
    },
    async addStd() {
      this.formValid = true;
      this.stdCode.isValid = true;

      await this.findStd();
      if (!this.formValid || !this.stdCode.isValid) {
        this.error = "student id not found";
        return;
      }
      this.addStdLoading = true;
      try {
        await this.$store.dispatch("quota/addReqQuotaByAdmin", {
          stdInfo: this.stdInfo,
          quotaId: this.id,
          result: "accept",
        });
      } catch (e) {
        this.error = e.message;
        return;
      }
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "เพิ่มนักศึกษาสำเร็จ",
      });
      this.addReqVisible = false;
      this.addStdLoading = false;
      this.clearStdModal();
    },

    async findStd() {
      this.addStdLoading = true;
      const stdRef = db.collection("students");
      const stdDocs = await stdRef
        .where("stdCode", "==", this.stdCode.val)
        .get()
        .catch((error) => {
          this.addStdLoading = false;
          this.stdCode.isValid = false;
          this.formValid = false;
          this.error = error;
        });

      if (stdDocs.empty) {
        this.addStdLoading = false;
        this.stdCode.isValid = false;
        this.formValid = false;
        this.error = "ไม่พบรหัสนักศึกษาในฐานข้อมูล";
        return;
      }

      const stdArr = stdDocs.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });

      this.stdInfo = stdArr[0];

      this.addStdLoading = false;
      this.stdCode.isValid = true;
      this.formValid = true;
    },
    closeAddReqDia() {
      this.addReqVisible = false;
      this.clearStdModal();
    },
    closeError() {
      this.error = null;
    },
    getMajor(majorId) {
      return this.$store.getters["majors/getMajorById"](majorId);
    },
    async loadData() {
      this.isLoading = true;
      await this.loadReqQuota();
      this.isLoading = false;
    },

    async loadReqQuota() {
      try {
        await this.$store.dispatch("quota/setReqQuotaByQuota", {
          quotaId: this.id,
        });
      } catch (e) {
        this.error = e.message;
      }
    },
    async submitResult(type) {
      this.dialogLoading = true;
      if (type !== "accept" && type !== "reject" && type !== "delete") {
        this.error = "your selected result doesn't support";
        this.dialogLoading = false;
        return;
      }

      try {
        await this.$store.dispatch("quota/updateReqQuotaBatch", {
          quotaId: this.id,
          field: "result",
          value: type,
          reqArr: this.checkedReq,
        });
      } catch (e) {
        this.error = e;
        this.dialogLoading = false;
        return;
      }

      this.$store.dispatch("updateToast", {
        mode: "success",
        message: "เปลี่ยนแปลงข้อมูลเสร็จสิ้น",
        show: true,
      });
      this.checkedReq = [];
      this.dialogLoading = false;
    },
    openSecSum() {
      this.secSumVisible = true;
    },
    closeSecSum() {
      this.secSumVisible = false;
    },
  },
};
</script>

<style scoped>
.form-row {
  @apply pb-3;
}
.std-info-grid {
  @apply grid grid-cols-4 py-6;
}

.std-info-grid h3 {
  @apply col-span-4 md:col-span-1 pb-4;
}

.std-info-data {
  @apply col-span-4 md:col-span-3;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
