<template>
  <div class="flex flex-col w-full">
    <div
      class="text-white bg-primary-200 flex flex-shrink-0 flex-col pl-4 py-4"
    >
      <div class="flex relative  items-center p-4 h-12">
        <span class="text-2xl tracking-wide font-semibolds">รายวิชา</span>
      </div>
    </div>
  </div>
  <div class="px-4">
    <div class=" flex justify-center items-center" v-if="loadingData">
      <base-spinner class="my-10"></base-spinner>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import LoadDataMixin from "../../mixins/loadData.js";
export default {
  mixins: [LoadDataMixin], // loadYear, loadSubject, await this.loadConditions();
  data() {
    return {
      loadingData: false,
    };
  },
  created() {
    this.loadData();
  },

  methods: {
    async loadData() {
      this.loadingData = true;
      await this.loadYear();
      await this.loadSubject();
      await this.loadConditions();
      this.loadingData = false;
    },
  },
};
</script>
