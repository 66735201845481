export default {
    programs(state) {
        return state.programs;
    },

    getUserProgram(state, getters, rootState, rootGetters) {
        const programs = getters.programs;
        const userProgram = rootGetters.userProgram;
        return programs.find((program) => program.code === userProgram);
    },

    getProgramById: (state, getters) => (id) => {
        const programs = getters.programs;
        return programs.find((program) => program.id === id);
    },
    getProgramByCamCode: (state, getters) => (camCode) => {
        const programs = getters.programs;
        return programs.find((program) => program.camCode === camCode);
    },
    getProgramByCode: (state, getters) => (code) => {
        const programs = getters.programs;
        return programs.find((program) => program.code === code);
    },
};