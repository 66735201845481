<template>
  <div class="flex flex-col w-full">
    <div
      class="text-white bg-primary-200 flex flex-shrink-0 flex-col pl-4 py-4"
    >
      <div class="flex relative  items-center p-4 h-12">
        <span class="text-2xl tracking-wide font-semibolds">เจ้าหน้าที่</span>
      </div>
    </div>
  </div>
  <div class="px-4">
    <div class=" flex justify-center items-center" v-if="loadingData">
      <base-spinner class="my-10"></base-spinner>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
  <toast
    :show="!!error"
    mode="error"
    @close="error = null"
    fixed
    :message="error"
  >
  </toast>
</template>

<script>
import LoadDataMixin from "../../mixins/loadData.js";
export default {
  mixins: [LoadDataMixin], // loadStaff
  data() {
    return {
      loadingData: false,
      error: null,
    };
  },
  created() {
    this.loadData();
  },

  methods: {
    async loadData() {
      this.loadingData = true;
      // load data here
      await this.loadStaff();

      this.loadingData = false;
    },
  },
};
</script>
