<template>
  <transition name="toast">
    <div
      class="flex fixed top-20 right-10 max-w-xl font-kanit z-50"
      v-if="show"
    >
      <div class="m-auto">
        <div
          class="bg-white rounded-lg border-gray-300 border p-3 shadow-lg min-w-100 "
          :class="{
            'bg-green-50': isSuccess,
            'border-green-300': isSuccess,
            'bg-red-50': isError,
            'border-red-300': isError,
            'bg-yellow-50': isWarning,
            'border-yellow-300': isWarning,
            'bg-blue-50': isInfo,
            'border-blue-300': isInfo,
            'bg-gray-50': isLoading,
            'border-gray-300': isLoading,
          }"
        >
          <div class="flex flex-row">
            <div class="px-2 flex items-center">
              <svg
                v-if="isSuccess"
                width="24"
                height="24"
                viewBox="0 0 1792 1792"
                fill="#44C997"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1299 813l-422 422q-19 19-45 19t-45-19l-294-294q-19-19-19-45t19-45l102-102q19-19 45-19t45 19l147 147 275-275q19-19 45-19t45 19l102 102q19 19 19 45t-19 45zm141 83q0-148-73-273t-198-198-273-73-273 73-198 198-73 273 73 273 198 198 273 73 273-73 198-198 73-273zm224 0q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
                />
              </svg>
              <svg
                v-if="isWarning"
                class="w-6 h-6 text-yellow-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>

              <svg
                v-if="isError"
                class="w-6 h-6 text-red-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <svg
                v-if="isInfo"
                class="w-6 h-6 text-blue-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>

              <div class="lds-ellipsis" v-if="isLoading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div class="ml-2 mr-6">
              <span class="font-semibold">{{ title }}</span>
              <span class="block text-gray-500">{{ message }}</span>
            </div>
            <button
              @click="tryClose(true)"
              class="flex items-start"
              v-if="fixed"
            >
              <div>
                <svg
                  class="w-4 h-4 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  components: {},
  emits: ["close"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    duration: {
      type: Number,
      required: false,
      default: 3000,
    },
    mode: {
      type: String,
      required: false,
      default: "info",
    },
    message: {
      required: true,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    title() {
      if (this.mode === "success") {
        return "Success";
      } else if (this.mode === "error") {
        return "Error";
      } else if (this.mode === "warning") {
        return "Warning";
      } else if (this.mode === "loading") {
        return "Loading...";
      } else {
        return "Notification";
      }
    },
    isSuccess() {
      return this.mode === "success";
    },
    isError() {
      return this.mode === "error";
    },
    isWarning() {
      return this.mode === "warning";
    },
    isInfo() {
      return this.mode === "info" || !this.mode;
    },
    isLoading() {
      return this.mode === "loading";
    },
  },
  watch: {
    show(newVal) {
      if (newVal === true && !this.fixed) {
        this.tryClose();
      }
    },
  },
  methods: {
    async tryClose(force = false) {
      if (!force) {
        await sleep(this.duration);
      }
      this.$emit("close");
    },
    // async hideToast() {
    //   await sleep(this.duration);
    //   this.isShow = false;
    // },
  },
};
</script>

<style>
/* enter transitions */
.toast-enter-from {
  opacity: 0;
  transform: translateY(-60px);
}
/* .toast-enter-to {
    opacity: 1;
    transform: translateY(0);
  } */
.toast-enter-active {
  transition: all 0.3s ease;
}
/* leave transitions */
/* .toast-leave-from {
    opacity: 1;
    transform: translateY(0);
  } */
.toast-leave-to {
  opacity: 0;
  transform: translateY(-60px);
}
.toast-leave-active {
  transition: all 0.3s ease;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 40px;
}
.lds-ellipsis div {
  position: absolute;
  top: 40%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(138, 138, 138);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
