<template>
  <div
    class="bg-white rounded-md border-2 "
    :class="{ 'border-red-400': error, 'bg-red-50': error }"
  >
    <!-- {{ value }} -->
    <multiselect
      v-model="value"
      :placeholder="placeHolder"
      label="text"
      trackBy="text"
      :searchable="true"
      :options="dataList"
      :disabled="disabled"
    >
      <!-- Selected -->
      <template v-slot:singlelabel="{ value }">
        <div class="multiselect-single-label">
          {{ value.text }}
        </div>
      </template>
      <!-- Dropdown -->
      <template v-slot:option="{ option }">
        {{ option.text }}
      </template>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
export default {
  components: { multiselect: Multiselect },
  props: {
    placeHolder: {
      type: String,
      required: false,
      default: null,
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    setValue: { required: false },
    textField: { type: String },
    valueField: { type: String },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  created() {
    this.value = this.setValue;
  },
  computed: {
    dataList() {
      let newArr = [];
      for (const option of this.options) {
        // skip duplicate text
        const found = newArr.some((e) => e.text === option[this.textField]);
        if (!found) {
          newArr.push({
            text: option[this.textField],
            value: option[this.valueField],
          });
        }
      }
      if (this.reverse) {
        return newArr.reverse();
      } else {
        return newArr;
      }
    },
  },
  methods: {},
  watch: {
    value(newValue) {
      // this.$emit("update", newValue);
      this.$emit("selectedValue", newValue);
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped></style>
