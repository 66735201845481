<template>
  <div class="text-center py-20" v-if="false">
    <h3>Coming Soon</h3>
  </div>
  <base-card class="mt-12">
    <div class="flex space-x-2">
      <div class=" w-full">
        <label class="block  text-sm text-gray-500 "
          >เลขทะเบียน (ใส่ได้มากกว่า 1 ค่า)</label
        >
        <Multiselect
          v-model="stdCodeArray"
          mode="tags"
          :closeOnSelect="false"
          :searchable="true"
          :createTag="true"
        />
      </div>
    </div>

    <div class="w-full flex justify-end pt-12">
      <base-button class="px-10" @click="downloadExcel()">ตกลง</base-button>
    </div>
    <!-- <base-button @click="test()">Test</base-button> -->
  </base-card>
  <toast
    :show="!!error"
    mode="error"
    @close="error = null"
    fixed
    :message="error"
  >
  </toast>
  <div class="flex justify-center py-20" v-if="isLoading">
    <base-spinner></base-spinner>
  </div>
  <div v-else-if="!isLoading && tableData">
    <!-- {{ tableData }} -->
    <h4>นักศึกษาในคณะ</h4>
    <table
      v-for="(theMajor, majorId) in tableData['tbsStudent']"
      :key="majorId"
      class="table-fixed mb-10"
    >
      <tr>
        <th colspan="4">{{ getMajorById(majorId).name }}</th>
      </tr>
      <tr>
        <th>ปี</th>
        <th v-for="status in statusList" :key="status.id">{{ status.name }}</th>
        <th>รับเข้า</th>
        <th>ย้ายเข้า</th>
        <th>ย้ายออก</th>
      </tr>
      <tr v-for="(yearData, yearName) in theMajor" :key="yearName">
        <td>{{ yearName }}</td>
        <td v-for="status in statusList" :key="status.id">
          {{ yearData[status.id] }}
        </td>
        <td>{{ yearData["admitCount"] }}</td>
        <td>{{ yearData["toCount"] }}</td>
        <td>{{ yearData["fromCount"] }}</td>
      </tr>
    </table>

    <h4>นักศึกษานอกคณะ</h4>
    <table class="table-fixed mb-10">
      <tr>
        <th>ปี</th>
        <th>จำนวน</th>
      </tr>
      <tr v-for="(yearCount, year) in tableData['notTbsStudent']" :key="year">
        <td>{{ year }}</td>
        <td>{{ yearCount }}</td>
      </tr>
    </table>
  </div>
  <h5 class="text-center pt-6" v-else>ไม่มีข้อมูลที่ต้องแสดง</h5>
</template>

<script>
// import XLSX from "xlsx";
import Multiselect from "@vueform/multiselect";
export default {
  components: { Multiselect },
  data() {
    return {
      stdCodeArray: [],
      stdSchool: null,
      stdStatus: null,
      stdProgram: null,
      stdMajor: null,
      error: null,

      // data
      tableData: null,
      isLoading: false,
    };
  },
  computed: {
    statusList() {
      return this.$store.getters["students/status"];
    },
  },
  methods: {
    calculateSum() {
      for (const majorIndex in this.tableData["tbsStudent"]) {
        this.tableData["tbsStudent"][majorIndex]["sum"] = this.getEmptySum();
        for (const yearIndex in this.tableData["tbsStudent"][majorIndex]) {
          for (const statusIndex in this.tableData["tbsStudent"][majorIndex][
            yearIndex
          ]) {
            if (yearIndex !== "sum") {
              this.tableData["tbsStudent"][majorIndex]["sum"][
                statusIndex
              ] += this.tableData["tbsStudent"][majorIndex][yearIndex][
                statusIndex
              ];
            }
          }
        }
      }
      this.tableData["notTbsStudent"]["sum"] = 0;
      for (const yearIndex in this.tableData["notTbsStudent"]) {
        if (yearIndex !== "sum") {
          this.tableData["notTbsStudent"]["sum"] += this.tableData[
            "notTbsStudent"
          ][yearIndex];
        }
      }
    },

    getEmptySum() {
      const resObj = {};
      for (const major of this.statusList) {
        resObj[major.id] = 0;
      }
      resObj["admitCount"] = 0;
      resObj["toCount"] = 0;
      resObj["fromCount"] = 0;
      return resObj;
    },

    getMajorById(id) {
      return this.$store.getters["majors/getMajorById"](id);
    },
    getStatusById(id) {
      return this.$store.getters["students/getStatusById"](id);
    },
    async downloadExcel() {
      this.isLoading = true;
      await this.getStudentInfoJson();
      this.calculateSum();
      this.isLoading = false;
    },
    async getStudentInfoJson() {
      try {
        let allStudentCount = await this.$store.dispatch(
          "adminStudents/getStudentCount",
          {
            stdCodeArray: this.stdCodeArray,
          }
        );
        this.tableData = allStudentCount;
      } catch (e) {
        this.error = e;
      }
    },
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  @apply border-gray-100;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
