// import { isLogVisible } from "../../../js/debug.js";
import { db } from "../../../js/firebaseDb";
import { sliceIntoChunks } from "../../../js/utility.js";
// import firebase from 'firebase/app';
// import "firebase/firestore";

function petSort(payload) {
    return payload.sort(function(a, b) {
        if (a.subCode < b.subCode) {
            return -1;
        } else if (a.subCode > b.subCode) {
            return 1;
        } else {
            if (a.quotaInfo.secId < b.quotaInfo.secId) {
                return -1;
            } else if (a.quotaInfo.secId > b.quotaInfo.secId) {
                return 1;
            } else {
                if (a.quotaInfo.group < b.quotaInfo.group) {
                    return -1;
                } else if (a.quotaInfo.group > b.quotaInfo.group) {
                    return 1;
                } else {
                    if (a.stdCode < b.stdCode) {
                        return -1;
                    } else if (a.stdCode > b.stdCode) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            }
        }
    });
}

export default {
    async setPetitionList(context) {
        const staffInfo = context.rootGetters.userInfo;
        const isSudo = context.rootGetters.isSudo;
        const userProgram = context.rootGetters["programs/getUserProgram"];
        const fSemYear = context.rootGetters.fSemYear;
        let colRef = null;

        if (isSudo) {
            colRef = db.collection(`quota_petitions`).where("fSemYear", "==", fSemYear);
        } else {
            colRef = db
                .collection(`quota_petitions`)
                .where("fSemYear", "==", fSemYear)
                .where("subMajorId", "in", staffInfo.majors);
        }

        await colRef.onSnapshot((querySnapshot) => {
            if (querySnapshot.empty) {
                context.commit("setPetitionList", []);
            }
            let resArr = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });

            // sort array by reqDate
            resArr = resArr.sort(
                (objA, objB) =>
                Number(objA.time.toDate()) - Number(objB.time.toDate())
            );

            // filter campus
            resArr = resArr.filter((p) => p.quotaInfo.campus === userProgram.camCode);
            const sortedArr = petSort(resArr);

            context.commit("setPetitionList", sortedArr);
        });
    },
    async updatePetition(context, payload) {
        const colRef = db.collection(`quota_petitions`).doc(payload.id);
        await colRef.update(payload.data);
    },
    async rejectAllPetition(context) {
        const petitons = context.getters.petitionList;
        const pendingPet = petitons.filter((p) => p.status === "pending");
        const chunckPendingPet = sliceIntoChunks(pendingPet, 100);

        // set log reference
        for (const pets of chunckPendingPet) {
            // Get a new write batch
            const batch = db.batch();
            for (const aPet of pets) {
                const colRef = db.collection("quota_petitions").doc(aPet.id);
                // Update User Info data
                batch.update(colRef, { status: "reject" });
                // Commit the batch
            }
            await batch.commit();
        }
    },
};