import { isLogVisible } from "../../../js/debug.js";
import { db } from "../../../js/firebaseDb";
// import firebase from "firebase/app";
// import "firebase/firestore";

export default {
    async setStaff(context) {
        // prepare data
        const staffRef = db.collection(`staffs`);
        await staffRef.onSnapshot((staffDocs) => {
            if (staffDocs.empty) {
                context.commit("setStaff", []);
                const error = new Error("No such document!");
                if (isLogVisible()) {
                    console.error(error);
                }

                throw error;
            }

            let staffArr = staffDocs.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });

            staffArr = staffArr.filter(
                (staff) => !staff.status || staff.status !== "inactive"
            );

            context.commit("setStaff", staffArr);
        });
    },
    async disableStaff(context, payload) {
        // prepare data
        const staffId = payload.id;
        const status = "inactive";
        const staffRef = db.collection(`staffs`).doc(staffId);
        await staffRef.update({ status: status }).catch((error) => {
            console.error("Error adding document: ", error);
        });

        const userRef = db.collection(`users`).doc(staffId);
        await userRef.update({ status: status }).catch((error) => {
            console.error("Error adding document: ", error);
        });
    },
};