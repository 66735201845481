import { isLogVisible } from "../../../js/debug.js";
import { db } from "../../../js/firebaseDb";
// import firebase from "firebase/app";
// import "firebase/firestore";

export default {
    async setSection(context) {
        // prepare data
        const uProgram = context.rootGetters["programs/getUserProgram"];

        if (!uProgram) {
            if (isLogVisible()) {
                console.error("can't find user program");
            }
            return;
        }
        const sectionRef = db.collection(`sections`);
        const sectionDocs = await sectionRef
            .where("camCode", "==", uProgram.camCode)
            // .orderBy("secCode")
            .get()
            .catch((error) => {
                if (isLogVisible()) {
                    console.error("Error getting documents: ", error);
                }
            });

        if (sectionDocs.empty) {
            context.commit("setSection", []);
            const error = new Error("No such document!");
            if (isLogVisible()) {
                console.error(error);
            }

            throw error;
        }

        const sectionArr = sectionDocs.docs.map((doc) => {
            const payload = {
                code: doc.data().secCode,
                time: doc.data().secTime,
                camCode: doc.data().camCode,
                id: doc.id,
            };
            // if ("status" in doc.data()) {
            //     payload["status"] = doc.data().status;
            // }
            return payload;
        });

        if (isLogVisible()) {
            console.log(sectionArr);
        }

        context.commit("setSection", sectionArr);
    },
    async addSection(context, submitData) {
        const uProgram = context.rootGetters["programs/getUserProgram"];

        if (!uProgram) {
            if (isLogVisible()) {
                console.error("can't find user program");
            }
            return;
        }
        const payload = {
            secCode: submitData.code,
            secTime: submitData.time,
            camCode: uProgram.camCode,
        };
        const statePayload = {
            code: submitData.code,
            time: submitData.time,
            camCode: uProgram.camCode,
        };
        // Add a new document with a generated id.
        const docRef = await db
            .collection("sections")
            .add(payload)

        .catch((error) => {
            console.error("Error adding document: ", error);
        });
        if (isLogVisible()) {
            console.log(submitData);
        }

        statePayload["id"] = docRef.id;
        if (isLogVisible()) {
            console.log(statePayload);
            console.log("Document written with ID: ", docRef.id);
        }

        context.commit("addSection", statePayload);
    },
    async editSection(context, submitData) {
        const uProgram = context.rootGetters["programs/getUserProgram"];

        if (!uProgram) {
            if (isLogVisible()) {
                console.error("can't find user program");
            }
            return;
        }
        if (isLogVisible()) {
            console.log(submitData);
        }
        const payload = {
            secCode: submitData.code,
            secTime: submitData.time,
        };
        const statePayload = {
            code: submitData.code,
            time: submitData.time,
            camCode: uProgram.camCode,
        };

        await db
            .collection("sections")
            .doc(submitData.id)
            .update(payload)

        .catch((error) => {
            console.error("Error adding document: ", error);
        });
        statePayload["id"] = submitData.id;
        context.commit("updateSection", statePayload);

        await context.dispatch("updateSectionQuota", {
            code: submitData.code,
            time: submitData.time,
            campus: uProgram.camCode,
        });
    },
    async deleteSection(context, submitData) {
        await db
            .collection("sections")
            .doc(submitData.id)
            .delete()
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
        context.commit("deleteSection", { id: submitData.id });
    },
    async updateSectionQuota(context, updatePayload) {
        const fSemYear = context.rootGetters.fSemYear;
        const quotaRef = db.collection(`quota_${fSemYear}`);
        const quotaDocs = await quotaRef
            .where("secId", "==", updatePayload.code)
            .get()
            .catch((error) => {
                this.error = error.message;
                return;
            });
        const quotaArr = quotaDocs.docs.map((doc) => {
            return {...doc.data(), id: doc.id };
        });
        const filteredQuota = quotaArr.filter(
            (q) => q.campus == updatePayload.campus
        );

        const batch = db.batch();

        for (const doc of filteredQuota) {
            // const theData = doc.data();
            const qId = doc.id;
            const userRef = quotaRef.doc(qId);
            batch.update(userRef, {
                secTime: updatePayload.time,
            });
        }

        await batch.commit();
    },
};