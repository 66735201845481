<template>
  <div>
    <p style="text-align: center;">
      <span class="text-3xl text-primary-500"
        ><strong>นโยบายข้อมูลส่วนบุคคล</strong></span
      >
    </p>
    <h2 class="text-center">
      คณะพาณิชยศาสตร์และการบัญชี มหาวิทยาลัยธรรมศาสตร์
    </h2>
    <p>&nbsp;</p>
    <p class="text-left">
      <span
        >คำประกาศเกี่ยวกับความเป็นส่วนตัว (“ประกาศ”)
        นี้จัดทำขึ้นเพื่อให้ท่านซึ่งเป็นผู้ใช้งานระบบโควตา (ซึ่งต่อไปในประกาศนี้
        เรียกว่า “กิจกรรมการประมวลผล”) ได้ทราบและเข้าใจรูปแบบการเก็บรวบรวม ใช้
        และเปิดเผย (“ประมวลผล”) ข้อมูลส่วนบุคคลที่ คณะพาณิชยศาสตร์และการบัญชี
        มหาวิทยาลัยธรรมศาสตร์ (ซึ่งต่อไปในประกาศนี้ เรียกว่า “คณะ”) ดำเนินการ
        ในฐานะผู้ควบคุมข้อมูลส่วนบุคคล
        ในข้อมูลส่วนบุคคลที่เก็บรวบรวมจากท่านเพื่อการดำเนินการภายใต้กิจกรรมการประมวลผลนี้
        คณะได้ทำการพัฒนาเว็บไซต์
        สำหรับอำนวยความสะดวกในการแจ้งความจำนงโควตาของคณะ
        รวมถึงอำนวยความสะดวกสำหรับเจ้าหน้าที่จัดการข้อมูลนักศึกษา
        จัดเก็บข้อมูลบนมาตรฐานความปลอดภัย ทั้งนี้
        เราดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
        ดังนี้
      </span>
    </p>
    <p>&nbsp;</p>
    <p class="text-left">
      <strong
        ><span class="text-2xl"
          >วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน</span
        ></strong
      >
    </p>
    <p class="text-left">
      <span
        >เราดำเนินการเก็บรวบรวมข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์
        ดังต่อไปนี้ <br />
        (1) เพื่อประมวลผลโควตาวิชา <br />
        (2) เพื่อความจำเป็นในการยืนยันตัวบุคคุล <br />
        (3) เพื่อการติดต่อนักศึกษาและประชาสัมพันธ์ข้อมูลของคณะ <br />
        (4) เพื่อความจำเป็นหากมีการ้องขอข้อมูลจากหน่วยงานที่เกี่ยวข้อง
      </span>
    </p>
    <p>&nbsp;</p>
    <p class="text-left">
      <span class="text-2xl"
        ><strong>ข้อมูลส่วนบุคคลที่เราเก็บรวบรวมและใช้</strong></span
      >
    </p>
    <p class="text-left">
      <span
        >เราดำเนินการเก็บรวบรวมข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์
        ดังต่อไปนี้ <br />
        (1) แหล่งข้อมูลและรายการข้อมูลส่วนบุคคลที่เก็บรวบรวม มีดังนี้
        <br />
        <table class="table-fixed">
          <tr>
            <th>แหล่ง/วิธีการเก็บรวบรวม</th>
            <th>รายการข้อมูลส่วนบุคคล</th>
          </tr>
          <tr>
            <td>1. เก็บข้อมูลจากท่านโดยตรงผ่านการลงทะเบียนเว็บไซต์</td>
            <td>
              <ul>
                <li>ชื่อ นามสกุล</li>
                <li>เพศ</li>
                <li>เบอร์โทรศัพท์</li>
                <li>เลขบัตรประจำตัวประชาชน</li>
                <li>ที่อยู่</li>
                <li>รหัสนักศีกษา</li>
                <li>วัน เดือน ปี เกิด</li>
                <li>โรงเรียนมัธยมปลาย</li>
                <li>อีเมล</li>
                <li>
                  ข้อมูลผู้ติดต่อในกรณีฉุกเฉิน(ชื่อ นามสกุล,เบอร์โทรศัพท์)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              2. เก็บโดยการใช้เทคโนโลยีตรวจจับหรือติดตามพฤติกรรมการใช้งานของท่าน
            </td>
            <td>
              <ul>
                <li>Cookie data</li>
              </ul>
            </td>
          </tr>
        </table>

        (2) จุดประสงค์การใช้งานข้อมูลส่วนบุคคล <br />
        <table class="table-fixed">
          <tr>
            <th>จุดประสงค์ในการใช้ข้อมูล</th>
            <th>รายการข้อมูลส่วนบุคคลที่ใช้</th>
          </tr>
          <tr>
            <td>เพื่อการยืนยันตัวตนสมัครใช้บริการ</td>
            <td>
              <ul>
                <li>ชื่อ นามสกุล</li>
                <li>เพศ</li>
                <li>เบอร์โทรศัพท์</li>
                <li>เลขบัตรประจำตัวประชาชน</li>
                <li>ที่อยู่</li>
                <li>รหัสนักศีกษา</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>เพื่อติดต่อสื่อสารและ ประชาสัมพันธ์</td>
            <td>
              <ul>
                <li>อีเมล</li>
                <li>เบอร์โทรศัพท์</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>เพื่อหน่วยงานที่เกี่ยวข้อง</td>
            <td>
              <ul>
                <li>โรงเรียนมัธยมปลาย</li>
              </ul>
            </td>
          </tr>
        </table>
      </span>
    </p>
    <p class="text-left">
      <span
        >คณะฯ
        จะทำการเก็บรวบรวมข้อมูลส่วนบุคคลของท่านเพื่อใช้งานตามวัตถุประสงค์ที่แจ้งไว้เป็นระยะเวลาตราบเท่าที่ท่านยังเป็นผู้ใช้ของคณะฯ
        และจะเก็บและใช้งานต่อเนื่องไปเป็นเวลา 10 ปี
        หลังจากที่ท่านสิ้นสุดการเป็นผู้ใช้ของคณะฯ ทั้งนี้
        เท่าที่จำเป็นตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล
        หรือตามที่กฎหมายกำหนด</span
      >
    </p>
    <p>&nbsp;</p>
    <p class="text-left">
      <span class="text-2xl"
        ><strong>การเปิดเผยข้อมูลส่วนบุคคลของท่าน</strong></span
      >
    </p>
    <p class="text-left">
      <span
        >เราเปิดเผยข้อมูลส่วนบุคคลของท่านต่อบุคคลหรือนิติบุคคลดังต่อไปนี้
        ศูนย์ทดสอบสมรรถนะแห่งมหาวิทยาลัยธรรมศาสตร์ (TCTC) เพื่อการ
        ประชาสัมพันธ์นักเรียนในระดับมัธยมศึกษา
      </span>
    </p>
    <div>
      <p>&nbsp;</p>
      <p class="text-left">
        <span class="text-2xl"
          ><strong
            >สิทธิตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒
            ของท่าน</strong
          ></span
        >
      </p>
      <p class="text-left">
        <span
          >พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒
          มีวัตถุประสงค์เพื่อให้ข้อมูลส่วนบุคคลของท่านอยู่ในความควบคุมของท่านได้มากขึ้น
          โดยท่านสามารถใช้สิทธิตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒
          เมื่อบทบัญญัติในส่วนที่เกี่ยวกับสิทธิของเจ้าของข้อมูลส่วนบุคคลมีผลใช้บังคับ
          ซึ่งมีรายละเอียดดังต่อไปนี้
          <ul>
            <li>
              (1) สิทธิในการเข้าถึง
              รับสำเนาและขอให้เปิดเผยที่มาของข้อมูลส่วนบุคคลของท่านที่เราเก็บรวบรวมอยู่
              เว้นแต่กรณีที่เรามีสิทธิปฏิเสธคำขอของท่านตามกฎหมายหรือคำสั่งศาล
              หรือกรณีที่คำขอของท่านจะมีผลกระทบที่อาจก่อให้เกิดความเสียหายต่อสิทธิและเสรีภาพของบุคคลอื่น
            </li>
            <li>
              (2)
              สิทธิในการขอแก้ไขข้อมูลส่วนบุคคลของท่านที่ไม่ถูกต้องหรือไม่ครบถ้วน
              เพื่อให้มีความถูกต้อง เป็นปัจจุบัน สมบูรณ์
              และไม่ก่อให้เกิดความเข้าใจผิด
            </li>
            <li>
              (3)
              สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคลของท่านในกรณีหนึ่งกรณีใดดังต่อไปนี้
              <ul class="pl-12">
                <li>
                  (3.1)
                  เมื่ออยู่ในช่วงเวลาที่เราทำการตรวจสอบตามคำร้องขอของท่านให้แก้ไขข้อมูลส่วนบุคคลของท่านให้ถูกต้อง
                  สมบูรณ์และเป็นปัจจุบัน
                </li>
                <li>
                  (3.2) ข้อมูลส่วนบุคคลของท่านถูกเก็บรวบรวม
                  ใช้หรือเปิดเผยโดยมิชอบด้วยกฎหมาย
                </li>
                <li>
                  (3.3)
                  เมื่อข้อมูลส่วนบุคคลของท่านหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์เราได้แจ้งไว้ในการเก็บรวบรวม
                  แต่ท่านประสงค์ให้เราเก็บรักษาข้อมูลนั้นต่อไปเพื่อประกอบการใช้สิทธิตามกฎหมายของท่าน
                </li>
                <li>
                  (3.4)
                  เมื่ออยู่ในช่วงเวลาที่เรากำลังพิสูจน์ให้ท่านเห็นถึงเหตุอันชอบด้วยกฎหมายในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน
                  หรือตรวจสอบความจำเป็นในการเก็บรวบรวม ใช้
                  หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อประโยชน์สาธารณะ
                  อันเนื่องมาจากการที่ท่านได้ใช้สิทธิคัดค้านการเก็บรวบรวม ใช้
                  หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
                </li>
              </ul>
            </li>
            <li>
              (4) สิทธิในการคัดค้านการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
              เว้นแต่กรณีที่เรามีเหตุในการปฏิเสธคำขอของท่านโดยชอบด้วยกฎหมาย
              (เช่น เราสามารถแสดงให้เห็นว่าการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลของท่านมีเหตุอันชอบด้วยกฎหมายยิ่งกว่า
              หรือเพื่อการก่อตั้งสิทธิเรียกร้องตามกฎหมาย
              การปฏิบัติตามหรือการใช้สิทธิเรียกร้องทางกฎหมาย
              หรือเพื่อประโยชน์สาธารณะตามภารกิจของเรา)
            </li>
          </ul>
        </span>
      </p>
    </div>
    <div>
      <p>&nbsp;</p>
      <p class="text-left">
        <span class="text-2xl"
          ><strong>ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล</strong></span
        >
      </p>
      <p class="text-left">
        <span
          >เราเก็บรักษาข้อมูลส่วนบุคคลของท่าน เป็นระยะเวลาไม่เกิน 4 ปี
          หรือเมื่อได้รับการสั่งการจากผู้มีอำนาจ
          นับจากวันที่ท่านสิ้นสุดสถานะการเป็นนักศึกษา <br />ทั้งนี้
          เมื่อพ้นระยะเวลาดังกล่าวเราจะทำการ <br />
          ทำให้ข้อมูลส่วนบุคคลของท่านไม่สามารถระบุตัวตนได้เพื่อการใช้ประโยชน์ด้านอื่น
          เช่น การวิเคราะห์ทางสถิติ การปรับปรุงประสิทธิภาพการทำงาน
          หรือประโยชน์สาธารณะที่สำคัญ
        </span>
      </p>
    </div>
    <div>
      <p>&nbsp;</p>
      <p class="text-left">
        <span class="text-2xl"
          ><strong>การรักษาความมั่นคงปลอดภัยข้อมูลส่วนบุคคล</strong></span
        >
      </p>
      <p class="text-left">
        <span
          >เรามีมาตรการในการรักษาความมั่นคงปลอดภัยข้อมูลส่วนบุคคลของท่านอย่างเหมาะสม
          ทั้งในเชิงเทคนิคและการบริหารจัดการ เพื่อป้องกันมิให้ข้อมูลสูญหาย
          หรือมีการเข้าถึง ทำลาย ใช้ เปลี่ยนแปลง แก้ไข
          หรือเปิดเผยข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาต
          ซึ่งสอดคล้องกับนโยบายและแนวปฏิบัติด้านความมั่นคงปลอดภัยสารสนเทศ
          (Information Security Policy) ของเรา นอกจากนี้
          เราได้กำหนดให้มีนโยบายการคุ้มครองข้อมูลส่วนบุคคลขึ้นโดยประกาศให้ทราบกันโดยทั่วทั้งองค์กร
          พร้อมแนวทางปฏิบัติเพื่อให้เกิดความมั่นคงปลอดภัยในการเก็บรวบรวม ใช้
          และเปิดเผยข้อมูลส่วนบุคคล โดยธำรงไว้ซึ่งความเป็นความลับ
          (Confidentiality) ความถูกต้องครบถ้วน (Integrity) และสภาพพร้อมใช้งาน
          (Availability) ของข้อมูลส่วนบุคคล
          โดยเราได้จัดให้มีการทบทวนนโยบายดังกล่าวรวมถึงประกาศนี้ในระยะเวลาตามที่เหมาะสม
        </span>
      </p>
    </div>
    <div>
      <p>&nbsp;</p>
      <p class="text-left">
        <span class="text-2xl"
          ><strong>การมีส่วนร่วมของเจ้าของข้อมูลส่วนบุคคล</strong></span
        >
      </p>
      <p class="text-left">
        <span
          >เราอาจเปิดเผยข้อมูลส่วนบุคคลก็ต่อเมื่อได้รับคำร้องขอจากเจ้าของข้อมูลส่วนบุคคล
          ผู้สืบสิทธิ์ ทายาท ผู้แทนโดยชอบธรรม
          หรือผู้อนุบาลหรือผู้พิทักษ์ตามกฎหมาย โดยส่งคำร้องขอผ่าน
          คณะพาณิชยศาสตร์และการบัญชี มหาวิทยาลัยธรรมศาสตร์ ทาง โทรศัพท์ : +66
          2696 5730-1 E-mail : pr@tbs.tu.ac.th ในกรณีที่เจ้าของข้อมูล
          ผู้สืบสิทธิ์ ทายาท ผู้แทนโดยชอบธรรม
          หรือผู้พิทักษ์ตามกฎหมายมีการคัดค้านการจัดเก็บ ความถูกต้อง
          หรือการกระทำใด ๆ เช่น การแจ้งดำเนินการปรับปรุงแก้ไขข้อมูลส่วนบุคคล
          เราจะดำเนินการบันทึกหลักฐานคำคัดค้านดังกล่าวไว้เป็นหลักฐานด้วย ทั้งนี้
          เราอาจปฏิเสธสิทธิตามวรรคสองได้ตามกรณีที่มีกฎหมายกำหนด
          หรือในกรณีที่ข้อมูลส่วนบุคคลของท่านถูกทำให้ไม่ปรากฏชื่อหรือสิ่งบอกลักษณะอันสามารถระบุตัวท่านได้
        </span>
      </p>
    </div>
    <div>
      <p>&nbsp;</p>
      <p class="text-left">
        <span class="text-2xl"
          ><strong
            >ความรับผิดชอบของบุคคลซึ่งประมวลผลข้อมูลส่วนบุคคล</strong
          ></span
        >
      </p>
      <p class="text-left">
        <span
          >เราได้กำหนดให้เจ้าหน้าที่เฉพาะผู้ที่มีอำนาจหน้าที่เกี่ยวข้องในการจัดเก็บรวบรวม
          ใช้
          และเปิดเผยข้อมูลส่วนบุคคลของกิจกรรมการประมวลผลนี้เท่านั้นที่จะสามารถเข้าถึงข้อมูลส่วนบุคคลของท่านได้
          โดยเราจะดำเนินการให้เจ้าหน้าที่ปฏิบัติตามประกาศนี้อย่างเคร่งครัด
        </span>
      </p>
    </div>
    <div>
      <p>&nbsp;</p>
      <p class="text-left">
        <span class="text-2xl"
          ><strong
            >การเปลี่ยนแปลงแก้ไขคำประกาศเกี่ยวกับความเป็นส่วนตัว</strong
          ></span
        >
      </p>
      <p class="text-left">
        <span
          >ในการปรับปรุงหรือเปลี่ยนแปลงประกาศนี้
          เราอาจพิจารณาแก้ไขเปลี่ยนแปลงตามที่เห็นสมควร
          และจะทำการแจ้งให้ท่านทราบผ่านช่องทางเว็บไซต์ระบบโควตา
          โดยมีวันที่ของเวอร์ชั่นล่าสุดกำกับอยู่ตอนท้าย อย่างไรก็ดี
          เราขอแนะนำให้ท่านโปรดตรวจสอบเพื่อรับทราบประกาศฉบับใหม่อย่างสม่ำเสมอ
          โดยเฉพาะก่อนที่ท่านจ้ะเปิดเผยข้อมูลส่วนบุคคลแก่เรา
          โดยในการเข้าใช้งานผลิตภัณฑ์หรือบริการภายใต้กิจกรรมการประมวลผลนี้ของท่าน
          ถือเป็นการรับทราบตามข้อตกลงในประกาศนี้ ทั้งนี้
          โปรดหยุดการใช้งานหากท่านไม่เห็นด้วยกับข้อตกลงในประกาศฉบับนี้
          หากท่านยังคงใช้งานต่อไปภายหลังจากที่ประกาศนี้มีการแก้ไขและนำขึ้นประกาศในช่องทางข้างต้นแล้ว
          จะถือว่าท่านได้รับทราบการเปลี่ยนแปลงดังกล่าวแล้ว
        </span>
      </p>
    </div>
    <div>
      <p>&nbsp;</p>
      <p class="text-left">
        <span class="text-2xl"><strong>การติดต่อสอบถาม </strong></span>
      </p>
      <p class="text-left">
        <span
          >ท่านสามารถติดต่อสอบถามเกี่ยวกับประกาศฉบับนี้ได้ที่
          ผู้ควบคุมข้อมูลส่วนบุคคล (Data Controller)
          <ul>
            <li>
              <b>ชื่อ</b>: คณะพาณิชยศาสตร์และการบัญชี มหาวิทยาลัยธรรมศาสตร์
            </li>
            <li>
              <b>สถานที่ติดต่อ</b>: 99 หมู่ 18 ถนนพหลโยธิน ตำบลคลองหนึ่ง
              อำเภอคลองหลวง จังหวัดปทุมธานี 12121
            </li>
            <li>
              <b>ช่องทางการติดต่อ</b>: โทรศัพท์ : +66 2696 5730-1 E-mail :
              pr@tbs.tu.ac.th
            </li>
          </ul>
        </span>
      </p>
    </div>
  </div>
</template>
