// import { isLogVisible } from "../../../js/debug.js";
import { db } from "../../../js/firebaseDb";
// import firebase from "firebase/app";
// import "firebase/firestore";

export default {
    async setFaq(context) {
        const faqRef = db.collection(`FAQ`);

        await faqRef.onSnapshot((querySnapshot) => {
            if (querySnapshot.empty) {
                context.commit("setFaq", []);
            }
            const faqArr = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });

            context.commit("setFaq", faqArr);
        });
    },
    async addFaq(context, submitData) {
        const payload = {
            question: submitData.question,
            ans: submitData.ans,
        };
        const docRef = await db
            .collection("FAQ")
            .add(payload)

        .catch((error) => {
            console.error("Error adding document: ", error);
        });
        payload["id"] = docRef.id;
        context.commit("addFaq", payload);
    },
    async editFaq(context, submitData) {
        const payload = {
            question: submitData.question,
            ans: submitData.ans,
        };

        await db
            .collection("FAQ")
            .doc(submitData.id)
            .update(payload)

        .catch((error) => {
            console.error("Error adding document: ", error);
        });
        payload["id"] = submitData.id;
        context.commit("updateFaq", payload);
    },
    async deleteFaq(context, submitData) {
        await db
            .collection("FAQ")
            .doc(submitData.id)
            .delete()

        .catch((error) => {
            console.error("Error adding document: ", error);
        });
        context.commit("deleteFaq", { id: submitData.id });
    },
};