<template>
  <!-- This is Admin Main -->
  <admin-header></admin-header>
  <div v-if="isloading" class="flex justify-center py-10">
    <base-spinner></base-spinner>
  </div>

  <div class="pb-32" v-else>
    <router-view></router-view>
  </div>

  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
</template>

<script>
import AdminMixin from "../../mixins/admin.js";
import { db } from "../../js/firebaseDb.js";
import AdminHeader from "../../components/layouts/Header/Admin.vue";

export default {
  mixins: [AdminMixin], //logoutAdmin
  components: { AdminHeader },
  data() {
    return {
      error: null,
      isloading: false,
    };
  },
  async created() {
    this.isloading = true;
    await this.loadUser();
    await this.forceAdminLogout();
    this.isloading = false;
  },
  computed: {
    isSudo() {
      return this.$store.getters["isSudo"];
    },
    isAdmin() {
      return this.$store.getters["isAdmin"];
    },
  },
  methods: {
    closeError() {
      this.error = null;
    },

    async loadUser() {
      try {
        await this.$store.dispatch("setUser", { mode: "admin" });
      } catch (e) {
        this.error = e.message;
      }
    },

    async forceAdminLogout() {
      const scheduleRef = db.collection(`schedule`).where("isEnd", "==", false);
      await scheduleRef.onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
          const error = new Error("No such document!");
          throw error;
        }

        const scheduleArr = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });

        if (scheduleArr.length > 1) {
          const eMsg = "matched schedule exceed 1";
          const error = new Error(eMsg);
          throw error;
        }

        const theSchedule = scheduleArr[0];
        if (this.isAdmin && !this.isSudo) {
          if (!theSchedule.adminLogin) {
            this.logoutAdmin();
          }
        }
      });
    },
  },
};
</script>
