export default {
  faqs(state) {
    return state.faqs;
  },
  fullFAQ(state, getters) {
    let faqArr = [];
    const faqs = getters.faqs;
    for (const faq of faqs) {
      faqArr.push({
        ...faq,
        fullFAQ: 'Q: ' + faq.question + ' ' + 'A: ' + faq.answer,
      });
    }
    return faqArr;
  },
  //   getNameById: (state, getters) => (instId) => {
  //     const instructors = getters.fullName;
  //     const filteredInst = instructors.find((inst) => inst.id === instId);
  //     return filteredInst;
  //   },
};
