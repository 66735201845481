import { isLogVisible } from "../../../js/debug.js";
import { db } from "../../../js/firebaseDb";
import { sliceIntoChunks } from "../../../js/utility.js";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
    // async getStudentOverview(context, payload) {
    //     const stdCodeArray = payload.stdCodeArray;
    //     let studentInfoArr = [];
    //     for (const stdCode of stdCodeArray) {
    //         const studentRef = db.collection("students");
    //         const theStudentRef = studentRef
    //             .where("stdCode", ">=", stdCode)
    //             .where("stdCode", "<=", stdCode + "\uf8ff");
    //         const studentDocs = await theStudentRef.get().catch((error) => {
    //             console.error("Error getting documents: ", error);
    //         });

    //         const studentArr = studentDocs.docs.map((doc) => {
    //             return {...doc.data(), id: doc.id };
    //         });
    //     }
    // },

    async getStudentCount(context, payload) {
        const stdCodeArray = payload.stdCodeArray;

        // get all major
        const majors = context.rootGetters["majors/majors"];

        // get all status
        const statusList = context.rootGetters["students/status"];

        const responseCount = { tbsStudent: {}, notTbsStudent: {} };
        for (const stdCode of stdCodeArray) {
            // student data
            const studentRef = db.collection("students");
            const theStudentRef = studentRef
                .where("stdCode", ">=", stdCode)
                .where("stdCode", "<=", stdCode + "\uf8ff");
            const studentDocs = await theStudentRef.get().catch((error) => {
                console.error("Error getting documents: ", error);
            });
            const studentArr = studentDocs.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });

            const tbsStudent = studentArr.filter(
                (std) => std.stdCode.substring(2, 4) === "02"
            );
            const notTbsStudent = studentArr.filter(
                (std) => std.stdCode.substring(2, 4) !== "02"
            );

            // major change data
            const majorChangeRef = db.collection("student_major_change");
            const theMajorChangeRef = majorChangeRef
                .where("stdCode", ">=", stdCode)
                .where("stdCode", "<=", stdCode + "\uf8ff");
            const majorChangeDocs = await theMajorChangeRef.get().catch((error) => {
                console.error("Error getting documents: ", error);
            });
            const majorChangeArr = majorChangeDocs.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });

            for (const major of majors) {
                const stdInMajor = tbsStudent.filter((std) => std.major == major.id);
                const admitCount = majorChangeArr.filter(
                    (m) => m.to == major.id && m.from == 0
                );

                const toCount = majorChangeArr.filter(
                    (m) => m.to == major.id && m.from !== 0
                );

                const fromCount = majorChangeArr.filter((m) => m.from == major.id);

                if (responseCount["tbsStudent"][major.id] === undefined) {
                    responseCount["tbsStudent"][major.id] = {};
                }

                if (responseCount["tbsStudent"][major.id][stdCode] === undefined) {
                    responseCount["tbsStudent"][major.id][stdCode] = {};
                }

                // loop each student status
                let payload = {
                    admitCount: admitCount.length,
                    toCount: toCount.length,
                    fromCount: fromCount.length,
                };

                const statusObj = {};
                for (const status of statusList) {
                    const theStatus = stdInMajor.filter((std) => std.status == status.id);
                    statusObj[status.id] = theStatus.length;
                }

                responseCount["tbsStudent"][major.id][stdCode] = {
                    ...payload,
                    ...statusObj,
                };
            }
            responseCount["notTbsStudent"][stdCode] = notTbsStudent.length;
        }

        return responseCount;
    },

    async getStudentInfoById(context, payload) {
        const studentRef = db.collection("students").doc(payload.id);
        const studentDoc = await studentRef.get().catch((error) => {
            console.error("Error getting documents: ", error);
        });
        const studentData = {...studentDoc.data(), id: studentDoc.id };

        return studentData;
    },

    async getStudentInfo(context, payload) {
        const stdCodeArray = payload.stdCodeArray;

        let studentInfoArr = [];
        for (const stdCode of stdCodeArray) {
            const studentRef = db.collection("students");
            const theStudentRef = studentRef
                .where("stdCode", ">=", stdCode)
                .where("stdCode", "<=", stdCode + "\uf8ff");

            const studentDocs = await theStudentRef.get().catch((error) => {
                console.error("Error getting documents: ", error);
            });

            const studentArr = studentDocs.docs.map((doc) => {
                let payload = {...doc.data(), id: doc.id };
                const theProgram = context.rootGetters["programs/getProgramByCode"](
                    doc.data().program
                );
                payload["programText"] = theProgram.name;
                const theStatus = context.rootGetters["students/getStatusById"](
                    doc.data().status
                );
                payload["statusText"] = theStatus.name;

                const theMajor1 = context.rootGetters["majors/getMajorById"](
                    doc.data().major
                );
                payload["majorText"] = theMajor1.name;

                if (doc.data().major2) {
                    const theMajor2 = context.rootGetters["majors/getMajorById"](
                        doc.data().major2
                    );
                    payload["major2Text"] = theMajor2.name;
                }

                return payload;
            });

            studentInfoArr.push(...studentArr);
        }
        return studentInfoArr;
    },
    async updateStudentMajor(
        context,
        stdCode,
        stdId,
        fromMajor,
        toMajor,
        majorNo,
        remark,
        staffUsername
    ) {
        const schedule = context.rootGetters.schedule;
        // set log reference
        const basePayload = {
            stdCode: stdCode,
            stfId: staffUsername,
            time: firebase.firestore.FieldValue.serverTimestamp(),
            semester: schedule.semester,
            year: schedule.year,
            remark: remark,
        };
        // Get a new write batch
        const batch = db.batch();

        const majorChangeRef = db.collection("student_major_change").doc();
        batch.set(majorChangeRef, {
            ...basePayload,
            from: fromMajor,
            to: toMajor,
            majorNo: majorNo,
        });

        const userRef = db.collection("students").doc(stdId);
        if (majorNo == 1) {
            batch.update(userRef, {
                major: toMajor,
            });
        } else {
            batch.update(userRef, {
                [`major${majorNo}`]: toMajor,
            });
        }

        // Commit the batch
        await batch.commit();
    },
    async updateStudentMajorBatch(context, payload) {
        const stdInfoArr = payload.stdInfoArr;
        const toMajor = payload.toMajor;
        const toMajorNo = payload.toMajorNo;
        const toMajorRemark = payload.toMajorRemark;

        // get admin data
        const adminInfo = context.rootGetters.userInfo;
        // get schedule data
        const semester = payload.semester;
        const year = payload.year;

        const chunckStdInfoArr = sliceIntoChunks(stdInfoArr, 100);

        // set user reference
        for (const theChuck of chunckStdInfoArr) {
            // Get a new write batch
            const batch = db.batch();

            for (const stdInfo of theChuck) {
                const basePayload = {
                    stdCode: stdInfo.code,
                    stfId: adminInfo.username,
                    stdName: stdInfo.stdName,
                    time: firebase.firestore.FieldValue.serverTimestamp(),
                    semester: semester,
                    year: year,
                    remark: toMajorRemark,
                };

                const majorChangeRef = db.collection("student_major_change").doc();

                if (toMajorNo == 1 && toMajor !== stdInfo.oldMajor) {
                    // 1
                    const userRef = db.collection("students").doc(stdInfo.id);
                    batch.update(userRef, {
                        major: toMajor,
                    });

                    // update state (Vuex)
                    context.commit("updateStudentMajor", {
                        stdId: stdInfo.id,
                        toMajor: toMajor,
                        toMajorNo: toMajorNo,
                    });

                    batch.set(majorChangeRef, {
                        ...basePayload,
                        from: stdInfo.oldMajor,
                        to: toMajor,
                        majorNo: toMajorNo,
                    });
                } else if (toMajorNo == 2 && toMajor !== stdInfo.oldMajor2) {
                    // 2
                    const userRef = db.collection("students").doc(stdInfo.id);
                    batch.update(userRef, {
                        major2: toMajor,
                    });

                    // update state (Vuex)
                    context.commit("updateStudentMajor", {
                        stdId: stdInfo.id,
                        toMajor: toMajor,
                        toMajorNo: toMajorNo,
                    });

                    batch.set(majorChangeRef, {
                        ...basePayload,
                        from: stdInfo.oldMajor2 || 0,
                        to: toMajor,
                        majorNo: toMajorNo,
                    });
                } else {
                    // pass
                }
            }

            // Commit the batch
            await batch.commit();
        }
    },

    async updateStudentStatusBatch(context, payload) {
        const stdInfoArr = payload.stdInfoArr;
        const toStatus = payload.toStatus;
        const toStatusRemark = payload.toStatusRemark;

        // get admin data
        const adminInfo = context.rootGetters.userInfo;
        // get schedule data
        const semester = payload.semester;
        const year = payload.year;

        const chunckStdInfoArr = sliceIntoChunks(stdInfoArr, 100);

        // set user reference
        for (const theChuck of chunckStdInfoArr) {
            // Get a new write batch
            const batch = db.batch();

            for (const stdInfo of theChuck) {
                const basePayload = {
                    stdCode: stdInfo.code,
                    stfId: adminInfo.username,
                    time: firebase.firestore.FieldValue.serverTimestamp(),
                    stdName: stdInfo.stdName,
                    semester: semester,
                    year: year,
                    remark: toStatusRemark,
                };

                const statusChangeRef = db.collection("student_status_change").doc();

                if (toStatus && toStatus !== stdInfo.oldStatus) {
                    // 1
                    const userRef = db.collection("students").doc(stdInfo.id);
                    batch.update(userRef, {
                        status: toStatus,
                    });

                    // update state (Vuex)
                    context.commit("updateStudentStatus", {
                        stdId: stdInfo.id,
                        toStatus: toStatus,
                    });

                    batch.set(statusChangeRef, {
                        ...basePayload,
                        from: stdInfo.oldStatus,
                        to: toStatus,
                    });
                } else {
                    // pass
                }
            }

            // Commit the batch
            await batch.commit();
        }
    },

    async updateStudentInfo(context, payload) {
        // prepare data
        const uId = payload.id;
        const stdCode = payload.stdCode;
        delete payload.id;
        delete payload.stdCode;

        // set user reference
        const userRef = db.collection("students").doc(uId);

        // get old data
        const oldUser = await userRef.get().catch((error) => {
            throw Error(`"Error get documents: ", ${error}`);
        });
        const oldDoc = {
            ...oldUser.data(),
            id: oldUser.id,
        };

        // get admin data
        const adminInfo = context.rootGetters.userInfo;

        // get schedule data
        const schedule = context.rootGetters.schedule;

        // Get a new write batch
        const batch = db.batch();

        // set log reference
        const basePayload = {
            stdCode: stdCode,
            stdName: `${oldDoc.nameTitle} ${oldDoc.firstName} ${oldDoc.lastName}`,
            stfId: adminInfo.username,
            time: firebase.firestore.FieldValue.serverTimestamp(),
            semester: schedule.semester,
            year: schedule.year,
            remark: null,
        };
        const majorChangeRef = db.collection("student_major_change").doc();
        const major2ChangeRef = db.collection("student_major_change").doc();
        const statusChangeRef = db.collection("student_status_change").doc();
        if (payload.status && payload.status !== oldDoc.status) {
            batch.set(statusChangeRef, {
                ...basePayload,
                from: oldDoc.status || null,
                to: payload.status,
            });
        }
        if (payload.major && payload.major !== oldDoc.major) {
            batch.set(majorChangeRef, {
                ...basePayload,
                from: oldDoc.major || null,
                to: payload.major,
                majorNo: 1,
            });
        }
        if (payload.major2 && payload.major2 !== oldDoc.major2) {
            batch.set(major2ChangeRef, {
                ...basePayload,
                from: oldDoc.major2 || null,
                to: payload.major2,
                majorNo: 2,
            });
        }

        // Update User Info data
        batch.update(userRef, payload);

        // Commit the batch
        await batch.commit();

        // get new data
        await context.dispatch("setStudentInfo", { uid: uId });
        await context.dispatch("setStudentMajorChange", { stdCode: stdCode });
        await context.dispatch("setStudentStatusChange", { stdCode: stdCode });
    },
    async setStudent(context, payload) {
        const mode = payload.mode;
        const queryText = payload.queryText;
        let studentRef = db.collection("students");

        // https://stackoverflow.com/questions/46568142/google-firestore-query-on-substring-of-a-property-value-text-search
        if (queryText) {
            if (mode === "stdCode") {
                // ok
                studentRef = studentRef
                    .where("stdCode", ">=", queryText)
                    .where("stdCode", "<=", queryText + "\uf8ff");
            } else if (mode === "stdName") {
                studentRef = studentRef
                    .where("firstName", ">=", queryText)
                    .where("firstName", "<=", queryText + "\uf8ff");
            } else if (mode === "stdSurName") {
                studentRef = studentRef
                    .where("lastName", ">=", queryText)
                    .where("lastName", "<=", queryText + "\uf8ff");
            } else {
                throw Error("this mode is not supported");
            }
        }

        const studentDocs = await studentRef
            .limit(3000)
            .get()
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });

        const studentArr = studentDocs.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
            };
        });

        context.commit("setStudent", studentArr);
    },
    async setStudentInfo(context, payload) {
        const uId = payload.uid;
        const userRef = db.collection("students").doc(uId);
        const response = await userRef.get().catch((error) => {
            console.error("Error getting documents: ", error);
        });
        const userInfo = {
            ...response.data(),
            id: response.id,
        };

        context.commit("setStudentInfo", userInfo);
    },

    async setStudentMajorChange(context, payload) {
        const stdCode = payload.stdCode;
        const majorChangeRef = db.collection("student_major_change");
        const majorChangeDocs = await majorChangeRef
            .where("stdCode", "==", stdCode)
            .get()
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });

        const majorChangeArr = majorChangeDocs.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
                time: doc.data().time.toDate(),
            };
        });

        context.commit("setMajorChange", majorChangeArr);
    },

    async setStudentStatusChange(context, payload) {
        const stdCode = payload.stdCode;
        const statusChangeRef = db.collection("student_status_change");
        const statusChangeDocs = await statusChangeRef
            .where("stdCode", "==", stdCode)
            .get()
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });
        const statusChangeArr = statusChangeDocs.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
                time: doc.data().time.toDate(),
            };
        });

        context.commit("setStatusChange", statusChangeArr);
    },

    async getQuotaBy(context, payload) {
        const mode = payload.mode;
        let quotaId = null;
        if (mode === "id") {
            quotaId = payload.id;
        } else {
            console.error("this mode is not supported");
        }

        const fSemYear = context.rootGetters.fSemYear;

        const quotaRef = db.collection(`quota_${fSemYear}`).doc(quotaId);
        const quotaDoc = await quotaRef.get().catch((error) => {
            console.error("Error getting documents: ", error);
        });
        const doc = {
            id: quotaDoc.id,
            ...quotaDoc.data(),
        };
        return doc;
    },

    async setQuotaBy(context, payload) {
        const mode = payload.mode;
        let idArr = null;
        const fSemYear = context.rootGetters.fSemYear;
        if (mode === "idArr") {
            idArr = payload.idArr;
        } else {
            console.error("this mode is not supported");
        }

        const quotaRef = db.collection(`quota_${fSemYear}`);

        const idArrFormat = sliceIntoChunks(idArr, 10);

        let response = [];
        for (const theIdArr of idArrFormat) {
            const quotaReqDocs = await quotaRef
                .where("__name__", "in", theIdArr)
                .get()
                .catch((error) => {
                    console.error("Error getting documents: ", error);
                });
            if (quotaReqDocs.empty) {
                if (isLogVisible()) {
                    const error = new Error("No such document!");
                    console.error(error);
                }
            }

            const quotaReqArr = quotaReqDocs.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });
            response.push(...quotaReqArr);
        }
        context.commit("setQuota", response);
    },

    async setStdReqQuota(context, payload) {
        const userId = payload.uid;
        const fSemYear = context.rootGetters.fSemYear;

        const quotaRef = db.collection(`quota_request_${fSemYear}`);
        const quotaReqDocs = await quotaRef
            .where("userId", "==", userId)
            .get()
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });
        if (quotaReqDocs.empty) {
            if (isLogVisible()) {
                const error = new Error("No such document!");
                console.error(error);
            }
        }

        let quotaIdArr = [];
        const quotaReqArr = quotaReqDocs.docs.map((doc) => {
            quotaIdArr.push(doc.data().quotaId);
            return {
                ...doc.data(),
                id: doc.id,
            };
        });
        await context.dispatch("setQuotaBy", { mode: "idArr", idArr: quotaIdArr });
        context.commit("setReqQuota", quotaReqArr);
    },

    async getMajorChange(context, payload) {
        // unpack payload
        const changeYear = payload.changeYear;

        let majorChangeRef = db.collection("student_major_change");

        if (changeYear) {
            majorChangeRef = majorChangeRef.where("year", "==", changeYear);
        }
        const majorChangeDocs = await majorChangeRef.get().catch((error) => {
            console.error("Error getting documents: ", error);
        });

        let majorChangeArr = majorChangeDocs.docs.map((doc) => {
            const toMajor = context.rootGetters["majors/getMajorById"](doc.data().to);
            const fromMajor = context.rootGetters["majors/getMajorById"](
                doc.data().from
            );
            return {
                toText: toMajor ? toMajor.name : null,
                fromText: fromMajor ? fromMajor.name : null,
                ...doc.data(),
                id: doc.id,
            };
        });

        majorChangeArr = majorChangeArr.sort(
            (objA, objB) => Number(objA.time.toDate()) - Number(objB.time.toDate())
        );

        return majorChangeArr;
    },
    async getStatusChange(context, payload) {
        // unpack payload
        const changeYear = payload.changeYear;

        let statusChangeRef = db.collection("student_status_change");

        if (changeYear) {
            statusChangeRef = statusChangeRef.where("year", "==", changeYear);
        }
        const statusChangeDocs = await statusChangeRef.get().catch((error) => {
            console.error("Error getting documents: ", error);
        });

        let statusChangeArr = statusChangeDocs.docs.map((doc) => {
            const toStatus = context.rootGetters["students/getStatusById"](
                doc.data().to
            );
            const fromStatus = context.rootGetters["students/getStatusById"](
                doc.data().from
            );
            return {
                toText: toStatus ? toStatus.name : null,
                fromText: fromStatus ? fromStatus.name : null,
                ...doc.data(),
                id: doc.id,
            };
        });

        statusChangeArr = statusChangeArr.sort(
            (objA, objB) => Number(objA.time.toDate()) - Number(objB.time.toDate())
        );

        return statusChangeArr;
    },
    async setStdPetition(context, payload) {
        const stdCode = payload.stdCode;
        const schedule = context.rootGetters.schedule;
        const fSemYear = context.rootGetters.fSemYear;

        const colRef = db
            .collection(`quota_petitions`)
            .where("fSemYear", "==", fSemYear)
            .where("stdCode", "==", stdCode);
        const userYear = context.rootGetters["years/getYearByStdCode"](stdCode);

        await colRef.onSnapshot((querySnapshot) => {
            if (querySnapshot.empty) {
                context.commit("setStdPetitions", []);
            }
            let resArr = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                    stdSubCode: doc.data().quotaInfo[`sub${userYear.name}`].code,
                    stdSubName: doc.data().quotaInfo[`sub${userYear.name}`].name,
                    stdSubYear: doc.data().quotaInfo[`sub${userYear.name}`].year,
                };
            });

            resArr = resArr.filter((q) => {
                return q.qRound === schedule.qRound;
            });

            context.commit("setStdPetitions", resArr);
        });
    },
};