import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

export default {
    namespaced: true,
    state() {
        return {
            instructors: [],
            // instructors: [
            //   {
            //     id: "i1",
            //     prefix: "ศ.",
            //     name: "เกษรี",
            //     surname: "ณรงค์เดช",
            //   },
            //   {
            //     id: "i2",
            //     prefix: "รศ.",
            //     name: "เอกชัย",
            //     surname: "ชัยประเสริฐสิทธิ",
            //   },
            //   {
            //     id: "i3",
            //     prefix: "รศ.",
            //     name: "นิพัทธ์",
            //     surname: "จิตรประสงค์",
            //   },
            //   {
            //     id: "i4",
            //     prefix: "ศ.",
            //     name: "เสนาะ",
            //     surname: "ติเยาว์",
            //   },
            //   {
            //     id: "i5",
            //     prefix: "รศ.",
            //     name: "สุนี",
            //     surname: "ประจวบเหมาะ",
            //   },
            //   {
            //     id: "i6",
            //     prefix: "รศ.",
            //     name: "สุมนา",
            //     surname: "อยู่โพธิ์",
            //   },
            //   {
            //     id: "i7",
            //     prefix: "รศ.",
            //     name: "ประยูร",
            //     surname: "บุญประเสริฐ",
            //   },
            // ],
        };
    },
    getters,
    actions,
    mutations,
};