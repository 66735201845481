export default {
    computed: {
        canRegister() {
            const inTime = !!this.studentRule &&
                !!this.studentRule.rule &&
                !!this.studentRule.rule.register;
            if (inTime) {
                return this.checkYearAndSchool;
            } else {
                return false;
            }
        },
        canCancel() {
            const inTime = !!this.studentRule &&
                !!this.studentRule.rule &&
                !!this.studentRule.rule.cancel;
            if (inTime) {
                return this.checkYearAndSchool;
            } else {
                return false;
            }
        },
        canLogin() {
            const inTime = !!this.studentRule &&
                !!this.studentRule.rule &&
                !!this.studentRule.rule.login;
            if (inTime) {
                return this.checkYearAndSchool;
            } else {
                return false;
            }
        },
        studentRule() {
            return this.$store.getters["currentScheduleRuleStudent"];
        },
        checkYearAndSchool() {
            // check if ... is student in TBS
            const student = this.$store.getters["userInfo"];
            if (!student) {
                return false;
            }
            const stdYear = student.stdCode.substring(0, 2);
            const stdSchool = student.stdCode.substring(2, 4);
            if (stdSchool === "02") {
                const allowArray = this.studentRule.allowStdInternal || [];
                return allowArray.some((item) => item === stdYear);
            } else {
                const allowArray = this.studentRule.allowStdExternal || [];
                return allowArray.some((item) => item === stdYear);
            }
        },
        canAddQuotaPetition() {
            const inTime = !!this.studentRule &&
                !!this.studentRule.rule &&
                !!this.studentRule.rule.petition;
            if (inTime) {
                return this.checkYearAndSchool;
            } else {
                return false;
            }
        },
    },
    methods: {},
};