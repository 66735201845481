<template>
  <div class="py-10 text-center">
    <h2 class="pb-5">ประมวลผลโควตา</h2>
    <h3 class="text-gray-500">
      การแจ้งความจำนง {{ program.name }} ครั้งที่
      {{ qRoundToText(schedule.qRound) }} เทอม
      {{ schedule.semester }} ปีการศึกษา {{ schedule.year }}
    </h3>
  </div>

  <router-view></router-view>
</template>

<script>
import TextConverterMixin from "../../mixins/textConverter.js";
export default {
  mixins: [TextConverterMixin], //qRoundToText()
  computed: {
    // Quota Data of User
    schedule() {
      return this.$store.getters["schedule"];
    },
    program() {
      return this.$store.getters["programs/getUserProgram"];
    },
  },
};
</script>
