import firebase from "firebase/app";
import "firebase/firestore";
import { db, auth } from "../../../js/firebaseDb.js";
import { isLogVisible } from "../../../js/debug.js";
import { v4 as uuidv4 } from "uuid";

export default {
    // TOAST
    updateToast(context, submittedData) {
        const payload = {
            mode: submittedData.mode,
            show: submittedData.show,
            message: submittedData.message,
        };

        context.commit("setToast", payload);
    },
    clearToast(context) {
        const payload = {
            mode: null,
            show: false,
            message: null,
        };

        context.commit("setToast", payload);
    },

    updateUserProgram(context, submittedData) {
        // const payload = {
        //   userProgram: submittedData.userProgram,
        // };

        localStorage.setItem("userProgram", submittedData.userProgram);

        context.commit("setUserProgram", submittedData.userProgram);
    },
    tryUserProgram(context) {
        const uProgram = localStorage.getItem("userProgram");

        if (uProgram) {
            context.commit("setUserProgram", uProgram);
        }
    },

    // GENERAL
    // Not use function
    // tryUpdateRole(context) {
    //     const uRole = localStorage.getItem("userRole");

    //     if (uRole) {
    //         context.commit("setUserRole", uRole);
    //     }
    // },
    // updateRole(context, submittedData) {
    //     console.log(submittedData);
    //     localStorage.setItem("userRole", submittedData.userRole);
    //     context.commit("setUserRole", submittedData.userRole);
    // },

    // setUserId(context, submittedData) {
    //     context.commit("setUserId", submittedData.userId);
    // },

    // async setUserInfo(context) {
    //     // === Data that need to be sent === //
    //     const uId = auth.currentUser.uid;
    //     const userRef = db.collection("students").doc(uId);
    //     const response = await userRef.get().catch((error) => {
    //         console.error("Error getting documents: ", error);
    //     });

    //     if (isLogVisible()) {
    //         console.log(response);
    //     }
    //     if (response.empty) {
    //         const error = new Error("No such document!");
    //         console.error(error);
    //         throw error;
    //     }

    //     const uData = {
    //         id: response.id,
    //         nameTitle: response.data().nameTitle,
    //         firstName: response.data().firstName,
    //         lastName: response.data().lastName,
    //         birthday: response.data().birthday,
    //         gender: response.data().gender,
    //         stdCode: response.data().stdCode,
    //         tel: response.data().tel,
    //         conAddress: response.data().conAddress,
    //         homAddress: response.data().homAddress,
    //         major: response.data().major,
    //         major2: response.data().major2 || null,
    //         program: response.data().program,
    //         email: response.data().email,
    //     };

    //     console.log(uData);

    //     context.commit("setUserInfo", uData);
    // },

    async updateStudentInfo(context, payload) {
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        const uId = payload.id;
        const newUserInfo = {
            nameTitle: payload.nameTitle,
            firstName: payload.firstName,
            lastName: payload.lastName,
            gender: payload.gender,
            tel: payload.tel,
            conAddress: payload.conAddress,
            homAddress: payload.homAddress,
            // 12/21
            stdIdCard: payload.stdIdCard,
            birthday: payload.birthday,
            stdHighSchool: payload.stdHighSchool,
            stdHighSchoolSchool: payload.stdHighSchoolSchool,
            stdHighSchoolGrade: payload.stdHighSchoolGrade,
            emergTel: payload.emergTel,
            emergName: payload.emergName,
            emergRelation: payload.emergRelation,
            updateInfoTime: timestamp,
        };

        // === Data that need to be sent === //
        const studentRef = db.collection(`students`).doc(uId);

        // Set all field of the Quota
        await studentRef.update(newUserInfo).catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
        });

        await context.dispatch("setUser", { mode: "student" }).catch((error) => {
            console.error("Error setUser", error);
        });
    },

    async updateUserInfo(context, submittedData) {
        const mode = submittedData.mode;

        if (mode === "student") {
            context.dispatch("updateStudentInfo", submittedData);
        } else {
            throw Error(`updateUserInfo Error: not support ${mode} mode`);
        }
    },

    async setUser(context, payload) {
        const uId = auth.currentUser.uid;
        const mode = payload.mode;
        if (isLogVisible()) {
            console.log("start .. setUser");
            console.log(`uid : ${uId}`);
            console.log(`mode : ${mode}`);
        }

        let userId = null;
        let userRole = null;
        let userInfo = null;
        if (mode === "student") {
            // student info
            const studentRef = db.collection("students").doc(uId);
            const response = await studentRef.get().catch((error) => {
                console.error("Error getting documents: ", error);
            });

            // user info
            const userRef = db.collection("users").doc(uId);
            const userDoc = await userRef.get().catch((error) => {
                console.error("Error getting documents: ", error);
            });
            const consentId = userDoc.data().consentId || null;

            // consent info
            if (consentId) {
                await context.dispatch("setUserConsent", consentId);
            }

            // save data to Vuex
            const doc = {
                ...response.data(),
                id: response.id,
            };

            userId = doc.stdCode;
            userRole = "student";
            userInfo = doc;

            // set StatusChange
            await context.dispatch("setStudentMajorChange", { stdCode: userId });
            await context.dispatch("setStudentStatusChange", {
                stdCode: userId,
            });

            // set userProgram
            context.commit("setUserProgram", doc.program);
        } else if (mode === "admin") {
            // staff info
            const staffRef = db.collection("staffs").doc(uId);
            const staffDoc = await staffRef.get().catch((error) => {
                console.error("Error getting documents: ", error);
            });
            // user info
            const userRef = db.collection("users").doc(uId);
            const userDoc = await userRef.get().catch((error) => {
                console.error("Error getting documents: ", error);
            });
            const consentId = userDoc.data().consentId || null;

            // consent info
            if (consentId) {
                context.dispatch("setUserConsent", consentId);
            }

            const doc = {
                ...staffDoc.data(),
                id: staffDoc.id,
                role: userDoc.data().role,
            };

            userId = doc.username;
            userRole = "admin";
            userInfo = doc;

            //
        } else {
            throw Error(`setUser Error: mode ${mode} not support`);
        }

        if (isLogVisible()) {
            console.log("setting user Data");
            console.log(`UserId: ${userId}`);
            console.log(`userRole: ${userRole}`);
            console.log(`userInfo: ${userInfo}`);
        }

        // set user Info based on user role
        context.commit("setUserId", userId);
        context.commit("setUserRole", userRole);
        context.commit("setUserInfo", userInfo);
    },
    clearUser(context) {
        context.commit("setUserId", null);
        context.commit("setUserRole", null);
        context.commit("setUserInfo", null);
        // context.commit("setUserProgram", null);
    },

    async setLoginId(context, uuid) {
        const loginId = uuidv4();

        // update login Id in Firebase
        const UserRef = db.collection("users").doc(uuid);
        await UserRef.update({ loginId: loginId }).catch((error) => {
            console.error("Error updating document: ", error);
        });

        // save login Id to localStorage
        localStorage.setItem("loginId", loginId);

        // update login Id in Vuex
        context.commit("setLoginId", loginId);
    },

    trySetLoginId(context) {
        const loginId = localStorage.getItem("loginId");

        if (loginId) {
            // update login Id in Vuex
            context.commit("setLoginId", loginId);
        }
    },
    async checkLoginId(context) {
        const uuid = auth.currentUser.uid;

        // get LoginId from firebase
        // update login Id in Firebase
        const UserRef = db.collection("users").doc(uuid);
        const userDoc = await UserRef.get().catch((error) => {
            console.error("Error updating document: ", error);
        });

        const userData = userDoc.data();
        userData["id"] = userDoc.id;

        // check if ... LoginId compare to LoginId In Vuex
        const localLoginId = context.getters.loginId;

        if (isLogVisible()) {
            console.log(localLoginId);
            console.log(userData.loginId);
            console.log(localLoginId === userData.loginId);
        }

        return localLoginId === userData.loginId;
    },
    async setUserConsent(context, consentId) {
        const consentRef = db.collection("consents_member").doc(consentId);
        const consentDoc = await consentRef.get().catch((error) => {
            console.error("Error getting documents: ", error);
        });
        let userConsent = null;
        if (consentDoc.exists) {
            userConsent = {
                ...consentDoc.data(),
                id: consentDoc.id,
            };
        }
        context.commit("setConsentInfo", userConsent);
    },
    async addUserConsent(context, payload) {
        // sent data to server
        const uuid = payload.uuid;
        const username = payload.username;
        const consentRef = db.collection("consents_member").doc();
        const userRef = db.collection("users").doc(uuid);
        const currentDate = new Date();

        // update consent data to firebase
        const batch = db.batch();

        const consentPayload = {
            time: currentDate,
            username: username,
            uuid: uuid,
        };
        batch.set(consentRef, consentPayload);

        batch.update(userRef, {
            consentId: consentRef.id,
        });

        // Commit the batch
        await batch.commit();

        // update state
        context.dispatch("setUserConsent", consentRef.id);
    },

    async setStudentMajorChange(context, payload) {
        const stdCode = payload.stdCode;
        const majorChangeRef = db.collection("student_major_change");
        const majorChangeDocs = await majorChangeRef
            .where("stdCode", "==", stdCode)
            .get()
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });

        const majorChangeArr = majorChangeDocs.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
                time: doc.data().time.toDate(),
            };
        });

        context.commit("setMajorChange", majorChangeArr);
    },

    async setStudentStatusChange(context, payload) {
        const stdCode = payload.stdCode;
        const statusChangeRef = db.collection("student_status_change");
        const statusChangeDocs = await statusChangeRef
            .where("stdCode", "==", stdCode)
            .get()
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });
        const statusChangeArr = statusChangeDocs.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
                time: doc.data().time.toDate(),
            };
        });

        context.commit("setStatusChange", statusChangeArr);
    },
};