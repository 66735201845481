<template>
  <StdInfoForm mode="student"></StdInfoForm>
</template>

<script>
// import { auth } from "../../js/firebaseDb.js";
import StdInfoForm from "../../components/Student/StdInfoForm.vue";
export default {
  components: { StdInfoForm },
};
</script>
