export default {
  addSubject(state, subjectData) {
    state.subjects.push(subjectData);
  },
  setSubject(state, payload) {
    state.subjects = payload;
  },
  clearSubject(state) {
    state.subjects = [];
  },
  addYear(state, payload) {
    state.years.push(payload);
  },
  setYear(state, payload) {
    state.years = payload;
  },
};
