import { isLogVisible } from "../../../js/debug.js";
import { db } from "../../../js/firebaseDb";

export default {
    //----- YEAR -----//
    async addYear(context, payload) {
        if (isLogVisible()) {
            console.log("add year called");
        }

        const program = context.rootGetters.userProgram;
        const YearData = {
            name: payload.year,
            effectEst: payload.effectEst,
        };

        const yearDoc = db.collection(`years_${program}`);
        const response = await yearDoc.add(YearData).catch((error) => {
            console.error("Error adding document: ", error);
        });
        if (response.empty) {
            const error = new Error("Can't send data to server");
            if (isLogVisible()) {
                console.error(error);
            }
        }

        context.commit("addYear", {
            id: response.id,
            ...YearData,
        });
    },

    async setYear(context) {
        if (isLogVisible()) {
            console.log("setYear in vuex start");
        }

        const selectedProgram = context.rootGetters.userProgram;

        const yearRef = db.collection(`years_${selectedProgram}`);
        const response = await yearRef
            .orderBy("name")
            .get()
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });
        if (isLogVisible()) {
            console.log(response);
        }
        if (response.empty) {
            const error = new Error("No such document!");
            if (isLogVisible()) {
                console.error(error);
            }
            throw error;
        }

        const years = [];
        response.forEach((doc) => {
            const year = {
                id: doc.id,
                name: parseInt(doc.data().name),
                effectEst: parseInt(doc.data().effectEst),
            };
            years.push(year);
        });

        if (isLogVisible()) {
            console.log(years);
        }

        context.commit("setYear", years);
    },

    async deleteYear(context, payload) {
        const year = parseInt(payload.year);
        const program = context.rootGetters.userProgram;
        const theYear = context.getters.getYearByYear(year);

        // https://firebase.google.com/docs/firestore/manage-data/delete-data#web-v8
        const yearRef = db.collection(`years_${program}`);
        yearRef
            .doc(theYear.id)
            .delete()
            .catch((error) => {
                console.error("Error removing document: ", error);
            });

        // update data in VueX
        const years = context.getters.years;
        const selectedYear = context.getters.getYearByYear(year);
        var index = years.indexOf(selectedYear);

        if (index !== -1) {
            years.splice(index, 1);
            if (isLogVisible()) {
                console.log("removed" + index);
            }
        }

        if (isLogVisible()) {
            console.log("year " + year + " was deleted ");
        }

        context.commit("setYear", years);
    },
};