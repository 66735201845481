import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";

export default {
    state() {
        return {
            // user Data
            userId: "",
            userRole: "", // admin,student
            userInfo: null,
            userProgram: "",
            userState: null, // read,edit
            consentInfo: null,

            // etc
            loginId: null,
            toast: { mode: null, show: false, msg: null },

            // student
            statusChange: [],
            majorChange: [],
        };
    },
    getters,
    mutations,
    actions,
};