<template>
  <div class="py-10 text-center">
    <h2 class="pb-5">ดูข้อมูล/แก้ไข โควตา</h2>
    <h3 class="text-gray-500">
      การแจ้งความจำนง {{ program.name }} ครั้งที่
      {{ qRoundToText(schedule.qRound) }} เทอม
      {{ schedule.semester }} ปีการศึกษา {{ schedule.year }}
    </h3>
  </div>

  <div class="px-2">
    <!-- <h3>รังสิต</h3> -->

    <div>
      <!-- <div class="text-xl font-semi-bold py-4 text-center" v-if="!hasSubjects">
        ไม่มีข้อมูลวิชาในหลักสูตร โปรด<router-link
          :to="{ name: 'subjects-list' }"
          class="text-primary-400"
        >
          เพิ่มวิชา </router-link
        >ใหม่
      </div> -->
      <div class="text-xl font-semi-bold py-4 text-center" v-if="!hasQuota">
        ไม่มีข้อมูลโควตาในฐานข้อมูล โปรด<router-link
          :to="{ name: 'quota-add' }"
          class="text-primary-400"
        >
          เพิ่มโควตา </router-link
        >ใหม่
      </div>

      <div v-else>
        <div class="flex justify-end pb-2">
          <base-button
            class="flex space-x-2 "
            mode="outline"
            @click="downloadQuotaList()"
            ><svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              ></path>
            </svg>
            <span>Excel</span>
          </base-button>
          <base-button
            class="flex space-x-2 "
            mode="outline"
            @click="downloadRemainQty()"
            ><svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              ></path>
            </svg>
            <span>คงเหลือ</span>
          </base-button>
        </div>
        <table style="table-fixed ">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />

            <col span="3" class="bg-blue-50" />
            <col span="3" class="" />
            <col span="3" class="bg-blue-50" />
            <col span="3" class="" />
            <col span="3" class="bg-blue-50" />
            <col />
            <col class="bg-blue-50" />
            <col />
            <col class="bg-blue-50" />
          </colgroup>
          <thead>
            <tr>
              <th colspan="4" class="text-center px-1 w-1/12">
                <h4>รหัสวิชา/ชื่อวิชา</h4>
              </th>
              <th rowspan="2" class="text-center px-1 w-0.5/12">
                <h4>Sec</h4>
              </th>
              <th rowspan="2" class="text-center px-1 w-0.5/12">
                <h4>Grp</h4>
              </th>
              <th colspan="3" class="text-center px-1 w-1/12">
                <h4>ครั้งที่ 1</h4>
              </th>
              <th colspan="3" class="text-center px-1 w-1/12">
                <h4>ครั้งที่ 2</h4>
              </th>
              <th colspan="3" class="text-center px-1 w-1/12">
                <h4>ครั้งที่ 3</h4>
              </th>
              <th colspan="3" class="text-center px-1 w-1/12">
                <h4>ครั้งที่ 4.1</h4>
              </th>
              <th colspan="3" class="text-center px-1 w-1/12">
                <h4>ครั้งที่ 4.2</h4>
              </th>
              <th rowspan="2" class="text-center px-1 w-0.5/12">
                <h4>
                  รวม<br />ได้<br />
                  1+2
                </h4>
              </th>
              <th rowspan="2" class="text-center px-1 w-0.5/12">
                <h4>ลง<br />จริง</h4>
              </th>
              <th rowspan="2" class="text-center px-1 w-0.5/12">
                <h4>ลง<br />จริง<br />+3</h4>
              </th>
              <th rowspan="2" class="text-center px-1 w-0.5/12">
                <h4>ลง<br />จริง<br />+3<br />+4.1<br />+4.2</h4>
              </th>
              <th rowspan="2" class="text-center px-1 w-2/12">
                <h4>อาจารย์</h4>
              </th>
              <th rowspan="2" class="text-center px-1 w-2/12">
                <h4>หมายเหตุ</h4>
              </th>
              <th rowspan="2" class="text-center px-1 w-0.5/12">
                <h4>เงื่อน<br />ไข</h4>
              </th>
              <th rowspan="2" class="text-center px-1 w-0.5/12">
                <h4>แก้ไข</h4>
              </th>
            </tr>
            <tr class="text-center">
              <th v-for="(theYear, index) in last4Year" :key="index">
                {{ theYear.name }}
              </th>
              <th>เปิด</th>
              <th>ขอ</th>
              <th>ได้</th>
              <th>เปิด</th>
              <th>ขอ</th>
              <th>ได้</th>
              <th>เปิด</th>
              <th>ขอ</th>
              <th>ได้</th>
              <th>เปิด</th>
              <th>ขอ</th>
              <th>ได้</th>
              <th>เปิด</th>
              <th>ขอ</th>
              <th>ได้</th>
            </tr>
          </thead>

          <!-- send Major instead of Offlink -->
          <quota-item :yearNo="yearNo" ref="quotaItem"></quota-item>
        </table>
      </div>
    </div>
  </div>
  <div v-if="logVisible">
    <div>{{ majors }}</div>
    <div>{{ quota }}</div>
  </div>
  <!-- <base-button @click="downloadQuotaList()">Download</base-button>
  <base-button @click="getQuotaList('7maoy7o2pkrkt476pb1xrn80fln5a')"
    >ค้นหา</base-button
  >
  <hr />
  {{ majorOffLinks }}
  <hr />
  {{ last4Year }} -->
</template>

<script>
import QuotaItem from "../../components/Quota/QuotaItem.vue";
import { isLogVisible } from "../../js/debug.js";
import LoadDataMixin from "../../mixins/loadData.js";
import TextConverterMixin from "../../mixins/textConverter.js";
import XLSX from "xlsx";
export default {
  mixins: [LoadDataMixin, TextConverterMixin], // loadSection(),qRoundToText()
  components: { QuotaItem },
  data() {
    return {
      yearNo: 4,
    };
  },

  computed: {
    majors() {
      return this.$store.getters["majors/majors"];
    },
    // get Meta Data of Quota
    schedule() {
      return this.$store.getters["schedule"];
    },
    program() {
      return this.$store.getters["programs/getUserProgram"];
    },
    last4Year() {
      return this.$store.getters["years/getLast4Years"];
    },

    // get Quota Data
    quota() {
      return this.$store.getters["quota/quota"];
    },
    hasQuota() {
      return this.$store.getters["quota/hasQuota"];
    },
    logVisible() {
      return isLogVisible();
    },
    editSuccess() {
      if (this.$refs.quotaItem !== undefined) {
        return this.$refs.quotaItem.editSuccess;
      } else {
        return false;
      }
    },

    // export excel
    majorOffLinks() {
      return this.$store.getters["quota/getMajorOffLinks"];
    },
  },
  methods: {
    // export excel
    getQuotaList(offLink) {
      const quotaList = this.$store.getters["quota/getQuotaByOffLink"](offLink);
      return quotaList;
    },
    sumNumber(n1, n2) {
      const num1 = parseInt(n1);
      const num2 = parseInt(n2);

      if (num1 >= 0 && num2 >= 0) {
        return num1 + num2;
      } else if (num1 >= 0) {
        return num1;
      } else if (num2 >= 0) {
        return num2;
      } else {
        return "-";
      }
    },
    subNumber(n1, n2) {
      const num1 = parseInt(n1);
      const num2 = parseInt(n2);
      const result = num1 - num2;
      return Math.max(result, 0);
    },
    getInsText(instData) {
      let theText = "";
      for (const [i, theInst] of instData.entries()) {
        theText = theText.concat(theInst.name);
        if (i + 1 < instData.length) {
          theText = theText.concat(", ");
        }
      }
      return theText;
    },
    async downloadRemainQty() {
      this.isLoading = true;

      const wb = XLSX.utils.book_new();

      // subject code
      // subject in offlink data
      for (const theSubject of this.majorOffLinks) {
        // init worksheet
        const dataWS = XLSX.utils.json_to_sheet([{}]);

        // hearder 1
        XLSX.utils.sheet_add_json(
          dataWS,
          [
            {
              A: "",
              B: "รหัสวิชา/ชื่อวิชา",
              C: "",
              D: "",
              E: "",
              F: "",
              G: "",
              H: "ครั้งที่ 1",
              I: "",
              K: "ครั้งที่ 2",
              J: "",
              L: "",

              N: "ครั้งที่ 3",
              M: "",
              O: "",

              N1: "ครั้งที่ 4.1",
              M1: "",
              O1: "",

              N2: "ครั้งที่ 4.2",
              M2: "",
              O2: "",
            },
          ],
          {
            skipHeader: true,
            origin: `B1`,
          }
        );

        // last 4 year
        XLSX.utils.sheet_add_json(
          dataWS,
          [
            {
              A: this.last4Year[0].name,
              B: this.last4Year[1].name,
              C: this.last4Year[2].name,
              D: this.last4Year[3].name,
            },
          ],
          {
            skipHeader: true,
            origin: `A2`,
          }
        );

        // hearder 2
        XLSX.utils.sheet_add_json(
          dataWS,
          [
            {
              A: "Sec",
              B: "Grp",
              O: "อาจารย์",
              C: "จำนวนรับสูงสุด",
              D: "ขอ",
              E: "ได้",
              F: "เปิด",
              G: "ขอ",
              H: "ได้",
              I: "เปิด",
              J: "ขอ",
              K: "ได้",
              I2: "เปิด",
              J2: "ขอ",
              K2: "ได้",
              I3: "เปิด",
              J3: "ขอ",
              K3: "ได้",
              L: "รวมได้1+2",
              M: "ลงจริง",
              N: "ลงจริง+3+4.1+4.2",
              P: "ที่ว่าง",
              Q: "หมายเหตุ",
            },
          ],
          {
            skipHeader: true,
            origin: `E2`,
          }
        );

        let startRow = 3;
        for (const theOffLinkId of theSubject["offLinks"]) {
          const subjectLists = this.getQuotaList(theOffLinkId);
          XLSX.utils.sheet_add_json(
            dataWS,
            [
              {
                A: subjectLists[0][`sub${this.last4Year[0].name}`]
                  ? subjectLists[0][`sub${this.last4Year[0].name}`]["code"]
                  : "-",
                B: subjectLists[0][`sub${this.last4Year[1].name}`]
                  ? subjectLists[0][`sub${this.last4Year[1].name}`]["code"]
                  : "-",
                C: subjectLists[0][`sub${this.last4Year[2].name}`]
                  ? subjectLists[0][`sub${this.last4Year[2].name}`]["code"]
                  : "-",
                D: subjectLists[0][`sub${this.last4Year[3].name}`]
                  ? subjectLists[0][`sub${this.last4Year[3].name}`]["code"]
                  : "-",
              },
            ],
            {
              skipHeader: true,
              origin: `A${startRow}`,
            }
          );

          // // subject name
          // XLSX.utils.sheet_add_json(
          //   dataWS,
          //   [
          //     {
          //       A: subjectLists[0][`sub${this.last4Year[3].name}`]
          //         ? subjectLists[0][`sub${this.last4Year[3].name}`]["name"]
          //         : subjectLists[0][`sub${this.last4Year[2].name}`]
          //         ? subjectLists[0][`sub${this.last4Year[2].name}`]["name"]
          //         : subjectLists[0][`sub${this.last4Year[2].name}`]
          //         ? subjectLists[0][`sub${this.last4Year[1].name}`]["name"]
          //         : subjectLists[0][`sub${this.last4Year[1].name}`]
          //         ? subjectLists[0][`sub${this.last4Year[0].name}`]["name"]
          //         : "-",
          //     },
          //   ],
          //   {
          //     skipHeader: true,
          //     origin: `A${startRow + 1}`,
          //   }
          // );

          // sec Detail
          const excelQuotaList = [];
          for (const quota of subjectLists) {
            const payload = {
              A: quota.secId,
              B: quota.group,
              Q5_inst:
                "instructors" in quota
                  ? this.getInsText(quota.instructors)
                  : quota.instName,
              Q1_1:
                quota.q1Offer ||
                quota.q2Offer ||
                quota.q3Offer ||
                quota.q4Offer ||
                quota.q5Offer ||
                0,
              Q1_2: quota.q1Request >= 0 ? quota.q1Request : "-",
              Q1_3: quota.q1Accept >= 0 ? quota.q1Accept : "-",
              Q2_1: quota.q2Offer >= 0 ? quota.q2Offer : "-",
              Q2_2: quota.q2Request >= 0 ? quota.q2Request : "-",
              Q2_3: quota.q2Accept >= 0 ? quota.q2Accept : "-",
              Q3_1: quota.q3Offer >= 0 ? quota.q3Offer : "-",
              Q3_2: quota.q3Request >= 0 ? quota.q3Request : "-",
              Q3_3: quota.q3Accept >= 0 ? quota.q3Accept : "-",
              Q4_1: quota.q3Offer >= 0 ? quota.q4Offer : "-",
              Q4_2: quota.q3Request >= 0 ? quota.q4Request : "-",
              Q4_3: quota.q3Accept >= 0 ? quota.q4Accept : "-",
              Q5_1: quota.q3Offer >= 0 ? quota.q5Offer : "-",
              Q5_2: quota.q3Request >= 0 ? quota.q5Request : "-",
              Q5_3: quota.q3Accept >= 0 ? quota.q5Accept : "-",
              Q4_12: this.sumNumber(quota.q1Accept, quota.q2Accept),
              Q4_reg: quota.regNo >= 0 ? quota.regNo : "-",
              Q4_reg345: this.sumNumber(
                quota.q5Accept || 0,
                this.sumNumber(
                  quota.q4Accept || 0,
                  this.sumNumber(quota.regNo || 0, quota.q3Accept || 0)
                )
              ),
              avail: this.subNumber(
                quota.q1Offer ||
                  quota.q2Offer ||
                  quota.q3Offer ||
                  quota.q4Offer ||
                  quota.q5Offer ||
                  0,
                this.sumNumber(
                  quota.q5Accept || 0,
                  this.sumNumber(
                    quota.q4Accept || 0,
                    this.sumNumber(quota.regNo || 0, quota.q3Accept || 0)
                  )
                )
              ),
              remark: quota.remark ? quota.remark : "-",
            };
            excelQuotaList.push(payload);
          }
          XLSX.utils.sheet_add_json(dataWS, excelQuotaList, {
            skipHeader: true,
            origin: `E${startRow}`,
          });
          startRow += Math.max(3, excelQuotaList.length + 1);
        }
        XLSX.utils.book_append_sheet(
          wb,
          dataWS,
          `${theSubject.mName.substring(0, 20)}`,
          true
        );
      }

      XLSX.writeFile(wb, `MaximumQuota.xlsx`);
      this.isLoading = false;
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "ดาวน์โหลดไฟล์สำเร็จ",
      });
    },
    async downloadQuotaList() {
      this.isLoading = true;

      const wb = XLSX.utils.book_new();

      // subject code
      // subject in offlink data
      for (const theSubject of this.majorOffLinks) {
        // init worksheet
        const dataWS = XLSX.utils.json_to_sheet([{}]);

        // hearder 1
        XLSX.utils.sheet_add_json(
          dataWS,
          [
            {
              A: "",
              B: "รหัสวิชา/ชื่อวิชา",
              C: "",
              D: "",
              E: "",
              F: "",
              G: "",
              H: "ครั้งที่ 1",
              I: "",
              J: "",
              K: "ครั้งที่ 2",
              L: "",
              M: "",
              N: "ครั้งที่ 3",
              O: "",
              P: "",
              Q: "ครั้งที่ 4.1",
              R: "",
              S: "",
              T: "ครั้งที่ 4.2",
            },
          ],
          {
            skipHeader: true,
            origin: `B1`,
          }
        );

        // last 4 year
        XLSX.utils.sheet_add_json(
          dataWS,
          [
            {
              A: this.last4Year[0].name,
              B: this.last4Year[1].name,
              C: this.last4Year[2].name,
              D: this.last4Year[3].name,
            },
          ],
          {
            skipHeader: true,
            origin: `A2`,
          }
        );

        // hearder 2
        XLSX.utils.sheet_add_json(
          dataWS,
          [
            {
              A: "Sec",
              B: "Grp",
              C: "เปิด",
              D: "ขอ",
              E: "ได้",
              F: "เปิด",
              G: "ขอ",
              H: "ได้",
              I: "เปิด",
              J: "ขอ",
              K: "ได้",
              K1: "เปิด",
              K2: "ขอ",
              K3: "ได้",
              K4: "เปิด",
              K5: "ขอ",
              K6: "ได้",
              L: "รวมได้1+2",
              M: "ลงจริง",
              N: "ลงจริง+3",
              N1: "ลงจริง+3+4.1+4.2",
              O: "อาจารย์",
              P: "หมายเหตุ",
            },
          ],
          {
            skipHeader: true,
            origin: `E2`,
          }
        );

        let startRow = 3;
        for (const theOffLinkId of theSubject["offLinks"]) {
          const subjectLists = this.getQuotaList(theOffLinkId);
          XLSX.utils.sheet_add_json(
            dataWS,
            [
              {
                A: subjectLists[0][`sub${this.last4Year[0].name}`]
                  ? subjectLists[0][`sub${this.last4Year[0].name}`]["code"]
                  : "-",
                B: subjectLists[0][`sub${this.last4Year[1].name}`]
                  ? subjectLists[0][`sub${this.last4Year[1].name}`]["code"]
                  : "-",
                C: subjectLists[0][`sub${this.last4Year[2].name}`]
                  ? subjectLists[0][`sub${this.last4Year[2].name}`]["code"]
                  : "-",
                D: subjectLists[0][`sub${this.last4Year[3].name}`]
                  ? subjectLists[0][`sub${this.last4Year[3].name}`]["code"]
                  : "-",
              },
            ],
            {
              skipHeader: true,
              origin: `A${startRow}`,
            }
          );

          // subject name
          XLSX.utils.sheet_add_json(
            dataWS,
            [
              {
                A: subjectLists[0][`sub${this.last4Year[3].name}`]
                  ? subjectLists[0][`sub${this.last4Year[3].name}`]["name"]
                  : subjectLists[0][`sub${this.last4Year[2].name}`]
                  ? subjectLists[0][`sub${this.last4Year[2].name}`]["name"]
                  : subjectLists[0][`sub${this.last4Year[2].name}`]
                  ? subjectLists[0][`sub${this.last4Year[1].name}`]["name"]
                  : subjectLists[0][`sub${this.last4Year[1].name}`]
                  ? subjectLists[0][`sub${this.last4Year[0].name}`]["name"]
                  : "-",
              },
            ],
            {
              skipHeader: true,
              origin: `A${startRow + 1}`,
            }
          );

          // sec Detail
          const excelQuotaList = [];
          for (const quota of subjectLists) {
            const payload = {
              A: quota.secId,
              B: quota.group,
              Q1_1: quota.q1Offer >= 0 ? quota.q1Offer : "-",
              Q1_2: quota.q1Request >= 0 ? quota.q1Request : "-",
              Q1_3: quota.q1Accept >= 0 ? quota.q1Accept : "-",
              Q2_1: quota.q2Offer >= 0 ? quota.q2Offer : "-",
              Q2_2: quota.q2Request >= 0 ? quota.q2Request : "-",
              Q2_3: quota.q2Accept >= 0 ? quota.q2Accept : "-",
              Q3_1: quota.q3Offer >= 0 ? quota.q3Offer : "-",
              Q3_2: quota.q3Request >= 0 ? quota.q3Request : "-",
              Q3_3: quota.q3Accept >= 0 ? quota.q3Accept : "-",
              Q4_1: quota.q4Offer >= 0 ? quota.q4Offer : "-",
              Q4_2: quota.q4Request >= 0 ? quota.q4Request : "-",
              Q4_3: quota.q4Accept >= 0 ? quota.q4Accept : "-",
              Q5_1: quota.q5Offer >= 0 ? quota.q5Offer : "-",
              Q5_2: quota.q5Request >= 0 ? quota.q5Request : "-",
              Q5_3: quota.q5Accept >= 0 ? quota.q5Accept : "-",
              Q4_12: this.sumNumber(quota.q1Accept, quota.q2Accept),
              Q4_reg: quota.regNo >= 0 ? quota.regNo : "-",
              Q4_reg3: this.sumNumber(quota.regNo, quota.q3Accept),
              Q4_reg345: this.sumNumber(
                this.sumNumber(
                  this.sumNumber(quota.regNo, quota.q3Accept),
                  quota.q4Accept
                ),
                quota.q5Accept
              ),
              Q5_inst:
                "instructors" in quota
                  ? this.getInsText(quota.instructors)
                  : quota.instName,
              Q6: quota.remark ? quota.remark : "",
            };
            excelQuotaList.push(payload);
          }
          XLSX.utils.sheet_add_json(dataWS, excelQuotaList, {
            skipHeader: true,
            origin: `E${startRow}`,
          });
          startRow += Math.max(3, excelQuotaList.length + 1);
        }
        XLSX.utils.book_append_sheet(
          wb,
          dataWS,
          `${theSubject.mName.substring(0, 20)}`,
          true
        );
      }

      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, dataWS);
      // XLSX.utils.book_append_sheet(wb, dataWS, "Sheet2", true);
      // XLSX.utils.book_append_sheet(wb, dataWS, "Sheet3", true);
      XLSX.writeFile(wb, `QuotaList.xlsx`);
      this.isLoading = false;
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "ดาวน์โหลดไฟล์สำเร็จ",
      });
    },
  },
};
</script>

<style scoped>
td {
  /* width: 150px;
  text-align: center;
  border: 1px solid black; */
  @apply py-2;
}

th {
  /* width: 150px;
  text-align: center;
  border: 1px solid black; */
  @apply py-2 border-2 border-blue-200;
}
</style>
