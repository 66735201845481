<template>
  <div>
    <div
      class="text-base md:text-xl font-semibold  pb-20 text-center"
      v-if="!reqQuota || reqQuota.length === 0"
    >
      ไม่มีข้อมูลโควตาที่รอพิจารณา
    </div>
    <div class="grid-table-main" v-else>
      <div class="text-center">
        <BaseBadge
          mode="success"
          :show="badgeVisible"
          message="บันทึกโควตาแล้ว รอการพิจารณาจากทางคณะฯ"
          @close="badgeVisible = false"
        ></BaseBadge>
      </div>

      <div
        class="
              grid grid-cols-3
              md:grid-cols-12
              grid-table-header
            "
      >
        <div class="col-span-2 md:hidden w-full h-auto">วิชา</div>
        <div class="md:hidden w-full h-auto">สถานะ</div>
        <div class="hidden md:block w-full h-auto">รหัสวิชา</div>
        <div class="hidden md:block w-full h-auto md:col-span-2 ">
          ชื่อวิชา
        </div>
        <div class="hidden md:block w-full h-auto">Campus</div>
        <div class="hidden md:block w-full h-auto">Sec-Group</div>
        <div class="hidden md:block w-full h-auto md:col-span-2">วันเวลา</div>
        <div class="hidden md:block w-full h-auto md:col-span-2 ">
          ผู้บรรยาย
        </div>
        <div class="hidden md:block w-full h-auto">จำนวน ขอ/รับ</div>
        <div class="hidden md:block w-full h-auto">สถานะ</div>
        <div class="hidden md:block w-full h-auto">ยกเลิก</div>
      </div>

      <div class="grid-table-body">
        <div
          class="grid grid-cols-3 md:grid-cols-12 grid-table-row"
          v-for="q in reqQuota"
          :key="q.id"
        >
          <div class="col-span-2 md:col-span-10 md:grid md:grid-cols-10 pl-2">
            <div
              class="md:col-span-4 md:grid md:grid-cols-4 md:text-center md:my-auto"
            >
              <span class="font-bold">
                {{ q.subCode }}<span class="md:hidden"> - </span></span
              >
              <span class="md:col-span-2">{{ q.subName }} </span>
              <span class="">
                <span class=" md:hidden"> (</span>{{ q.campus }}</span
              ><span class=" md:hidden">)</span>
            </div>
            <div
              class="md:col-span-3 md:grid md:grid-cols-3 md:text-center md:my-auto"
            >
              <span>
                <span class="md:hidden">sec </span>{{ q.secId }}-{{
                  q.group
                }}</span
              >
              <span class="md:col-span-2">
                <span class=" md:hidden"> (</span>{{ q.quota.secTime
                }}<span class=" md:hidden">)</span>
              </span>
            </div>
            <div
              class="md:col-span-2  md:text-center md:my-auto"
              v-if="q.quota.instructors"
            >
              <span
                v-for="instructor in q.quota.instructors"
                :key="instructor.id"
              >
                {{ instructor.name }}
                <br />
              </span>
            </div>
            <div class="md:col-span-2  md:text-center md:my-auto" v-else>
              <span>{{ q.quota.instName }}</span>
            </div>
            <div class="md:text-center pt-3 md:pt-0 md:my-auto">
              <span>
                <span class="md:hidden">จำนวนขอ/รับ: </span>
                {{ q.quota[`q${schedule.qRound}Request`] || 0 }}/{{
                  q.quota[`q${schedule.qRound}Offer`]
                }}
              </span>
            </div>
          </div>
          <div
            class="col-span-1 md:col-span-2 md:grid md:grid-cols-2 text-left md:text-center my-auto "
          >
            <div
              class="text-blue-500 mx-auto flex justify-left md:justify-center md:my-auto pb-4 md:pb-0"
            >
              <div class="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-1 md:mr-0"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="font-semibold">
                  รอพิจารณา
                </span>
              </div>
            </div>

            <div class="">
              <span
                class=" p-3  hover:font-medium  "
                @click="showCancelDialog(q)"
                :class="{
                  'text-gray-500  cursor-not-allowed': !canCancel,
                  'text-red-500 hover:text-red-600 cursor-pointer': canCancel,
                }"
              >
                ยกเลิก
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <SpinnerDialog :show="loadingDialog"></SpinnerDialog>
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
  <confirm-modal
    :show="cancelDialog"
    title="ยืนยันยกเลิกโควตา"
    @close="
      () => {
        cancelDialog = false;
        cancelQuota = null;
      }
    "
    @confirm="ccReqQuota()"
  >
  </confirm-modal>
</template>

<script>
import BaseBadge from "../../components/UI/BaseBadge.vue";
import LoadDataMixin from "../../mixins/loadData.js";
import StudentRule from "../../mixins/studentRule.js";
import SpinnerDialog from "../../components/UI/SpinnerDialog.vue";
import ConfirmModal from "../../components/UI/ConfirmModal.vue";
export default {
  mixins: [LoadDataMixin, StudentRule], // canCancel()
  components: { SpinnerDialog, BaseBadge, ConfirmModal },
  data() {
    return {
      error: null,
      loadingDialog: false,
      badgeVisible: true,

      // cancel
      cancelDialog: false,
      cancelQuota: null,
    };
  },
  computed: {
    // reqQuota() {
    //   return this.$store.getters["quota/reqQuota"];
    // },
    schedule() {
      return this.$store.getters["schedule"];
    },
    reqQuota() {
      return this.$store.getters["quota/getReqQuotaByResult"]("pending");
    },
  },

  methods: {
    showCancelDialog(q) {
      this.cancelDialog = true;
      this.cancelQuota = q;
    },
    async ccReqQuota() {
      const reqQuota = this.cancelQuota;
      if (!this.canCancel) {
        this.error = "ระบบไม่อนุญาตให้ยกเลิกโควตาในขณะนี้";
        return;
      }
      this.loadingDialog = true;
      try {
        await this.$store.dispatch("quota/cancelReqQuota", reqQuota);
        await this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "ยกเลิกโควตาสำเร็จ",
        });
      } catch (e) {
        this.error = e.message;
      }
      this.loadingDialog = false;
    },
    closeError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
