<template>
  <div class=" flex justify-center items-center" v-if="loadingData">
    <base-spinner class="my-10"></base-spinner>
  </div>
  <div v-else class="font-kanit">
    <the-header></the-header>

    <!-- Main Section -->
    <main class=" text-gray-700" style="min-height:70vh">
      <!-- h-screen  -->
      <router-view></router-view>
      <toast
        :mode="theToast.mode"
        :show="theToast.show"
        :message="theToast.message"
        @close="clostToast"
      ></toast>
    </main>
    <!-- End content wrapper -->

    <consent-banner></consent-banner>

    <the-footer
      :buildVersion="currentBuild !== null ? currentBuild.build_version : ''"
    ></the-footer>
  </div>
  <div class="text-center flex justify-center">
    <BaseBadge
      :canClose="false"
      mode="warning"
      class="fixed top-8 font-kanit"
      :show="updateVisible"
      message="เว็บไซต์มีข้อมูลใหม่ที่ต้องอัพเดต"
      @close="null"
    >
      <template v-slot:button>
        <base-button mode="flat" class="flex" @click="reloadPage()">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            ></path>
          </svg>
          <span class="pl-1">
            อัพเดตทันที
          </span>
        </base-button>
      </template>
    </BaseBadge>
  </div>
  <toast
    :show="!!error"
    mode="error"
    @close="error = null"
    fixed
    :message="error"
  >
  </toast>
</template>

<script>
import BaseBadge from "./components/UI/BaseBadge.vue";
import { db, auth } from "./js/firebaseDb.js";
import TheHeader from "./components/layouts/TheHeader.vue";
import TheFooter from "./components/layouts/TheFooter.vue";
import ConsentBanner from "./components/UI/ConsentBanner.vue";
import LoadDataMixin from "./mixins/loadData.js";
import { isLogVisible } from "./js/debug.js";
export default {
  components: { TheHeader, TheFooter, ConsentBanner, BaseBadge },
  data() {
    return {
      loadingData: false,
      updateVisible: false,
      currentBuild: null,
      error: null,
    };
  },
  mixins: [LoadDataMixin], //loadSchedule()
  created() {
    this.loadData();
    this.setPageTitle();
    // this.checkStateData();
  },
  mounted() {
    auth.onAuthStateChanged((user) => {
      if (!user && this.$route.meta.requiresAuth) {
        this.$router.replace({ name: "login" });
      }
    });
  },
  computed: {
    logVisible() {
      return isLogVisible();
    },
    theToast() {
      return this.$store.getters["toast"];
    },
    // schedule() {
    //   return this.$store.getters.schedule;
    // },
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    clostToast() {
      this.$store.dispatch("clearToast");
      // if (this.theToast.mode !== "loading") {
      //   this.$store.dispatch("clearToast");
      // }
    },
    async loadData() {
      this.loadingData = true;
      this.runSetTime();
      await this.setMajor();
      await this.setStatus();
      await this.loadSchedule();
      // await this.setQuotaRoundParam();
      this.setBuildVersion();
      this.loadingData = false;
    },
    async setQuotaRoundParam() {
      try {
        await this.$store.dispatch("parameters/setQuotaRoundParam");
      } catch (e) {
        this.error = e.message;
      }
    },
    async setMajor() {
      try {
        await this.$store.dispatch("majors/setMajor");
      } catch (e) {
        this.error = e.message;
      }
    },
    async setStatus() {
      try {
        await this.$store.dispatch("students/setStatus");
      } catch (e) {
        this.error = e.message;
      }
    },
    setPageTitle() {
      document.title = "Quota System";
    },

    setBuildVersion() {
      // get data from DB
      const paraRef = db.collection("parameters").doc("build_version");
      paraRef.onSnapshot((querySnapshot) => {
        const hostBuildVersion = {
          ...querySnapshot.data(),
          id: querySnapshot.id,
        };
        if (this.currentBuild === null) {
          this.currentBuild = hostBuildVersion;
        } else {
          // check build version
          this.currentBuild = hostBuildVersion;
          if (
            hostBuildVersion.build_version == this.currentBuild.build_version
          ) {
            this.updateVisible = true;
          }
        }
      });
    },
    runSetTime() {
      this.$store.dispatch("runSetTime");
    },
  },
};
</script>

<style>
/* table */
th.table-control {
  @apply sticky top-0 px-2 py-3 text-gray-900 bg-gray-300;
}

table.table-control {
  @apply relative w-full border;
}

tbody.table-control {
  @apply divide-y bg-gray-100;
}

td.table-control {
  @apply px-2 py-4 text-center;
}
/* END table */

input:disabled {
  @apply text-gray-400;
}

.vc-select-arrow {
  display: none !important;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
