export default {
    setInstructor(state, payload) {
        state.instructors = payload;
    },
    addInstructor(state, payload) {
        state.instructors.push(payload);
    },
    updateInstructor(state, newInstructor) {
        const instructors = state.instructors;
        const selectedInstructor = instructors.find(
            (s) => s.id === newInstructor.id
        );
        const index = instructors.indexOf(selectedInstructor);

        if (index !== -1) {
            instructors.splice(index, 1, newInstructor);
        }
    },
    deleteInstructor(state, payload) {
        const instructors = state.instructors;
        const selectedInstructor = instructors.find((s) => s.id === payload.id);
        const index = instructors.indexOf(selectedInstructor);

        if (index !== -1) {
            instructors.splice(index, 1);
        }
    },
};