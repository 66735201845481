<template>
  <div class="py-20">
    <div
      style="max-width: 1050px; margin: 0px auto; width: 100%; padding: 0px;"
    >
      <!-- privacy notice content -->
      <privacy-policy-content></privacy-policy-content>
    </div>
  </div>
</template>

<script>
import PrivacyPolicyContent from "../../components/Student/PrivacyPolicyContent.vue";
export default {
  components: { "privacy-policy-content": PrivacyPolicyContent },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
  @apply mb-4;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
