<template>
  <!-- THis is Student Quota Main -->
  <std-header></std-header>
  <div class=" flex justify-center items-center" v-if="loadingData">
    <base-spinner class="my-10"></base-spinner>
  </div>
  <div class="px-2 md:px-10 xl:px-24 2xl:px-60 text-sm md:text-base" v-else>
    <router-view></router-view>
  </div>
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
  <ConsentModal v-if="!loadingData"></ConsentModal>
  <UpdateStdBanner></UpdateStdBanner>
</template>

<script>
import { db } from "../../js/firebaseDb.js";
import LoadDataMixin from "../../mixins/loadData.js";
import StudentMixin from "../../mixins/student.js";
import StdHeader from "../../components/layouts/Header/Std.vue";
import ConsentModal from "../../components/UI/ConsentModal.vue";
import UpdateStdBanner from "../../components/UI/UpdateStdBanner.vue";
import { isLogVisible } from "../../js/debug.js";
export default {
  components: { StdHeader, ConsentModal, UpdateStdBanner },
  mixins: [LoadDataMixin, StudentMixin], // loadYear(), loadQuota(), logoutStudent();

  data() {
    return {
      loadingData: false,
      error: null,
    };
  },
  computed: {
    isLogVisible() {
      return isLogVisible();
    },
    consentInfo() {
      return this.$store.getters.userConsentInfo;
    },
    stdCode() {
      return (
        this.$store.getters.userInfo && this.$store.getters.userInfo["stdCode"]
      );
    },
    stdCodeAndYear() {
      return (
        this.stdCode &&
        this.$store.getters["years/getYearByStdCode"](this.stdCode)
      );
    },
  },
  created() {
    this.loadData();
  },

  methods: {
    async loadData() {
      this.loadingData = true;

      if (this.isLogVisible) {
        console.log("Start .. Loading data in Student Quota Main");
      }
      await this.forceStudentLogout();
      await this.loadUser();
      await this.loadYear();
      await this.loadQuota("student");
      await this.setReqQuota();
      await this.trySetLoginId();
      // await this.trySetBskQuota(); // not use Basket Quota in Localstorage
      if (this.isLogVisible) {
        console.log("Complete ..  Loading data in Student Quota Main");
      }
      this.loadingData = false;
    },
    async setReqQuota() {
      try {
        await this.$store.dispatch("quota/setReqQuota");
      } catch (e) {
        this.error = e.message;
      }
    },
    async trySetBskQuota() {
      try {
        await this.$store.dispatch("quota/trySetBskQuota");
      } catch (e) {
        this.error = e.message;
      }
    },
    async trySetLoginId() {
      try {
        await this.$store.dispatch("trySetLoginId");
      } catch (e) {
        this.error = e.message;
      }
    },
    async loadUser() {
      try {
        await this.$store.dispatch("setUser", { mode: "student" });
      } catch (e) {
        this.error = e.message;
      }
    },
    closeError() {
      this.error = null;
    },
    async forceStudentLogout() {
      const scheduleRef = db.collection(`schedule`).where("isEnd", "==", false);
      await scheduleRef.onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
          const error = new Error("No such document!");
          throw error;
        }

        const scheduleArr = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });

        if (scheduleArr.length > 1) {
          const eMsg = "matched schedule exceed 1";
          const error = new Error(eMsg);
          throw error;
        }

        const theSchedule = scheduleArr[0];
        if (!theSchedule.studentLogin) {
          this.logoutStudent();
        }
      });
    },
  },
};
</script>
