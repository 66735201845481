<template>
  <div class="px-2" v-if="isShowStdData">
    <h2 class="text-gray-500 font-semibold pt-6 pb-3">
      ข้อมูลนักศึกษา
    </h2>
    <div class="border-solid my-5 border-b-4 border-primary-250 w-48"></div>
    <div>
      <div class="flex space-x-60">
        <h3 class="text-gray-500 font-semibold py-5">
          ข้อมูลรหัสผู้ใช้
        </h3>
      </div>

      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          Username
        </div>
        <div class="col-span-5">
          {{ userInfo.stdCode }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          Password
        </div>
        <div class="col-span-5">
          ********
        </div>
        <button
          class="text-blue-550 "
          @click="showModal = 'resetPassword'"
          v-if="isSudo"
        >
          แก้ไข
        </button>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          E-Mail
        </div>
        <div class="col-span-5">
          {{ userInfo.email }}
        </div>
        <button
          class="text-blue-550"
          @click="showModal = 'resetEmail'"
          v-if="isSudo"
        >
          แก้ไข
        </button>
      </div>
    </div>

    <div v-if="userInfo">
      <div class="flex space-x-60">
        <h3 class="text-gray-500 font-semibold py-5">
          ข้อมูลนักศึกษา
        </h3>
        <button class="text-blue-550" @click="openModal2" v-if="isSudo">
          แก้ไข
        </button>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          รหัสนักศึกษา
        </div>
        <div class="col-span-5">
          {{ userInfo.stdCode }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          ชื่อ
        </div>
        <div class="col-span-5">
          {{ userInfo.nameTitle }} {{ userInfo.firstName }}
          {{ userInfo.lastName }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          หลักสูตร
        </div>
        <div class="col-span-5">
          {{ userInfo.program }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          สาขาวิชาเอก
        </div>
        <div class="col-span-5">
          {{ stdMajorText(userInfo.major) }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          สาขาวิชาเอก 2
        </div>
        <div class="col-span-5">
          {{ stdMajorText(userInfo.major2) }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          สถานภาพ
        </div>
        <div class="col-span-5">
          {{ stdStatusText(userInfo.status) }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          เพศ
        </div>
        <div class="col-span-5">
          {{ genderText }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          ที่อยู่
        </div>
        <div class="col-span-5">
          {{ userInfo.conAddress }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          ที่อยู่ตามทะเบียนบ้าน
        </div>
        <div class="col-span-5">
          {{ userInfo.homAddress }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          เบอร์โทรศัพท์
        </div>
        <div class="col-span-5">
          {{ userInfo.tel }}
        </div>
      </div>
      <!-- <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          E-Mail
        </div>
        <div class="col-span-5">
          {{ userInfo.email }}
        </div>
      </div> -->
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          เลขประจำตัวประชาชน
        </div>
        <div class="col-span-5">
          {{ userInfo.stdIdCard }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          วันเกิด
        </div>
        <div class="col-span-5">
          {{ userInfo.birthday ? getDMY(userInfo.birthday.seconds) : "-" }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          <b>Emergency Contact</b>
        </div>
        <div class="col-span-5"></div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          เบอร์โทร
        </div>
        <div class="col-span-5">
          {{ userInfo.emergTel ? userInfo.emergTel : "-" }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          ชื่อ-นามสกุล
        </div>
        <div class="col-span-5">
          {{ userInfo.emergName ? userInfo.emergName : "-" }}
        </div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          ความสัมพันธ์
        </div>
        <div class="col-span-5">
          {{ userInfo.emergRelation ? userInfo.emergRelation : "-" }}
        </div>
      </div>

      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          <b>โรงเรียนมัธยมปลาย</b>
        </div>
        <div class="col-span-5"></div>
      </div>
      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          ชื่อโรงเรียน
        </div>
        <div class="col-span-5">
          {{ userInfo.stdHighSchool }}
        </div>
      </div>

      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          หลักสูตร
        </div>
        <div class="col-span-5">
          {{ userInfo.stdHighSchoolSchool }}
        </div>
      </div>

      <div class="flex space-x-10 pb-2">
        <div class="text-gray-500 w-40 text-right ">
          ผลการศึกษา
        </div>
        <div class="col-span-5">
          {{ userInfo.stdHighSchoolGrade }}
        </div>
      </div>
    </div>

    <div class="std-info-grid col-span-4">
      <h3 class="text-gray-500 font-semibold  ">
        ประวัติการเปลี่ยนแปลงข้อมูล
      </h3>
      <div class="std-info-data space-y-2 col-span-4">
        <div
          class="flex items-center justify-center"
          v-if="studentChangeLog.length === 0"
        >
          <span>ไม่มีข้อมูลที่ต้องแสดง</span>
        </div>
        <div class="grid-table-main" v-else>
          <div
            class="
              grid grid-cols-6
              grid-table-header
            "
          >
            <div class="w-full h-auto">ประเภท</div>
            <div class="w-full h-auto">จาก</div>
            <div class="w-full h-auto">ไปยัง</div>
            <div class="w-full h-auto">เทอม/ปีการศึกษา</div>
            <div class="w-full h-auto">แก้ไขโดย</div>
            <div class="w-full h-auto">เวลา</div>
          </div>

          <div class="grid-table-body">
            <div
              class="grid grid-cols-6 grid-table-row"
              v-for="(changeLog, index) in studentChangeLog"
              :key="index"
            >
              <div class="text-center my-auto ">
                {{ changeLog.type === "major" ? "สาขาวิชาเอก" : "สถานะ" }}
                {{
                  changeLog.type === "major" ? `ที่ ${changeLog.majorNo}` : ""
                }}
              </div>
              <div class="text-center my-auto ">
                {{
                  changeLog.type === "major"
                    ? stdMajorText(changeLog.from)
                    : stdStatusText(changeLog.from)
                }}
              </div>
              <div class="text-center my-auto ">
                {{
                  changeLog.type === "major"
                    ? stdMajorText(changeLog.to)
                    : stdStatusText(changeLog.to)
                }}
              </div>
              <div class="text-center my-auto ">
                {{ changeLog.semester }}/{{ changeLog.year }}
              </div>
              <div class="text-center my-auto ">
                {{ changeLog.stfId }}
              </div>
              <div class="text-center my-auto ">
                {{ changeLog.time.toLocaleString() }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h3 class="text-center">
      สิทธิ์ของคุณไม่สามารถเข้าถึงข้อมูลนักศึกษารายนี้ได้
    </h3>
  </div>

  <!-- Edit Dialog 1 -->
  <data-modal
    :show="showModal == 'resetPassword'"
    :deleteBtn="false"
    title="เปลี่ยน Password นักศึกษา"
    @close="showModal = null"
    @submit="submit1"
  >
    <form @submit.prevent>
      <!-- <p class="text-sm leading-5 text-gray-500 pt-4 pb-2">
        ข้อมูลนักศึกษา
      </p>
      <p class="text-sm leading-5 text-gray-700">
        ----
      </p> -->

      <div class="form-row flex flex-col">
        <div class="pr-2 pb-3 md:pb-0">
          <label>
            New password
          </label>
          <input
            class="form-control"
            @blur="v$.newPassword.$touch"
            type="password"
            v-model="newPassword"
          />
          <span
            class="errors"
            v-for="error of v$.newPassword.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>
    </form>
  </data-modal>

  <!-- Edit Dialog 2 -->
  <data-modal
    :show="showModal === 'editInfo'"
    :deleteBtn="false"
    title="แก้ไขข้อมูลนักศึกษา"
    @close="showModal = null"
    @submit="submit2"
  >
    <form @submit.prevent>
      <div class="form-row flex space-x-2">
        <div class="w-full">
          <label>
            รหัสนักศึกษา
          </label>
          <input
            class="form-control"
            type="text"
            :value="userInfo.stdCode"
            disabled
          />
        </div>
        <div class="w-full">
          <label>สถานภาพ</label>
          <select
            class="form-control px-1"
            :class="{ 'cursor-not-allowed': !isSudo }"
            placeholder=""
            v-model.number="status"
            :disabled="!isSudo"
          >
            <option
              v-for="status in stdStatusList"
              :value="status.id"
              :key="status.id"
            >
              {{ status.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-row flex space-x-2">
        <div class=" pb-3 md:pb-0">
          <label>
            คำนำหน้า
          </label>
          <select
            name="select"
            @blur="v$.nameTitle.$touch"
            v-model.trim="nameTitle"
            class="form-control"
            :class="{
              errors: v$.nameTitle.$error,
            }"
          >
            <option value="" selected disabled hidden></option>
            <option value="นาย">นาย</option>
            <option value="นางสาว">นางสาว</option>
            <option value="นาง">นาง</option>
          </select>
          <span
            class="errors"
            v-for="error of v$.nameTitle.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class=" pb-3 md:pb-0">
          <label>
            ชื่อ
          </label>
          <input
            class="form-control"
            @blur="v$.firstName.$touch"
            :class="{
              errors: v$.firstName.$error,
            }"
            type="text"
            v-model="firstName"
          />
          <span
            class="errors"
            v-for="error of v$.firstName.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="pb-3 md:pb-0">
          <label>
            นามสกุล
          </label>
          <input
            class="form-control"
            @blur="v$.lastName.$touch"
            :class="{
              errors: v$.lastName.$error,
            }"
            type="text"
            v-model="lastName"
          />
          <span
            class="errors"
            v-for="error of v$.lastName.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>

      <div class="form-row flex space-x-2">
        <div class="w-full">
          <label>สาขาวิชาเอก 1</label>
          <select
            class="form-control text-sm md:text-base"
            v-model.number="major"
            :class="{ 'cursor-not-allowed': !isSudo }"
            :disabled="!isSudo"
          >
            <option
              v-for="major in stdMajorList"
              :value="major.id"
              :key="major.id"
            >
              {{ major.name }}
            </option>
          </select>
        </div>

        <div class="w-full">
          <label>สาขาวิชาเอก 2</label>
          <select
            class="form-control text-sm md:text-base"
            v-model.number="major2"
            :class="{ 'cursor-not-allowed': !isSudo }"
            :disabled="!isSudo"
          >
            <option :value="null">
              -
            </option>
            <option
              v-for="major in stdMajorList"
              :value="major.id"
              :key="major.id"
            >
              {{ major.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-row flex space-x-2">
        <div class="w-full">
          <label>หลักสูตร</label>
          <select
            class="form-control text-sm md:text-base"
            v-model.number="program"
          >
            <option
              v-for="program in stdProgramList"
              :value="program.code"
              :key="program.id"
            >
              {{ program.name }}
            </option>
          </select>
        </div>

        <div class="w-full">
          <label>เพศ</label>
          <select
            name="select"
            @blur="v$.gender.$touch"
            v-model.trim="gender"
            class="form-control px-1"
            :class="{
              errors: v$.gender.$error,
            }"
          >
            <option value="F">หญิง</option>
            <option value="M">ชาย</option>
          </select>

          <span
            class="errors"
            v-for="error of v$.gender.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>

      <div class="form-row flex space-x-2">
        <div class="w-full">
          <label>
            วันเกิด
          </label>
          <BaseDatePickerVue
            class="w-full"
            @blur="v$.birthday.$touch"
            v-model="birthday"
          ></BaseDatePickerVue>
          <span
            class="errors"
            v-for="error of v$.birthday.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="w-full">
          <label>
            เลขบัตรประจำตัวประชาชน
          </label>
          <input
            class="form-control"
            @blur="v$.stdIdCard.$touch"
            :class="{
              errors: v$.stdIdCard.$error,
            }"
            type="text"
            v-model="stdIdCard"
          />
          <span
            class="errors"
            v-for="error of v$.stdIdCard.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>

        <div class="w-ful">
          <label> </label>
        </div>
      </div>

      <div class="form-row">
        <label>
          ที่อยู่
        </label>
        <input
          class="form-control"
          @blur="v$.conAddress.$touch"
          :class="{
            errors: v$.conAddress.$error,
          }"
          type="text"
          v-model="conAddress"
        />
        <span
          class="errors"
          v-for="error of v$.conAddress.$errors"
          :key="error.$uid"
          >{{ error.$message }}<br />
        </span>
      </div>

      <div class="form-row">
        <label>
          ที่อยู่ตามทะเบียนบ้าน
        </label>
        <input
          class="form-control"
          @blur="v$.homAddress.$touch"
          :class="{
            errors: v$.homAddress.$error,
          }"
          type="text"
          v-model="homAddress"
        />
        <span
          class="errors"
          v-for="error of v$.homAddress.$errors"
          :key="error.$uid"
          >{{ error.$message }}<br />
        </span>
      </div>

      <div class="form-row flex ">
        <div class="pr-2">
          <label>
            เบอร์โทรศัพท์
          </label>
          <input
            class="form-control"
            @blur="v$.tel.$touch"
            :class="{
              errors: v$.tel.$error,
            }"
            type="text"
            v-model="tel"
          />
          <span class="errors" v-for="error of v$.tel.$errors" :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="">
          <label>
            E-Mail
          </label>
          <input class="form-control" type="text" v-model="email" disabled />
        </div>
      </div>
      <hr class="pb-4" />
      <!-- Emergency contact form -->
      <b>Emergency Contact</b>
      <div class="form-row">
        <label>
          เบอร์
        </label>
        <input
          class="form-control"
          @blur="v$.emergTel.$touch"
          :class="{
            errors: v$.emergTel.$error,
          }"
          type="text"
          v-model="emergTel"
        />
        <span
          class="errors"
          v-for="error of v$.emergTel.$errors"
          :key="error.$uid"
          >{{ error.$message }}<br />
        </span>
      </div>

      <div class="form-row flex ">
        <div class="pr-2">
          <label>
            ชื่อ-นามสกุล
          </label>
          <input
            class="form-control"
            @blur="v$.emergName.$touch"
            :class="{
              errors: v$.emergName.$error,
            }"
            type="text"
            v-model="emergName"
          />
          <span
            class="errors"
            v-for="error of v$.emergName.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="pr-2">
          <label>
            ความสัมพันธ์
          </label>
          <input
            class="form-control"
            @blur="v$.emergRelation.$touch"
            :class="{
              errors: v$.emergRelation.$error,
            }"
            type="text"
            v-model="emergRelation"
          />
          <span
            class="errors"
            v-for="error of v$.emergRelation.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>
      <hr class="pb-4" />
      <div><b>โรงเรียนมัธยมปลาย</b></div>
      <div class="form-row">
        <label>
          ชื่อโรงเรียน
        </label>
        <input
          class="form-control"
          @blur="v$.stdHighSchool.$touch"
          :class="{
            errors: v$.stdHighSchool.$error,
          }"
          type="text"
          v-model="stdHighSchool"
        />
        <span
          class="errors"
          v-for="error of v$.stdHighSchool.$errors"
          :key="error.$uid"
          >{{ error.$message }}<br />
        </span>
      </div>

      <div class="form-row flex space-x-2">
        <div class="w-full">
          <label>
            หลักสูตรมัธยมปลาย
          </label>
          <input
            class="form-control"
            @blur="v$.stdHighSchoolSchool.$touch"
            :class="{
              errors: v$.stdHighSchoolSchool.$error,
            }"
            type="text"
            v-model="stdHighSchoolSchool"
          />
          <span
            class="errors"
            v-for="error of v$.stdHighSchoolSchool.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="w-full">
          <label>
            ผลการศึกษามัธยมปลาย
          </label>
          <input
            class="form-control"
            @blur="v$.stdHighSchoolGrade.$touch"
            :class="{
              errors: v$.stdHighSchoolGrade.$error,
            }"
            type="text"
            v-model="stdHighSchoolGrade"
          />
          <span
            class="errors"
            v-for="error of v$.stdHighSchoolGrade.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>
    </form>
  </data-modal>

  <!-- Edit Dialog 3 -->
  <data-modal
    :show="showModal == 'resetEmail'"
    :deleteBtn="false"
    title="เปลี่ยน Email นักศึกษา"
    @close="showModal = null"
    @submit="submit3"
  >
    <form @submit.prevent>
      <div class="form-row flex flex-col">
        <div class="pr-2 pb-3 md:pb-0">
          <label>
            New Email
          </label>
          <input
            class="form-control"
            @blur="v$.newEmail.$touch"
            type="email"
            v-model="newEmail"
          />
          <span
            class="errors"
            v-for="error of v$.newEmail.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>
    </form>
  </data-modal>

  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
</template>

<script>
import { functions } from "../../js/firebaseDb.js";
import FormValidation from "../../mixins/formValidation.js";
import DataModal from "../../components/UI/DataModal.vue";
import useVuelidate from "@vuelidate/core";
import {
  required,
  numeric,
  not,
  minLength,
  maxLength,
  helpers,
  email,
  integer,
} from "@vuelidate/validators";
import BaseDatePickerVue from "../../components/UI/BaseDatePicker.vue";
export default {
  props: { mode: { type: String, required: false, default: "student" } },
  mixins: [FormValidation], // isNumber()
  components: { DataModal, BaseDatePickerVue },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      showModal: null,
      error: null,

      // modal1
      newPassword: null,

      // modal2
      nameTitle: null,
      firstName: null,
      lastName: null,
      gender: null,
      conAddress: null,
      homAddress: null,
      tel: null,
      email: null,
      stduuid: null,

      program: null,
      major: null,
      major2: null,
      status: null,
      stdIdCard: null,
      birthday: null,
      stdHighSchool: null,
      stdHighSchoolGrade: null,
      stdHighSchoolSchool: null,
      date: new Date(),
      // optional
      schoolName: null,
      schoolMajor: null,
      schoolGrade: null,
      // Emergency contact
      emergTel: null,
      emergName: null,
      emergRelation: null,

      // modal3
      newEmail: null,
    };
  },
  validations() {
    return {
      // form 1
      newPassword: { required, minLengthValue: minLength(6) },

      // form 2
      nameTitle: { required },
      firstName: {
        required,
        char: helpers.withMessage("This field should be string", not(numeric)),
        minLength: minLength(2),
      },
      lastName: {
        required,
        char: helpers.withMessage("This field should be string", not(numeric)),
        minLength: minLength(2),
      },
      gender: { required },
      conAddress: { required },
      homAddress: { required },
      tel: {
        required,
        integer,
        minLengthValue: minLength(8),
        maxLengthValue: maxLength(12),
      },
      // birthday: { required },
      // citizenId: {
      //   required,
      //   integer,
      //   minLengthValue: minLength(13),
      //   maxLengthValue: maxLength(13),
      // },
      // for admin
      birthday: { required },
      stdIdCard: {
        required,
        minLengthValue: minLength(13),
        maxLengthValue: maxLength(13),
      },
      stdHighSchool: {},
      stdHighSchoolSchool: {},
      stdHighSchoolGrade: {},

      // emergency contact
      emergTel: {},
      emergName: {},
      emergRelation: {},

      newEmail: { required, email },
    };
  },
  computed: {
    isShowStdData() {
      const staffInfo = this.$store.getters["userInfo"];
      if (staffInfo.role === "A") {
        return true;
      } else {
        const majors = staffInfo.majors;
        if (
          (this.userInfo.major && majors.includes(this.userInfo.major)) ||
          (this.userInfo.major2 && majors.includes(this.userInfo.major2))
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    stdStatusList() {
      return this.$store.getters["students/status"];
    },
    stdProgramList() {
      return this.$store.getters["programs/programs"];
    },
    stdMajorList() {
      return this.$store.getters["majors/majors"];
    },
    genderText() {
      if (this.userInfo) {
        if (this.userInfo.gender === "M") {
          return "ชาย";
        } else if (this.userInfo.gender === "F") {
          return "หญิง";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },

    userInfo() {
      return this.$store.getters["adminStudents/studentInfo"];
    },
    studentChangeLog() {
      return this.$store.getters["adminStudents/studentChangeLog"];
    },
    isSudo() {
      return this.$store.getters["isSudo"];
    },
  },
  methods: {
    stdStatusText(statusId) {
      if (statusId) {
        const selectedStatus = this.$store.getters["students/getStatusById"](
          statusId
        );
        return selectedStatus ? selectedStatus.name : "-";
      } else {
        return "-";
      }
    },
    stdMajorText(majorId) {
      if (majorId) {
        const selectedMajor = this.$store.getters["majors/getMajorById"](
          majorId
        );
        return selectedMajor ? selectedMajor.name : "-";
      } else {
        return "-";
      }
    },
    async submit2() {
      // Form validation
      this.v$.nameTitle.$touch();
      this.v$.firstName.$touch();
      this.v$.lastName.$touch();
      this.v$.gender.$touch();
      this.v$.conAddress.$touch();
      this.v$.homAddress.$touch();
      this.v$.tel.$touch();
      this.v$.birthday.$touch();

      if (
        this.v$.nameTitle.$error ||
        this.v$.firstName.$error ||
        this.v$.lastName.$error ||
        this.v$.gender.$error ||
        this.v$.conAddress.$error ||
        this.v$.homAddress.$error ||
        this.v$.tel.$error ||
        this.v$.birthday.$error
      ) {
        this.isLoading = false;
        await this.$store.dispatch("clearToast");
        return;
      }

      await this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังบันทึกข้อมูล",
      });

      // get user authen data
      let submittedData = {
        id: this.stduuid,
        stdCode: this.userInfo.stdCode,
        nameTitle: this.nameTitle,
        firstName: this.firstName,
        lastName: this.lastName,
        gender: this.gender || null,
        conAddress: this.conAddress || null,
        homAddress: this.homAddress || null,
        tel: this.tel || null,
        // email: this.email,
        program: this.program,
        major: this.major,
        major2: this.major2 || null,
        status: this.status,
        stdIdCard: this.stdIdCard || null,
        birthday: this.birthday || null,
        stdHighSchool: this.stdHighSchool || null,
        stdHighSchoolGrade: this.stdHighSchoolGrade || null,
        stdHighSchoolSchool: this.stdHighSchoolSchool || null,
        // emergency contact
        emergTel: this.emergTel || null,
        emergName: this.emergName || null,
        emergRelation: this.emergRelation || null,
      };

      this.showModal = null;
      try {
        await this.$store.dispatch(
          "adminStudents/updateStudentInfo",
          submittedData
        );
        await this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "อัพเดตข้อมูลสำเร็จ",
        });
      } catch (err) {
        this.error = err.message;
      }

      this.showModal = null;
    },
    async submit1() {
      await this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังบันทึกข้อมูล",
      });

      this.v$.newPassword.$touch();

      if (this.v$.newPassword.$error) {
        this.isLoading = false;
        await this.$store.dispatch("clearToast");
        return;
      }

      const updateUserPassword = functions.httpsCallable("updateUserPassword");
      await updateUserPassword({
        uid: this.userInfo.id,
        password: this.newPassword,
      }).catch((error) => {
        this.$store.dispatch("clearToast");
        this.error = error.message;
      });

      this.$store.dispatch("clearToast");
      this.showModal = null;
      this.newPassword = null;
      this.v$.newPassword.$reset();
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "อัพเดตข้อมูลสำเร็จ",
      });
    },
    async submit3() {
      await this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังบันทึกข้อมูล",
      });

      this.v$.newEmail.$touch();

      if (this.v$.newEmail.$error) {
        this.isLoading = false;
        await this.$store.dispatch("clearToast");
        return;
      }

      const resetEmailStudent = functions.httpsCallable("resetEmailStudent");
      await resetEmailStudent({
        uid: this.userInfo.id,
        email: this.newEmail,
      })
        .then(() => {
          this.$store.dispatch("clearToast");
          this.showModal = null;
          this.newEmail = null;
          this.v$.newEmail.$reset();
          this.$store.dispatch("updateToast", {
            mode: "success",
            show: true,
            message: "อัพเดตข้อมูลสำเร็จ",
          });
        })
        .catch((error) => {
          this.$store.dispatch("clearToast");
          this.error = error.message;
        });
    },
    openModal2() {
      this.nameTitle = this.userInfo.nameTitle;
      this.firstName = this.userInfo.firstName;
      this.lastName = this.userInfo.lastName;
      this.gender = this.userInfo.gender;
      this.conAddress = this.userInfo.conAddress;
      this.homAddress = this.userInfo.homAddress;
      this.tel = this.userInfo.tel;
      this.email = this.userInfo.email;
      this.stduuid = this.userInfo.id;
      this.program = this.userInfo.program;
      this.major = this.userInfo.major;
      this.major2 = this.userInfo.major2;
      this.status = this.userInfo.status;
      this.stdIdCard = this.userInfo.stdIdCard;
      this.birthday = this.userInfo.birthday
        ? this.userInfo.birthday.toDate()
        : null;
      this.stdHighSchool = this.userInfo.stdHighSchool;
      this.stdHighSchoolGrade = this.userInfo.stdHighSchoolGrade;
      this.stdHighSchoolSchool = this.userInfo.stdHighSchoolSchool;
      this.emergTel = this.userInfo.emergTel;
      this.emergName = this.userInfo.emergName;
      this.emergRelation = this.userInfo.emergRelation;
      this.showModal = "editInfo";
    },
    closeError() {
      this.error = null;
    },
    getDMYDash(seconds) {
      const dt = new Date(seconds * 1000);
      return dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
    },
    getDMY(seconds) {
      const dt = new Date(seconds * 1000);
      return dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate();
    },
  },
};
</script>

<style scoped>
.form-row {
  @apply pb-3;
}
.std-info-grid {
  @apply grid grid-cols-4 py-6;
}

.std-info-grid h3 {
  @apply col-span-4 md:col-span-1 pb-4;
}

.std-info-data {
  @apply col-span-4 md:col-span-3;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
