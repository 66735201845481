export default {
    methods: {
        qRoundToText(round) {
            if (round == 4) {
                return 4.1;
            } else if (round == 5) {
                return 4.2;
            } else {
                return round;
            }
        },
    },
};