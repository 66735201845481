<template>
  <div class="px-60">
    <base-card class="flex justify-end">
      <base-button :to="{ name: 'staff-detail' }" @click="addBasket()">
        + เพิ่มตะกร้าวิชา</base-button
      >
    </base-card>
    <!-- start block -->
    <div class="mb-4" v-for="theBasket in baskets" :key="theBasket.id">
      <div
        @click="
          activeFaq === theBasket.id
            ? (activeFaq = null)
            : (activeFaq = theBasket.id)
        "
        class="z-10 flex items-center justify-between bg-gray-200 pl-3 pr-2 py-3 w-full rounded text-gray-600 font-bold cursor-pointer hover:bg-gray-300"
      >
        <!-- quota Code  -->
        <div class="flex items-center space-x-2">
          <!-- expand Icon -->
          <span>
            <svg
              v-if="activeFaq === theBasket.id"
              class="w-3 h-3 fill-current"
              viewBox="0 -192 469.33333 469"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m437.332031.167969h-405.332031c-17.664062 0-32 14.335937-32 32v21.332031c0 17.664062 14.335938 32 32 32h405.332031c17.664063 0 32-14.335938 32-32v-21.332031c0-17.664063-14.335937-32-32-32zm0 0"
              />
            </svg>
            <svg
              v-else
              class="w-3 h-3 fill-current"
              viewBox="0 0 469.33333 469.33333"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0"
              />
            </svg>
          </span>
          <span v-for="quota in theBasket.quotaList" :key="quota.id"
            >{{ quota.subCode }},</span
          >
        </div>

        <span class="pr-2 space-x-2">
          <span>{{ theBasket.request }}/{{ theBasket.offer }}</span>
          <base-button class="z-20 px-4" @click="editBasket($event)"
            >แก้ไข</base-button
          >
        </span>
      </div>
      <div class="p-3" v-if="activeFaq === theBasket.id">
        <div class="grid-table-main ">
          <div class="grid-table-body">
            <div
              class="grid grid-cols-7 grid-table-row"
              v-for="quotaItem in theBasket.quotaList"
              :key="quotaItem.id"
            >
              <div class="text-center my-auto ">
                {{ quotaItem.subCode }}
              </div>
              <div class="col-span-2  text-center my-auto">
                {{ quotaItem.subName }}
              </div>
              <div class=" text-center my-auto">
                {{ quotaItem.secId }}-{{ quotaItem.group }}
              </div>
              <div class="col-span-2 text-center my-auto">
                <span v-for="inst in quotaItem.instructors" :key="inst.id"
                  >{{ inst.name }},</span
                >
              </div>
              <div class=" text-center my-auto">
                {{ quotaItem.q1Request ? quotaItem.q1Request : "-" }}/{{
                  quotaItem.q1Offer ? quotaItem.q1Offer : "-"
                }}
              </div>
            </div>
          </div>
        </div>
        <p class="text-gray-600 mb-3">
          {{ theBasket.quotaList }}
        </p>
      </div>
    </div>
  </div>

  <!-- Edit Dialog   -->
  <data-modal
    :show="basketDialog"
    :deleteBtn="false"
    :title="mode === 'add' ? 'เพิ่มตะกร้าวิชา' : 'แก้ไขตะกร้าวิชา'"
    @close="null"
    @delete="trydeleteStaff()"
    @submit="submitData()"
    :submitBtn="!formLoading"
    :cancelBtn="!formLoading"
    :closeIcon="!formLoading"
    class="w-full"
  >
    <div class="flex justify-center" v-if="formLoading">
      <base-spinner></base-spinner>
    </div>
    <form @submit.prevent class="py-4 pr-6 " v-else>
      <div class="grid grid-cols-6 font-bold">
        <div>รหัสวิชา</div>
        <div>sec/group</div>
        <div>ชื่อวิชา</div>
        <div>อาจารย์</div>
        <div>จัดสรร</div>
        <div>จำนวนว่าง</div>
      </div>
      <div class="grid grid-cols-6">
        <div>AC201</div>
        <div>1001/01</div>
        <div>Fundamental Accounting</div>
        <div>อาจารย์</div>
        <div>จัดสรร</div>
        <div>จำนวนว่าง</div>
      </div>
    </form>
  </data-modal>
  <toast
    :show="!!error"
    mode="error"
    @close="error = null"
    fixed
    :message="error"
  >
  </toast>
  {{ baskets }}
</template>

<script>
import DataModal from "../../components/UI/DataModal.vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, email, sameAs } from "@vuelidate/validators";
export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  components: { DataModal },
  data() {
    return {
      mode: null,
      loadingData: false,
      error: null,
      activeFaq: null,
      formLoading: false,

      basketDialog: false,
    };
  },
  validations() {
    return {
      username: {
        required,
        minLength: minLength(2),
      },
      email: { required, email },
      password: { required, minLength: minLength(6) },
      cfPassword: {
        required,
        sameAsPassword: sameAs(this.password),
        minLength: minLength(6),
      },
      prefix: {
        required,
        minLength: minLength(2),
      },
      firstName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      gender: {
        required,
      },
      role: {
        required,
      },
      pickedMajor: {
        required,
      },
    };
  },
  computed: {
    baskets() {
      return this.$store.getters["baskets/fullBaskets"];
    },
  },
  methods: {
    editBasket(event) {
      alert("ok");
      event.stopPropagation();
    },
    addBasket() {
      this.mode = "add";
      this.basketDialog = true;
    },
  },
};
</script>
