import { isLogVisible } from "../../../js/debug.js";
import { db } from "../../../js/firebaseDb";
// import firebase from "firebase/app";
// import "firebase/firestore";

export default {
    async setMajor(context) {
        // prepare data
        const majorsRef = db.collection(`majors`);

        await majorsRef.onSnapshot((majorsDocs) => {
            if (majorsDocs.empty) {
                context.commit("setMajor", []);
                const error = new Error("No such document!");
                if (isLogVisible()) {
                    console.error(error);
                }
                throw error;
            }
            const majorsArr = majorsDocs.docs.map((doc) => {
                const payload = {
                    ...doc.data(),
                };

                // for compatible with old id pattern
                const pattern = /[\d+]/gi;
                const idText = String(doc.id);
                const idInt = idText.match(pattern);
                if (idInt.length === idText.length) {
                    payload["id"] = parseInt(doc.id);
                } else {
                    payload["id"] = doc.id;
                }
                return payload;
            });

            context.commit("setMajor", majorsArr);
        });
    },

    async addMajor(context, payload) {
        const scheduleRuleRef = db.collection(`majors`).doc();
        const theData = {
            name: payload.name,
            code: payload.code,
            status: payload.status,
        };
        // sent data to server
        await scheduleRuleRef.set(theData);
    },

    async updateMajor(context, payload) {
        const scheduleRuleRef = db.collection(`majors`).doc(String(payload.id));
        const theData = {
            name: payload.name,
            code: payload.code,
            status: payload.status,
        };
        await scheduleRuleRef.update(theData);
    },
};