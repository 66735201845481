export default {
    baskets(state) {
        return state.baskets;
    },
    fullBaskets(state, getters, rootState, rootGetters) {
        const baskets = getters.baskets;

        for (const basket of baskets) {
            const quotaData = [];
            for (const quotaId of basket.quotaList) {
                const quotaDetail = rootGetters["quota/getQuotaById"](quotaId);
                quotaData.push(quotaDetail);
            }
            basket.quotaList = quotaData;
        }
        return baskets;
    },
};