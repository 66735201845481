<template>
  <div class="bg-gray-50 font-kanit px-6 no-print">
    <div class="text-gray-500 flex justify-center space-x-20 pt-8">
      <!-- <div>
        <h4>TBS Quota</h4>
        <ul>
          <li>
            <button
              class="hover:text-gray-600 transition duration-500"
              @click="toAdmin"
            >
              สำหรับเจ้าหน้าที่ (Admin Login)
            </button>
          </li>
          <li>
            <button
              class="hover:text-gray-600 transition duration-500"
              @click="toStudent"
            >
              สำหรับนักศึกษา (Student Login)
            </button>
          </li>
        </ul>
      </div> -->
      <div>
        <!-- <h4 class="text-center">เกี่ยวกับเรา</h4> -->
        <ul class="flex justify-center space-x-4 ">
          <li>ติดต่อเรา</li>
          <li>
            <router-link
              class="hover:text-gray-600 transition duration-500"
              :to="{ name: 'FAQ' }"
              >FAQ</router-link
            >
          </li>
        </ul>
        <ul class="flex justify-center space-x-4 md:space-x-24">
          <li>
            <router-link
              class="hover:text-gray-600 transition duration-500"
              :to="{ name: 'privacy-policy' }"
              >นโยบายข้อมูลส่วนบุคคล</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- <div class="text-gray-500 flex justify-center">
      <button
        class="hover:text-gray-600 transition duration-500"
        @click="toAdmin"
      >
        Admin Login
      </button>
      <span class="px-4"> | </span>
      <button
        class="hover:text-gray-600 transition duration-500"
        @click="toStudent"
      >
        Student Login
      </button>
    </div> -->
    <div class="flex justify-center ">
      <div
        class="border-solid  border-b-1 border-gray-300 w-full md:w-3/4 my-4"
      ></div>
    </div>
    <p class="text-blue-550 text-center">
      Copyright by Thammasat Business School, Thammasat University
    </p>
    <p class="text-gray-100 ">
      DB: {{ firebase.projectId }} | build version: {{ buildVersion }}
    </p>

    <div v-if="isLogVisible">
      {{ userRole }}
      <hr />
      {{ scheduleRule }}
      <hr />
      {{ currentScheduleRule }}
    </div>
  </div>
</template>

<script>
import { firebaseConfig } from "../../js/firebaseDb.js";
import { isLogVisible } from "../../js/debug.js";
export default {
  props: {
    buildVersion: {
      type: String,
      required: false,
    },
  },
  computed: {
    userRole() {
      return this.$store.getters["userRole"];
    },
    isLogVisible() {
      return isLogVisible();
    },
    theDb() {
      return process.env.VUE_APP_DB;
    },
    firebase() {
      return firebaseConfig;
    },
    scheduleRule() {
      return this.$store.getters["scheduleRule"];
    },
    currentScheduleRule() {
      return this.$store.getters["currentScheduleRule"];
    },
  },
  methods: {
    toAdmin() {
      this.$router.replace({
        name: "admin-login",
      });
    },
    toStudent() {
      this.$router.replace({
        name: "login",
      });
    },
  },
};
</script>
