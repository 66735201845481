<template>
  <div class="py-10 text-center">
    <h2 class="pb-5">ค้นหาข้อมูลนักศึกษา</h2>
    <!-- {{ selectedStd }} -->
    <!-- {{ selectedStdInPage }} -->
  </div>
  <base-card>
    <div class="flex space-x-2">
      <div class=" w-full ">
        <div>
          <label class="block  text-sm text-gray-500 ">ค้นหาด้วย</label>
          <select
            class="form-control text-sm md:text-base pr-10"
            v-model="searchMode"
          >
            <option value="0" disabled>โปรดเลือก</option>
            <option value="stdCode">รหัสนักศึกษา</option>
            <option value="stdName">ชื่อ</option>
            <option value="stdSurName">นามสกุล</option>
          </select>
        </div>
      </div>
      <div class=" w-full">
        <label class="block  text-sm text-gray-500 opacity-0 ">message</label>
        <input
          class="form-control text-sm md:text-base"
          type="text"
          placeholder="ค้นหา..."
          v-model="searchValue"
        />
      </div>
    </div>

    <div class="w-full flex justify-end pt-5">
      <base-button class="px-10" @click="searchStd()">ค้นหา</base-button>
    </div>
  </base-card>

  <!-- major modal -->
  <data-modal
    :cancelBtn="false"
    :show="showDialog === 'major'"
    title="เปลี่ยนแปลงสาขาวิชาเอก"
    @close="showDialog = null"
    @submit="updateMajor"
  >
    <div class=" flex justify-center items-center" v-if="majorLoading">
      <base-spinner class="my-10"></base-spinner>
    </div>
    <div v-else>
      <div class="flex mt-4">
        จำนวนนักศีกษาที่ต้องการเปลี่ยนแปลง {{ selectedStd.length }} คน
      </div>
      <div class="flex space-x-2">
        <div class="w-full mt-4">
          <label class="block  text-sm text-gray-500 ">ไปยังสาขาวิชาเอก</label>
          <select
            class="form-control text-sm md:text-base"
            v-model.number="toMajor"
          >
            <option :value="null" disabled>โปรดเลือกเอก</option>
            <option :value="null">
              -
            </option>
            <option
              v-for="major in stdMajorList"
              :value="major.id"
              :key="major.id"
            >
              {{ major.name }}
            </option>
          </select>
        </div>
        <div class="w-full mt-4">
          <label class="block  text-sm text-gray-500 ">ลำดับวิชาเอก</label>
          <select
            class="form-control text-sm md:text-base"
            v-model.number="toMajorNo"
          >
            <option :value="1">1</option>
            <option :value="2">2</option>
          </select>
        </div>
      </div>
      <div class="flex space-x-2">
        <div class="w-full mt-4">
          <label class="block  text-sm text-gray-500 ">เทอม</label>
          <select
            class="form-control text-sm md:text-base"
            v-model.number="semester"
          >
            <option :value="null" disabled>โปรดเลือกเทอม</option>
            <option :value="1">1</option>
            <option :value="2">2</option>
            <option :value="2">3</option>
          </select>
        </div>
        <div class="w-full mt-4">
          <label class="block  text-sm text-gray-500 ">ปีการศึกษา</label>
          <input class=" form-control" type="text" v-model="year" />
        </div>
      </div>
      <div class="flex mt-4">
        <div class=" flex space-x-6 pr-2 w-full">
          <div class=" flex-grow">
            <label class="form-control">หมายเหตุ</label>
            <input class=" form-control" type="text" v-model="toMajorRemark" />
          </div>
        </div>
      </div>
    </div>
  </data-modal>

  <!-- status modal -->
  <data-modal
    :cancelBtn="false"
    :show="showDialog === 'status'"
    title="เปลี่ยนแปลงสถานะ"
    @close="showDialog = null"
    @submit="updateStatus"
  >
    <div class=" flex justify-center items-center" v-if="statusLoading">
      <base-spinner class="my-10"></base-spinner>
    </div>
    <div v-else>
      <div class="flex mt-4">
        จำนวนนักศีกษาที่ต้องการเปลี่ยนแปลง {{ selectedStd.length }} คน
      </div>
      <div class="flex space-x-2">
        <div class="w-full mt-4">
          <label class="block  text-sm text-gray-500 ">ไปยังสถานะ</label>
          <select
            class="form-control text-sm md:text-base"
            v-model.number="toStatus"
          >
            <option :value="null" disabled>โปรดเลือกสถานะ</option>
            <option
              v-for="status in stdStatusList"
              :value="status.id"
              :key="status.id"
            >
              {{ status.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex space-x-2">
        <div class="w-full mt-4">
          <label class="block  text-sm text-gray-500 ">เทอม</label>
          <select
            class="form-control text-sm md:text-base"
            v-model.number="semester"
          >
            <option :value="null" disabled>โปรดเลือกเทอม</option>
            <option :value="1">1</option>
            <option :value="2">2</option>
            <option :value="2">3</option>
          </select>
        </div>
        <div class="w-full mt-4">
          <label class="block  text-sm text-gray-500 ">ปีการศึกษา</label>
          <input class=" form-control" type="text" v-model="year" />
        </div>
      </div>
      <div class="flex mt-4">
        <div class=" flex space-x-6 pr-2 w-full">
          <div class=" flex-grow">
            <label class="form-control">หมายเหตุ</label>
            <input class=" form-control" type="text" v-model="toStatusRemark" />
          </div>
        </div>
      </div>
    </div>
  </data-modal>

  <div class="py-10">
    <div class="flex justify-between bg-gray-100 rounded-t-lg py-4 pr-4">
      <div class="flex pl-4 space-x-6 items-center">
        <div
          v-if="isSudo"
          class="flex cursor-pointer"
          @click="
            () => {
              this.defaultSchedule();
              showDialog = 'major';
            }
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-primary-400 hover:text-primary-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            /></svg
          ><span class="text-gray-600 hover:text-gray-700">เปลี่ยนแปลงเอก</span>
        </div>
        <div
          v-if="isSudo"
          class="flex cursor-pointer"
          @click="
            () => {
              this.defaultSchedule();
              showDialog = 'status';
            }
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-primary-400 hover:text-primary-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
          <span class="text-gray-600 hover:text-gray-700"
            >เปลี่ยนแปลงสถานะ</span
          >
        </div>
      </div>
      <div class="flex space-x-2">
        <div class="w-full">
          <label class="block  text-sm text-gray-500 ">ในคณะ/นอกคณะ</label>
          <select class="form-control text-sm md:text-base" v-model="stdSchool">
            <option :value="null">ทั้งหมด</option>
            <option value="inSchool">น.ศ. ในคณะ</option>
            <option value="outSchool">น.ศ. นอกคณะ</option>
          </select>
        </div>
        <div class="w-full">
          <label class="block  text-sm text-gray-500 ">สถานภาพ</label>
          <select
            class="form-control px-1"
            placeholder=""
            v-model.number="stdStatus"
          >
            <option :value="null">ทั้งหมด</option>
            <option
              v-for="status in stdStatusList"
              :value="status.id"
              :key="status.id"
            >
              {{ status.name }}
            </option>
          </select>
        </div>
        <div class="w-full">
          <label class="block  text-sm text-gray-500 ">หลักสูตร</label>
          <select
            class="form-control text-sm md:text-base"
            v-model.number="stdProgram"
          >
            <option :value="null">ทั้งหมด</option>
            <option
              v-for="program in stdProgramList"
              :value="program.code"
              :key="program.id"
            >
              {{ program.name }}
            </option>
          </select>
        </div>
        <div class="w-full">
          <label class="block  text-sm text-gray-500 ">สาขาวิชาเอก</label>
          <select
            class="form-control text-sm md:text-base"
            v-model.number="stdMajor"
          >
            <option :value="null">ทั้งหมด</option>
            <option
              v-for="major in stdMajorList"
              :value="major.id"
              :key="major.id"
            >
              {{ major.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="grid-table-main ">
      <div
        class="
              grid grid-cols-12
              grid-table-header
            "
      >
        <div class="w-full h-auto flex flex-col items-center justify-center">
          <!-- <input
            type="checkbox"
            class="form-checkbox"
            v-model="selectedStdInPage"
          /> -->
          <div>ลำดับ</div>
        </div>
        <div class="w-full h-auto flex items-center justify-center">
          เลขทะเบียน
        </div>
        <div class="w-full h-auto col-span-3 flex items-center justify-center">
          ชื่อ-นามสกุล
        </div>
        <div class="w-full h-auto flex items-center justify-center">
          หลักสูตร
        </div>
        <div class="w-full h-auto col-span-3 flex items-center justify-center">
          สาขาวิชาเอก
        </div>
        <div class="w-full h-auto flex items-center justify-center">สถานะ</div>
        <div class="w-full h-auto flex items-center justify-center">
          ภาคการศึกษาที่จบ/ลาออก/ถูกถอนชื่อ
        </div>
        <div class="w-full h-auto flex items-center justify-center">
          ดูข้อมูล
        </div>
      </div>

      <div class="grid-table-body">
        <div
          class="grid grid-cols-12 grid-table-row"
          v-for="(student, index) in filteredStudents"
          :key="index"
        >
          <div class="text-center my-auto space-x-2">
            <input
              type="checkbox"
              class="form-checkbox"
              v-model="selectedStd"
              :value="{
                id: student.id,
                code: student.stdCode,
                stdName: `${student.nameTitle} ${student.firstName} ${student.lastName}`,
                oldMajor: student.major,
                oldMajor2: student.major2,
                oldStatus: student.status,
              }"
            />
            <span>{{ index + 1 }}</span>
          </div>
          <div class="text-center my-auto">
            {{ student.stdCode }}
          </div>
          <div class="col-span-3 text-center my-auto ">
            {{ student.firstName + " " + student.lastName }}
          </div>
          <div class=" text-center my-auto">
            {{ student.program ? getProgram(student.program).name : "-" }}
          </div>
          <div class="col-span-3 text-center my-auto">
            {{ student.major ? getMajor(student.major).name : "-" }}
            {{ student.major2 ? "," : "" }}<br />
            {{ student.major2 ? getMajor(student.major2).name : "" }}
          </div>
          <div class=" text-center my-auto">
            {{ student.status ? getStatus(student.status).name : "-" }}
          </div>
          <div class=" text-center my-auto">
            xxxx
          </div>
          <div class=" text-center my-auto">
            <base-button
              v-if="isSudo"
              mode="super-flat"
              link
              :to="{ name: 'admin-std-info', params: { stdId: student.id } }"
              >ดูข้อมูล</base-button
            >
            <base-button
              v-else
              mode="super-flat"
              link
              :to="{ name: 'admin-std-quota', params: { stdId: student.id } }"
              >ดูข้อมูล</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="filteredStudents.length === 0" class="text-center">
    <h3>ไม่มีข้อมูลนักศึกษาที่จะแสดง</h3>
  </div>
  <!-- <div v-else class="flex justify-center py-5">
    <v-pagination
      v-model="page"
      :pages="pageCount"
      :range-size="1"
      active-color="#DCEDFF"
      @update:modelValue="updateHandler"
    />
  </div> -->
  <!-- {{ filteredStudents.length }}
  {{ filteredStudents }} -->
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
  <!-- <base-button @click="test1()">set</base-button>
  <base-button @click="test2()">get</base-button> -->
</template>

<script>
// import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { sortText } from "../../js/utility.js";
import { setFirebaseindex, getAllIndex } from "../../js/cloudg.js";
import DataModal from "../../components/UI/DataModal.vue";
export default {
  components: {
    // VPagination,
    DataModal,
  },
  data() {
    return {
      searchValue: null,
      searchMode: "stdCode",
      stdSchool: null,
      stdStatus: null,
      stdProgram: null,
      stdMajor: null,
      error: null,
      selectedStd: [],
      selectedStdInPage: false,

      semester: null,
      year: null,

      // update major form
      toMajor: null,
      toMajorRemark: null,
      toMajorNo: 1,
      majorLoading: false,

      // update status form
      toStatus: null,
      toStatusRemark: null,
      statusLoading: false,

      // system parameter
      showDialog: null,
      page: 1,
      dataInPage: 10,
    };
  },
  watch: {
    // page() {
    //   this.selectedStdInPage = false;
    //   this.selectedStd = [];
    // },
    selectedStdInPage(newValue) {
      if (newValue) {
        const pagedStd = this.pagedStudent.map((std) => std.id);
        this.selectedStd = pagedStd;
      } else {
        this.selectedStd = [];
      }
    },
  },
  computed: {
    students() {
      return this.$store.getters["adminStudents/students"];
    },
    filteredStudents() {
      let studentLists = this.students;
      if (this.stdStatus) {
        studentLists = studentLists.filter(
          (std) => std.status === this.stdStatus
        );
      }
      if (this.stdProgram) {
        studentLists = studentLists.filter(
          (std) => std.program === this.stdProgram
        );
      }
      if (this.stdMajor) {
        studentLists = studentLists.filter(
          (std) => std.major === this.stdMajor || std.major2 === this.stdMajor
        );
      }
      if (this.stdSchool === "inSchool") {
        studentLists = studentLists.filter(
          (std) => std.stdCode.slice(2, 4) == "02"
        );
      } else if (this.stdSchool === "outSchool") {
        studentLists = studentLists.filter(
          (std) => std.stdCode.slice(2, 4) != "02"
        );
      } else {
        // pass
      }
      return sortText(studentLists, "stdCode");
    },
    stdStatusList() {
      return this.$store.getters["students/status"];
    },
    stdProgramList() {
      return this.$store.getters["programs/programs"];
    },
    stdMajorList() {
      return this.$store.getters["majors/majors"];
    },
    pageCount() {
      const filterQCount = this.filteredStudents.length;
      return Math.ceil(filterQCount / this.dataInPage);
    },
    pagedStudent() {
      const pagedItems = this.filteredStudents.slice(
        this.page * this.dataInPage - this.dataInPage,
        this.page * this.dataInPage
      );
      return pagedItems;
    },
    isSudo() {
      return this.$store.getters["isSudo"];
    },
    schedule() {
      return this.$store.getters["schedule"];
    },
  },
  methods: {
    async updateMajor() {
      if (this.selectedStd.length === 0) {
        this.error =
          "คุณยังไม่ได้เลือกนักศีกษา โปรดเลือกนักศีกษาที่ต้องการเปลี่ยนแปลงข้อมูล";
        return;
      }
      this.majorLoading = true;
      try {
        await this.$store.dispatch("adminStudents/updateStudentMajorBatch", {
          stdInfoArr: this.selectedStd,
          toMajor: this.toMajor,
          toMajorNo: this.toMajorNo,
          toMajorRemark: this.toMajorRemark,
          semester: this.semester,
          year: this.year,
        });
        this.page = 1;
        this.selectedStd = [];
        this.showDialog = null;
        await this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "บันทึกข้อมูลสำเร็จ",
        });
      } catch (e) {
        this.error = e.message;
      }
      this.majorLoading = false;
    },
    async updateStatus() {
      if (this.selectedStd.length === 0) {
        this.error =
          "คุณยังไม่ได้เลือกนักศีกษา โปรดเลือกนักศีกษาที่ต้องการเปลี่ยนแปลงข้อมูล";
        return;
      }
      try {
        await this.$store.dispatch("adminStudents/updateStudentStatusBatch", {
          stdInfoArr: this.selectedStd,
          toStatus: this.toStatus,
          toStatusRemark: this.toStatusRemark,
          semester: this.semester,
          year: this.year,
        });
        this.page = 1;
        this.selectedStd = [];
        this.showDialog = null;
        await this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "บันทึกข้อมูลสำเร็จ",
        });
      } catch (e) {
        this.error = e.message;
      }
    },
    getProgram(programCode) {
      return this.$store.getters["programs/getProgramByCode"](programCode);
    },
    getStatus(statusId) {
      return this.$store.getters["students/getStatusById"](statusId);
    },
    getMajor(majorId) {
      return this.$store.getters["majors/getMajorById"](majorId);
    },
    updateHandler() {},

    async searchStd() {
      if (!this.searchValue || this.searchValue == "") {
        this.error = "ต้องใส่ค่าในการค้นหา";
        return;
      }
      // set
      try {
        await this.$store.dispatch("adminStudents/setStudent", {
          mode: this.searchMode,
          queryText: this.searchValue,
        });
        this.page = 1;
      } catch (e) {
        this.error = e.message;
      }
    },
    closeError() {
      this.error = null;
    },
    defaultSchedule() {
      this.year = this.schedule.year;
      this.semester = this.schedule.semester;
    },

    test1() {
      setFirebaseindex();
    },
    test2() {
      getAllIndex();
    },
  },
};
</script>
