<template>
  <h2 class="text-gray-500 font-semibold pt-5 ">
    แจ้งความจำนงรอบที่ {{ qRoundToText(schedule.qRound) }} เทอม
    {{ schedule.semester }} ปีการศึกษา {{ schedule.year }}
  </h2>
  <!-- <div class="border-solid my-6 border-b-4 border-primary-250 w-48"></div> -->
  <div
    class="border-b mt-10 mb-5 flex  text-gray-500 space-x-0 md:space-x-6 text-center"
  >
    <div
      class="px-2 pb-1 cursor-pointer"
      :class="{ 'selected-table-menu': uTable == 'add' }"
      @click="uTable = 'add'"
    >
      ตะกร้าวิชา ({{ bskQuotaNo }})
    </div>
    <div
      class="px-2 pb-1 cursor-pointer"
      :class="{ 'selected-table-menu': uTable == 'waiting' }"
      @click="uTable = 'waiting'"
    >
      รอพิจารณา ({{ pendingNo }})
    </div>
    <div
      class="px-2 pb-1 cursor-pointer"
      :class="{ 'selected-table-menu': uTable == 'result' }"
      @click="uTable = 'result'"
    >
      ผลการขอโควตา ({{ reqQuotaResultStatusNo }})
    </div>
    <div
      class="px-2 pb-1 cursor-pointer"
      :class="{ 'selected-table-menu': uTable == 'cancel' }"
      @click="uTable = 'cancel'"
    >
      โควตาที่ยกเลิก ({{ reqCancelQuotaNo }})
    </div>
  </div>
  <q-add-table
    v-if="uTable == 'add'"
    @to-waiting-table="uTable = 'waiting'"
    @to-result-table="uTable = 'result'"
  ></q-add-table>
  <q-waiting-table v-if="uTable == 'waiting'"></q-waiting-table>
  <QResultTable v-if="uTable == 'result'"></QResultTable>
  <q-cancel-table v-if="uTable == 'cancel'"></q-cancel-table>

  <div class="border-solid my-10 border-b-1 border-gray-300 w-full"></div>

  <h2 class="text-gray-500 font-semibold">
    ค้นหารายวิชารอบที่ {{ qRoundToText(schedule.qRound) }} เทอม
    {{ schedule.semester }} ปีการศึกษา {{ schedule.year }}
  </h2>
  <div class="border-solid my-6 border-b-4 border-primary-250 w-48"></div>

  <!-- search subject section -->
  <div class=" flex justify-center items-center" v-if="isLoading">
    <base-spinner class="my-10"></base-spinner>
  </div>
  <div v-else>
    <base-card class="grid grid-cols-2 md:grid-cols-4 space-x-2 ">
      <div class="col-span-2 flex mt-4 w-full space-x-2">
        <div class="w-full">
          <label class="block  text-sm text-gray-500 ">ศูนย์/หลักสูตร</label>
          <select class="form-control text-sm md:text-base" v-model="campus">
            <!-- :disabled="!subject1.type" -->
            <option value="0" disabled>โปรดเลือก</option>
            <option value="R">รังสิต(4 ปี)</option>
            <option value="T">ท่าพระจันทร์(5 ปี)</option>
          </select>
        </div>
        <div class="w-full">
          <label class="block  text-sm text-gray-500 ">ค้นหาด้วย</label>
          <select
            class="form-control text-sm md:text-base"
            v-model="filterType"
          >
            <!-- :disabled="!subject1.type" -->
            <option value="0" disabled>โปรดเลือก</option>
            <option value="subCode">รหัสวิชา</option>
            <option value="subName">ชื่อวิชา</option>
            <option value="subMajor">สาขาวิชาเอก</option>
          </select>
        </div>
      </div>
      <div class="col-span-2 mt-2 pr-2 md:mt-4  w-full md:w-auto">
        <label class="block  text-sm text-gray-500 opacity-0 ">message</label>
        <select
          class="form-control px-1 text-sm md:text-base"
          v-model="filterValue"
          v-if="filterType === 'subMajor'"
        >
          <option v-for="major in majors" :value="major.id" :key="major.id">
            {{ major.code }} - {{ major.name }}
          </option>
        </select>
        <input
          class="form-control text-sm md:text-base"
          type="text"
          placeholder="ค้นหา..."
          v-model="filterValue"
          v-else
        />
      </div>
    </base-card>

    <div class="grid-table-main">
      <div
        class="
              grid grid-cols-3
              md:grid-cols-11
              grid-table-header
            "
      >
        <div class="md:hidden w-full h-auto col-span-2">วิชา</div>
        <div class="md:hidden w-full h-auto">จำนวน ขอ/รับ</div>
        <div class="hidden md:block w-full h-auto">รหัสวิชา</div>
        <div class="hidden md:block w-full h-auto md:col-span-2 ">ชื่อวิชา</div>
        <div class="hidden md:block w-full h-auto">Sec-Group</div>
        <div class="hidden md:block w-full h-auto md:col-span-2">วันเวลา</div>
        <div class="hidden md:block w-full h-auto md:col-span-2 ">
          ผู้บรรยาย
        </div>
        <div class="hidden md:block w-full h-auto">หมายเหตุ</div>
        <div class="hidden md:block w-full h-auto">จำนวน ขอ/รับ</div>
        <div class="hidden md:block w-full h-auto">ใส่ตะกร้า</div>
      </div>

      <div class="grid-table-body">
        <div
          class="grid grid-cols-3 md:grid-cols-11 grid-table-row"
          v-for="q in pagedQuota"
          :key="q.id"
        >
          <div class="col-span-2 md:col-span-9 md:grid md:grid-cols-9 ">
            <div
              class="md:col-span-3 md:grid md:grid-cols-3 md:text-center md:my-auto"
            >
              <span class="font-bold">
                {{ q.subCode }}<span class="md:hidden"> - </span></span
              >
              <span class="md:col-span-2">
                {{ q.subName }}
                <span class="md:hidden">({{ q.campus }}) </span></span
              >
            </div>
            <div
              class="md:col-span-3 md:grid md:grid-cols-3 md:text-center md:my-auto"
            >
              <span>
                <span class="md:hidden">sec </span>{{ q.secId }}-{{
                  q.group
                }}</span
              >
              <span class="md:col-span-2">
                <span class="md:hidden"> (</span>{{ q.secTime
                }}<span class="md:hidden">)</span></span
              >
            </div>

            <div
              class="md:col-span-2  md:text-center md:my-auto"
              v-if="q.instructors"
            >
              <span v-for="instructor in q.instructors" :key="instructor.id">
                {{ instructor.name }}
                <br />
              </span>
            </div>
            <div class="md:col-span-2  md:text-center md:my-auto" v-else>
              <span>{{ q.instName }}</span>
            </div>
            <div
              class="pt-2 md:pt-0 text-left md:text-center my-auto block px-1"
            >
              <div v-if="q.remark">
                <span class="md:hidden font-semibold">หมายเหตุ </span>
                <span> {{ q.remark }} </span>
              </div>
              <div v-else class="hidden md:block">-</div>
            </div>
          </div>
          <div
            class="col-span-1 md:col-span-2 md:grid md:grid-cols-2 text-center my-auto"
          >
            <div class="pb-2 md:pb-0">
              <span>
                {{ q[`q${schedule.qRound}Request`] || 0 }}/{{
                  q[`q${schedule.qRound}Offer`]
                }}
              </span>
            </div>
            <div>
              <base-button
                :mode="
                  q[`q${schedule.qRound}Offer`] === 0
                    ? 'disabled-flat'
                    : 'super-flat'
                "
                @click="addBskQuota(q)"
                v-if="canAddToBasket(q.offLink)"
                >+ ใส่ตะกร้า</base-button
              >
              <p v-else>ไม่สามารถขอโควตา</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="text-xl font-semibold py-20 text-center"
      v-if="filterdQuota.length === 0"
    >
      ไม่มีข้อมูลโควตา
    </div>
    <div v-else class="flex justify-center py-5">
      <v-pagination
        v-model="page"
        :pages="pageCount"
        :range-size="1"
        active-color="#DCEDFF"
        @update:modelValue="updateHandler"
      />
    </div>
  </div>

  <!-- end search subject section -->

  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
  <div v-if="logVisible">
    {{ page }}
    {{ pagedQuota }}
    <hr />
    {{ quota }}
    <hr />
    userId: {{ userId }}
    <br />
    userRole: {{ userRole }}
    <br />
    userInfo: {{ userInfo }}
  </div>
</template>

<script>
import { isLogVisible } from "../../js/debug.js";
import QAddTable from "../../components/Student/QAddTable.vue";
import QWaitingTable from "../../components/Student/QWaitingTable.vue";
import QResultTable from "../../components/Student/QResultTable.vue";
import QCancelTable from "../../components/Student/QCancelTable.vue";
import TextConverterMixin from "../../mixins/textConverter.js";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  mixins: [TextConverterMixin], //qRoundToText()
  components: {
    QAddTable,
    QWaitingTable,
    QResultTable,
    QCancelTable,
    VPagination,
  },
  data() {
    return {
      // search
      filterType: "subCode",
      filterValue: null,
      // pagedQuota: null,

      // system
      error: null,
      campus: "R",
      uTable: "add",
      page: 1,
      dataInPage: 10,
      isLoading: false,
    };
  },
  created() {
    this.isLoading = true;
    this.defaultProgram();
    this.isLoading = false;
  },
  computed: {
    userRole() {
      return this.$store.getters["userRole"];
    },
    userInfo() {
      return this.$store.getters["userInfo"];
    },
    userId() {
      return this.$store.getters["userId"];
    },

    //
    schedule() {
      return this.$store.getters["schedule"];
    },
    pagedQuota() {
      const pagedItems = this.filterdQuota.slice(
        this.page * this.dataInPage - this.dataInPage,
        this.page * this.dataInPage
      );
      return pagedItems;
    },
    pageCount() {
      const filterQCount = this.filterdQuota.length;
      return Math.ceil(filterQCount / this.dataInPage);
    },
    majors() {
      return this.$store.getters["majors/majors"];
    },
    logVisible() {
      return isLogVisible();
    },
    reqQuota() {
      return this.$store.getters["quota/fReqQuota"];
    },
    filter() {
      return {
        type: this.filterType,
        value: this.filterValue,
        campus: this.campus,
      };
    },
    quota() {
      return this.$store.getters["quota/quota"];
    },
    filterdQuota() {
      return this.$store.getters["quota/getQuotaByFilter"](this.filter);
    },
    bskQuotaNo() {
      return this.$store.getters["quota/bskQuotaNo"];
    },
    // reqQuotaNo() {
    //   return this.$store.getters["quota/reqQuotaNo"];
    // },
    pendingNo() {
      return this.$store.getters["quota/reqQuotaNoByStatus"]("pending");
    },
    reqQuotaResultStatusNo() {
      return this.$store.getters["quota/reqQuotaResultStatus"].length;
    },
    reqCancelQuotaNo() {
      return this.$store.getters["quota/getReqQuotaByResult"]("cancel").length;
    },
    qRoundCustomRule() {
      return this.$store.getters["scheduleCustomRule"];
    },
  },
  methods: {
    canAddToBasket(offLink) {
      if (
        this.qRoundCustomRule.onlyAccSub &&
        this.qRoundCustomRule.onlyAccSub.includes(offLink) &&
        this.userInfo.major !== 9
      ) {
        return false;
      } else {
        return true;
      }
    },
    reloadPage() {
      window.location.reload();
    },
    updateHandler() {},
    defaultProgram() {
      const theProgram = this.$store.getters["programs/getProgramByCode"](
        this.userInfo.program
      );
      if (theProgram) {
        this.campus = theProgram.camCode;
      }
    },
    addBskQuota(quota) {
      // check quota
      if (quota[`q${this.schedule.qRound}Offer`] === 0) {
        this.error = `ไม่สามารถเพิ่ม ${quota.subCode} ลงตะกร้าได้ เนื่องจากจำนวนรับเป็น 0`;
        return;
      }

      try {
        this.$store.dispatch("quota/addBskQuota", quota);
        this.$store.dispatch("updateToast", {
          mode: "info",
          show: true,
          message: `เพิ่มวิชา ${quota.subCode}(${quota.subName}) ลงตะกร้าสำเร็จ`,
        });
      } catch (e) {
        this.error = e.message;
      }
      this.uTable = "add";
    },
    closeError() {
      // this.formValid = true;
      this.error = null;
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}

.selected-table-menu {
  @apply border-b-2 font-semibold border-blue-550 text-blue-550;
}
</style>
