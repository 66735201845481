import { sortNumber } from "../../../js/utility.js";
export default {
    userProgram(state) {
        return state.userProgram;
    },
    userRole(state) {
        return state.userRole;
    },
    userInfo(state) {
        return state.userInfo;
    },
    userConsentInfo(state) {
        return state.consentInfo;
    },
    userId(state) {
        return state.userId;
    },
    isAdmin(state) {
        return state.userRole === "admin";
    },
    isStudent(state) {
        return state.userRole === "student";
    },
    toast(state) {
        return state.toast;
    },
    hasUserProgram(state) {
        return !!state.userProgram;
    },
    loginId(state) {
        return state.loginId;
    },
    isSudo(state) {
        return state.userRole === "admin" && state.userInfo.role === "A";
    },
    isStaff(state) {
        return state.userRole === "admin" && state.userInfo.role === "S";
    },
    // schedule(state) {
    //   return state.schedule;
    // },
    // fSemYear(state) {
    //   const formattedStr = state.schedule.semester + "_" + state.schedule.year;
    //   return formattedStr;
    // },
    studentChangeLog(state) {
        let resArray = [];
        for (const item of state.statusChange) {
            resArray.push({...item, type: "status" });
        }
        for (const item of state.majorChange) {
            resArray.push({...item, type: "major" });
        }
        return sortNumber(resArray, "time");
    },
};