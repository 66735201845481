<template>
  <div class="flex flex-col w-full">
    <div
      class="text-white bg-primary-200 flex flex-shrink-0 flex-col pl-4 pt-4"
    >
      <div class="flex relative  items-center p-4 h-12">
        <span class="text-2xl tracking-wide font-semibold"
          >รายงานข้อมูลนักศึกษา</span
        >
      </div>
    </div>

    <div class="text-white bg-blue-400 flex w-full pl-4">
      <div class="flex overflow-hidden h-12 ml-2">
        <router-link
          :to="{ name: 'report-student-info' }"
          class="flex items-center"
        >
          <button class="mx-3 hover:border-b-2 border-white">
            <span>ข้อมูลนักศึกษา</span>
          </button></router-link
        >
        <router-link
          :to="{ name: 'report-student-overview' }"
          class="flex items-center"
        >
          <button class="mx-3 hover:border-b-2 border-white">
            <span>สรุปจำนวนนักศึกษา</span>
          </button></router-link
        >

        <router-link
          :to="{ name: 'report-student-major' }"
          class="flex items-center"
        >
          <button class="mx-3 hover:border-b-2 border-white">
            <span>ข้อมูลการเปลี่ยนแปลงสาขาวิชาเอก</span>
          </button></router-link
        >
        <router-link
          :to="{ name: 'report-student-status' }"
          class="flex items-center"
        >
          <button class="mx-3 hover:border-b-2 border-white">
            <span>ข้อมูลการเปลี่ยนแปลงสถานภาพ</span>
          </button></router-link
        >
      </div>
    </div>
  </div>
  <div class="px-4 pb-32">
    <div class=" flex justify-center items-center" v-if="loadingData">
      <base-spinner class="my-10"></base-spinner>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
  <toast
    :show="isSuccess"
    mode="success"
    message="บันทึกเสร็จสิ้นแล้ว"
    @close="closeSuccess()"
  />
  <hr />
  <toast
    :show="!!error"
    mode="error"
    @close="error = null"
    fixed
    :message="error"
  >
  </toast>
</template>

<script>
import LoadDataMixin from "../../mixins/loadData.js";
export default {
  mixins: [LoadDataMixin], // loadYear(),
  data() {
    return {
      isSuccess: false,
      loadingData: false,
      error: null,
    };
  },
  created() {
    this.loadData();
  },

  watch: {
    $route: "fetchData",
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    isSudo() {
      return this.$store.getters["isSudo"];
    },
  },
  methods: {
    async loadData() {
      this.loadingData = true;
      await this.setScheduleList();
      this.loadingData = false;
    },
    async setScheduleList() {
      try {
        await this.$store.dispatch("setScheduleList");
      } catch (e) {
        this.error = e;
      }
    },
    closeSuccess() {
      this.isSuccess = false;
    },
    fetchData() {
      this.isSuccess = this.$route.params.success == "true" ? true : false;
    },
  },
};
</script>

<style scoped>
a:active,
a:hover,
a.router-link-active {
  @apply border-b-4 border-white pt-1;
}

a:hover span,
a:active span,
a.router-link-active span {
  @apply font-extrabold;
}
</style>
