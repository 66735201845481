// import { isLogVisible } from "../../../js/debug.js";
import { sortText, tripleSort } from "../../../js/utility.js";
export default {
    quota(state) {
        return state.quota;
    },
    hasQuota(state) {
        return state.quota.length > 0;
    },
    getQuotaByOffLink: (state, getters) => (offLink) => {
        const quota = getters.quota;
        const filterdQuota = quota.filter((q) => q.offLink === offLink);
        const sortQuota = tripleSort(filterdQuota, ["subCode", "secId", "group"]);
        return sortQuota;
    },
    getQuotaById: (state, getters) => (id) => {
        const quota = getters.quota;
        const selectedQuota = quota.find((q) => q.id === id);
        return selectedQuota || false;
    },
    // fStdQuota(state, getters, rootState, rootGetters) {
    //   const quota = getters.quota;
    //   const newQuota = [];

    //   quota.forEach((q) => {
    //     if (isLogVisible()) {
    //       console.log(q.subId);
    //     }

    //     const subDetail = rootGetters["subjects/getSubjectsById"](q.subId);
    //     if (isLogVisible()) {
    //       console.log(subDetail);
    //     }

    //     const instDetail = rootGetters["instructors/getNameById"](q.instId);
    //     if (isLogVisible()) {
    //       console.log(instDetail);
    //     }

    //     const secDetail = rootGetters["sections/getSecById"](q.secId);
    //     if (isLogVisible()) {
    //       console.log(secDetail);
    //     }

    //     newQuota.push({
    //       ...q,
    //       subject: subDetail,
    //       section: secDetail,
    //       instructor: instDetail,
    //     });
    //   });
    //   if (isLogVisible()) {
    //     console.log(newQuota);
    //   }

    //   return newQuota;
    // },
    getQuotaByFilter: (state, getters) => (filterPayload) => {
        const filterType = filterPayload.type;
        const filterValue = filterPayload.value;
        const filterCampus = filterPayload.campus || "all";

        // return all offers if user  not provide data
        if (!filterValue) {
            return getters.getQuotaByCampus(filterCampus);
        }

        // all filterd Offer return array
        if (filterType === "subCode") {
            const theQuota = getters.getQuotaBySubCode(filterValue);
            return getters.getQuotaByCampus(filterCampus, theQuota);
        } else if (filterType === "subName") {
            const theQuota = getters.getQuotaBySubName(filterValue);
            return getters.getQuotaByCampus(filterCampus, theQuota);
        } else if (filterType === "subMajor") {
            const theQuota = getters.getQuotaBySubMajor(filterValue);
            return getters.getQuotaByCampus(filterCampus, theQuota);
        }
    },

    getQuotaByCampus: (state, getters) => (campus, quota = getters.quota) => {
        if (campus === "all") {
            return quota;
        } else {
            return quota.filter((q) => q.campus === campus);
        }
    },

    getQuotaBySubMajor: (state, getters) => (subMajor) => {
        const quota = getters.quota;
        const filteredQuota = quota.filter((q) => q.majorId == subMajor);
        return filteredQuota;
    },

    getQuotaBySubCode: (state, getters) => (subCode) => {
        const regex = new RegExp(`${subCode}.*`, "i");
        const quota = getters.quota;
        const filteredQuota = quota.filter((q) => regex.test(q.subCode));
        return filteredQuota;
    },
    getQuotaBySubName: (state, getters) => (subName) => {
        const regex = new RegExp(`${subName}.*`, "i");
        const quota = getters.quota;
        const filteredQuota = quota.filter((q) => regex.test(q.subName));
        return filteredQuota;
    },
    getMajorOffLinks(state, getters, rootState, rootGetters) {
        const majors = rootGetters["majors/majors"];
        const quota = getters.quota;

        const majorOffLinks = [];

        for (const m of majors) {
            const offLinks = new Set();
            const filterdQuota = quota.filter((q) => q.majorId === m.id);
            const sortQuota = sortText(filterdQuota, "subCode");
            for (const q of sortQuota) {
                offLinks.add(q.offLink);
            }

            if (offLinks.size !== 0) {
                majorOffLinks.push({
                    mId: m.id,
                    mName: m.name,
                    offLinks: Array.from(offLinks),
                });
            }
        }
        return sortText(majorOffLinks, "mName"); //sort text by code
        // return majorOffLinks;
    },
    offLinkList(state, getters) {
        const quota = getters.quota;
        const offLinkSet = new Set();
        for (const theQuota of quota) {
            offLinkSet.add(theQuota.offLink);
        }
        if (offLinkSet.size > 0) {
            return Array.from(offLinkSet);
        } else {
            return null;
        }
    },

    reqQuota(state) {
        const reqQuota = state.reqQuota;
        return reqQuota;
    },

    reqQuotaNo(state) {
        return state.reqQuota.length;
    },

    reqQuotaNotCc(state, getters) {
        const reqQuota = getters.reqQuota;
        let filtReqQuota = reqQuota.filter(
            (q) => q.reqResult && q.reqResult.toLowerCase() !== "cancel"
        );
        filtReqQuota = filtReqQuota.filter(
            (q) => q.reqResult && q.reqResult.toLowerCase() !== "reject"
        );
        return filtReqQuota;
    },

    getReqQuotaByResult: (state, getters) => (qResult) => {
        const reqQuota = getters.reqQuota;
        const filtReqQuota = reqQuota.filter(
            (q) => q.reqResult && q.reqResult.toLowerCase() == qResult.toLowerCase()
        );
        return filtReqQuota;
    },

    reqQuotaResultStatus(state, getters) {
        const reqQuota = getters.reqQuota;
        const filtReqQuota = reqQuota.filter(
            (q) =>
            q.reqResult &&
            q.reqResult.toLowerCase() != "cancel" &&
            q.reqResult.toLowerCase() != "pending"
        );
        return filtReqQuota;
    },

    reqQuotaNoByStatus: (state, getters) => (qResult) => {
        return getters.getReqQuotaByResult(qResult).length;
    },

    // fReqQuota(state, getters, rootState, rootGetters) {
    //   const reqQuota = getters.reqQuota;
    //   const newQuota = [];

    //   reqQuota.forEach((q) => {
    //     if (isLogVisible()) {
    //       console.log(q.subId);
    //     }

    //     const subDetail = rootGetters["subjects/getSubjectsById"](q.subId);
    //     if (isLogVisible()) {
    //       console.log(subDetail);
    //     }

    //     const instDetail = rootGetters["instructors/getNameById"](q.instId);
    //     if (isLogVisible()) {
    //       console.log(instDetail);
    //     }

    //     const secDetail = rootGetters["sections/getSecById"](q.secId);
    //     if (isLogVisible()) {
    //       console.log(secDetail);
    //     }

    //     newQuota.push({
    //       ...q,
    //       subject: subDetail,
    //       section: secDetail,
    //       instructor: instDetail,
    //     });
    //   });
    //   if (isLogVisible()) {
    //     console.log(newQuota);
    //   }

    //   return newQuota;
    // },

    bskQuota(state) {
        return state.bskQuota;
    },

    bskQuotaNo(state) {
        return state.bskQuota.length;
    },
};