import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

export default {
    state() {
        return {
            schedule: {
                // adCloseDate: "4/27/2021, 4:21:51 PM",
                // adOpenDate: "4/20/2021, 4:21:51 PM",
                // isEnd: false,
                // qRound: 1,
                // // quotaMode: "transaction", // batch || transaction
                // semester: 2,
                // updateBy: "u1",
                // updateTime: "4/20/2021, 4:21:51 PM",
                // usCloseDate: "5/15/2021, 4:21:51 PM",
                // usOpenDate: "5/06/2021, 4:21:51 PM",
                // year: 2563,
            },
            scheduleRule: [],
            scheduleList: [],
            customRule: {},
            serverTime: null,
        };
    },
    getters,
    actions,
    mutations,
};