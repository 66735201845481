import { isLogVisible } from "../../../js/debug.js";
import { sortNumber } from "../../../js/utility.js";
import { db, functions } from "../../../js/firebaseDb";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  async setSchedule(context) {
    if (isLogVisible()) {
      console.log("start setSchedule");
    }

    const scheduleRef = db.collection(`schedule`).where("isEnd", "==", false);

    await scheduleRef.onSnapshot(async (querySnapshot) => {
      if (isLogVisible()) {
        console.log(querySnapshot);
      }
      if (querySnapshot.empty) {
        const error = new Error("No such document!");
        throw error;
      }

      const scheduleArr = querySnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      if (scheduleArr.length > 1) {
        const eMsg = "matched schedule exceed 1";
        if (isLogVisible()) {
          console.error(eMsg);
        }
        const error = new Error(eMsg);
        throw error;
      }

      const theSchedule = scheduleArr[0];
      context.commit("setSchedule", theSchedule);
      await context.dispatch("setScheduleRule");
      await context.dispatch("setScheduleCustomRule");
    });
  },

  async setScheduleCustomRule(context) {
    const schedule = context.getters.schedule;
    const colRef = db.collection(`parameters`).doc("quota_round_custom_rule");
    await colRef.onSnapshot((doc) => {
      if (doc.data() && doc.data()[schedule.qRound.toString()]) {
        const payload = {
          ...doc.data()[schedule.qRound.toString()],
          id: doc.id,
        };
        context.commit("setScheduleCustomRule", payload);
      }
    });
  },

  async updateSchedule(context, payload) {
    // prepare data
    const scheduleId = payload.scheduleId;
    const studentLogin = payload.studentLogin;
    const adminLogin = payload.adminLogin;

    // sending data to server
    const schedule = db.collection(`schedule`).doc(scheduleId);
    await schedule.update({
      studentLogin: studentLogin,
      adminLogin: adminLogin,
    });

    // update Vuex
    await context.dispatch("setSchedule");
  },

  async setScheduleList(context) {
    const scheduleRef = db.collection(`schedule`);
    await scheduleRef.onSnapshot((querySnapshot) => {
      if (querySnapshot.empty) {
        const error = new Error("No such document!");
        throw error;
      }

      const scheduleArr = querySnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      context.commit("setScheduleList", scheduleArr);
    });
  },

  async addScheduleRule(context, payload) {
    const event = new Date();
    const scheduleRuleRef = db.collection(`schedule_rule`).doc();
    let theData = {
      open: payload.open,
      close: payload.close,
      name: payload.name || event.toLocaleString("th-TH"),
      rule: payload.rule,
      effectUser: payload.effectUser,
      semYear: payload.semYear,
    };
    if (payload.effectUser === "student") {
      if (payload.allowStdExternal) {
        theData.allowStdExternal = payload.allowStdExternal;
      }

      if (payload.allowStdInternal) {
        theData.allowStdInternal = payload.allowStdInternal;
      }
    } else if (payload.effectUser === "staff") {
      // pass
    } else {
      throw Error(`${payload.effectUser} user is not supported`);
    }

    await scheduleRuleRef.set(theData);
  },

  async deleteScheduleRule(context, payload) {
    const scheduleRuleRef = db.collection(`schedule_rule`).doc(payload.id);
    await scheduleRuleRef.delete();
  },

  async updateScheduleRule(context, payload) {
    const event = new Date();
    const scheduleRuleRef = db.collection(`schedule_rule`).doc(payload.id);
    let theData = {
      open: payload.open,
      close: payload.close,
      name: payload.name || event.toLocaleString("th-TH"),
      rule: payload.rule,
    };
    if (payload.effectUser === "student") {
      if (payload.allowStdExternal) {
        theData.allowStdExternal = payload.allowStdExternal;
      }

      if (payload.allowStdInternal) {
        theData.allowStdInternal = payload.allowStdInternal;
      }
    } else if (payload.effectUser === "staff") {
      // pass
    } else {
      throw Error(`${payload.effectUser} user is not supported`);
    }

    await scheduleRuleRef.update(theData);
  },

  async setScheduleRule(context) {
    const fSemYear = context.rootGetters.fSemYear;
    const scheduleRuleRef = db
      .collection(`schedule_rule`)
      .where("semYear", "==", fSemYear);

    await scheduleRuleRef.onSnapshot((querySnapshot) => {
      let scheduleRuleArr = querySnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
          sortTime: doc.data().open.toDate(),
        };
      });

      scheduleRuleArr = sortNumber(scheduleRuleArr, "sortTime");
      context.commit("setScheduleRule", scheduleRuleArr);
    });
  },

  checkForceLogout(context) {
    const isAdmin = context.getters["isAdmin"];
    const isSudo = context.getters["isSudo"];
    const isStudent = context.getters["isStudent"];
    const schedule = context.getters["schedule"];
    const userRole = context.getters["userRole"];
    const userInfo = context.getters["userInfo"];

    console.log(isAdmin);
    console.log(isSudo);
    console.log(isStudent);
    console.log(schedule);
    console.log(userRole);
    console.log(userInfo);

    if (isAdmin && isSudo) {
      // pass
    } else if (isAdmin) {
      if (!schedule.adminLogin) {
        this.logoutAdmin();
      }
    } else if (isStudent) {
      if (!schedule.studentLogin) {
        this.logoutStudent();
      }
    } else {
      throw Error("mode in forceLogout error");
    }
  },

  async deleteQuotaPetitions(context) {
    const fSemYear = context.rootGetters.fSemYear;
    const deletePetitions = functions.httpsCallable("deletePetitions");
    await deletePetitions({
      folder: `${fSemYear}/`,
      fSemYear: fSemYear,
    });
  },

  async nextSchedule(context, payload) {
    const schedule = context.getters.schedule;
    const forceNextSem = payload.forceNextSem;
    const oldId = schedule.id;
    const newSchedule = {
      ...schedule,
    };
    let nextOk = false;

    // check Round
    if (schedule.qRound < 5 && !forceNextSem) {
      newSchedule["qRound"] = newSchedule["qRound"] + 1;
      nextOk = true;
    }

    // check Semester
    if (schedule.semester < 3 && !nextOk) {
      newSchedule["semester"] = newSchedule["semester"] + 1;
      newSchedule["qRound"] = 1;
      nextOk = true;

      // delete petition data
      await context.dispatch("deleteQuotaPetitions");
    }

    // check Year
    if (!nextOk) {
      newSchedule["year"] = newSchedule["year"] + 1;
      newSchedule["semester"] = 1;
      newSchedule["qRound"] = 1;
      nextOk = true;
    }

    // update new data payload
    newSchedule["updateBy"] = context.getters.userId;
    newSchedule["updateTime"] = firebase.firestore.FieldValue.serverTimestamp();
    newSchedule["adminLogin"] = true;
    newSchedule["studentLogin"] = true;
    delete newSchedule.id;

    const batch = db.batch();

    // update is end
    const oldScheduleRef = db.collection(`schedule`).doc(oldId);
    batch.update(oldScheduleRef, { isEnd: true });

    // update in Firestore
    const newScheduleRef = db.collection(`schedule`).doc();
    batch.set(newScheduleRef, newSchedule);

    await batch.commit().catch((error) => {
      console.error(error);
      throw Error("Error updating document: ", error);
    });

    // update to vuex
    newSchedule["id"] = newScheduleRef.id;
    context.commit("setSchedule", newSchedule);
  },
  runSetTime(context) {
    setInterval(async () => {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000);
      const url = "https://worldtimeapi.org/api/timezone/Asia/Bangkok";
      try {
        // throw 500;
        const res = await fetch(url, { signal: controller.signal });
        const data = await res.json();
        const ts = data.unixtime * 1000;
        // console.log("use server time");
        // console.log(ts);
        context.commit("setServerTime", ts);
      } catch (e) {
        try {
          // If world time API fails, try to fetch from your server function
          const getServerTimeFunction = functions.httpsCallable(
            "getServerTime"
          );
          const result = await getServerTimeFunction();
          const currentTime = result.data.time._seconds * 1000;
          // console.log("using firebase time");
          context.commit("setServerTime", currentTime);
        } catch (e) {
          // If both methods fail, raise an error
          throw new Error("Can't  connect to server please check your network");
        }
      } finally {
        clearTimeout(timeoutId);
      }
    }, 2000);
  },
  async currentScheduleRuleStudentServer(context) {
    const allRule = context.getters.scheduleRule;
    let currentTime = null;
    const getServerTimeFunction = functions.httpsCallable("getServerTime");

    try {
      const result = await getServerTimeFunction();
      currentTime = result.data.time._seconds * 1000; // This will be the server's current time
    } catch (error) {
      throw new Error(`Your Internet connection is not available ${error}`);
    }
    if (currentTime === null) {
      throw new Error(
        `Your Internet connection is not available : currentTime is null`
      );
    }
    const currentRule = allRule.find(
      (theRule) =>
        theRule.close.seconds * 1000 > currentTime &&
        currentTime > theRule.open.seconds * 1000 &&
        theRule.effectUser === "student"
    );
    console.log(currentRule);
    return currentRule;
  },
};
