import { isLogVisible } from "../../../js/debug.js";
import { db } from "../../../js/firebaseDb";

export default {
    //----- SUBJECT -----//

    async addSubject(context, payload) {
        const subjectData = {
            majorId: payload.majorId,
            type: payload.type,
            name: payload.name,
            code: payload.code,
            unit: payload.unit,
            year: payload.year,
            offLink: payload.offLink,
            condition: payload.condition,
        };
        const program = context.rootGetters.userProgram;

        // Update data in Firebase
        // const token = context.rootGetters.token;

        if (isLogVisible()) {
            console.log("start sending data to firbase");
        }

        // Add new Subject to Database
        const subColl = db.collection(`subjects_${program}`);
        const response = await subColl.add(subjectData).catch((error) => {
            console.error("Error adding document: ", error);
        });
        if (response.empty) {
            if (isLogVisible()) {
                const error = new Error("Can't send data to server");
                console.error(error);
            }
        }

        subjectData.id = response.id;

        context.commit("addSubject", {...subjectData });
    },

    async updateSubject(context, submittedData) {
        const id = submittedData.id;
        const newSubject = {
            majorId: submittedData.majorId,
            type: submittedData.type,
            name: submittedData.name,
            code: submittedData.code,
            unit: parseInt(submittedData.unit),
            year: submittedData.year,
            offLink: submittedData.offLink,
            condition: submittedData.condition,
        };
        const program = context.rootGetters.userProgram;

        var subRef = db.collection(`subjects_${program}`).doc(id);

        // Set all field of the Subject
        await subRef.update(newSubject).catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
        });

        const subjects = context.state.subjects;
        const selectedSubject = subjects.find((s) => s.id === id);
        var index = subjects.indexOf(selectedSubject);

        if (index !== -1) {
            subjects.splice(index, 1, newSubject);
            if (isLogVisible()) {
                console.log("updated Subject : " + index);
            }
        }

        context.commit("setSubject", subjects);
    },

    async copyCurriculum(context, payload) {
        const program = context.rootGetters.userProgram;
        const copyFromCur = parseInt(payload.copyFromCur);
        const copyToCur = parseInt(payload.copyToCur);
        const effectEst = parseInt(payload.effectEst);
        const subjects = context.getters.getSubjectByYear([copyFromCur]);

        const cloneSubjects = JSON.parse(JSON.stringify(subjects));

        for (const index in cloneSubjects) {
            delete cloneSubjects[index].id;
            cloneSubjects[index].year = copyToCur;

            const subColl = db.collection(`subjects_${program}`);
            const response = await subColl
                .add(cloneSubjects[index])
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
            if (response.empty) {
                const error = new Error("Can't send data to server");
                if (isLogVisible()) {
                    console.error(error);
                }
            }

            cloneSubjects[index].id = response.id;
        }
        await context.dispatch(
            "years/addYear", {
                year: copyToCur,
                effectEst: effectEst,
            }, { root: true }
        );

        context.state.subjects.push(...cloneSubjects);
    },

    async deleteSubjectByYear(context, payload) {
        // update data in Firbase
        if (isLogVisible()) {
            console.log("start sending delete data to firebase..." + payload.year);
        }

        const year = parseInt(payload.year);
        const program = context.rootGetters.userProgram;
        // const subjectsInYear = context.getters.getSubjectByYear([year]);
        if (isLogVisible()) {
            console.log("start sending delete data to firebase..." + subjects);
        }

        // const deletedSubject = subjects.filter((s) => s.year === year);

        const subColl = db.collection(`subjects_${program}`);
        const subDoc = await subColl
            .where("year", "==", year)
            .get()
            .catch((error) => {
                console.error("Error removing document: ", error);
            });
        const batch = db.batch();
        subDoc.forEach((doc) => {
            batch.delete(doc.ref);
        });
        await batch.commit();

        // update subject data in VueX
        const subjects = context.getters.subjects;
        const fiteredSubject = subjects.filter((s) => s.year !== year);
        context.commit("setSubject", fiteredSubject);

        // Trigger delete year method
        await context.dispatch(
            "years/deleteYear", {
                year: year,
            }, { root: true }
        );
    },

    async deleteSubject(context, payload) {
        const subjectId = payload.id;
        const program = context.rootGetters.userProgram;

        // update data in Firbase

        const subColl = db.collection(`subjects_${program}`);
        subColl
            .doc(subjectId)
            .delete()
            .catch((error) => {
                console.error("Error removing document: ", error);
            });

        // update data in VueX
        if (isLogVisible()) {
            console.log("start deleteSubject in VueX" + index);
        }

        const selectedSubject = context.getters.getSubjectsById(subjectId);
        const subjects = context.state.subjects;
        var index = subjects.indexOf(selectedSubject);
        if (index !== -1) {
            subjects.splice(index, 1);
            if (isLogVisible()) {
                console.log("removed" + index);
            }
        }
        context.commit("setSubject", subjects);
    },

    async setSubject(context) {
        const uProgram = context.rootGetters.userProgram;
        const userInfo = context.rootGetters.userInfo;
        const majors = userInfo.majors;

        const subjectRef = db.collection(`subjects_${uProgram}`);

        let subjectDocs = null;
        if (userInfo.role === "A") {
            subjectDocs = await subjectRef
                .orderBy("code")
                .get()
                .catch((error) => {
                    console.error("Error getting documents: ", error);
                });
        } else if (userInfo.role === "S") {
            subjectDocs = await subjectRef
                .where("majorId", "in", majors)
                .orderBy("code")
                .get()
                .catch((error) => {
                    console.error("Error getting documents: ", error);
                });
        } else {
            throw Error("this admin role is not supported");
        }

        if (subjectDocs.empty || !subjectDocs) {
            context.commit("clearSubject");
            const error = new Error("No such document!");
            if (isLogVisible()) {
                console.error(error);
            }

            throw error;
        }

        const subjects = subjectDocs.docs.map((doc) => {
            return {...doc.data(), id: doc.id };
        });

        context.commit("setSubject", subjects);
    },
};