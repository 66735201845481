<template>
  <div v-if="show">
    <div class="py-2 ">
      <div
        class=" px-2 py-1 shadow-md border   border-gray-200  items-center leading-none rounded-full flex lg:inline-flex"
        :class="{
          'bg-red-100': isError,
          'bg-green-50': isSuccess,
          'bg-yellow-50': isWarning,
        }"
        role="alert"
      >
        <span
          v-if="isError"
          class="flex rounded-full text-white bg-red-500 uppercase px-3 py-1 text-sm font-semibold mr-3"
          >!</span
        >
        <span
          v-if="isWarning"
          class="flex rounded-full text-white bg-yellow-500 uppercase px-3 py-1 text-sm font-semibold mr-3"
          >!</span
        >
        <svg
          v-if="isSuccess"
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 mr-3 my-1  text-green-500 "
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
        </svg>
        <span class=" mr-4 text-left flex-auto font-semibold">
          {{ message }}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 mr-2 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="tryClose()"
          v-if="canClose"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <div>
          <slot name="button"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //   data() {
  //     return {s
  //       isShow: true,
  //     };
  //   },
  props: {
    mode: {
      type: String,
      required: false,
      default: "error",
    },
    canClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  watch: {
    show: function(newVal) {
      this.isShow = newVal;
    },
  },
  computed: {
    isSuccess() {
      return this.mode === "success";
    },
    isError() {
      return this.mode === "error";
    },
    isWarning() {
      return this.mode === "warning";
    },
  },
  methods: {
    tryClose() {
      this.$emit("close");
    },
  },
};
</script>
