import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

export default {
  namespaced: true,
  state() {
    return {
      majors: [
        { text: "", value: "0" },
        { text: "AC", value: "9" },
        { text: "AI", value: "13" },
        { text: "BA", value: "10" },
        { text: "BI", value: "12" },
        { text: "FM", value: "22" },
        { text: "FN", value: "2" },
        { text: "HO", value: "5" },
        { text: "IBMP", value: "26" },
        { text: "IS", value: "4" },
        { text: "IT,IB", value: "7" },
        { text: "MK", value: "3" },
        { text: "MM", value: "23" },
        { text: "OM", value: "6" },
        { text: "RB", value: "1" },
        { text: "TU", value: "25" },
      ],
      years: [
        // { text: "2552", value: 2552, previousYear: 0 },
        // { text: "2556", value: 2556, previousYear: 2552 },
        // { text: "2558", value: 2558, previousYear: 2556 },
        // { text: "2561", value: 2561, previousYear: 2558 },
      ],
      subjects: [
        // {
        // },
        // {
        //   id: "s2",
        //   majorId: 9,
        //   type: "core",
        //   name: "FUNDAMENTAL ACCOUNTING",
        //   code: "AC201",
        //   unit: 3,
        //   year: 2556,
        //   offLink: "s1",
        //   condition: 2,
        // },
        // {
        //   id: "s3",
        //   majorId: 9,
        //   type: "core",
        //   name: "FUNDAMENTAL ACCOUNTING",
        //   code: "AC201",
        //   unit: 3,
        //   year: 2558,
        //   offLink: "s1",
        //   condition: 2,
        // },
        // {
        //   id: "s15",
        //   majorId: 9,
        //   type: "core",
        //   name: "FUNDAMENTAL ACCOUNTING",
        //   code: "AC201",
        //   unit: 3,
        //   year: 2561,
        //   offLink: "s1",
        //   condition: 2,
        // },
        // {
        //   id: "s5",
        //   majorId: 10,
        //   type: "core",
        //   name: "BUSINESS OPPORTUNITIES IN JAPAN",
        //   code: "BA331",
        //   unit: 3,
        //   year: 2558,
        //   offLink: "s5",
        //   condition: 2,
        // },
        // {
        //   id: "s6",
        //   majorId: 10,
        //   type: "core",
        //   name: "BUSINESS OPPORTUNITIES IN JAPAN",
        //   code: "BA331",
        //   unit: 3,
        //   year: 2561,
        //   offLink: "s5",
        //   condition: 2,
        // },
        // {
        //   id: "s7",
        //   majorId: 12,
        //   type: "elective",
        //   name: "BUSINESS COMMUNICATION IN CHINESS 2",
        //   code: "BI152",
        //   unit: 3,
        //   year: 2552,
        //   offLink: "s7",
        //   condition: 4,
        // },
        // {
        //   id: "s8",
        //   majorId: 12,
        //   type: "elective",
        //   name: "BUSINESS COMMUNICATION IN CHINESS 2",
        //   code: "BI152",
        //   unit: 3,
        //   year: 2556,
        //   offLink: "s7",
        //   condition: 4,
        // },
        // // {
        // //   id: "s9",
        // //   majorId: 12,
        // //   type: "elective",
        // //   name: "BUSINESS COMMUNICATION IN CHINESS 2",
        // //   code: "BI152",
        // //   unit: 3,
        // //   year: 2558,
        // //   offLink: "s7",
        // //   condition: 4,
        // // },
        // {
        //   id: "s10",
        //   majorId: 12,
        //   type: "elective",
        //   name: "BUSINESS COMMUNICATION IN CHINESS 2",
        //   code: "BI152",
        //   unit: 3,
        //   year: 2561,
        //   offLink: "s7",
        //   condition: 4,
        // },
        // {
        //   id: "s11",
        //   majorId: 0,
        //   type: "elective",
        //   name: "ECONOMICS OF AGRICULTURAL AND RURAL DEVELOPMENT",
        //   code: "EC390",
        //   unit: 3,
        //   year: 2556,
        //   offLink: "s11",
        //   condition: 2,
        // },
        // {
        //   id: "s12",
        //   majorId: 0,
        //   type: "elective",
        //   name: "ECONOMICS OF AGRICULTURAL AND RURAL DEVELOPMENT",
        //   code: "EC390",
        //   unit: 3,
        //   year: 2558,
        //   offLink: "s11",
        //   condition: 2,
        // },
        // {
        //   id: "s13",
        //   majorId: 0,
        //   type: "elective",
        //   name: "ECONOMICS OF AGRICULTURAL AND RURAL DEVELOPMENT",
        //   code: "EC390",
        //   unit: 3,
        //   year: 2561,
        //   offLink: "s11",
        //   condition: 2,
        // },
        // {
        //   id: "s14",
        //   majorId: 9,
        //   type: "elective",
        //   name: "PUBLIC POLICY AND INDUSTRIALIZATION",
        //   code: "EC482",
        //   unit: 3,
        //   year: 2556,
        //   offLink: "s14",
        //   condition: 1,
        // },
        // {
        //   id: "s16",
        //   majorId: 12,
        //   type: "elective",
        //   name: "BURIN IN TBS",
        //   code: "AC999",
        //   unit: 3,
        //   year: 2561,
        //   offLink: "s16",
        //   condition: 4,
        // },
        // {
        //   id: "s17",
        //   majorId: 12,
        //   type: "elective",
        //   name: "BURIN IN TBS",
        //   code: "AC777",
        //   unit: 3,
        //   year: 2556,
        //   offLink: "s16",
        //   condition: 4,
        // },
      ],
    };
  },
  getters,
  actions,
  mutations,
};
