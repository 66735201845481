<template>
  <transition name="fade">
    <a @click="scrollTop" v-show="visible" class="bottom-right">
      <a class="btn btn-light bg-gray-800">
        <font-awesome-icon
          class="text-primary-100"
          :icon="['fas', 'angle-double-up']"
          size="3x"
        />
      </a>
    </a>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    scrollTop: function() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 20);
    },
    scrollListener: function() {
      this.visible = window.scrollY > 150;
    },
  },
  mounted: function() {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeUnmount: function() {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}

.btn {
  border-radius: 8px;
  padding-top: 27px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
}
</style>
