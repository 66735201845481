<template>
  <tr class="text-center" v-for="(sec, index) in quotaList" :key="sec.id">
    <!-- first row -->

    <td class="px-1" v-if="index === 0">
      <div v-if="quotaList[0][`sub${last4Year[0].name}`]">
        {{ quotaList[0][`sub${last4Year[0].name}`]["code"] }}
      </div>
      <div v-else>-</div>
    </td>
    <td class="px-1" v-if="index === 0">
      <div v-if="quotaList[0][`sub${last4Year[1].name}`]">
        {{ quotaList[0][`sub${last4Year[1].name}`]["code"] }}
      </div>
      <div v-else>-</div>
    </td>
    <td class="px-1" v-if="index === 0">
      <div v-if="quotaList[0][`sub${last4Year[2].name}`]">
        {{ quotaList[0][`sub${last4Year[2].name}`]["code"] }}
      </div>
      <div v-else>-</div>
    </td>
    <td class="px-1" v-if="index === 0">
      <div v-if="quotaList[0][`sub${last4Year[3].name}`]">
        {{ quotaList[0][`sub${last4Year[3].name}`]["code"] }}
      </div>
      <div v-else>-</div>
    </td>

    <!-- second row -->
    <td class="px-1" colspan="4" v-if="index === 1">
      {{ lastSubName }}
    </td>

    <td class="px-1" colspan="4" v-if="index > 1"></td>

    <td class="px-1">{{ sec.secId }}</td>
    <td class="px-1">{{ sec.group }}</td>
    <!-- round 1 -->
    <td class="px-1">{{ sec.q1Offer >= 0 ? sec.q1Offer : "-" }}</td>
    <td
      class="px-1"
      :class="{ 'text-red-500 font-bold': sec.q1Request > sec.q1Offer }"
    >
      {{ sec.q1Request >= 0 ? sec.q1Request : "-" }}
    </td>
    <td class="px-1">{{ sec.q1Accept >= 0 ? sec.q1Accept : "-" }}</td>
    <!-- round 2 -->
    <td class="px-1">{{ sec.q2Offer >= 0 ? sec.q2Offer : "-" }}</td>
    <td
      class="px-1"
      :class="{ 'text-red-500 font-bold': sec.q2Request > sec.q2Offer }"
    >
      {{ sec.q2Request >= 0 ? sec.q2Request : "-" }}
    </td>
    <td class="px-1">{{ sec.q2Accept >= 0 ? sec.q2Accept : "-" }}</td>
    <!-- round 3 -->
    <td class="px-1">{{ sec.q3Offer >= 0 ? sec.q3Offer : "-" }}</td>
    <td
      class="px-1"
      :class="{ 'text-red-500 font-bold': sec.q3Request > sec.q3Offer }"
    >
      {{ sec.q3Request >= 0 ? sec.q3Request : "-" }}
    </td>
    <td class="px-1">{{ sec.q3Accept >= 0 ? sec.q3Accept : "-" }}</td>

    <!-- round 4 -->
    <td class="px-1">{{ sec.q4Offer >= 0 ? sec.q4Offer : "-" }}</td>
    <td
      class="px-1"
      :class="{ 'text-red-500 font-bold': sec.q4Request > sec.q4Offer }"
    >
      {{ sec.q4Request >= 0 ? sec.q4Request : "-" }}
    </td>
    <td class="px-1">{{ sec.q4Accept >= 0 ? sec.q4Accept : "-" }}</td>

    <!-- round 5 -->
    <td class="px-1">{{ sec.q5Offer >= 0 ? sec.q5Offer : "-" }}</td>
    <td
      class="px-1"
      :class="{ 'text-red-500 font-bold': sec.q5Request > sec.q5Offer }"
    >
      {{ sec.q5Request >= 0 ? sec.q5Request : "-" }}
    </td>
    <td class="px-1">{{ sec.q5Accept >= 0 ? sec.q5Accept : "-" }}</td>

    <td class="px-1">{{ sumNumber(sec.q1Accept, sec.q2Accept) }}</td>
    <td class="px-1">{{ sec.regNo >= 0 ? sec.regNo : "-" }}</td>
    <td class="px-1">{{ sumNumber(sec.regNo, sec.q3Accept) }}</td>
    <td class="px-1">
      {{
        sumNumber(
          sumNumber(sumNumber(sec.regNo, sec.q3Accept), sec.q4Accept),
          sec.q5Accept
        )
      }}
    </td>
    <td class="px-1 " v-if="sec.instructors">
      <span v-for="instructor in sec.instructors" :key="instructor.id">
        {{ instructor.name }}
        <br />
      </span>
    </td>
    <td class="px-1 " v-else>
      {{ sec.instName }}
    </td>
    <td class="px-1">
      {{ !!sec.remark ? sec.remark : "-" }}
    </td>
    <td class="px-1">
      {{ !!sec.condition ? sec.condition : "-" }}
    </td>
    <td>
      <router-link
        :to="{ name: 'quota-sec', params: { id: sec.id } }"
        v-if="secDetail"
      >
        <base-button class="mx-auto" mode="flat">Detail </base-button>
      </router-link>

      <base-button
        class="mx-auto"
        :mode="staffCanEdit ? 'flat' : 'disabled-flat'"
        @click="editQuota(sec.id)"
        v-else
        >แก้ไข</base-button
      >
    </td>
  </tr>
  <tr v-html="secondRow"></tr>
  <tr>
    <td v-for="index in 22" :key="index" class="py-5"></td>
  </tr>
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
</template>

<script>
import StaffRuleMixin from "../../mixins/staffRule.js";
// import { sortText } from "../../js/utility.js";
export default {
  mixins: [StaffRuleMixin], // ,staffCanEdit
  emits: ["edit"],
  props: {
    offLink: {
      required: true,
    },
    yearNo: {
      required: true,
    },
    secDetail: {
      require: false,
      default: false,
    },
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    lastSubName() {
      if (this.quotaList[0][`sub${this.last4Year[3].name}`]) {
        return this.quotaList[0][`sub${this.last4Year[3].name}`]["name"];
      } else if (this.quotaList[0][`sub${this.last4Year[2].name}`]) {
        return this.quotaList[0][`sub${this.last4Year[2].name}`]["name"];
      } else if (this.quotaList[0][`sub${this.last4Year[1].name}`]) {
        return this.quotaList[0][`sub${this.last4Year[1].name}`]["name"];
      } else if (this.quotaList[0][`sub${this.last4Year[0].name}`]) {
        return this.quotaList[0][`sub${this.last4Year[0].name}`]["name"];
      } else {
        return "-";
      }
    },
    secondRow() {
      if (this.quotaList.length === 1) {
        return (
          '<td class="px-1 text-center" colspan="4" v-if="index === 1">' +
          this.lastSubName +
          "</td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>"
        );
      } else {
        return "";
      }
    },
    quotaList() {
      const quotaList = this.$store.getters["quota/getQuotaByOffLink"](
        this.offLink
      );
      return quotaList;
    },
    last4Year() {
      return this.$store.getters["years/getLast4Years"];
    },
  },
  methods: {
    closeError() {
      this.error = null;
    },
    editQuota(quotaId) {
      if (!this.staffCanEdit) {
        this.error = "ระบบไม่อนุญาตให้แก้ไขโควตาในขณะนี้";
        return;
      }
      this.$emit("edit", quotaId, this.offLink);
    },
    sumNumber(n1, n2) {
      const num1 = parseInt(n1);
      const num2 = parseInt(n2);

      if (num1 >= 0 && num2 >= 0) {
        return num1 + num2;
      } else if (num1 >= 0) {
        return num1;
      } else if (num2 >= 0) {
        return num2;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
/* td {
  @apply pb-4;
} */
</style>
