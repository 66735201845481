<template>
  <button v-if="!link" :class="[btn, mode]">
    <!-- :disabled="mode === 'disabled' || mode === 'disabled-flat'" -->
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="[btn, mode]">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  computed: {
    btn() {
      if (this.mode === "super-flat") {
        return "";
      } else {
        return "btn";
      }
    },
  },
  props: {
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: false,
      default: "main",
    },
    to: {
      // type: String,
      required: false,
      default: "/",
    },
  },
};
</script>

<style scopeds>
.main {
  @apply text-white bg-primary-250  border-none hover:bg-primary-200 hover:border-primary-200  transition ease-out duration-500;
}

/* .main {
  @apply text-white bg-primary-250  border-none hover:bg-primary-100 hover:border-primary-100 hover:text-primary-400  transition ease-out duration-500;
} */

.btn {
  @apply rounded-lg py-2 px-3  text-base font-semibold cursor-pointer tracking-wider;
}

/* uppercase */

.flat {
  @apply text-primary-400 mr-4 border-none hover:bg-blue-50 hover:text-primary-400 transition ease-out duration-500;
}

.super-flat {
  @apply text-primary-400 border-none font-semibold hover:text-primary-500  transition ease-out duration-500;
}

.outline {
  @apply text-primary-400 mr-4 border-primary-400 md:border-2 hover:bg-primary-400 hover:text-gray-50 transition ease-out duration-500;
}

.gray {
  @apply bg-secondary-50 text-secondary-200 hover:shadow-inner transform hover:bg-opacity-20 transition ease-out duration-700;
}

.disabled {
  @apply bg-gray-200 text-gray-400 mr-4 border-none cursor-not-allowed;
}
.disabled-flat {
  @apply text-gray-400 cursor-not-allowed;
}

.super-flat-danger {
  @apply text-red-500 border-none font-semibold hover:text-red-600  transition ease-out duration-500;
}
</style>
