<template>
  <base-card class="mt-12">
    <div class="flex space-x-2">
      <div class="w-full">
        <label class="block  text-sm text-gray-500 ">สาขาวิชาเอกเดิม</label>
        <select
          class="form-control text-sm md:text-base"
          v-model.number="fromMajor"
        >
          <option :value="null">ทั้งหมด</option>
          <option
            v-for="major in stdMajorList"
            :value="major.id"
            :key="major.id"
          >
            {{ major.name }}
          </option>
        </select>
      </div>
      <div class="w-full">
        <label class="block  text-sm text-gray-500 ">สาขาวิชาเอกใหม่</label>
        <select
          class="form-control text-sm md:text-base"
          v-model.number="toMajor"
        >
          <option :value="null">ทั้งหมด</option>
          <option
            v-for="major in stdMajorList"
            :value="major.id"
            :key="major.id"
          >
            {{ major.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex space-x-2 pt-4">
      <div class="w-full">
        <label class="block  text-sm text-gray-500 ">ปีการศึกษาที่ย้าย</label>
        <select
          class="form-control px-1"
          placeholder=""
          v-model.number="changeYear"
        >
          <option :value="null">ทั้งหมด</option>
          <option v-for="year in scheduleYearList" :value="year" :key="year">
            {{ year }}
          </option>
        </select>
      </div>
      <div class="w-full">
        <label class="block  text-sm text-gray-500 ">เทอม</label>
        <select
          class="form-control px-1"
          placeholder=""
          v-model.number="changeSemester"
        >
          <option :value="null">ทั้งหมด</option>
          <option :value="1">1</option>
          <option :value="2">2</option>
          <option :value="3">3</option>
        </select>
      </div>
    </div>

    <div class="w-full flex justify-end pt-5">
      <base-button class="px-10" @click="downloadMajorChange()"
        >ดาวน์โหลด</base-button
      >
    </div>
  </base-card>
  <!-- {{ scheduleYearList }} -->
  <toast :show="!!error" mode="error" @close="error = null" :message="error">
  </toast>
</template>

<script>
import XLSX from "xlsx";
export default {
  computed: {
    scheduleYearList() {
      return this.$store.getters.scheduleYearList;
    },
    stdMajorList() {
      return this.$store.getters["majors/majors"];
    },
  },
  data() {
    return {
      fromMajor: null,
      toMajor: null,
      changeYear: null,
      changeSemester: null,
      error: null,
    };
  },
  methods: {
    async downloadMajorChange() {
      this.isLoading = true;
      const exportJson = await this.getMajorChangeJson();
      if (this.logVisible) {
        console.log(exportJson);
      }
      const dataWS = XLSX.utils.json_to_sheet(exportJson);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "edit_major_report.xlsx");
      this.isLoading = false;
    },
    async getMajorChangeJson() {
      try {
        let allMajorChange = await this.$store.dispatch(
          "adminStudents/getMajorChange",
          {
            changeYear: this.changeYear,
          }
        );

        if (this.fromMajor) {
          allMajorChange = allMajorChange.filter(
            (item) => item.from == this.fromMajor
          );
        }

        if (this.toMajor) {
          allMajorChange = allMajorChange.filter(
            (item) => item.to == this.toMajor
          );
        }

        if (this.changeSemester) {
          allMajorChange = allMajorChange.filter(
            (item) => item.semester == this.changeSemester
          );
        }

        const resAllMajorChange = allMajorChange.map((doc) => {
          return {
            ภาคการศึกษา: `${doc.semester}/${doc.year}`,
            เลขทะเบียน: doc.stdCode,
            "ชื่อ-นามสกุล": doc.stdName,
            สาขาเดิม: doc.fromText,
            สาขาใหม่: doc.toText,
            เอกที่: doc.majorNo,
            ผู้บันทึกข้อมูล: doc.stfId,
            วันที่บันทึกข้อมูล: doc.time.toDate(),
            หมายเหตุ: doc.remark,
          };
        });
        return resAllMajorChange;
      } catch (e) {
        this.error = e;
      }
    },
  },
};
</script>
