<template>
  <!-- This is an example component -->
  <div class="h-screen font-kanit login bg-cover">
    <div
      class="container mx-auto h-full flex flex-1 justify-center items-center"
    >
      <div class="w-full max-w-lg">
        <div class="flex justify-center">
          <form
            class="w-11/12 sm:w-4/5  m-4 p-10 bg-white bg-opacity-90 rounded shadow-xl"
          >
            <p class="text-gray-500 font-medium text-center text-xl ">
              เข้าสู่ระบบสำหรับเจ้าหน้าที่
            </p>
            <div class="mt-4">
              <label class="block text-sm text-gray-500 " for="Username"
                >Username</label
              >
              <input
                class="form-control"
                type="text"
                id="username"
                placeholder="Username"
                @blur="v$.username.$touch"
                v-model="username"
                required
              />
            </div>
            <span
              class="errors"
              v-for="error of v$.username.$errors"
              :key="error.$uid"
              ><div>{{ error.$message }}</div>
            </span>

            <div class="mt-4">
              <label class="block  text-sm text-gray-500 ">Password</label>
              <input
                class="form-control"
                type="password"
                id="password"
                placeholder="Password"
                @blur="v$.password.$touch"
                v-model="password"
                required
              />
            </div>
            <span
              class="errors"
              v-for="error of v$.password.$errors"
              :key="error.$uid"
              ><div>{{ error.$message }}</div>
            </span>

            <div class="my-8 items-center flex justify-center">
              <base-button
                type="submit"
                class="px-8 font-normal text-xl py-1.5"
                @click.prevent="adminLogin()"
              >
                Login
              </base-button>
            </div>
            <div class="flex justify-center ">
              <div class="border-solid  border-b-1 border-gray-300 w-2/3"></div>
            </div>

            <div
              class="flex pt-2 space-x-4 justify-center font-light text-sm text-blue-550"
            >
              <router-link :to="{ name: 'forget-password' }">
                <p>ลืมรหัสผ่าน</p>
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <toast
    :show="!!error"
    mode="error"
    :duration="5000"
    @close="closeError"
    :message="error"
  >
  </toast>
  <spinner-dialog :show="isLoading"></spinner-dialog>
</template>
<script>
import { db, auth } from "../../js/firebaseDb.js";
import SpinnerDialog from "../../components/UI/SpinnerDialog.vue";
import FormValidateMixin from "../../mixins/formValidation.js";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

export default {
  mixins: [FormValidateMixin], // validStdNo()
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  components: { SpinnerDialog },
  data() {
    return {
      username: null,
      password: null,

      error: null,
      isLoading: false,
      formValid: true,
    };
  },
  validations() {
    return {
      username: {
        required,
      },
      password: { required, minLengthValue: minLength(6) },
    };
  },
  methods: {
    closeError() {
      this.error = null;
    },
    async adminLogin() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.isLoading = true;

      // mapping user by users collection
      const userRef = db.collection("users");
      const userDocs = await userRef
        .where("username", "==", this.username)
        .get()
        .catch((error) => {
          this.isLoading = false;
          console.error("Error getting documents: ", error);
        });
      if (userDocs.size === 0) {
        this.isLoading = false;
        this.error = "Username not found";
        return;
      }

      if (userDocs.size > 1) {
        this.isLoading = false;
        this.error = "found user more than 1 in Database";
        return;
      }

      const userArr = userDocs.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      const userDoc = userArr[0];

      // is user is admin?
      if (!userDoc.isAdmin) {
        this.isLoading = false;
        this.error = "username is not allowed in admin page";
        return;
      }

      // is user inactive?
      if (userDoc.status && userDoc.status === "inactive") {
        this.isLoading = false;
        this.error = "username was inactived or deleted";
        return;
      }

      // check if .. user is not "A" role then check allow login from db
      if (userDoc.role !== "A") {
        const scheduleRef = db.collection("schedule");
        const scheduleDocs = await scheduleRef
          .where("isEnd", "==", false)
          .get()
          .catch((error) => {
            this.isLoading = false;
            console.error("Error getting documents: ", error);
          });

        if (scheduleDocs.size > 1) {
          this.isLoading = false;
          this.error = "found more than 1 open schedule in database";
          return;
        }

        const scheduleArr = scheduleDocs.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        const scheduleDoc = scheduleArr[0];

        if (!scheduleDoc.adminLogin) {
          this.isLoading = false;
          this.error =
            "ณ เวลานี้ยังไม่เปิดให้เจ้าหน้าที่ใช้ระบบ โปรดเข้าระบบใหม่อีกครั้ง ในเวลาที่ทางคณะกำหนด";
          return;
        }
      }

      auth
        .signInWithEmailAndPassword(userDoc.email, this.password)
        .then(() => {
          this.$router.replace({
            name: "quota-list",
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.error = error.message;
        });
    },
  },
};
</script>

<style scoped>
.login {
  /*
    background: url('https://tailwindadmin.netlify.app/dist/images/login-new.jpeg');
  */
  /* background: url("../../assets/snow.jpg");
  background-repeat: no-repeat;
  background-size: cover; 
  bg-gradient-to-r from-green-300 to-blue-300;*/
  @apply bg-brown-100;
}
</style>
