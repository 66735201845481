<template>
  <div
    class="text-base md:text-xl font-semibold  pb-20 text-center"
    v-if="!reqCancelQuota || reqCancelQuota.length === 0"
  >
    ไม่มีข้อมูลโควตาที่คุณยกเลิก
  </div>
  <div class="grid-table-main" v-else>
    <div
      class="
              grid grid-cols-3
              md:grid-cols-12
              grid-table-header
            "
    >
      <div class="col-span-2 md:hidden w-full h-auto">วิชา</div>
      <div class="md:hidden w-full h-auto">สถานะ</div>
      <div class="hidden md:block w-full h-auto">รหัสวิชา</div>
      <div class="hidden md:block w-full h-auto md:col-span-2 ">
        ชื่อวิชา
      </div>
      <div class="hidden md:block w-full h-auto">Campus</div>
      <div class="hidden md:block w-full h-auto">Sec-Group</div>
      <div class="hidden md:block w-full h-auto md:col-span-2">วันเวลา</div>
      <div class="hidden md:block w-full h-auto md:col-span-2 ">
        ผู้บรรยาย
      </div>
      <div class="hidden md:block w-full h-auto">รอบ</div>
      <div class="hidden md:block w-full h-auto md:col-span-2">สถานะ</div>
    </div>

    <div class="grid-table-body">
      <div
        class="grid grid-cols-3 md:grid-cols-12 grid-table-row"
        v-for="q in reqCancelQuota"
        :key="q.id"
      >
        <div class="col-span-2 md:col-span-10 md:grid md:grid-cols-10 pl-2">
          <div
            class="md:col-span-4 md:grid md:grid-cols-4 md:text-center md:my-auto"
          >
            <span class="font-semibold">
              {{ q.subCode }}<span class="md:hidden"> - </span></span
            >

            <span class="md:col-span-2">
              {{ q.subName }}
            </span>
            <span class="">
              <span class=" md:hidden">(</span>
              {{ q.campus }}</span
            >
            <span class=" md:hidden">)</span>
          </div>
          <div
            class="md:col-span-3 md:grid md:grid-cols-3 md:text-center md:my-auto"
          >
            <span>
              <span class="md:hidden">sec </span>{{ q.quota.secId }}-{{
                q.quota.group
              }}</span
            >
            <span class="md:col-span-2"> ({{ q.quota.secTime }})</span>
          </div>
          <div
            class="md:col-span-2  md:text-center md:my-auto"
            v-if="q.quota.instructors"
          >
            <span
              v-for="instructor in q.quota.instructors"
              :key="instructor.id"
            >
              {{ instructor.name }}
              <br />
            </span>
          </div>
          <div class="md:col-span-2  md:text-center md:my-auto" v-else>
            <span>{{ q.quota.instName }}</span>
          </div>
          <div class="md:text-center pt-3 md:pt-0 md:my-auto">
            <span>
              <span class="md:hidden">รอบ: </span>
              {{ qRoundToText(q.reqQutTime) }}
            </span>
          </div>
        </div>
        <div
          class="col-span-1 md:col-span-2 md:grid md:grid-cols-1 text-left md:text-center my-auto "
        >
          <div
            class="text-blue-500 mx-auto flex justify-left md:justify-center md:my-auto pb-4 md:pb-0"
          >
            <div class="flex items-center">
              <ResultIcon :reqQuota="q" mode="student"></ResultIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SpinnerDialog :show="loadingDialog"></SpinnerDialog>
</template>
<script>
import ResultIcon from "../UI/ResultIcon.vue";
import SpinnerDialog from "../../components/UI/SpinnerDialog.vue";
import TextConverterMixin from "../../mixins/textConverter.js";

export default {
  mixins: [TextConverterMixin], //qRoundToText()
  components: { ResultIcon, SpinnerDialog },
  data() {
    return {
      loadingDialog: false,
    };
  },
  computed: {
    reqCancelQuota() {
      return this.$store.getters["quota/getReqQuotaByResult"]("cancel");
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
