<template>
  <DataModal
    title="ข้อตกลงและเงื่อนไขการใช้บริการ"
    :show="consentDialog"
    @close="confirmNotConsent = true"
    @submit="confirmConsent()"
    submitText="ยอมรับ"
    :cancelBtn="false"
    :submitBtn="!submitingConsent"
    :canScroll="false"
  >
    <div class="flex justify-center py-10" v-if="submitingConsent">
      <base-spinner></base-spinner>
    </div>

    <div v-else>
      <div
        class=" shadow-sm py-6 px-2 border"
        style="max-height: 50vh; overflow-y: auto;"
      >
        <privacy-policy-content></privacy-policy-content>
      </div>
      <div class="px-2 py-2 flex">
        <input
          type="checkbox"
          class="form-checkbox mt-1"
          v-model="checkedConsent"
        />
        <div class="pl-2">
          Give consent for Thammasat Business School to collect (either directly
          from you or from other sources) and use your Data and to disclose such
          Data to Thammasat Business School partners and other relevant third
          parties.
          <!--**, and further consent to such persons collecting and using
          your Data for the purposes of analytics and promotion or marketing of
          their products and services (including special benefits) that may be
          of benefit and interest to you.-->
        </div>
      </div>
    </div>
  </DataModal>
  <ConfirmModal
    title="หากคุณปฏิเสธข้อตกลงและเงื่อนไขการใช้บริการ คุณจะไม่สามารถใช้งานเว็บไซต์ได้ ยืนยันการปฏิเสธ"
    :show="confirmNotConsent"
    @confirm="closeConsent()"
    @close="confirmNotConsent = false"
    mode="danger"
  ></ConfirmModal>
  <toast
    :show="!!error"
    mode="error"
    @close="error = null"
    fixed
    :message="error"
  >
  </toast>
</template>

<script>
import DataModal from "./DataModal.vue";
import ConfirmModal from "./ConfirmModal.vue";
import StudentMixin from "../../mixins/student.js";
import PrivacyPolicyContent from "../../components/Student/PrivacyPolicyContent.vue";
export default {
  components: {
    DataModal,
    ConfirmModal,
    "privacy-policy-content": PrivacyPolicyContent,
  },
  mixins: [StudentMixin], // logoutStudent();
  data() {
    return {
      confirmNotConsent: false,
      checkedConsent: false,
      submitingConsent: false,
      expDuration: 180, // minute or day

      error: null,
    };
  },
  computed: {
    stdInfo() {
      return this.$store.getters.userInfo;
    },
    consentInfo() {
      return this.$store.getters.userConsentInfo;
    },
    consentDialog() {
      // return false;
      if (this.consentInfo) {
        const currentTime = new Date();
        const expDate = this.consentInfo.time.toDate();
        // expDate.setMinutes(expDate.getMinutes() + this.expDuration);
        expDate.setDate(expDate.getDate() + this.expDuration);
        if (expDate && currentTime) {
          if (currentTime < expDate) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    async confirmConsent() {
      if (!this.checkedConsent) {
        this.error = "โปรดยอมรับเงื่อนไขการใช้บริการ";
        return;
      }
      this.submitingConsent = true;
      // call action
      await this.$store.dispatch("addUserConsent", {
        uuid: this.stdInfo.id,
        username: this.stdInfo.stdCode,
      });
      this.submitingConsent = false;
      this.error = null;
    },
    closeConsent() {
      this.confirmNotConsent = false;
      this.logoutStudent();
    },
  },
};
</script>
