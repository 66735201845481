<template>
  <DatePicker
    class="inline-block h-full"
    :modelValue="theDate"
    @update:modelValue="theDate = $event"
    timezone="Asia/Bangkok"
  >
    <template v-slot="{ inputValue, togglePopover }">
      <div class="flex items-center" @click="togglePopover()">
        <button
          class="p-2  h-10 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            class="w-4 h-4 fill-current"
          >
            <path
              d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
            ></path>
          </svg>
        </button>
        <input
          :value="inputValue"
          class="bg-white text-gray-700 w-full py-2 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
          readonly
        />
      </div>
    </template>
  </DatePicker>
</template>

<!-- https://vcalendar.io/timezones.html -->
<script>
import { DatePicker } from "v-calendar";
export default {
  components: { DatePicker },
  data() {
    return { theDate: null };
  },
};
</script>
