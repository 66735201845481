<template>
  <div class="pb-10">
    <h2 class="text-gray-500 font-semibold pt-5 ">
      ระบบยื่นคำร้องขอเพิ่มวิชา รอบที่ {{ qRoundToText(schedule.qRound) }} เทอม
    {{ schedule.semester }}/{{ schedule.year }}
    </h2>

    <div>
      <div
        class="border-b mt-10 mb-5 flex  text-gray-500 space-x-0 md:space-x-6 text-center"
      >
        <div
          class="px-2 pb-1 cursor-pointer"
          :class="{ 'selected-table-menu': uMenu == 'addPet' }"
          @click="uMenu = 'addPet'"
        >
          ยื่นคำร้อง
        </div>
        <div
          class="px-2 pb-1 cursor-pointer"
          :class="{ 'selected-table-menu': uMenu == 'myPet' }"
          @click="uMenu = 'myPet'"
        >
          คำร้องของฉัน
        </div>
      </div>

      <!-- search quota table -->
      <div v-if="uMenu === 'addPet'">
        <div v-if="canAddQuotaPetition">
          <base-card class="grid grid-cols-2 md:grid-cols-4 space-x-2 ">
            <div class="col-span-2 flex mt-4 w-full space-x-2">
              <div class="w-full">
                <label class="block  text-sm text-gray-500 "
                  >ศูนย์/หลักสูตร</label
                >
                <select
                  class="form-control text-sm md:text-base"
                  v-model="campus"
                >
                  <!-- :disabled="!subject1.type" -->
                  <option value="0" disabled>โปรดเลือก</option>
                  <option value="R">รังสิต(4 ปี)</option>
                  <option value="T">ท่าพระจันทร์(5 ปี)</option>
                </select>
              </div>
              <div class="w-full">
                <label class="block  text-sm text-gray-500 ">ค้นหาด้วย</label>
                <select
                  class="form-control text-sm md:text-base"
                  v-model="filterType"
                >
                  <!-- :disabled="!subject1.type" -->
                  <option value="0" disabled>โปรดเลือก</option>
                  <option value="subCode">รหัสวิชา</option>
                  <option value="subName">ชื่อวิชา</option>
                  <option value="subMajor">สาขาวิชาเอก</option>
                </select>
              </div>
            </div>
            <div class="col-span-2 mt-2 pr-2 md:mt-4  w-full md:w-auto">
              <label class="block  text-sm text-gray-500 opacity-0 "
                >message</label
              >
              <select
                class="form-control px-1 text-sm md:text-base"
                v-model="filterValue"
                v-if="filterType === 'subMajor'"
              >
                <option
                  v-for="major in majors"
                  :value="major.id"
                  :key="major.id"
                >
                  {{ major.code }} - {{ major.name }}
                </option>
              </select>
              <input
                class="form-control text-sm md:text-base"
                type="text"
                placeholder="ค้นหา..."
                v-model="filterValue"
                v-else
              />
            </div>
          </base-card>

          <div class="grid-table-main">
            <div
              class="
              grid grid-cols-3
              md:grid-cols-11
              grid-table-header
            "
            >
              <div class="md:hidden w-full h-auto col-span-2">วิชา</div>
              <div class="md:hidden w-full h-auto">จำนวน ขอ/รับ</div>
              <div class="hidden md:block w-full h-auto">รหัสวิชา</div>
              <div class="hidden md:block w-full h-auto md:col-span-2 ">
                ชื่อวิชา
              </div>
              <div class="hidden md:block w-full h-auto">Sec-Group</div>
              <div class="hidden md:block w-full h-auto md:col-span-2">
                วันเวลา
              </div>
              <div class="hidden md:block w-full h-auto md:col-span-2 ">
                ผู้บรรยาย
              </div>
              <div class="hidden md:block w-full h-auto">หมายเหตุ</div>
              <div class="hidden md:block w-full h-auto">จำนวน ขอ/รับ</div>
              <div class="hidden md:block w-full h-auto">ยื่นคำร้อง</div>
            </div>

            <div class="grid-table-body">
              <div
                class="grid grid-cols-3 md:grid-cols-11 grid-table-row"
                v-for="q in pagedQuota"
                :key="q.id"
              >
                <div class="col-span-2 md:col-span-9 md:grid md:grid-cols-9 ">
                  <div
                    class="md:col-span-3 md:grid md:grid-cols-3 md:text-center md:my-auto"
                  >
                    <span class="font-bold">
                      {{ q.subCode }}<span class="md:hidden"> - </span></span
                    >
                    <span class="md:col-span-2">
                      {{ q.subName }}
                      <span class="md:hidden">({{ q.campus }}) </span></span
                    >
                  </div>
                  <div
                    class="md:col-span-3 md:grid md:grid-cols-3 md:text-center md:my-auto"
                  >
                    <span>
                      <span class="md:hidden">sec </span>{{ q.secId }}-{{
                        q.group
                      }}</span
                    >
                    <span class="md:col-span-2">
                      <span class="md:hidden"> (</span>{{ q.secTime
                      }}<span class="md:hidden">)</span></span
                    >
                  </div>

                  <div
                    class="md:col-span-2  md:text-center md:my-auto"
                    v-if="q.instructors"
                  >
                    <span
                      v-for="instructor in q.instructors"
                      :key="instructor.id"
                    >
                      {{ instructor.name }}
                      <br />
                    </span>
                  </div>
                  <div class="md:col-span-2  md:text-center md:my-auto" v-else>
                    <span>{{ q.instName }}</span>
                  </div>
                  <div
                    class="pt-2 md:pt-0 text-left md:text-center my-auto block px-1"
                  >
                    <div v-if="q.remark">
                      <span class="md:hidden font-semibold">หมายเหตุ </span>
                      <span> {{ q.remark }} </span>
                    </div>
                    <div v-else class="hidden md:block">-</div>
                  </div>
                </div>
                <div
                  class="col-span-1 md:col-span-2 md:grid md:grid-cols-2 text-center my-auto"
                >
                  <div class="pb-2 md:pb-0">
                    <span>
                      {{ q[`q${schedule.qRound}Request`] || 0 }}/{{
                        q[`q${schedule.qRound}Offer`]
                      }}
                    </span>
                  </div>
                  <div>
                    <base-button
                      :mode="
                        isPetDuplicate(q.id) ? 'disabled-flat' : 'super-flat'
                      "
                      @click="selectQuota(q)"
                      :disabled="isPetDuplicate(q.id)"
                      >ยื่นคำร้อง</base-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="text-xl font-semibold py-20 text-center"
            v-if="filterdQuota.length === 0"
          >
            ไม่มีข้อมูลโควตา
          </div>
          <div v-else class="flex justify-center py-5">
            <v-pagination
              v-model="page"
              :pages="pageCount"
              :range-size="1"
              active-color="#DCEDFF"
            />
          </div>
        </div>
        <div class="text-center py-10" v-else>
          <h4>ณ ขณะนี้ยังไม่เปิดระบบให้ยื่นคำร้อง</h4>
        </div>
      </div>

      <div v-if="uMenu === 'myPet'">
        <div class="text-center py-10" v-if="myPetitions.length === 0">
          <h4>คุณยังไม่มีคำร้องขอเพิ่มวิชา</h4>
        </div>

        <div v-else class="grid-table-main">
          <div
            class="
              grid grid-cols-3
              md:grid-cols-12
              grid-table-header
            "
          >
            <div class="col-span-2 md:hidden w-full h-auto">วิชา</div>
            <div class="md:hidden w-full h-auto">สถานะ</div>
            <div class="hidden md:block w-full h-auto">รหัสวิชา</div>
            <div class="hidden md:block w-full h-auto md:col-span-3 ">
              ชื่อวิชา
            </div>
            <div class="hidden md:block w-full h-auto">Campus</div>
            <div class="hidden md:block w-full h-auto">Sec-Group</div>
            <div class="hidden md:block w-full h-auto md:col-span-2">
              วันเวลา
            </div>
            <div class="hidden md:block w-full h-auto md:col-span-2 ">
              ผู้บรรยาย
            </div>
            <div class="hidden md:block w-full h-auto md:col-span-2">สถานะ</div>
          </div>
          <!-- {{ myPetitions }} -->
          <div class="grid-table-body">
            <div
              class="grid grid-cols-3 md:grid-cols-12 grid-table-row"
              v-for="q in myPetitions"
              :key="q.id"
            >
              <div
                class="col-span-2 md:col-span-10 md:grid md:grid-cols-10 pl-2"
              >
                <div
                  class="md:col-span-5 md:grid md:grid-cols-5 md:text-center md:my-auto"
                >
                  <span class="font-bold">
                    {{ q.stdSubCode
                    }}<span class="md:hidden">
                      -
                    </span></span
                  >
                  <span class="md:col-span-3">{{ q.quotaInfo.subName }} </span>
                  <span class="">
                    <span class=" md:hidden"> (</span>{{ q.quotaInfo.campus
                    }}<span class=" md:hidden">)</span>
                  </span>
                </div>
                <div
                  class="md:col-span-3 md:grid md:grid-cols-3 md:text-center md:my-auto"
                >
                  <span>
                    <span class="md:hidden">sec </span
                    >{{ q.quotaInfo.secId }}-{{ q.quotaInfo.group }}</span
                  >
                  <span class="md:col-span-2">
                    <span class=" md:hidden"> (</span>{{ q.quotaInfo.secTime
                    }}<span class=" md:hidden"> )</span></span
                  >
                </div>

                <div
                  class="md:col-span-2  md:text-center md:my-auto"
                  v-if="q.quotaInfo.instructors"
                >
                  <span
                    v-for="instructor in q.quotaInfo.instructors"
                    :key="instructor.id"
                  >
                    {{ instructor.name }}
                    <br />
                  </span>
                </div>
                <div class="md:col-span-2  md:text-center md:my-auto" v-else>
                  <span>{{ q.quotaInfo.instName }}</span>
                </div>
              </div>
              <div
                class="col-span-1 md:col-span-2  md:grid  text-left md:text-center my-auto "
              >
                <div
                  class=" mx-auto flex justify-left md:justify-center md:my-auto pb-4 md:pb-0"
                >
                  <div
                    class="flex text-yellow-500"
                    v-if="q.status === 'pending'"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 "
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span class="font-semibold" title="รอการพิจารณาจากทางคณะฯ">
                      รอพิจารณา
                    </span>
                  </div>
                  <div class="flex text-blue-500 " v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 "
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span
                      class="font-semibold"
                      title="โปรดตรวจสอบผลพิจารณาหน้าผลแจ้งความจำนง"
                    >
                      พิจารณาแล้ว
                    </span>
                  </div>
                </div>
                <div
                  class=""
                  v-if="q.status === 'pending' && canAddQuotaPetition"
                >
                  <span
                    class=" p-3  text-red-500 hover:text-red-600 hover:font-medium cursor-pointer "
                    @click="
                      () => {
                        cancelModalVisible = true;
                        cancelPet = q;
                      }
                    "
                  >
                    ยกเลิก
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Dialog -->
    <data-modal
      :show="petModal"
      :deleteBtn="false"
      title="ยื่นคำร้องโควตา"
      submitText="ยื่นคำร้อง"
      @close="closePetModal()"
      @submit="addQuotaPetition()"
    >
      <div>
        <!-- subject data -->
        <div class="border rounded border-gray-50 bg-gray-50 px-4 py-4 mb-4">
          <div>
            <span class="font-semibold">วิชา : </span
            >{{ selectedQuota.subCode }} - {{ selectedQuota.subName }}
          </div>
          <div>
            <span class="font-semibold">section : </span
            >{{ selectedQuota.secId }}-{{ selectedQuota.group }} ({{
              selectedQuota.secTime
            }})
          </div>
          <div class="" v-if="selectedQuota.instructors">
            <span class="font-semibold">อาจารย์ : </span>
            <span
              v-for="instructor in selectedQuota.instructors"
              :key="instructor.id"
            >
              {{ instructor.name }} ,
            </span>
          </div>
          <div class="" v-else>
            <span class="font-semibold">อาจารย์ : </span>
            <span>{{ selectedQuota.instName }}</span>
          </div>
          <div v-if="selectedQuota.remark">
            <span class="font-semibold">หมายเหตุ : </span>
            <span> {{ selectedQuota.remark }} </span>
          </div>
          <div>
            <span class="font-semibold">จำนวน ขอ/รับ : </span>
            <span>
              {{ selectedQuota[`q${schedule.qRound}Request`] || 0 }}/{{
                selectedQuota[`q${schedule.qRound}Offer`]
              }}
            </span>
          </div>
        </div>

        <!-- contact data -->
        <div class="border rounded border-gray-50 bg-gray-50 px-4 py-4 mb-4">
          <div>
            <span class="font-semibold">เบอร์โทร : </span>{{ userInfo.tel }}
          </div>
          <div>
            <span class="font-semibold">E-mail : </span>{{ userInfo.email }}
          </div>
          <div>
            <div class="mb-4">
              หากข้อมูลติดต่อผิดพลาดโปรดแก้ไขในข้อมูลส่วนตัวของนักศึกษาก่อนกดยื่นคำร้อง
            </div>
            <base-button
              class="md:px-10"
              mode="outline "
              :link="true"
              :to="{ name: 'std-info' }"
              >แก้ไข</base-button
            >
          </div>
        </div>
        <form @submit.prevent class="space-y-2">
          <div class="form-row" v-if="!isTbsStudent">
            <label>
              คณะ
            </label>
            <input
              class="form-control"
              :class="{
                errors: v$.stdSchool.$error,
              }"
              type="text"
              v-model="stdSchool"
            />
            <span
              class="errors"
              v-for="error of v$.stdSchool.$errors"
              :key="error.$uid"
              >{{ error.$message }} <br />
            </span>
          </div>
          <div class="form-row space-y-1">
            <label>
              เหตุผลที่ต้องการศึกษาในรายวิชานี้
            </label>
            <div v-for="petReason in petitionReasons" :key="petReason.id">
              <input
                class="mr-1 form-checkbox"
                @blur="v$.checkedReason.$touch"
                :class="{
                  errors: v$.checkedReason.$error,
                }"
                type="checkbox"
                :id="petReason.id"
                :value="petReason.message"
                v-model="checkedReason"
              />
              <label :for="petReason.id">{{ petReason.message }}</label>
            </div>
            <span
              class="errors"
              v-for="error of v$.checkedReason.$errors"
              :key="error.$uid"
              >{{ error.$message }} <br />
            </span>
          </div>
          <div class="form-row">
            <label>
              เหตุผลเพิ่มเติม
            </label>
            <!-- <input class="form-control" type="text" v-model="addReason" /> -->
            <textarea  class="form-control" v-model="addReason" placeholder="" rows="4"></textarea>
          </div>
          <div class="mt-4">
            <label class="mt-6"
              >เอกสารประกอบการยื่นคำร้อง (ไฟล์ pdf ขนาดไม่เกิน 50 MB)
              ซึ่งในไฟล์ประกอบด้วย</label
            >
            <label class="flex flex-col">
              <span>1.ผลการแจ้งความจำนงรายวิชา (หาได้จาก TBS Quota)</span>
              <span>2.ผลการเรียน (หาได้จาก Reg)</span>
              <span
                >3.แผนการเรียน/ตารางเรียนตลอดสัปดาห์ ระบุวิชาที่กำลังขอ Quota
                ด้วย (สร้างได้จากการเขียนหรือพิมพ์จากโปรแกรมใดๆ)</span
              >
            </label>

            <div class="">
              <base-button
                @click="onPickFile()"
                mode="outline"
                class="flex"
                v-if="!theFile"
                @blur="v$.checkedReason.$touch"
              >
                <svg
                  class="w-6 h-6"
                  data-darkreader-inline-fill=""
                  fill="currentColor"
                  style="--darkreader-inline-fill: currentColor;"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <div>Add PDF</div>
              </base-button>
              <div
                v-else
                class="w-auto py-2 px-2 border border-gray-300 rounded-r-md flex items-center justify-between"
              >
                <div>{{ fileName }}</div>
                <div @click="theFile = null">
                  <svg
                    class="w-6 h-6 text-gray-500 cursor-pointer"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <span
                class="errors"
                v-for="error of v$.theFile.$errors"
                :key="error.$uid"
                >{{ error.$message }} <br />
              </span>

              <input
                type="file"
                style="display: none"
                ref="fileInput"
                accept="application/pdf"
                @change="onFilePicked"
              />
            </div>
          </div>
        </form>
      </div>
    </data-modal>
    <SpinnerDialog :show="isUploading" text="กำลังบันทึกข้อมูล"></SpinnerDialog>
    <toast
      :show="!!error"
      mode="error"
      @close="error = null"
      fixed
      :message="error"
    >
    </toast>
    <confirm-modal
      :show="cancelModalVisible"
      title="ยืนยันยกเลิกคำร้องนี้ออกจากระบบ"
      @close="cancelModalVisible = false"
      @confirm="cancelQuotaPet()"
    >
    </confirm-modal>
  </div>
  <!-- {{ petitionReasons }} -->
</template>
<script>
import LoadDataMixin from "../../mixins/loadData.js";
import StudentRuleMixin from "../../mixins/studentRule.js";
import TextConverterMixin from "../../mixins/textConverter.js";
// import { storage } from "../../js/firebaseDb.js";
import SpinnerDialog from "../../components/UI/SpinnerDialog.vue";
import DataModal from "../../components/UI/DataModal.vue";
import ConfirmModal from "../../components/UI/ConfirmModal.vue";
// import { isLogVisible } from '../../js/debug.js';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mixins: [LoadDataMixin, StudentRuleMixin,TextConverterMixin], // loadQuotaPetition,canAddQuotaPetitio,qRoundToText()
  components: { SpinnerDialog, VPagination, DataModal, ConfirmModal },

  data() {
    return {
      // search
      filterType: "subCode",
      filterValue: null,
      campus: "R",
      uTable: "add",
      page: 1,
      dataInPage: 10,

      // modal
      petModal: false,
      checkedReason: [],
      addReason: null,
      selectedQuota: null,
      theFile: null,
      stdSchool: null,

      // cancel
      cancelModalVisible: false,
      cancelPet: null,

      // system
      isUploading: false,
      error: null,
      subjectPetition: null,
      sectionPetition: null,
      groupPetition: null,
      reasonPetition: null,
      personalReasonPetition: null,
      uMenu: "addPet",
    };
  },
  validations() {
    return {
      checkedReason: { required, minLength: minLength(1) },
      theFile: {
        required: helpers.withMessage(
          "จำเป็นต้องแนบเอกสารประกอบการพิจารณา",
          required
        ),
      },
      stdSchool: { required },
    };
  },
  async created() {
    await this.loadData();
    this.defaultProgram();
  },
  computed: {
    userInfo() {
      return this.$store.getters["userInfo"];
    },
    fSemYear() {
      return this.$store.getters["fSemYear"];
    },
    schedule() {
      return this.$store.getters["schedule"];
    },
    petitionReasons() {
      return this.$store.getters["stdQuotaPetitions/petitionReasons"];
    },
    myPetitions() {
      return this.$store.getters["stdQuotaPetitions/stdPetitions"];
    },
    fileName() {
      return this.theFile ? this.theFile.name : "";
    },
    filterdQuota() {
      return this.$store.getters["quota/getQuotaByFilter"](this.filter);
    },
    pagedQuota() {
      const pagedItems = this.filterdQuota.slice(
        this.page * this.dataInPage - this.dataInPage,
        this.page * this.dataInPage
      );
      return pagedItems;
    },
    pageCount() {
      const filterQCount = this.filterdQuota.length;
      return Math.ceil(filterQCount / this.dataInPage);
    },
    filter() {
      return {
        type: this.filterType,
        value: this.filterValue,
        campus: this.campus,
      };
    },
    isTbsStudent() {
      const stdStr = this.userInfo.stdCode.toString();
      if (stdStr.substring(2, 4) == "02") {
        return true;
      } else {
        return false;
      }
    },
    stdMajorNSchool() {
      if (this.isTbsStudent) {
        return this.getMajor(this.userInfo.major).name;
      } else {
        return this.stdSchool;
      }
    },
    majors() {
      return this.$store.getters["majors/majors"];
    },
  },
  methods: {
    defaultProgram() {
      const theProgram = this.$store.getters["programs/getProgramByCode"](
        this.userInfo.program
      );
      if (theProgram) {
        this.campus = theProgram.camCode;
      }
    },
    async loadData() {
      try {
        await this.$store.dispatch("stdQuotaPetitions/setPetitionReason");
        await this.$store.dispatch("stdQuotaPetitions/setStdPetition");
      } catch (e) {
        this.error = e.message;
      }
    },
    getMajor(majorId) {
      return this.$store.getters["majors/getMajorById"](majorId);
    },
    clearPetModalForm() {
      this.checkedReason = [];
      this.addReason = null;
      this.selectedQuota = null;
      this.theFile = null;
      this.v$.$reset();
    },
    closePetModal() {
      this.petModal = false;
    },
    async cancelQuotaPet() {
      if (this.cancelPet.status !== "pending") {
        this.error = "ไม่สามารถยกเลิกคำร้องวิชาที่พิจารณาแล้ว";
        return;
      }

      this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังบันทึกข้อมูล",
      });
      try {
        await this.$store.dispatch("stdQuotaPetitions/updatePetition", {
          id: this.cancelPet.id,
        });
        this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "ยกเลิกคำร้องสำเร็จ",
        });
      } catch (e) {
        this.$store.dispatch("clearToast");
        this.error = e.message;
      }
    },
    async addQuotaPetition() {
      this.v$.checkedReason.$touch();
      this.v$.theFile.$touch();
      if (!this.isTbsStudent) {
        this.v$.stdSchool.$touch();
      }

      if (
        this.v$.checkedReason.$error ||
        this.v$.theFile.$error ||
        (!this.isTbsStudent && this.v$.stdSchool.$error)
      ) {
        return;
      }

      if (this.isPetDuplicate(this.selectedQuota.id)) {
        this.error = "ไม่สามารถยื่นคำร้อง คุณได้ทำการขอโควตาดังกล่าวไปแล้ว";
        return;
      }

      this.isUploading = true;
      try {
        await this.$store.dispatch("stdQuotaPetitions/addQuotaPetition", {
          studentInfo: this.userInfo,
          reason: this.checkedReason,
          personalReason: this.addReason,
          theFile: this.theFile,
          quotaInfo: this.selectedQuota,
          isTbsStd: this.isTbsStudent,
          stdMajor: this.stdMajorNSchool,
          qRound: this.schedule.qRound,
          fSemYear: this.fSemYear,
          status: "pending",
          // time TODO
        });
        this.closePetModal();
        this.clearPetModalForm();
        await this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "บันทึกข้อมูลสำเร็จ",
        });
      } catch (e) {
        this.error = e;
        console.error(e);
      }
      this.isUploading = false;
    },
    // async addQuotaPetition_2() {
    //   // check form

    //   // call action
    //   const payload = {
    //     studentInfo: this.userInfo,
    //     reason: this.reasonPetition,
    //     addReason: this.addReason,
    //     file: "abc",
    //     quotaId: this.selectedQuota.id,
    //   };
    //   await db
    //     .collection("quota_petitions")
    //     .add(payload)
    //     .catch((error) => {
    //       this.error = error.message;
    //     });
    // },

    selectQuota(theQuota) {
      if (this.selectedQuota && theQuota.id !== this.selectedQuota.id) {
        this.clearPetModalForm();
      }
      this.selectedQuota = theQuota;
      this.petModal = true;
    },
    validateSize(files, size) {
      const fileSize = files[0].size / 1024 / 1024; // in MiB
      return fileSize <= size;
    },
    onPickFile() {
      this.v$.checkedReason.$touch;
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      // select file and save file ref in to varaible
      const files = event.target.files;
      const fName = files[0].name;
      if (!this.validateSize(files, 50)) {
        this.error = "Can't add the file : file size exceeds 50 MiB ";
        this.theFile = null;
        return;
      }
      if (fName.lastIndexOf(".") <= 0) {
        this.error = "Please add a valid file!";
        return;
      }
      // const fileReader = new FileReader();
      // fileReader.readAsDataURL(files[0]);
      this.theFile = files[0];
    },
    // uploadPDF() {
    //   this.isUploading = true;
    //   try {
    //     // prepare data
    //     const dt = new Date();
    //     const dtStr = `${dt.getDate()}-${dt.getMonth()}-${dt.getFullYear()}_${dt.getHours()}-${dt.getMinutes()}-${dt.getSeconds()}`;
    //     const storageRef = storage.ref();
    //     const pdfRef = storageRef.child(
    //       `${this.fSemYear}/${this.schedule.qRound}/${this.userInfo.stdCode}_subject_${dtStr}.pdf`
    //     );

    //     // upload file to server
    //     pdfRef.put(this.theFile).then(() => {
    //       this.theFile = null;
    //       this.isUploading = false;
    //       this.$store.dispatch("updateToast", {
    //         mode: "success",
    //         show: true,
    //         message: "อัพโหลดข้อมูลสำเร็จ",
    //       });
    //     });
    //   } catch (err) {
    //     this.isUploading = false;
    //     this.error = err.message;
    //     throw err;
    //   }
    // },
    isPetDuplicate(quotaId) {
      return this.myPetitions.some(
        (p) => p.quotaInfo.id === quotaId && this.schedule.qRound === p.qRound
      );
    },
  },
};
</script>
<style scoped>
.selected-table-menu {
  @apply border-b-2 font-semibold border-blue-550 text-blue-550;
}
textarea.form-control {
  @apply w-full px-2 py-2 mb-1 border-2 border-gray-200 text-gray-700 rounded-md focus:outline-none  transition-colors;
}
</style>
