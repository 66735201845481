<template>
  <div class="px-60">
    <base-card class="flex justify-end">
      <base-button @click="addMajorForm()"> + เพิ่มภาควิชา</base-button>
    </base-card>
    <div class="grid-table-main ">
      <div
        class="
              grid grid-cols-4
              grid-table-header
            "
      >
        <div class="w-full h-auto  flex items-center justify-center">
          ชื่อ
        </div>
        <div class="w-full h-auto flex items-center justify-center">
          รหัสวิชา
        </div>
        <div class="w-full h-auto  flex items-center justify-center">
          สถานะ
        </div>
        <div class="w-full h-auto  flex items-center justify-center">
          แก้ไข
        </div>
      </div>

      <div class="grid-table-body">
        <div
          class="grid grid-cols-4 grid-table-row"
          v-for="major in majors"
          :key="major.id"
        >
          <div class=" text-left my-auto ">
            {{ major.name }}
          </div>
          <div class=" text-center my-auto">{{ major.code }}</div>
          <div class=" text-center my-auto">{{ major.status }}</div>
          <div class=" text-center my-auto">
            <base-button mode="super-flat" @click="editMajor(major)"
              >แก้ไข</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit Dialog -->
  <data-modal
    :show="editMajorVisible"
    :deleteBtn="false"
    :title="mode === 'add' ? 'เพิ่มภาควิชา' : 'แก้ไขภาควิชา'"
    @close="editMajorVisible = false"
    @delete="trydeleteMajor()"
    @submit="submitMajorData()"
  >
    <div class="flex justify-center" v-if="formLoading">
      <base-spinner></base-spinner>
    </div>
    <form @submit.prevent class="py-4 pr-6" v-else>
      <div class="grid grid-cols-3 gap-4 ">
        <div
          class="col-span-1 text-right font-semibold flex justify-end items-center"
        >
          ชื่อ
        </div>
        <div class="col-span-2">
          <input
            class=" form-control"
            @blur="v$.majorName.$touch"
            :class="{
              errors: v$.majorName.$error,
            }"
            type="text"
            v-model="majorName"
          />
          <span
            class="errors"
            v-for="error of v$.majorName.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-4 ">
        <div
          class="col-span-1 text-right font-semibold flex justify-end items-center"
        >
          รหัสวิชา
        </div>
        <div class="col-span-2">
          <input
            class=" form-control"
            @blur="v$.majorCode.$touch"
            :class="{
              errors: v$.majorCode.$error,
            }"
            type="text"
            v-model="majorCode"
          />
          <span
            class="errors"
            v-for="error of v$.majorCode.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-4 ">
        <div
          class="col-span-1 text-right font-semibold flex justify-end items-center"
        >
          สถานะ
        </div>
        <div class="col-span-2 w-full">
          <select
            class="form-control"
            @blur="v$.majorStatus.$touch"
            :class="{
              errors: v$.majorStatus.$error,
            }"
            v-model="majorStatus"
            disabled
          >
            <option disabled :value="null">Please select one</option>
            <option value="open">open</option>
          </select>
          <span
            class="errors"
            v-for="error of v$.majorStatus.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>
    </form>
  </data-modal>

  <toast
    :show="!!error"
    mode="error"
    @close="error = null"
    fixed
    :message="error"
  >
  </toast>
</template>

<script>
// import { db, functions } from "../../js/firebaseDb.js";
import DataModal from "../../components/UI/DataModal.vue";
// import SpinnerDialog from "../../components/UI/SpinnerDialog.vue";
import "firebase/firestore";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import LoadDataMixin from "../../mixins/loadData.js";
export default {
  mixins: [LoadDataMixin],
  components: { DataModal },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      // system
      editMajorVisible: false,
      error: null,
      mode: null,

      // form
      formLoading: false,

      // user
      majorId: null,
      majorName: null,
      majorCode: null,
      majorStatus: null,
    };
  },
  validations() {
    return {
      majorName: {
        required,
        minLength: minLength(2),
      },
      majorCode: {
        required,
        minLength: minLength(2),
      },
      majorStatus: {
        required,
      },
    };
  },
  computed: {
    majors() {
      return this.$store.getters["majors/majors"];
    },
  },
  methods: {
    clearForm() {
      this.majorId = null;
      this.majorName = null;
      this.majorCode = null;
      this.majorStatus = null;
    },

    async updateMajor() {
      await this.$store.dispatch("majors/updateMajor", {
        id: this.majorId,
        name: this.majorName,
        code: this.majorCode,
        status: this.majorStatus,
      });
    },
    async addMajor() {
      await this.$store.dispatch("majors/addMajor", {
        name: this.majorName,
        code: this.majorCode,
        status: this.majorStatus,
      });
    },

    trydeleteMajor() {
      alert("major is deleted");
    },
    async submitMajorData() {
      // check validation
      this.v$.$touch();
      if (this.v$.$error) return;

      this.formLoading = true;

      try {
        if (this.mode === "add") {
          if (this.v$.$error) return;
          await this.addMajor();
        } else if (this.mode === "edit") {
          await this.updateMajor();
        } else {
          throw Error(`mode ${this.mode} is not supported`);
        }
        this.editMajorVisible = false;
        this.clearForm();
        this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message:
            this.mode === "add" ? "เพิ่มข้อมูลสำเร็จ" : "แก้ไขข้อมูลสำเร็จ",
        });
      } catch (err) {
        this.error = err.message;
      }
      this.formLoading = false;
    },
    addMajorForm() {
      this.mode = "add";
      this.editMajorVisible = true;
      this.clearForm();
      this.v$.$reset();
      this.majorStatus = "open";
    },
    async editMajor(majorData) {
      this.mode = "edit";
      this.editMajorVisible = true;
      this.majorId = majorData.id;
      this.majorName = majorData.name;
      this.majorCode = majorData.code;
      this.majorStatus = majorData.status;
    },
  },
};
</script>

<style scoped>
.form-row {
  @apply pb-3;
}
.std-info-grid {
  @apply grid grid-cols-4 py-6;
}

.std-info-grid h3 {
  @apply col-span-4 md:col-span-1 pb-4;
}

.std-info-data {
  @apply col-span-4 md:col-span-3;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
