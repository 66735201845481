import { sortNumber } from "../../../js/utility.js";
export default {
    studentInfo(state) {
        return state.studentInfo;
    },
    reqQuota(state) {
        return state.reqQuota;
    },
    quota(state) {
        return state.reqQuota;
    },
    reqQuotaAndQuota(state) {
        const allReqQuota = state.reqQuota;
        const allQuota = state.quota;

        let newReqQuota = [];
        for (const theRequest of allReqQuota) {
            const quotaData = allQuota.find(
                (quota) => quota.id === theRequest.quotaId
            );
            newReqQuota.push({...theRequest, quota: quotaData });
        }
        return newReqQuota;
    },
    students(state) {
        return state.students;
    },

    studentChangeLog(state) {
        let resArray = [];
        for (const item of state.statusChange) {
            resArray.push({...item, type: "status" });
        }
        for (const item of state.majorChange) {
            resArray.push({...item, type: "major" });
        }
        return sortNumber(resArray, "time");
    },
    stdPetitions(state) {
        return state.stdPetitions;
    },
};