import { db } from "./firebaseDb.js";

function getId() {
    return (
        Math.random()
        .toString(36)
        .substr(2) +
        Date.now().toString(36) +
        Math.random()
        .toString(36)
        .substr(2)
    );
}

function QuodSort(payload, attribute) {
    return payload.sort(function(a, b) {
        if (a[attribute[0]] < b[attribute[0]]) {
            return -1;
        } else if (a[attribute[0]] > b[attribute[0]]) {
            return 1;
        } else {
            if (a[attribute[1]] < b[attribute[1]]) {
                return -1;
            } else if (a[attribute[1]] > b[attribute[1]]) {
                return 1;
            } else {
                if (a[attribute[2]] < b[attribute[2]]) {
                    return -1;
                } else if (a[attribute[2]] > b[attribute[2]]) {
                    return 1;
                } else {
                    if (a[attribute[3]] < b[attribute[3]]) {
                        return -1;
                    } else if (a[attribute[3]] > b[attribute[3]]) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            }
        }
    });
}

function tripleSort(payload, attribute) {
    return payload.sort(function(a, b) {
        if (a[attribute[0]] < b[attribute[0]]) {
            return -1;
        } else if (a[attribute[0]] > b[attribute[0]]) {
            return 1;
        } else {
            if (a[attribute[1]] < b[attribute[1]]) {
                return -1;
            } else if (a[attribute[1]] > b[attribute[1]]) {
                return 1;
            } else {
                if (a[attribute[2]] < b[attribute[2]]) {
                    return -1;
                } else if (a[attribute[2]] > b[attribute[2]]) {
                    return 1;
                } else {
                    return 0;
                }
            }
        }
    });
}

function doubleSort(payload, attribute) {
    return payload.sort(function(a, b) {
        if (a[attribute[0]] < b[attribute[0]]) {
            return -1;
        } else if (a[attribute[0]] > b[attribute[0]]) {
            return 1;
        } else {
            if (a[attribute[1]] < b[attribute[1]]) {
                return -1;
            } else if (a[attribute[1]] > b[attribute[1]]) {
                return 1;
            } else {
                return 0;
            }
        }
    });
}

function sortText(payload, attribute, mode = "asc") {
    return payload.sort(function(t1, t2) {
        var textA = t1[attribute].toUpperCase();
        var textB = t2[attribute].toUpperCase();

        if (mode === "desc") {
            if (textA < textB) {
                return 1;
            }
            if (textA > textB) {
                return -1;
            }
            return 0;
        }

        if (mode === "asc") {
            if (textA < textB) {
                return -1;
            }
            if (textA > textB) {
                return 1;
            }
            return 0;
        }
    });
}

function sortNumber(payload, attribute, mode = "asc") {
    return payload.sort(function(n1, n2) {
        let NoA = n1[attribute];
        let NoB = n2[attribute];

        if (mode === "desc") {
            if (NoA < NoB) {
                return 1;
            }
            if (NoA > NoB) {
                return -1;
            }
            return 0;
        }

        if (mode === "asc") {
            if (NoA < NoB) {
                return -1;
            }
            if (NoA > NoB) {
                return 1;
            }
            return 0;
        }
    });
}

function randNSort(payload, attribute, no) {
    if (typeof payload !== "object") {
        console.error("randArr Function can pass only object");
        return;
    }

    // insert random number
    payload.forEach((item) => {
        item["randNo"] = Math.floor(Math.random() * 1000);
    });

    // sort major then number
    payload.sort(function(a, b) {
        return (
            parseInt(a[attribute].toString().substring(0, 2)) -
            parseInt(b[attribute].toString().substring(0, 2)) || a.randNo - b.randNo
        );
    });

    // slice array
    let res = payload.slice(0, no);

    // delete random number
    res.forEach((i) => delete i["randNo"]);

    return res;
}

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

async function mapUser(currentUser) {
    const uid = currentUser.uid;
    let status = true;

    // Get user mapping data
    const userRef = db.collection("users").doc(uid);
    const response = await userRef.get().catch((error) => {
        console.error("Error getting documents: ", error);
        status = false;
    });
    if (response.empty) {
        status = false;
        const error = new Error("user not exists");
        console.error(error);
        throw error;
    }

    return { status: status, id: response.id, ...response.data() };
}

export {
    getId,
    sortText,
    sortNumber,
    randNSort,
    sliceIntoChunks,
    mapUser,
    tripleSort,
    QuodSort,
    doubleSort,
};