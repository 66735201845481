<template>
  <div class="px-60">
    <base-card class="flex justify-end">
      <base-button :to="{ name: 'staff-detail' }" @click="addStaff()">
        + เพิ่มเจ้าหน้าที่</base-button
      >
    </base-card>
    <div class="grid-table-main ">
      <div
        class="
              grid grid-cols-6
              grid-table-header
            "
      >
        <div class="w-full h-auto  flex items-center justify-center">
          รหัสผู้ใช้
        </div>
        <div class="w-full h-auto flex items-center justify-center">
          E-mail
        </div>
        <div class="w-full h-auto flex items-center justify-center">
          คำนำหน้า
        </div>
        <div class="w-full h-auto  flex items-center justify-center">
          ชื่อ
        </div>
        <div class="w-full h-auto  flex items-center justify-center">
          นามสกุล
        </div>
        <div class="w-full h-auto  flex items-center justify-center">
          แก้ไข
        </div>
      </div>

      <div class="grid-table-body">
        <div
          class="grid grid-cols-6 grid-table-row"
          v-for="staff in staffs"
          :key="staff.id"
        >
          <div class=" text-center my-auto ">
            {{ staff.username }}
          </div>
          <div class=" text-center my-auto ">
            {{ staff.email }}
          </div>
          <div class=" text-center my-auto">{{ staff.prefix }}</div>
          <div class=" text-center my-auto">{{ staff.firstName }}</div>
          <div class=" text-center my-auto">{{ staff.lastName }}</div>
          <div class=" text-center my-auto">
            <base-button mode="super-flat" @click="editStaff(staff.id)"
              >แก้ไข</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit Dialog   -->
  <data-modal
    :show="editStaffVisible"
    :deleteBtn="mode === 'edit'"
    :title="mode === 'add' ? 'เพิ่มเจ้าหน้าที่' : 'แก้ไขเจ้าหน้าที่'"
    @close="closeStaffDia"
    @delete="deleteStaffDialog = true"
    @submit="submitData()"
    :submitBtn="!formLoading"
    :cancelBtn="!formLoading"
    :closeIcon="!formLoading"
  >
    <div class="flex justify-center" v-if="formLoading">
      <base-spinner></base-spinner>
    </div>
    <form @submit.prevent class="py-4 pr-6" v-else>
      <div class="grid grid-cols-3 gap-4 ">
        <div
          class="col-span-1 text-right font-semibold flex justify-end items-center"
        >
          Username
        </div>
        <div class="col-span-2">
          <input
            class=" form-control"
            @blur="v$.username.$touch"
            :class="{
              errors: v$.username.$error,
            }"
            type="text"
            v-model="username"
          />
          <span
            class="errors"
            v-for="error of v$.username.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div
          v-if="mode === 'add'"
          class="col-span-1 text-right font-semibold flex justify-end items-center"
        >
          Password
        </div>
        <div v-if="mode === 'add'" class="col-span-2">
          <input class=" form-control" type="password" v-model="password" />
        </div>
        <div
          class="col-span-1 text-right font-semibold flex justify-end items-center"
          v-if="mode === 'add'"
        >
          Confirm Password
        </div>
        <div class="col-span-2" v-if="mode === 'add'">
          <input
            class=" form-control"
            @blur="v$.cfPassword.$touch"
            :class="{
              errors: v$.cfPassword.$error,
            }"
            v-model.trim="cfPassword"
            type="password"
          />
          <span
            class="errors"
            v-for="error of v$.cfPassword.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>

        <div
          v-if="mode === 'edit'"
          class="col-span-1 text-right font-semibold flex justify-end items-center"
        >
          Password
        </div>
        <div v-if="mode === 'edit'" class="col-span-2">
          <div class="flex justify-between">
            <input
              class=" form-control"
              @blur="v$.password.$touch"
              :class="{
                errors: v$.password.$error,
              }"
              type="password"
              v-model="password"
              v-if="isEditPassword"
            />
            <span v-else>*********</span>

            <div class="ml-2 flex">
              <base-button v-if="!isEditPassword" @click="isEditPassword = true"
                >แก้ไข</base-button
              >
              <base-button v-if="isEditPassword" @click="updatePassword()"
                >บันทึก</base-button
              >
              <base-button
                class="pr-0 mr-0"
                mode="flat"
                v-if="isEditPassword"
                @click="isEditPassword = false"
                >ยกเลิก</base-button
              >
            </div>
          </div>

          <span
            class="errors"
            v-for="error of v$.password.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>

        <div
          class="col-span-1 text-right font-semibold flex justify-end items-center"
        >
          Email
        </div>
        <div class="col-span-2 flex">
          <input
            class=" form-control"
            @blur="v$.email.$touch"
            :class="{
              errors: v$.email.$error,
            }"
            type="email"
            v-model="email"
            :disabled="mode === 'edit' && !isEditEmail"
          />
          <span
            class="errors"
            v-for="error of v$.email.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
          <div class="ml-2 flex">
            <base-button
              v-if="!isEditEmail && mode === 'edit'"
              @click="isEditEmail = true"
              >แก้ไข</base-button
            >
            <base-button v-if="isEditEmail" @click="updateEmail()"
              >บันทึก</base-button
            >
            <base-button
              class="pr-0 mr-0"
              mode="flat"
              v-if="isEditEmail"
              @click="
                () => {
                  email = editStaffInfo.email;
                  isEditEmail = false;
                }
              "
              >ยกเลิก</base-button
            >
          </div>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-4">
        <div class="col-span-1 text-right font-semibold py-4">สาขาวิชา</div>
        <div class="col-span-2 py-4">
          <ul>
            <li v-for="major in majors" :key="major.id">
              <label class="flex items-center">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  @blur="v$.pickedMajor.$touch"
                  :class="{
                    errors: v$.pickedMajor.$error,
                  }"
                  :value="major.id"
                  v-model="pickedMajor"
                />
                <span class="ml-2">{{ major.name }} </span>
              </label>
            </li>
          </ul>
          <span
            class="errors"
            v-for="error of v$.pickedMajor.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div
          class="col-span-1 text-right font-semibold flex justify-end items-center"
        >
          คำนำหน้า
        </div>
        <div class="col-span-2 w-40">
          <select
            class="form-control"
            @blur="v$.prefix.$touch"
            :class="{
              errors: v$.prefix.$error,
            }"
            v-model="prefix"
          >
            <option disabled :value="null">Please select one</option>
            <option value="นาย">นาย</option>
            <option value="นาง">นาง</option>
            <option value="นางสาว">นางสาว</option>
          </select>
          <span
            class="errors"
            v-for="error of v$.prefix.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div
          class="col-span-1 text-right font-semibold flex justify-end items-center"
        >
          ชื่อ
        </div>
        <div class="col-span-2">
          <input
            class=" form-control"
            type="text"
            @blur="v$.firstName.$touch"
            :class="{
              errors: v$.firstName.$error,
            }"
            v-model="firstName"
          />
          <span
            class="errors"
            v-for="error of v$.firstName.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div
          class="col-span-1 text-right font-semibold flex justify-end items-center"
        >
          นามสกุล
        </div>
        <div class="col-span-2">
          <input
            class=" form-control"
            @blur="v$.lastName.$touch"
            :class="{
              errors: v$.lastName.$error,
            }"
            type="text"
            v-model="lastName"
          />
          <span
            class="errors"
            v-for="error of v$.lastName.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div
          class="col-span-1 text-right font-semibold flex justify-end items-center"
        >
          เพศ
        </div>
        <div class="col-span-2 w-40">
          <div class="mt-2 flex">
            <label class="inline-flex items-center">
              <input
                type="radio"
                class="form-radio"
                @blur="v$.gender.$touch"
                :class="{
                  errors: v$.gender.$error,
                }"
                name="accountType"
                value="M"
                v-model="gender"
              />
              <span class="ml-2">ชาย</span>
            </label>
            <label class="inline-flex items-center ml-6">
              <input
                type="radio"
                class="form-radio"
                @blur="v$.gender.$touch"
                :class="{
                  errors: v$.gender.$error,
                }"
                name="accountType"
                value="F"
                v-model="gender"
              />
              <span class="ml-2">หญิง</span>
            </label>
            <span
              class="errors"
              v-for="error of v$.gender.$errors"
              :key="error.$uid"
              >{{ error.$message }}<br />
            </span>
          </div>
        </div>
        <div
          class="col-span-1 text-right font-semibold flex justify-end items-center"
        >
          หน้าที่
        </div>
        <div class="col-span-2">
          <select
            class="form-control"
            @blur="v$.role.$touch"
            :class="{
              errors: v$.role.$error,
            }"
            v-model="role"
          >
            <option disabled :value="null">Please select one</option>
            <option value="A">ผู้ดูแลระบบ</option>
            <option value="S">เลขาสาขาวิชา</option>
            <!-- <option value="P">ผู้ดูแลข้อมูลนักศึกษา</option>
            <option value="C">ผู้ดูแลข้อมูลรายวิชา</option>
            <option value="W">ผู้ดูแลข้อมูลภาระงานสอน</option> -->
          </select>
          <span
            class="errors"
            v-for="error of v$.role.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>
    </form>
  </data-modal>
  <toast
    :show="!!error"
    mode="error"
    @close="error = null"
    fixed
    :message="error"
  >
  </toast>
  <confirm-modal
    :show="deleteStaffDialog"
    title="ยืนยันลบเจ้าหน้าที่ออกจากระบบ"
    @close="deleteStaffDialog = false"
    @confirm="deleteStaff()"
  >
  </confirm-modal>
</template>

<script>
import { db, functions } from "../../js/firebaseDb.js";
import DataModal from "../../components/UI/DataModal.vue";
import ConfirmModal from "../../components/UI/ConfirmModal.vue";
// import SpinnerDialog from "../../components/UI/SpinnerDialog.vue";
import "firebase/firestore";
import useVuelidate from "@vuelidate/core";
import { required, minLength, email, sameAs } from "@vuelidate/validators";
import LoadDataMixin from "../../mixins/loadData.js";
export default {
  mixins: [LoadDataMixin], // loadStaff
  components: { DataModal, ConfirmModal },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: ["id"],
  data() {
    return {
      // system
      editStaffVisible: false,
      error: null,
      mode: null,
      isEditPassword: false,
      isEditEmail: false,
      editStaffInfo: null,

      // form
      formLoading: false,
      deleteStaffDialog: false,

      // user
      uid: null,
      username: null,
      password: null,
      cfPassword: null,
      email: null,
      pickedMajor: [],
      prefix: null,
      firstName: null,
      lastName: null,
      gender: null,
      role: null,
    };
  },
  validations() {
    return {
      username: {
        required,
        minLength: minLength(2),
      },
      email: { required, email },
      password: { required, minLength: minLength(6) },
      cfPassword: {
        required,
        sameAsPassword: sameAs(this.password),
        minLength: minLength(6),
      },
      prefix: {
        required,
        minLength: minLength(2),
      },
      firstName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      gender: {
        required,
      },
      role: {
        required,
      },
      pickedMajor: {
        required,
      },
    };
  },
  computed: {
    majors() {
      return this.$store.getters["majors/majors"];
    },
    staffs() {
      return this.$store.getters["staffs/staffs"];
    },
  },
  methods: {
    clearForm() {
      this.uid = null;
      this.username = null;
      this.password = null;
      this.cfPassword = null;
      this.email = null;
      this.pickedMajor = [];
      this.prefix = null;
      this.firstName = null;
      this.lastName = null;
      this.gender = null;
      this.role = null;
    },
    checkEditStaff() {
      this.v$.$touch();
      if (
        this.v$.username.$error ||
        this.v$.email.$error ||
        this.v$.pickedMajor.$error ||
        this.v$.prefix.$error ||
        this.v$.firstName.$error ||
        this.v$.lastName.$error ||
        this.v$.gender.$error ||
        this.v$.role.$error
      ) {
        this.error = "ข้อมูลไม่ครบถ้วน โปรดตรวจสอบและลองใหม่อีกครั้ง";
        return false;
      } else {
        return true;
      }
    },
    checkAddStaff() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.error = "ข้อมูลไม่ครบถ้วน โปรดตรวจสอบและลองใหม่อีกครั้ง";
        return false;
      } else {
        return true;
      }
    },
    async updateEmail() {
      try {
        this.v$.email.$touch();
        if (this.v$.email.$error) return;
        this.formLoading = true;
        const updateEmailAdmin = functions.httpsCallable("updateEmailAdmin");
        await updateEmailAdmin({
          uid: this.uid,
          email: this.email,
        });
        await this.loadStaff();
        this.isEditEmail = false;
        this.editStaffVisible = false;

        this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "แก้ไข E-mail สำเร็จ",
        });
      } catch (err) {
        this.error = err.message;
      }
      this.formLoading = false;
    },
    async updatePassword() {
      try {
        this.v$.password.$touch();
        if (this.v$.password.$error) return;
        this.formLoading = true;
        const updateUserPassword = functions.httpsCallable(
          "updateUserPassword"
        );
        await updateUserPassword({
          uid: this.uid,
          password: this.password,
        });
        await this.loadStaff();
        this.isEditPassword = false;
        this.editStaffVisible = false;
        this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "แก้ไข Password สำเร็จ",
        });
      } catch (err) {
        this.$store.dispatch("clearToast");
        this.error = err.message;
      }
      this.formLoading = false;
    },
    async updateStaff() {
      // check username
      const updateAdmin = functions.httpsCallable("updateAdmin");
      await updateAdmin({
        uid: this.uid,
        firstName: this.firstName,
        gender: this.gender,
        lastName: this.lastName,
        majors: this.pickedMajor,
        prefix: this.prefix,
        username: this.username,
        role: this.role,
      });
    },
    async signupStaff() {
      // check mail

      // check username

      const signupAdmin = functions.httpsCallable("signupAdmin");
      await signupAdmin({
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        gender: this.gender,
        lastName: this.lastName,
        majors: this.pickedMajor,
        prefix: this.prefix,
        username: this.username,
        role: this.role,
      });
    },

    closeStaffDia() {
      this.editStaffVisible = false;
    },
    trydeleteStaff() {
      alert("staff is deleted");
    },
    async submitData() {
      // check validation
      if (this.mode === "add") {
        if (!this.checkAddStaff()) {
          return;
        }

        this.formLoading = true;
        try {
          await this.signupStaff();
        } catch (err) {
          this.error = err.message;
          this.formLoading = false;
          return;
        }
      } else if (this.mode === "edit") {
        if (!this.checkEditStaff()) {
          return;
        }
        this.formLoading = true;
        try {
          await this.updateStaff();
        } catch (err) {
          this.error = err.message;
          this.formLoading = false;
          return;
        }
      } else {
        throw Error(`mode ${this.mode} is not supported`);
      }

      try {
        await this.loadStaff();
      } catch (err) {
        this.error = err.message;
        return;
      }

      this.editStaffVisible = false;
      this.clearForm();
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message:
          this.mode === "add" ? "เพิ่มข้อมูลสำเร็จ" : "แก้ไขข้อมูลสำเร็จ",
      });

      this.formLoading = false;
    },
    addStaff() {
      this.mode = "add";
      this.editStaffVisible = true;
      this.clearForm();
      this.v$.$reset();
    },
    async editStaff(staffId) {
      this.editStaffInfo = this.staffs.find((stf) => {
        return stf.id === staffId;
      });

      const userRef = db.collection("users").doc(this.editStaffInfo.id);
      const userDoc = await userRef.get();

      this.uid = this.editStaffInfo.id;
      this.username = this.editStaffInfo.username;
      this.email = this.editStaffInfo.email;
      this.pickedMajor = this.editStaffInfo.majors || [];
      this.prefix = this.editStaffInfo.prefix;
      this.firstName = this.editStaffInfo.firstName;
      this.lastName = this.editStaffInfo.lastName;
      this.gender = this.editStaffInfo.gender;
      this.role = userDoc.data().role;
      this.mode = "edit";
      this.v$.$reset();
      this.editStaffVisible = true;
      //set staff detail
    },
    async deleteStaff() {
      this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังลบเจ้าหน้าที่",
      });
      try {
        await this.$store.dispatch("staffs/disableStaff", { id: this.uid });
        this.editStaffVisible = false;
        this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "ลบเจ้าหน้าที่สำเร็จ",
        });
      } catch (err) {
        this.$store.dispatch("clearToast");
        this.error = err.message;
        return;
      }
    },
  },
};
</script>

<style scoped>
.form-row {
  @apply pb-3;
}
.std-info-grid {
  @apply grid grid-cols-4 py-6;
}

.std-info-grid h3 {
  @apply col-span-4 md:col-span-1 pb-4;
}

.std-info-data {
  @apply col-span-4 md:col-span-3;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
