import { doubleSort } from '../../../js/utility';
export default {
  conditions(state) {
    return doubleSort(state.conditions, ['condCode', 'block']);
  },
  condCode(state) {
    const theSet = new Set();
    const conditions = state.conditions;
    for (const condition of conditions) {
      theSet.add(condition.condCode);
    }
    const condCode = [...theSet].sort(function(a, b) {
      return a - b;
    });
    return condCode;
  },
  getConditionById: (state, getters) => (id) => {
    const conditions = getters.conditions;
    return conditions.find((cond) => cond.id === id);
  },
  getConditionsByCode: (state, getters) => (code) => {
    const conditions = getters.conditions;
    return conditions.filter((cond) => cond.condCode == code);
  },
};
