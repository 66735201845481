<template>
  <div class=" flex justify-center items-center" v-if="isLoading">
    <base-spinner class="my-10"></base-spinner>
  </div>
  <div v-else>
    <div
      class="text-base md:text-xl font-semibold  pb-20 text-center"
      v-if="!bskQuota || bskQuota.length === 0"
    >
      ไม่มีข้อมูลโควตาที่คุณเลือก โปรดเลือกวิชาที่ต้องการขอโควตา
    </div>
    <div v-else>
      <div v-if="isLogVisible">bskQuota : {{ bskQuota }}</div>
      <div class="text-center flex justify-center">
        <BaseBadge
          :canClose="false"
          class="fixed top-8 "
          :show="badgeVisible"
          message='วิชาในตะกร้าต้อง "ยืนยันขอโควตา" เพื่อบันทึกเข้าระบบ'
          @close="badgeVisible = false"
        ></BaseBadge>
      </div>

      <div class="grid-table-main">
        <div
          class="
              grid grid-cols-3
              md:grid-cols-12
              grid-table-header
            "
        >
          <div class="col-span-2 md:hidden w-full h-auto">วิชา</div>
          <div class="md:hidden w-full h-auto">สถานะ</div>
          <div class="hidden md:block w-full h-auto">รหัสวิชา</div>
          <div class="hidden md:block w-full h-auto md:col-span-2 ">
            ชื่อวิชา
          </div>
          <div class="hidden md:block w-full h-auto">Campus</div>
          <div class="hidden md:block w-full h-auto">Sec-Group</div>
          <div class="hidden md:block w-full h-auto md:col-span-2">วันเวลา</div>
          <div class="hidden md:block w-full h-auto md:col-span-2 ">
            ผู้บรรยาย
          </div>
          <div class="hidden md:block w-full h-auto">จำนวน ขอ/รับ</div>
          <div class="hidden md:block w-full h-auto">สถานะ</div>
          <div class="hidden md:block w-full h-auto">ยกเลิก</div>
        </div>

        <div class="grid-table-body">
          <div
            class="grid grid-cols-3 md:grid-cols-12 grid-table-row"
            v-for="q in bskQuota"
            :key="q.id"
          >
            <div class="col-span-2 md:col-span-10 md:grid md:grid-cols-10 pl-2">
              <div
                class="md:col-span-4 md:grid md:grid-cols-4 md:text-center md:my-auto"
              >
                <span class="font-bold">
                  {{ q.subCode }}<span class="md:hidden"> - </span></span
                >
                <span class="md:col-span-2">{{ q.subName }} </span>
                <span class="">
                  <span class=" md:hidden"> (</span>{{ q.campus
                  }}<span class=" md:hidden">)</span>
                </span>
              </div>
              <div
                class="md:col-span-3 md:grid md:grid-cols-3 md:text-center md:my-auto"
              >
                <span>
                  <span class="md:hidden">sec </span>{{ q.secId }}-{{
                    q.group
                  }}</span
                >
                <span class="md:col-span-2">
                  <span class=" md:hidden"> (</span>{{ q.secTime
                  }}<span class=" md:hidden"> )</span></span
                >
              </div>

              <div
                class="md:col-span-2  md:text-center md:my-auto"
                v-if="q.instructors"
              >
                <span v-for="instructor in q.instructors" :key="instructor.id">
                  {{ instructor.name }}
                  <br />
                </span>
              </div>
              <div class="md:col-span-2  md:text-center md:my-auto" v-else>
                <span>{{ q.instName }}</span>
              </div>
              <div class="md:text-center pt-3 md:pt-0 md:my-auto">
                <span>
                  <span class="md:hidden">จำนวนขอ/รับ: </span>
                  {{ q[`q${schedule.qRound}Request`] || 0 }}/{{
                    q[`q${schedule.qRound}Offer`] || 0
                  }}
                </span>
              </div>
            </div>
            <div
              class="col-span-1 md:col-span-2 md:grid md:grid-cols-2 text-left md:text-center my-auto "
            >
              <div
                class=" mx-auto flex justify-left md:justify-center md:my-auto pb-4 md:pb-0"
              >
                <div class="flex text-yellow-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <div class="font-bold">
                    รอยืนยัน
                  </div>
                </div>
              </div>

              <div class="">
                <span
                  class=" hover:bg-gray-100 p-3  text-red-500 hover:text-red-600 hover:font-medium cursor-pointer "
                  @click="rmBskQuota(q)"
                >
                  ยกเลิก
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center space-x-2 md:space-x-10 pt-10">
      <!-- <base-button mode="gray" @click="deleteBatch()">
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          ></path>
        </svg>
      </base-button> -->
      <base-button
        class="px-8 font-normal text-lg py-1.5"
        @click="submitReqQuota"
        :disabled="!canRegister"
        :class="{
          'bg-gray-300': !canRegister,
          'cursor-not-allowed': !canRegister,
        }"
      >
        ยืนยันขอโควตา
      </base-button>
    </div>
    <div v-if="isLogVisible">
      {{ !!canRegister }}
      {{ studentRule }}
    </div>
  </div>
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
  <div v-if="isLogVisible">
    <!-- {{ stdProgCode }} -->
    <!-- {{ programDetail }} -->
    <!-- {{ quotaMode }} -->
  </div>
  <SpinnerDialog :show="loadingDialog"></SpinnerDialog>
</template>

<script>
import BaseBadge from "../../components/UI/BaseBadge.vue";
import LoadDataMixin from "../../mixins/loadData.js";
import StudentRule from "../../mixins/studentRule.js";
import SpinnerDialog from "../../components/UI/SpinnerDialog.vue";
import { isLogVisible } from "../../js/debug.js";
export default {
  emits: ["to-waiting-table", "to-result-table"],
  mixins: [LoadDataMixin, StudentRule], // loadReqQuota(), canRegister(),studentRule
  components: { SpinnerDialog, BaseBadge },
  data() {
    return {
      error: null,
      formValid: true,
      errorQuota: [],
      isLoading: false,
      loadingDialog: false,
      badgeVisible: true,
      lastSubmitTime: null,
    };
  },
  computed: {
    isLogVisible() {
      return isLogVisible();
    },
    bskQuota() {
      return this.$store.getters["quota/bskQuota"];
    },
    schedule() {
      return this.$store.getters["schedule"];
    },

    quotaRound() {
      return this.$store.getters["schedule"].qRound;
    },
  },
  watch: {
    bskQuota: {
      handler: function(newValue) {
        if (newValue.length > 0) {
          this.badgeVisible = true;
        }
      },
      deep: true,
    },
  },

  methods: {
    async submitReqQuota() {
      const now = Date.now();
      // Check if it's less than 5 seconds since the last submission
      if (this.lastSubmitTime && now - this.lastSubmitTime < 1000) {
        // Show a message or do something to inform the user
        this.$store.dispatch("updateToast", {
          mode: "warning",
          show: true,
          message: "Please wait a moment before submitting again.",
        });
        return;
      }

      this.lastSubmitTime = now;

      this.loadingDialog = true;

      if (isLogVisible()) {
        console.log("submitting ReqQuota");
      }

      this.formValid = true;
      // TODO
      this.isFormValid();
      if (!this.formValid) {
        this.loadingDialog = false;
        return;
      }

      try {
        await this.$store.dispatch("quota/addReqQuota", this.bskQuota);
        await this.$store.dispatch("quota/clearBskQuota");
        await this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "บันทึกข้อมูลสำเร็จ",
        });
      } catch (e) {
        this.error = e.message;
        this.loadingDialog = false;
        return;
      }
      this.loadingDialog = false;
    },
    isFormValid() {
      if (isLogVisible()) {
        console.log("checking form validation");
      }

      this.formValid = true;
      this.errorQuota = [];
      this.error = null;

      if (this.bskQuota.length === 0) {
        this.error = "โปรดเลือกโควตาที่ต้องการยืนยันขอโควตา";
        this.formValid = false;
        return;
      }

      // normal check time from server and local
      if (!this.canRegister) {
        this.error = "ระบบยังไม่เปิดให้ลงทะเบียนโควตาในขณะนี้";
        this.formValid = false;
        return;
      }

      // recheck time again via firebase
      if (!this.canStudentRegisterServer()) {
        this.error = "ระบบยังไม่เปิดให้ลงทะเบียนโควตาในขณะนี้: server";
        this.formValid = false;
        return;
      }

      // check duplicate Quota request in add Table
      const quotaArr = this.bskQuota;

      // check duplicate Quota request in reqQuota
      const reqQuota = this.$store.getters["quota/reqQuotaNotCc"];

      let errMsg = "ไม่สามารถขอโควตาได้";

      // calulate subject amount both Vuex and Basket
      const subExceed = (reqQuota.length || 0) + (quotaArr.length || 0) > 7;
      if (subExceed) {
        this.formValid = false;
        errMsg = errMsg + `คุณได้ลงทะเบียนเกิน 7 วิชา, `;
      }

      // for each subject in basket
      for (const checkedQDetail of quotaArr) {
        // check if quota same quota ID OR same section OR same subject

        // check validation between basket and basket
        const countSameIdBasket = quotaArr.filter((rq) => {
          return rq.id === checkedQDetail.id;
        });
        const countSameSecBasket = quotaArr.filter((rq) => {
          return rq.secId == checkedQDetail.secId && rq.secId !== "0000";
        });
        const countSameOffLinkBasket = quotaArr.filter((rq) => {
          return rq.offLink == checkedQDetail.offLink;
        });
        const countEmptyOffer = quotaArr.filter((rq) => {
          return (
            !(`q${this.schedule.qRound}Offer` in rq) ||
            rq[`q${this.schedule.qRound}Offer`] == 0
          );
        });

        let localSameId = false;
        let localSameSec = false;
        let localSameOffLink = false;
        let hasEmptyOffer = false;

        if (countSameIdBasket.length > 1) {
          localSameId = true;
        }

        if (countSameSecBasket.length > 1) {
          localSameSec = true;
        }
        if (countSameOffLinkBasket.length > 1) {
          localSameOffLink = true;
        }

        if (countEmptyOffer.length > 0) {
          hasEmptyOffer = true;
        }

        let sameId = false;
        let sameSec = false;
        let sameOffLink = false;

        if (!localSameId && !localSameSec && !localSameOffLink) {
          // check validation between basket and vuex
          sameId = reqQuota.some((rq) => {
            return rq.quota.id === checkedQDetail.id;
          });
          sameSec = reqQuota.some((rq) => {
            return (
              rq.quota.secId == checkedQDetail.secId &&
              rq.quota.secId !== "0000"
            );
          });
          sameOffLink = reqQuota.some((rq) => {
            return rq.quota.offLink == checkedQDetail.offLink;
          });
        }

        const quotaData = this.$store.getters["quota/getQuotaById"](
          checkedQDetail.id
        );
        const subCode = quotaData.subCode;

        // Local Error
        if (localSameId || localSameOffLink) {
          this.formValid = false;
          errMsg = `ไม่สามารถขอวิชาที่ซ้ำกันได้ โปรดตรวจสอบตะกร้า และลองใหม่อีกครั้ง`;
        } else if (localSameSec) {
          this.formValid = false;
          errMsg = `ไม่สามารถขอวิชาที่ section ซ้ำกันได้ โปรดตรวจสอบตะกร้า และลองใหม่อีกครั้ง`;
        } else if (hasEmptyOffer) {
          this.formValid = false;
          errMsg = `ไม่สามารถขอวิชาที่จำนวนรับเป็น 0 ได้ โปรดตรวจสอบตะกร้า และลองใหม่อีกครั้ง`;
        }

        // Vuex Error
        if (!localSameId && !localSameSec && !localSameOffLink) {
          if (sameId) {
            this.formValid = false;
            errMsg = errMsg + `${subCode} คุณได้ลงทะเบียนวิชานี้ไปแล้ว, `;
          } else if (sameSec) {
            this.formValid = false;
            errMsg =
              errMsg +
              `${subCode} คุณได้ลงทะเบียนวิชาอื่นใน Section นี้ไปแล้ว, `;
          } else if (sameOffLink) {
            this.formValid = false;
            errMsg = errMsg + `${subCode} คุณได้ลงทะเบียนวิชานี้ไปแล้ว, `;
          }
        }
      }

      if (!this.formValid) {
        this.error = errMsg;
      }
    },
    rmBskQuota(quota) {
      try {
        this.$store.dispatch("quota/deleteBskQuota", quota.id);
      } catch (e) {
        this.error = e.message;
      }
    },
    closeError() {
      this.formValid = true;
      this.errorQuota = [];
      this.error = null;
    },
    async canStudentRegisterServer() {
      let StdRule = null;
      try {
        StdRule = await this.$store.dispatch(
          "currentScheduleRuleStudentServer"
        );
      } catch (e) {
        this.error = e.message;
        this.loadingDialog = false;
        console.log("time firebase  error false");
        return false;
      }
      if (StdRule == null) {
        return false;
      }

      const inTime = !!StdRule && !!StdRule.rule && !!StdRule.rule.register;
      if (inTime) {
        console.log("time firebase true");
        return true;
      } else {
        console.log("time firebase false");
        return false;
      }
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
