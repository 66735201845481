<template>
  <div class="flex flex-col w-full">
    <div
      class="text-white bg-primary-200 flex flex-shrink-0 flex-col pl-4 py-4"
    >
      <div class="flex relative  items-center p-4 h-12">
        <span class="text-2xl tracking-wide font-semibold"
          >รายการคำร้องขอเพิ่มวิชาคณะพาณิชย์ฯ มธ.</span
        >
      </div>
    </div>
  </div>
  <div class="text-center py-20" v-if="!staffCanEditPetition">
    <h3>ยังไม่เปิดให้แก้ไขคำร้อง</h3>
  </div>
  <div v-else>
    <div class="text-center pt-10">
      <h2>
        คำร้องครั้งที่ {{ qRoundToText(schedule.qRound) }} เทอม
        {{ schedule.semester }} ปีการศึกษา {{ schedule.year }}
      </h2>
    </div>
    <base-card class="mt-6">
      <div class="flex space-x-2">
        <div class=" w-full">
          <label class="block  text-sm text-gray-500 ">ค้นหาด้วยชื่อวิชา</label>
          <input
            class="form-control text-sm md:text-base pr-10"
            type="text"
            placeholder="ACxxx"
            v-model="filterText"
          />
        </div>
      </div>
      <div class="py-2">Filter</div>
      <div class="flex space-x-2">
        <div class="w-full">
          <label class="block  text-sm text-gray-500 ">Section</label>
          <select class="form-control text-sm md:text-base" v-model="filterSec">
            <option value="all">ทั้งหมด</option>
            <option v-for="sec in sections" :value="sec.code" :key="sec.id">
              {{ sec.code }}
            </option>
          </select>
        </div>
        <div class="w-full">
          <label class="block  text-sm text-gray-500 ">group</label>
          <select class="form-control text-sm md:text-base" v-model="filterGr">
            <option value="all">ทั้งหมด</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
          </select>
        </div>
        <div class="w-full">
          <label class="block  text-sm text-gray-500 ">รอบ</label>
          <select
            class="form-control text-sm md:text-base"
            v-model="filterRound"
          >
            <option value="all">ทั้งหมด</option>
            <option :value="1">1</option>
            <option :value="2">2</option>
            <option :value="3">3</option>
            <option :value="4">4.1</option>
            <option :value="5">4.2</option>
          </select>
        </div>
        <div class="w-full"></div>
      </div>
    </base-card>

    <div class="xl:px-10 2xl:px-40">
      <div class="flex justify-between bg-gray-100 rounded-t-lg py-2 mt-10 ">
        <div class="pl-2">
          <div>
            Status Stat
          </div>
          <span>All: {{ countPet("all") }} | </span>
          <span>Pending: {{ countPet("pending") }} | </span>
          <span>Accept: {{ countPet("accept") }} | </span>
          <span>Reject: {{ countPet("reject") }} </span>
        </div>
        <div class="flex">
          <base-button class="flex" mode="flat" @click="onExport()">
            <svg
              class="w-6 h-6 mr-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span>Excel</span>
          </base-button>
          <base-button
            @click="rejectAllModalVisible = true"
            v-if="isSudo"
            :mode="countPet('pending') > 0 ? 'flat' : 'disabled-flat'"
            :disabled="countPet('pending') === 0"
            >ยืนยันคำร้อง</base-button
          >
        </div>
      </div>
      <div class="flex items-center justify-center">
        <table
          class="flex flex-row flex-no-wrap md:block md:bg-white rounded-b-lg overflow-hidden md:shadow-lg mb-5 mx-auto w-full"
        >
          <thead class=" text-white">
            <tr
              class="bg-teal-400 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-2 "
            >
              <th class="p-3 text-center">No.</th>
              <th class="p-3 text-left">นักศึกษา</th>
              <th class="p-3 text-left">ติดต่อ</th>
              <th class="p-3 text-left">สาขาวิชาของนักศึกษา</th>
              <th class="p-3 text-left">รหัสวิชา</th>
              <!-- <th class="p-3 text-left">ชื่อวิชา</th> -->
              <th class="p-3 text-left">Section/Gr</th>
              <!-- <th class="p-3 text-left">Group</th> -->
              <th class="p-3 text-left">เหตุผล</th>
              <th class="p-3 text-left">เหตุผลเพิ่มเติม</th>
              <th class="p-3 border-b-1  text-left ">file</th>
              <th class="p-3 text-left">สถานะ</th>
              <th class="p-3 text-left">accept</th>
              <th class="p-3 text-left">reject</th>
            </tr>
          </thead>
          <tbody class="flex-1 md:flex-none">
            <tr
              v-for="(petition, index) in filteredPet"
              :key="petition"
              class="flex flex-col flex-no wrap md:table-row mb-2 md:mb-0 "
            >
              <td
                class="text-center border-grey-light border hover:bg-gray-100 p-3"
              >
                <div>{{ index + 1 }}</div>
              </td>
              <td class="border-grey-light border hover:bg-gray-100 p-3">
                <router-link
                  class="text-blue-550 hover:text-blue-600 hover:font-medium cursor-pointer"
                  :to="{
                    name: 'admin-std-quota',
                    params: { stdId: petition.studentInfo.id },
                  }"
                >
                  {{ petition.studentInfo.stdCode }}
                </router-link>
                <div>
                  {{ petition.studentInfo.nameTitle }}
                  {{ petition.studentInfo.firstName }}
                  {{ petition.studentInfo.lastName }}
                </div>
              </td>
              <td
                class="text-center border-grey-light border hover:bg-gray-100 p-3  text-blue-550 cursor-pointer"
                @click="
                  callDataModal(
                    'contact',
                    'ติดต่อนักศึกษา',
                    petition.studentInfo
                  )
                "
              >
                <svg
                  class="w-6 h-6 mx-auto"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </td>
              <td class="border-grey-light border hover:bg-gray-100 p-3">
                {{ getMajorNSchool(petition) }}
              </td>
              <td class="border-grey-light border hover:bg-gray-100 p-3">
                <router-link
                  :to="{
                    name: 'quota-sec',
                    params: { id: petition.quotaInfo.id },
                  }"
                >
                  <p class="text-blue-550 hover:text-blue-600">
                    {{ petition.subCode }}
                  </p>
                  <p>({{ petition.quotaInfo.subCode }})</p>
                </router-link>
              </td>
              <!-- <td class="border-grey-light border hover:bg-gray-100 p-3">
              {{ petition.quotaInfo.subName }}
            </td> -->
              <td class="border-grey-light border hover:bg-gray-100 p-3">
                {{ petition.quotaInfo.secId }} / {{ petition.quotaInfo.group }}
              </td>
              <!-- <td class="border-grey-light border hover:bg-gray-100 p-3">
              {{ petition.quotaInfo.group }}
            </td> -->
              <td
                class="w-52 xl:w-60 border-grey-light border hover:bg-gray-100 p-3"
              >
                <!-- <div :key="index" v-for="(item, index) in petition.reason">
                {{ item }}
                {{ index + 2 === petition.reason.length ? "," : "" }}
              </div> -->
                {{ reasonToStr(petition.reason) }}
              </td>
              <td class="border-grey-light border hover:bg-gray-100 p-3">
                <div v-if="!petition.personalReason">-</div>
                <div v-else-if="petition.personalReason.length > 20">
                  <div>{{ petition.personalReason.substring(0, 15) }}...</div>
                  <div
                    class="ml-1 text-blue-500 cursor-pointer"
                    @click="
                      callDataModal(
                        'personalReason',
                        'เหตุผลเพิ่มเติม',
                        petition.personalReason
                      )
                    "
                  >
                    more >
                  </div>
                </div>
                <div v-else>{{ petition.personalReason }}</div>
              </td>

              <td
                class=" border-grey-light border hover:bg-gray-100 p-3  text-blue-550"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  :href="petition.file"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 mx-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                    />
                  </svg>
                </a>
              </td>

              <td class="border-grey-light border hover:bg-gray-100 p-3">
                <div
                  class="flex items-center text-green-500"
                  v-if="petition.status === 'accept'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span class="font-semibold">
                    Accept
                  </span>
                </div>

                <div
                  class="flex items-center text-red-500"
                  v-else-if="petition.status === 'reject'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span class="font-semibold">
                    Reject
                  </span>
                </div>
                <div class="flex items-center text-blue-500" v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span class="font-semibold">
                    pending
                  </span>
                </div>
              </td>
              <td class="border-grey-light border hover:bg-gray-100 ">
                <div class="w-full flex justify-center">
                  <base-button
                    :mode="
                      petition.status === 'accept' ||
                      petition.qRound !== schedule.qRound
                        ? 'disabled-flat'
                        : 'super-flat'
                    "
                    @click="
                      () => {
                        selectedPetition = {
                          petId: petition.id,
                          stdInfo: petition.studentInfo,
                          quotaId: petition.quotaInfo.id,
                        };
                        selectedPetDialog = true;
                      }
                    "
                    :disabled="
                      petition.status === 'accept' ||
                        petition.qRound !== schedule.qRound
                    "
                  >
                    accept</base-button
                  >
                </div>
              </td>
              <td class="border-grey-light border hover:bg-gray-100  ">
                <div class="w-full flex justify-center">
                  <base-button
                    :mode="
                      petition.qRound !== schedule.qRound
                        ? 'disabled-flat'
                        : 'super-flat-danger'
                    "
                    @click="rejectPetition(petition)"
                    :disabled="petition.qRound !== schedule.qRound"
                    >reject</base-button
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="filteredPet.length === 0" class="flex justify-center py-10">
        <h4>ไม่มีข้อมูลคำร้องที่ตรงกัน</h4>
      </div>
    </div>
  </div>
  <data-modal
    :show="modalVisible"
    :deleteBtn="false"
    :submitBtn="false"
    :title="modalTitle"
    @close="modalVisible = false"
  >
    <div v-if="modalType === 'contact'">
      <div><b>E-mail :</b> {{ modalMessage.email }}</div>
      <div><b>Tel :</b> {{ modalMessage.tel }}</div>
    </div>
    <div v-else>
      {{ modalMessage }}
    </div>
  </data-modal>
  <toast
    :show="!!error"
    mode="error"
    @close="error = null"
    fixed
    :message="error"
  >
  </toast>
  <confirm-modal
    :show="selectedPetDialog"
    mode="success"
    title="คุณต้องการยืนยัน accept คำร้องใช่หรือไม่"
    @close="
      () => {
        selectedPetDialog = false;
        selectedPetition = {};
      }
    "
    @confirm="acceptPetition()"
  >
  </confirm-modal>

  <confirm-modal
    :show="rejectAllModalVisible"
    title="ยืนยันคำร้องจะทำการ reject นักศึกษาที่ยังมีสถานะ pending ทุกคนในระบบ"
    @close="rejectAllModalVisible = false"
    @confirm="rejectAllPetition()"
  >
  </confirm-modal>
</template>
<script>
import XLSX from "xlsx"; // import xlsx
import LoadDataMixin from "../../mixins/loadData.js";
import { functions } from "../../js/firebaseDb.js";
import { isLogVisible } from "../../js/debug.js";
import DataModal from "../../components/UI/DataModal.vue";
import StaffRuleMixin from "../../mixins/staffRule.js";
import ConfirmModal from "../../components/UI/ConfirmModal.vue";
import TextConverterMixin from "../../mixins/textConverter.js";

export default {
  setup() {},
  mixins: [LoadDataMixin, StaffRuleMixin, TextConverterMixin], // loadQuotaPetition,loadSection(),staffCanEditPetition,qRoundToText()

  components: { DataModal, ConfirmModal },
  data() {
    return {
      filterText: null,
      filterSec: "all",
      filterGr: "all",
      filterRound: "all",

      modalTitle: null,
      modalMessage: null,
      modalVisible: false,
      modalType: null,

      selectedPetDialog: false,
      selectedPetition: {},

      rejectAllModalVisible: false,
      error: null,
    };
  },
  async created() {
    this.isLoading = true;
    await this.loadYear();
    await this.loadQuotaPetition();
    await this.loadSection();
    await this.loadQuota("admin");
    this.setQRound();
    this.isLoading = false;
  },

  computed: {
    petitions() {
      return this.$store.getters["quotaPetitions/petitionList"];
    },
    filteredPet() {
      let petitionSub = this.petitions;
      if (this.filterText && this.filterSec !== "null") {
        petitionSub = petitionSub.filter((sub) => {
          return (
            sub.subCode.toLowerCase().indexOf(this.filterText.toLowerCase()) >
            -1
          );
        });
      }
      if (this.filterSec && this.filterSec !== "all") {
        petitionSub = petitionSub.filter((sub) => {
          return sub.quotaInfo.secId == this.filterSec;
        });
      }
      if (this.filterGr && this.filterGr !== "all") {
        petitionSub = petitionSub.filter((sub) => {
          return sub.quotaInfo.group == this.filterGr;
        });
      }
      if (this.filterRound && this.filterRound !== "all") {
        petitionSub = petitionSub.filter((sub) => {
          return sub.qRound == this.filterRound;
        });
      }
      return petitionSub;
    },
    logVisible() {
      return isLogVisible();
    },
    sections() {
      const section = this.$store.getters["sections/sections"];
      return section.filter((s) => {
        return s.camCode === this.userProgram.camCode;
      });
    },
    userProgram() {
      return this.$store.getters["programs/getUserProgram"];
    },
    isSudo() {
      return this.$store.getters["isSudo"];
    },
    schedule() {
      return this.$store.getters["schedule"];
    },
    fSemYear() {
      return this.$store.getters["fSemYear"];
    },
  },

  methods: {
    setQRound() {
      this.filterRound = this.schedule.qRound;
    },

    countPet(petStatus) {
      let fltArr = [];
      if (petStatus === "all") {
        fltArr = this.filteredPet;
      } else {
        fltArr = this.filteredPet.filter((p) => {
          return p.status === petStatus;
        });
      }
      return fltArr.length;
    },
    async loadQuotaPetition() {
      try {
        await this.$store.dispatch("quotaPetitions/setPetitionList");
      } catch (e) {
        this.error = e.message;
      }
    },
    async acceptPetition() {
      const petId = this.selectedPetition.petId;
      const stdInfo = this.selectedPetition.stdInfo;
      const quotaId = this.selectedPetition.quotaId;
      this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังบันทึกข้อมูล",
      });
      try {
        await this.$store.dispatch("quota/addReqQuotaByAdmin", {
          stdInfo: stdInfo,
          quotaId: quotaId,
          result: "accept",
        });
        await this.$store.dispatch("quotaPetitions/updatePetition", {
          id: petId,
          data: { status: "accept" },
        });
        this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "accept คำร้องสำเร็จ",
        });
      } catch (e) {
        this.$store.dispatch("clearToast");
        this.error = e.message;
      }
    },
    async rejectPetition(petData) {
      this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังบันทึกข้อมูล",
      });
      // check current status
      const isAccept = petData.status === "accept";
      try {
        if (isAccept) {
          // find Requested Quota data for rejecting a request
          const theReqQuota = await this.getReqQuota(
            petData.stdCode,
            petData.quotaInfo.id,
            petData.qRound,
            "accept"
          );

          if (theReqQuota.length > 1) {
            throw Error(
              "Found Request quota more than one item, please check the section and reject duplicated quota requests manually."
            );
          }

          // reject a request
          await this.$store.dispatch("quota/updateReqQuotaBatch", {
            quotaId: petData.quotaInfo.id,
            field: "result",
            value: "delete",
            reqArr: [theReqQuota[0].id],
          });
        }
        await this.$store.dispatch("quotaPetitions/updatePetition", {
          id: petData.id,
          data: { status: "reject" },
        });
        this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "reject คำร้องสำเร็จ",
        });
      } catch (e) {
        this.$store.dispatch("clearToast");
        this.error = e.message;
        throw e;
      }
    },

    async rejectAllPetition() {
      this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังบันทึกข้อมูล",
      });
      try {
        await this.$store.dispatch("quotaPetitions/rejectAllPetition");
        this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "ยืนยันคำร้องสำเร็จ",
        });
      } catch (e) {
        this.$store.dispatch("clearToast");
        this.error = e.message;
      }
    },
    getMajor(majorId) {
      return this.$store.getters["majors/getMajorById"](majorId);
    },
    callDataModal(type, title, msg) {
      this.modalType = type;
      this.modalTitle = title;
      this.modalMessage = msg;
      this.modalVisible = true;
    },
    clearDataModal() {
      this.modalType = null;
      this.modalTitle = null;
      this.modalMessage = null;
    },
    closeDataModal() {
      this.modalVisible = false;
      this.clearDataModal();
    },
    isPetActive(status) {
      return status === "pending";
    },
    async getPetitionJson() {
      try {
        const petJson = this.filteredPet.map((doc) => {
          return {
            time: doc.time.toDate(),
            email: doc.studentInfo.email,
            เบอร์ติดต่อ: doc.studentInfo.tel,
            เลขทะเบียน: doc.studentInfo.stdCode,
            คำนำหน้าชื่อ: doc.studentInfo.nameTitle,
            "ชื่อ-นามสกุล":
              doc.studentInfo.firstName + " " + doc.studentInfo.lastName,
            สาขาวิชาเอกของนักศึกษา: this.getMajorNSchool(doc),
            วิชาหลักสูตรล่าสุด: doc.subCode,
            "วิชาตามหลักสูตรน.ศ.": doc.quotaInfo.subCode,
            section: doc.quotaInfo.secId,
            group: doc.quotaInfo.group,
            รอบ: doc.qRound || "-",
            เหตุผล: this.reasonToStr(doc.reason),
            เหตุผลเพิ่มเติม: doc.personalReason ? doc.personalReason : "-",
            เอกสารแนบ: {
              l: {
                Target: doc.file,
              },
              v: doc.file,
            },
            สถานะ: doc.status,
          };
        });
        return petJson;
      } catch (e) {
        this.error = e;
      }
    },
    async onExport() {
      this.isLoading = true;
      const exportJson = await this.getPetitionJson();

      const userFilter = {
        sub: this.filterText || "all",
        sec: this.filterSec,
        gr: this.filterGr,
        round: this.filterRound,
      };

      // init worksheet
      const dataWS = XLSX.utils.json_to_sheet([{}]);

      // adddata to worksheet (Header)
      XLSX.utils.sheet_add_json(dataWS, [{ A: "รายการคำร้องขอเพิ่มวิชา" }], {
        skipHeader: true,
        origin: `E1`,
      });
      XLSX.utils.sheet_add_json(
        dataWS,
        [
          {
            วิชา: userFilter.sub,
            section: userFilter.sec,
            group: userFilter.gr,
            รอบ: this.qRoundToText(userFilter.round),
          },
        ],
        {
          skipHeader: false,
          origin: `D2`,
        }
      );

      XLSX.utils.sheet_add_json(dataWS, exportJson, {
        skipHeader: false,
        origin: `A5`,
      });

      // create new workbook
      const wb = XLSX.utils.book_new();

      // write sheet in to book
      XLSX.utils.book_append_sheet(wb, dataWS);

      // save book in file xlsx format
      XLSX.writeFile(
        wb,
        `คำร้อง_${userFilter.sub}_${userFilter.sec}_${
          userFilter.gr
        }_${this.qRoundToText(userFilter.round)}.xlsx`
      );
      this.isLoading = false;
    },
    getMajorNSchool(petInfo) {
      if (petInfo.stdMajor) {
        return petInfo.stdMajor;
      } else if (petInfo.studentInfo.major) {
        return this.getMajor(petInfo.studentInfo.major).name;
      } else {
        return "-";
      }
    },
    reasonToStr(reasonArr) {
      let theStr = "";
      for (let [i, aReason] of reasonArr.entries()) {
        if (i + 1 < reasonArr.length) {
          theStr = theStr + aReason + ", ";
        } else {
          theStr = theStr + aReason;
        }
      }
      return theStr;
    },
    async deletePetitions() {
      this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังบันทึกข้อมูล",
      });
      try {
        const deletePetitions = functions.httpsCallable("deletePetitions");
        await deletePetitions({
          folder: `${this.fSemYear}/`,
          fSemYear: this.fSemYear,
        });
        this.$store.dispatch("updateToast", {
          mode: "success",
          show: true,
          message: "ลบข้อมูลสำเร็จ",
        });
      } catch (e) {
        this.$store.dispatch("clearToast");
        this.error = e.message;
      }
    },
    async getReqQuota(stdCode, quotaId, reqQutTime, reqResult) {
      try {
        const theReqQuota = await this.$store.dispatch("quota/getReqQuota", {
          mode: "stdCode",
          searchValue: {
            stdCode: stdCode,
            quotaId: quotaId,
            reqQutTime: reqQutTime,
            reqResult: reqResult,
          },
        });
        return theReqQuota;
      } catch (e) {
        this.error = e.message;
        throw e;
      }
    },
  },
};
</script>

<style scoped>
.form-row {
  @apply pb-3;
}
.std-info-grid {
  @apply grid grid-cols-4 py-6;
}

.std-info-grid h3 {
  @apply col-span-4 md:col-span-1 pb-4;
}

.std-info-data {
  @apply col-span-4 md:col-span-3;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
