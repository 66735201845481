import { isLogVisible } from "../../../js/debug.js";

function sortSubjectCode(a, b) {
    var nameA = a.code.toUpperCase();
    var nameB = b.code.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
}

function sortSubjectYear(a, b) {
    var yearA = a.year;
    var yearB = b.year;
    if (yearA < yearB) {
        return -1;
    }
    if (yearA > yearB) {
        return 1;
    }
    return 0;
}

export default {
    // SUBJECT
    subjects(state) {
        // return state.subjects;
        return state.subjects.sort(sortSubjectCode);
    },

    hasSubjects(state) {
        return state.subjects.length > 0;
    },

    hasSubjectById: (state, getters) => (id) => {
        const subjects = getters.subjects;
        const findedSubject = subjects.find((subject) => subject.id === id);

        return !!findedSubject;
    },

    getSubjectByCode: (state, getters) => (subCode) => {
        const subjects = getters.subjects;
        const filteredSubjects = subjects.filter(
            (subject) => subject.code === subCode
        );
        filteredSubjects.sort(sortSubjectCode);
        return filteredSubjects;
    },

    getSubjectByCodeAndYear: (state, getters) => (payload) => {
        const yearDigit = payload.yearDigit || 4;
        const subjectCode = payload.code;
        const subjectYear = parseInt(payload.year);
        const subjects = getters.subjects;
        if (yearDigit === 2) {
            return subjects.find((subject) => {
                const twoDigitsubYear = parseInt(
                    subject.year.toString().substring(2, 4)
                );
                return subject.code === subjectCode && twoDigitsubYear === subjectYear;
            });
        } else if (yearDigit === 4) {
            return subjects.find((subject) => {
                return subject.code === subjectCode && subject.year === subjectYear;
            });
        } else {
            throw Error("yearDigit is not supported");
        }
    },

    getSubjectsById: (state, getters) => (id) => {
        const subjects = getters.subjects;
        return subjects.find((subject) => subject.id === id);
    },

    getSubjectsByOffLink: (state, getters) => (offLink) => {
        const subjects = getters.subjects;
        return subjects.filter((subject) => subject.offLink === offLink);
    },

    getSubjectByYear: (state, getters) => (years) => {
        const subjects = getters.subjects;
        const filteredSubjects = subjects.filter((subject) => {
            return years.some((year) => year === subject.year);
        });
        filteredSubjects.sort(sortSubjectCode);
        return filteredSubjects;
    },
    getLastSubjectByOffLink: (state, getters) => (OffLink) => {
        if (isLogVisible()) {
            console.log("getLastSubjectByOffLink");
            console.log(OffLink);
        }
        const subjects = getters.getSubjectsByOffLink(OffLink);
        if (isLogVisible()) {
            console.log(subjects);
        }
        const sortedSub = subjects.sort(sortSubjectYear);
        if (isLogVisible()) {
            console.log(sortedSub);
        }

        return sortedSub[sortedSub.length - 1];
    },
    getOffLinkByYear: (state, getters) => (years) => {
        const subjects = getters.subjects;
        const OffLinkSet = new Set();

        for (const subject of subjects) {
            const hasSubject = years.some((year) => year === subject.year);

            if (hasSubject) {
                OffLinkSet.add(subject.offLink);
            }
        }

        return [...OffLinkSet];
    },
    getSubjectByYearAndOffLink: (state, getters) => (payload) => {
        const year = payload.year;
        const offLink = payload.offLink;
        const subjects = getters.subjects;

        const foundSubject = subjects.find(
            (e) => e.year === year && e.offLink === offLink
        );

        return foundSubject;
    },

    //----- YEAR -----//
};