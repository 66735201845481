<template>
  <!-- <std-header></std-header> -->
  <div class="py-20">
    <div style="max-width: 1050px; margin: 0px auto; width: 100%; padding: 0px">
      <!-- privacy notice content -->
      <p style="text-align: center">
        <span class="text-3xl text-primary-500"
          ><strong>คำถามที่พบบ่อย (FAQ)</strong></span
        >
      </p>
      <div>
        <div class="bg-white mx-auto p-6">
          <!-- start block -->
          <div class="mb-4" v-for="faq in faqs" :key="faq.id">
            <div
              @click="
                activeFaq === faq.id ? (activeFaq = null) : (activeFaq = faq.id)
              "
              class="flex items-center justify-between bg-gray-200 pl-3 pr-2 py-3 w-full rounded text-gray-600 font-bold cursor-pointer hover:bg-gray-300"
            >
              {{ faq.question }}
              <span
                class="h-6 w-6 flex items-center justify-center text-teal-500"
              >
                <svg
                  v-if="activeFaq === faq.id"
                  class="w-3 h-3 fill-current"
                  viewBox="0 -192 469.33333 469"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m437.332031.167969h-405.332031c-17.664062 0-32 14.335937-32 32v21.332031c0 17.664062 14.335938 32 32 32h405.332031c17.664063 0 32-14.335938 32-32v-21.332031c0-17.664063-14.335937-32-32-32zm0 0"
                  />
                </svg>
                <svg
                  v-else
                  class="w-3 h-3 fill-current"
                  viewBox="0 0 469.33333 469.33333"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0"
                  />
                </svg>
              </span>
            </div>
            <div class="p-3" v-if="activeFaq === faq.id">
              <p class="text-gray-600 mb-3">
                {{ faq.ans }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import StdHeader from '../../components/layouts/Header/Std.vue';
  import LoadDataMixin from '../../mixins/loadData.js';
  import { isLogVisible } from '../../js/debug.js';

  export default {
    component: { StdHeader },
    mixins: [LoadDataMixin], // loadFaq

    data() {
      return {
        activeFaq: null,
      };
    },

    async created() {
      this.isLoading = true;
      await this.loadFaq();
      this.isLoading = false;
    },
    computed: {
      faqs() {
        return this.$store.getters['faqs/faqs'];
      },
      logVisible() {
        return isLogVisible();
      },
    },
    methods: {},
  };
</script>

<style scoped>
  .form-row {
    @apply pb-3;
  }
  .std-info-grid {
    @apply grid grid-cols-4 py-6;
  }

  .std-info-grid h3 {
    @apply col-span-4 md:col-span-1 pb-4;
  }

  .std-info-data {
    @apply col-span-4 md:col-span-3;
  }

  @media (min-width: 768px) {
    table {
      display: inline-table !important;
    }

    thead tr:not(:first-child) {
      display: none;
    }
  }

  td:not(:last-child) {
    border-bottom: 0;
  }

  th:not(:last-child) {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }

  th {
    @apply bg-brown-300 font-semibold;
  }
</style>
