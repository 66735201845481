<template>
  <!-- This is StdDetail -->
  <div class="flex justify-center py-20" v-if="isLoading">
    <base-spinner></base-spinner>
  </div>
  <div v-else class="main-padding">
    <div class="py-2 pt-20 flex justify-between no-print">
      <div>
        <router-link :to="getPrevRoute">
          <base-button mode="gray">
            <div class="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
              <span>ย้อนกลับ</span>
            </div>
          </base-button>
        </router-link>
      </div>
      <div>
        <base-button mode="flat" link :to="{ name: 'admin-std-info' }"
          >ข้อมูลบุคคล</base-button
        >
        <base-button mode="flat" link :to="{ name: 'admin-std-quota' }"
          >ผลแจ้งความจำนง</base-button
        >
        <base-button mode="flat" link :to="{ name: 'admin-std-petition' }"
          >รายการคำร้อง</base-button
        >
      </div>
    </div>
    <div class="shadow-lg rounded-xl border border-gray-100 px-10 py-5">
      <div v-if="selectedPage === 'quota'">
        <div>
          {{ studentInfo.nameTitle }} {{ studentInfo.firstName }}
          {{ studentInfo.lastName }} ({{ studentInfo.stdCode }}) | สาขาวิชาเอก
          {{ studentMajor.name }},{{ studentMajor2.name }}
        </div>
        <div class="my-4 border-b border-gray-300"></div>
      </div>

      <router-view></router-view>
    </div>
  </div>
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
  <!-- {{ selectedPage }}
  {{ reqQuotaAndQuota }} -->
</template>

<script>
export default {
  props: ["stdId"],
  data() {
    return {
      selectedPage: "quota",
      isLoading: false,
      error: null,
      prevRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((ev) => {
      ev.prevRoute = from;
    });
  },
  created() {
    this.loadData();
  },
  computed: {
    getPrevRoute() {
      if (this.prevRoute && this.prevRoute.name !== undefined) {
        return {
          name: this.prevRoute.name,
          params: this.prevRoute.params,
        };
      } else {
        return { name: "quota-list" };
      }
    },
    isSudo() {
      return this.$store.getters["isSudo"];
    },
    reqQuotaAndQuota() {
      return this.$store.getters["adminStudents/reqQuotaAndQuota"];
    },
    studentInfo() {
      return this.$store.getters["adminStudents/studentInfo"];
    },
    studentMajor() {
      if (this.studentInfo.major) {
        return this.$store.getters["majors/getMajorById"](
          this.studentInfo.major
        );
      } else {
        return "";
      }
    },
    studentMajor2() {
      if (this.studentInfo.major2) {
        return this.$store.getters["majors/getMajorById"](
          this.studentInfo.major2
        );
      } else {
        return "";
      }
    },
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      await this.setStudentInfo();
      await this.setStdReqQuota();
      await this.setStudentMajorChange();
      await this.setStudentStatusChange();
      this.isLoading = false;
    },
    async setStudentMajorChange() {
      try {
        await this.$store.dispatch("adminStudents/setStudentMajorChange", {
          stdCode: this.studentInfo.stdCode,
        });
      } catch (e) {
        this.error = e;
      }
    },
    async setStudentStatusChange() {
      try {
        await this.$store.dispatch("adminStudents/setStudentStatusChange", {
          stdCode: this.studentInfo.stdCode,
        });
      } catch (e) {
        this.error = e;
      }
    },
    async setStudentInfo() {
      try {
        await this.$store.dispatch("adminStudents/setStudentInfo", {
          uid: this.stdId,
        });
      } catch (e) {
        this.error = e;
      }
    },
    async setStdReqQuota() {
      try {
        await this.$store.dispatch("adminStudents/setStdReqQuota", {
          uid: this.stdId,
        });
      } catch (e) {
        this.error = e;
      }
    },
    closeError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
a:active,
a:hover,
a.router-link-active {
  @apply bg-blue-50;
}

.main-padding {
  @apply px-20;
}

@media print {
  .main-padding {
    @apply px-0;
  }
}

/* a:hover span,
a:active span,
a.router-link-active span {
  @apply font-extrabold;
} */
</style>
