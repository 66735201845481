<template>
  <div class="min-h-screen bg-blue-100 p-0 sm:p-12">
    <!-- gray-100 -->
    <div
      class="mx-auto max-w-md lg:max-w-xl px-6 py-12 bg-white border-0 shadow-lg sm:rounded-3xl"
    >
      <h1 class="text-2xl font-semi-bold mb-8 text-center">
        ลงทะเบียนเพื่อใช้ระบบโควตา
      </h1>
      <!-- https://tailwindcomponents.com/component/steps-bar -->
      <!-- https://tailwindcomponents.com/component/floating-form-labels -->

      <form id="form" novalidate v-if="!isComplete">
        <h2 class="text-lg font-normal pb-4 text-gray-500">
          ข้อมูลเข้าใช้ระบบ
        </h2>
        <div class="relative z-0 w-full mb-6">
          <input
            type="text"
            name="name"
            placeholder=" "
            @blur="v$.stdCode.$touch"
            v-model.trim="stdCode"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="name"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >รหัสนักศึกษา</label
          >
          <span
            class="errors"
            v-for="error of v$.stdCode.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>

        <div class="relative z-0 w-full mb-6">
          <input
            type="email"
            name="email"
            @blur="v$.email.$touch"
            v-model.trim="email"
            placeholder=""
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="email"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >name@example.com</label
          >
          <span
            class="errors"
            v-for="error of v$.email.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>

        <div class="relative z-0 w-full mb-6">
          <input
            type="password"
            name="password"
            placeholder=" "
            @blur="v$.password.$touch"
            v-model.trim="password"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="password"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >Password</label
          >
        </div>

        <div class="relative z-0 w-full mb-6">
          <input
            type="password"
            name="password"
            @blur="v$.cfPassword.$touch"
            v-model.trim="cfPassword"
            placeholder=" "
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="password"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >Confirm password</label
          >
          <span
            class="errors"
            v-for="error of v$.cfPassword.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <h2 class="text-lg font-normal pb-4 pt-6 text-gray-500">
          ข้อมูลส่วนบุคคล
        </h2>
        <div class="flex flex-col md:flex-row md:space-x-4 mb-8">
          <div class="flex-auto relative z-0 w-full md:w-48 mb-6">
            <select
              name="select"
              @blur="v$.nameTitle.$touch"
              v-model.trim="nameTitle"
              class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-black border-gray-200"
            >
              <option value="" selected disabled hidden></option>
              <option value="นาย">นาย</option>
              <option value="นางสาว">นางสาว</option>
              <option value="นาง">นาง</option>
            </select>
            <label
              for="select"
              class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
              >คำนำหน้า</label
            >
            <span
              class="errors"
              v-for="error of v$.nameTitle.$errors"
              :key="error.$uid"
              >{{ error.$message }}<br />
            </span>
          </div>
          <div class="flex-auto relative z-0 w-full mb-6 md:mb-0">
            <input
              type="text"
              name="name"
              placeholder=" "
              v-model.trim="firstName"
              @blur="v$.firstName.$touch"
              class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
            />
            <label
              for="name"
              class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
              >ชื่อ</label
            >
            <span
              class="errors"
              v-for="error of v$.firstName.$errors"
              :key="error.$uid"
              >{{ error.$message }}<br />
            </span>
          </div>
          <div class="flex-auto relative z-0 w-full ">
            <input
              type="text"
              name="name"
              placeholder=" "
              @blur="v$.lastName.$touch"
              v-model.trim="lastName"
              class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
            />
            <label
              for="name"
              class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
              >นามสกุล</label
            >
            <span
              class="errors"
              v-for="error of v$.lastName.$errors"
              :key="error.$uid"
              >{{ error.$message }}<br />
            </span>
          </div>
        </div>

        <fieldset class="relative z-0 w-full p-px mb-4">
          <legend
            class="absolute text-gray-500 transform scale-90 -top-3 origin-0"
          >
            เพศ
          </legend>
          <div class="block pt-3 pb-1 space-x-4">
            <label>
              <input
                type="radio"
                name="radio"
                value="M"
                @blur="v$.gender.$touch"
                v-model.trim="gender"
                class="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"
              />
              ชาย
            </label>
            <label>
              <input
                type="radio"
                name="radio"
                value="F"
                @blur="v$.gender.$touch"
                v-model.trim="gender"
                class="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"
              />
              หญิง
            </label>
          </div>
          <span
            class="errors"
            v-for="error of v$.gender.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </fieldset>

        <div class="relative z-0 w-full mb-6">
          <textarea
            name="name1"
            v-model.trim="conAddress"
            @blur="v$.conAddress.$touch"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          >
          </textarea>
          <label
            for="name1"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >ที่อยู่(ติดต่อได้สะดวก)</label
          >
          <span
            class="errors"
            v-for="error of v$.conAddress.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>

        <div class="relative z-0 w-full mb-6">
          <textarea
            name="name1"
            @blur="v$.homAddress.$touch"
            v-model.trim="homAddress"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          >
          </textarea>
          <label
            for="name1"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >ที่อยู่(ตามทะเบียนบ้าน)</label
          >
          <span
            class="errors"
            v-for="error of v$.homAddress.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>

        <div class="relative z-0 w-full mb-6">
          <input
            type="tel"
            name="name"
            placeholder=" "
            @blur="v$.tel.$touch"
            v-model.trim="tel"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="name"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >เบอร์โทร</label
          >
          <span class="errors" v-for="error of v$.tel.$errors" :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>

        <div class="relative z-0 w-full mb-6">
          <input
            type="citizenId"
            name="name"
            placeholder=" "
            @blur="v$.citizenId.$touch"
            v-model.trim="citizenId"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="citizenId"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >เลขประจำตัวประชาชน</label
          >
          <span
            class="errors"
            v-for="error of v$.citizenId.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>

        <div class="relative z-0 w-full mb-6">
          <input
            type="date"
            name="date"
            placeholder=" "
            @blur="v$.birthday.$touch"
            v-model="birthday"
            onclick="this.setAttribute('type', 'date');"
            class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="date"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >วัน/เดือน/ปี เกิด</label
          >
          <span
            class="errors"
            v-for="error of v$.birthday.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br /><br />
          </span>
          <!-- <hr />
          {{ birthday }}
          <hr />
          {{ new Date() }}
          <hr />
          {{ new Date(birthday) }} -->
        </div>

        <div class="relative z-0 w-full mb-6">
          <select
            name="select"
            @blur="v$.program.$touch"
            v-model.trim="program"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none z-1 focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          >
            <option value="" selected disabled hidden></option>
            <option value="undergrad">ปริญญาตรี</option>
            <option value="ibmp">ปริญญาตรี-โท 5 ปี</option>
          </select>
          <label
            for="select"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >เลือกหลักสูตร</label
          >
          <span
            class="errors"
            v-for="error of v$.program.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <h2 class="text-lg font-normal pb-4 pt-6 text-gray-500">
          Emergency Contact
        </h2>
        <div class="relative z-0 w-full mb-6">
          <input
            type="tel"
            name="name"
            placeholder=" "
            @blur="v$.emergTel.$touch"
            v-model.trim="emergTel"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="name"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >เบอร์โทร</label
          >
          <span
            class="errors"
            v-for="error of v$.emergTel.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="relative z-0 w-full mb-6">
          <input
            type="text"
            name="school"
            placeholder=" "
            @blur="v$.emergName.$touch"
            v-model.trim="emergName"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="school"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >ชื่อ-นามสกุล</label
          >
          <span
            class="errors"
            v-for="error of v$.emergName.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="relative z-0 w-full mb-6">
          <input
            type="text"
            name="school"
            placeholder=" "
            @blur="v$.emergRelation.$touch"
            v-model.trim="emergRelation"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="school"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >เกี่ยวข้องเป็น</label
          >
          <span
            class="errors"
            v-for="error of v$.emergRelation.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <h2 class="text-lg font-normal pb-4 pt-6 text-gray-500">
          โรงเรียนมัธยมปลาย
        </h2>
        <div class="relative z-0 w-full mb-6">
          <input
            type="text"
            name="school"
            placeholder=" "
            @blur="v$.schoolName.$touch"
            v-model.trim="schoolName"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="school"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >โรงเรียน</label
          >
          <span
            class="errors"
            v-for="error of v$.schoolName.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="relative z-0 w-full mb-6">
          <input
            type="text"
            name="school"
            placeholder=" "
            @blur="v$.schoolMajor.$touch"
            v-model.trim="schoolMajor"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="school"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >หลักสูตร</label
          >
          <span
            class="errors"
            v-for="error of v$.schoolMajor.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="relative z-0 w-full mb-6">
          <input
            type="text"
            name="school"
            placeholder=" "
            @blur="v$.schoolGrade.$touch"
            v-model.number="schoolGrade"
            class="pt-3 pb-1 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
          />
          <label
            for="school"
            class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
            >เกรดเฉลี่ย (GPAX)</label
          >
          <span
            class="errors"
            v-for="error of v$.schoolGrade.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <button
          id="button"
          type="button"
          class="w-full px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-pink-500 hover:bg-pink-600 hover:shadow-lg focus:outline-none"
          @click.prevent="stdSignUp()"
        >
          ลงทะเบียน
        </button>
      </form>

      <div v-else>
        <div class="flex justify-center py-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-14 w-14 text-green-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div class="text-xl text-center">
          ลงทะเบียนสำเร็จ
        </div>
        <div class="flex justify-center">
          <router-link
            id="button"
            type="button"
            class="text-center w-1/2 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-blue-500 hover:bg-blue-600 hover:shadow-lg focus:outline-none"
            :to="{ name: 'login' }"
          >
            เข้าสู่ระบบ
          </router-link>
        </div>
      </div>
      <!-- <div v-if="isLogVisible">
        <base-button @click.prevent="regStep = 1">1</base-button>
        <base-button @click.prevent="regStep = 2">2</base-button>
        <base-button @click.prevent="regStep = 3">3</base-button>
        <base-button @click.prevent="regStep = 4">4</base-button>
      </div> -->
    </div>
  </div>
  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
  <SpinnerDialog :show="isLoading"></SpinnerDialog>
</template>

<script>
import { auth, db } from "../../js/firebaseDb.js";
import firebase from "firebase/app";
import "firebase/firestore";
import { isLogVisible } from "../../js/debug.js";
import FormValidateMixin from "../../mixins/formValidation.js";
import SpinnerDialog from "../../components/UI/SpinnerDialog.vue";
import useVuelidate from "@vuelidate/core";

import {
  required,
  numeric,
  not,
  minLength,
  maxLength,
  helpers,
  sameAs,
  email,
  integer,
  decimal,
} from "@vuelidate/validators";

export default {
  components: { SpinnerDialog },
  mixins: [FormValidateMixin], // this.checkEmail(); this.checkPassword(); validStdNo()
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      form1IsValid: true,
      form2IsValid: true,

      stdCode: null,
      email: null,
      password: null,

      nameTitle: null,
      firstName: null,
      lastName: null,
      gender: null,
      conAddress: null,
      homAddress: null,
      tel: null,
      birthday: null,
      program: null,
      major: null,
      // optional data
      citizenId: null,
      schoolName: null,
      schoolMajor: null,
      schoolGrade: null,
      // Emergency contact information
      emergTel: null,
      emergName: null,
      emergRelation: null,

      // system
      isComplete: false,
      cfPassword: null,
      successEmail: null,
      error: null,
      formValid: true,
      isLoading: false,
    };
  },
  validations() {
    return {
      stdCode: {
        integer,
        required,
        validStdNo: helpers.withMessage(
          "รูปแบบเลขนักศึกษาไม่ถูกต้อง",
          this.validStdNo
        ),
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      email: { required, email },
      password: { required, minLengthValue: minLength(6) },
      cfPassword: {
        required,
        sameAsPassword: sameAs(this.password),
        minLengthValue: minLength(6),
      },

      nameTitle: { required },
      firstName: {
        required,
        char: helpers.withMessage("This field should be string", not(numeric)),
        minLength: minLength(2),
      },
      lastName: {
        required,
        char: helpers.withMessage("This field should be string", not(numeric)),
        minLength: minLength(2),
      },
      gender: { required },
      conAddress: { required },
      homAddress: { required },
      tel: {
        required,
        integer,
        minLengthValue: minLength(8),
        maxLengthValue: maxLength(12),
      },
      birthday: { required },
      program: { required },

      // optional data
      citizenId: {
        required,
        integer,
        minLengthValue: minLength(13),
        maxLengthValue: maxLength(13),
      },
      schoolName: { required },
      schoolMajor: { required },
      schoolGrade: { required, decimal },

      emergTel: {
        required,
        integer,
        minLengthValue: minLength(8),
        maxLengthValue: maxLength(12),
      },
      emergName: { required },
      emergRelation: { required },
    };
  },
  computed: {
    isLogVisible() {
      return isLogVisible;
    },
    schedule() {
      return this.$store.getters["schedule"];
    },
  },

  methods: {
    closeError() {
      this.error = null;
    },
    isFormValid() {
      this.formValid = true;
      this.checkstdCode();
      // this.checkEmail();
      // this.checkPassword();
      // this.checkNameTitle();
      // // this.checkFirstName();
      // this.checkLastName();
      // this.checkGender();
      // this.checkConAddress();
      // this.checkHomAddress();
      // this.checkTel();
      // this.checkBirthday();
      // this.checkProgram();
      // this.checkMajor();
    },

    checkstdCode() {
      this.stdCode.error = null;
      const regex = /\D/g;
      if (!this.stdCode || this.stdCode == "") {
        this.stdCode.error = "เลขนักศึกษาไม่สามารถเว้นว่างได้";
        this.formValid = false;
      } else if (this.stdCode.search(regex) !== -1) {
        this.stdCode.error = "เลขนักศึกษาต้องเป็นตัวเลขเท่านั้น ";
        this.formValid = false;
      } else if (this.stdCode.length !== 10) {
        this.stdCode.error = "จำนวนเลขนักศึกษาไม่ถูกต้อง ";
        this.formValid = false;
      } else if (!this.validStdNo(this.stdCode)) {
        this.stdCode.error = "รูปแบบเลขนักศึกษาไม่ถูกต้อง ";
        this.formValid = false;
      }
    },
    checkPassword() {
      this.password.error = null;
      if (!this.password || this.password == "") {
        this.password.error = "Password ไม่สามารถเว้นว่างได้";
        this.formValid = false;
      } else if (this.password !== this.cfPassword) {
        this.password.error = "Password ไม่ตรงกัน";
        this.formValid = false;
      }
    },
    checkNameTitle() {
      this.nameTitle.error = null;
      if (!this.nameTitle || this.nameTitle == "") {
        this.nameTitle.error = "คำนำหน้า ไม่สามารถเว้นว่างได้";
        this.formValid = false;
      }
    },
    checkLastName() {
      this.lastName.error = null;
      if (!this.lastName || this.lastName == "") {
        this.lastName.error = "นามสกุล ไม่สามารถเว้นว่างได้";
        this.formpalid = false;
      }
    },
    checkGender() {
      this.gender.error = null;
      if (!this.gender || this.gender == "") {
        this.gender.error = "เพศ ไม่สามารถเว้นว่างได้";
        this.formValid = false;
      }
    },
    checkConAddress() {
      this.conAddress.error = null;
      if (!this.conAddress || this.conAddress == "") {
        this.conAddress.error = "ที่อยู่ ไม่สามารถเว้นว่างได้";
        this.formValid = false;
      }
    },
    checkHomAddress() {
      this.homAddress.error = null;
      if (!this.homAddress || this.homAddress == "") {
        this.homAddress.error = "ที่อยู่ ไม่สามารถเว้นว่างได้";
        this.formValid = false;
      }
    },
    checkTel() {
      this.tel.error = null;
      const regex = /\D/g;
      if (!this.tel || this.tel == "") {
        this.tel.error = "เบอร์โทรไม่สามารถเว้นว่างได้";
        this.formValid = false;
      } else if (this.tel.search(regex) !== -1) {
        this.tel.error = "เบอร์โทรต้องเป็นตัวเลขเท่านั้น ";
        this.formValid = false;
      } else if (this.tel.length < 8 || this.tel.length > 12) {
        this.tel.error = "เบอร์โทรไม่ถูกต้อง ";
        this.formValid = false;
      }
    },
    checkBirthday() {
      this.birthday.error = null;
      if (!this.birthday || this.birthday == "") {
        this.birthday.error = "วันเกิด ไม่สามารถเว้นว่างได้";
        this.formValid = false;
      }
    },
    checkProgram() {
      this.program.error = null;
      if (!this.program || this.program == "") {
        this.program.error = "วันเกิด ไม่สามารถเว้นว่างได้";
        this.formValid = false;
      }
    },
    checkMajor() {
      this.major.error = null;
      if (!this.major || this.major == "") {
        this.major.error = "วันเกิด ไม่สามารถเว้นว่างได้";
        this.formValid = false;
      }
    },
    setMajor() {
      // check if ... In TBS Department
      const stdStr = this.stdCode.toString();
      if (stdStr.substring(2, 4) == "02") {
        // check if ... is Accounting Major
        if (parseInt(stdStr.substring(6, 9)) < 500) {
          if (this.program === "undergrad") {
            this.major = 9;
          } else if (this.program === "ibmp") {
            this.major = 13;
          } else {
            this.error = `${this.program} program is not supported`;
          }
        } else {
          // else ... Business Administrator
          if (this.program === "undergrad") {
            this.major = 10;
          } else if (this.program === "ibmp") {
            this.major = 12;
          } else {
            this.error = `${this.program} program is not supported`;
          }
        }
      } else {
        this.major = 0;
      }
    },

    async hasUsername(username) {
      // Initialize document
      const userRef = db.collection("users");
      const userDocs = await userRef.where("username", "==", username).get();

      if (userDocs.size === 0) {
        return false;
      } else {
        return true;
      }
    },

    async stdSignUp() {
      // check validation
      // this.isFormValid();
      // if (!this.formValid) {
      //   return;
      // }

      const hasUser = await this.hasUsername(this.stdCode);

      if (hasUser) {
        this.isLoading = false;
        this.error =
          "ไม่ได้สามารถลงทะเบียนได้ เนื่องจากรหัสนักศึกษานี้ถูกใช้ลงทะเบียนแล้ว";
        return;
      }

      this.isLoading = true;

      this.v$.$touch();
      if (this.v$.$error) {
        this.isLoading = false;
        return;
      }

      this.setMajor();

      // actually submit form
      await auth
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(async (cred) => {
          const payload = {
            stdCode: this.stdCode,
            firstName: this.firstName,
            nameTitle: this.nameTitle,
            lastName: this.lastName,
            gender: this.gender,
            conAddress: this.conAddress,
            homAddress: this.homAddress,
            program: this.program,
            major: this.major,
            tel: this.tel,
            birthday: new Date(this.birthday),
            email: this.email,
            status: 1,
            time: firebase.firestore.FieldValue.serverTimestamp(),
            updateInfoTime: firebase.firestore.FieldValue.serverTimestamp(),
            // optional
            stdHighSchool: this.schoolName,
            stdHighSchoolGrade: this.schoolGrade,
            stdHighSchoolSchool: this.schoolMajor,
            stdIdCard: this.citizenId,
            // emergency contact information
            emergTel: this.emergTel,
            emergName: this.emergName,
            emergRelation: this.emergRelation,
          };
          this.successEmail = cred.user.email;

          const batch = db.batch();
          batch.set(db.collection("students").doc(cred.user.uid), payload);
          batch.set(db.collection("users").doc(cred.user.uid), {
            email: this.email,
            username: this.stdCode,
            time: firebase.firestore.FieldValue.serverTimestamp(),
          });

          // prepare data for logging
          const majorChangeRef = db.collection("student_major_change").doc();
          const statusChangeRef = db.collection("student_status_change").doc();
          const basePayload = {
            stdCode: this.stdCode,
            stfId: "system",
            time: firebase.firestore.FieldValue.serverTimestamp(),
            semester: this.schedule.semester,
            year: this.schedule.year,
            remark: "",
          };

          // log student_major
          batch.set(majorChangeRef, {
            ...basePayload,
            from: 0,
            to: this.major,
            majorNo: 1,
          });

          batch.set(statusChangeRef, {
            ...basePayload,
            from: 0,
            to: 1,
          });

          await batch.commit();
          auth
            .signOut()
            .then(() => {
              this.isComplete = true;
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              this.error = error;
            });
          this.isComplete = true;
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = `${error.code} : ${error.message}`;
          this.isLoading = false;
        });
    },
    toStep(step) {
      // validate some thing
      this.regStep = step;
    },
  },
};
</script>

<style>
.-z-1 {
  z-index: -1;
}

.origin-0 {
  transform-origin: 0%;
}

input:focus ~ label,
input:not(:placeholder-shown) ~ label,
textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label,
select:focus ~ label,
select:not([value=""]):valid ~ label {
  /* @apply transform; scale-75; -translate-y-6; */
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
  --tw-translate-y: -1.5rem;
}

input:focus ~ label,
select:focus ~ label {
  /* @apply text-black; left-0; */
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  left: 0px;
}

.step-bar-active {
  @apply bg-green-500;
}

.step-icon-active {
  @apply bg-green-500 text-white;
}

.step-bar-inactive {
  @apply bg-gray-200;
}
.step-icon-inactive {
  @apply bg-white border-2 border-gray-200 text-gray-600;
}

input.errors {
  @apply border-red-500 bg-red-50;
}
</style>
