export default {
  methods: {
    isNumber: function(event) {
      event = event ? event : window.event;
      var charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        return true;
      }
    },

    checkEmail() {
      this.email.error = null;
      const regex = /^[^\s@]+@[^\s@]+$/g;
      if (!this.email.val || this.email.val == "") {
        this.email.error = "Email ไม่สามารถเว้นว่างได้";
        this.formValid = false;
      } else if (!regex.test(this.email.val)) {
        this.email.error = "รูปแบบ Email ไม่ถูกต้อง ";
        this.formValid = false;
      }
    },
    validStdNo(value) {
      // Accept only digits, dashes or spaces
      if (/[^0-9-\s]+/.test(value)) return false;

      // The Luhn Algorithm.
      let nCheck = 0;
      let bEven = false;
      value = value.replace(/\D/g, "");

      for (let n = value.length - 1; n >= 0; n--) {
        let cDigit = value.charAt(n);
        let nDigit = parseInt(cDigit, 10);
        if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
        nCheck += nDigit;
        bEven = !bEven;
      }

      return nCheck % 10 == 0;
    },
  },
};
