export default {
    addStudent(state, payload) {
        state.students.push(payload);
    },
    setStudent(state, payload) {
        state.students = payload;
    },
    clearStudent(state) {
        state.students = [];
    },
    setStatus(state, payload) {
        state.status = payload;
    },
    addStatus(state, payload) {
        state.status.push(payload);
    },
};