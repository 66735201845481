// import { isLogVisible } from "../../../js/debug.js";
import { db } from "../../../js/firebaseDb";

export default {
    async setConditions(context) {
        const conditionsRef = db.collection(`conditions`);
        const conditionsDoc = await conditionsRef.get().catch((error) => {
            console.error("Error getting documents: ", error);
        });

        if (conditionsDoc.empty) {
            const error = new Error("No such document!");
            throw error;
        }

        const conditions = [];
        conditionsDoc.forEach((doc) => {
            const year = {
                id: doc.id,
                ...doc.data(),
            };
            conditions.push(year);
        });

        context.commit("setConditions", conditions);
    },
};