import { isLogVisible } from "../../../js/debug.js";
import { db } from "../../../js/firebaseDb";
// import firebase from "firebase/app";
// import "firebase/firestore";

export default {
    async setInstructor(context) {
        const instRef = db.collection(`instructors`);
        const instDocs = await instRef.get().catch((error) => {
            console.error("Error getting documents: ", error);
        });

        if (instDocs.empty) {
            context.commit("setInstructor", []);
            const error = new Error("No such document!");
            throw error;
        }

        const instArr = instDocs.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
            };
        });

        context.commit("setInstructor", instArr);
    },
    async addInstructor(context, submitData) {
        const payload = {
            prefix: submitData.prefix,
            name: submitData.name,
            surname: submitData.surname,
        };
        const docRef = await db
            .collection("instructors")
            .add(payload)

        .catch((error) => {
            console.error("Error adding document: ", error);
        });

        payload["id"] = docRef.id;
        if (isLogVisible()) {
            console.log(submitData);
            console.log(payload);
            console.log("Document written with ID: ", docRef.id);
        }
        context.commit("addInstructor", payload);
    },
    async editInstructor(context, submitData) {
        if (isLogVisible()) {
            console.log(submitData);
        }

        const payload = {
            prefix: submitData.prefix,
            name: submitData.name,
            surname: submitData.surname,
        };

        await db
            .collection("instructors")
            .doc(submitData.id)
            .update(payload)
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
        payload["id"] = submitData.id;
        context.commit("updateInstructor", payload);

        await context.dispatch("updateInstructorQuota", {
            id: submitData.id,
            newName: `${submitData.prefix} ${submitData.name} ${submitData.surname}`,
        });
    },
    async updateInstructorQuota(context, updatePayload) {
        const fSemYear = context.rootGetters.fSemYear;
        const quotaRef = db.collection(`quota_${fSemYear}`);
        const quotaDocs = await quotaRef.get().catch((error) => {
            this.error = error.message;
            return;
        });
        for (const doc of quotaDocs.docs) {
            const theData = doc.data();
            const qId = doc.id;
            if ("instructors" in theData) {
                const newInstPayload = [];
                let isUpdate = false;
                for (const inst of theData.instructors) {
                    if (inst.id === updatePayload.id) {
                        isUpdate = true;
                        inst["name"] = updatePayload.newName;
                        newInstPayload.push(inst);
                    } else {
                        newInstPayload.push(inst);
                    }
                }
                if (isUpdate) {
                    await quotaRef
                        .doc(qId)
                        .update({ instructors: newInstPayload })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        });
                }
            }
        }
    },
    async deleteInstructor(context, submitData) {
        const payload = {
            status: "inActive",
        };

        await db
            .collection("instructors")
            .doc(submitData.id)
            .update(payload)

        .catch((error) => {
            console.error("Error adding document: ", error);
        });
        context.commit("deleteInstructor", { id: submitData.id });
    },
};