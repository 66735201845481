<template>
  <div>
    <div class="text-center py-10" v-if="myPetitions.length === 0">
      <h4>นักศึกษายังไม่มีคำร้องขอเพิ่มวิชา</h4>
    </div>

    <div v-else class="grid-table-main">
      <div
        class="
              grid grid-cols-3
              md:grid-cols-12
              grid-table-header
            "
      >
        <div class="col-span-2 md:hidden w-full h-auto">วิชา</div>
        <div class="md:hidden w-full h-auto">สถานะ</div>
        <div class="hidden md:block w-full h-auto">รหัสวิชา</div>
        <div class="hidden md:block w-full h-auto md:col-span-3 ">
          ชื่อวิชา
        </div>
        <div class="hidden md:block w-full h-auto">Campus</div>
        <div class="hidden md:block w-full h-auto">Sec-Group</div>
        <div class="hidden md:block w-full h-auto md:col-span-2">
          วันเวลา
        </div>
        <div class="hidden md:block w-full h-auto md:col-span-2 ">
          ผู้บรรยาย
        </div>
      </div>
      <!-- {{ myPetitions }} -->
      <div class="grid-table-body">
        <div
          class="grid grid-cols-3 md:grid-cols-12 grid-table-row"
          v-for="q in myPetitions"
          :key="q.id"
        >
          <div class="col-span-2 md:col-span-10 md:grid md:grid-cols-10 pl-2">
            <div
              class="md:col-span-5 md:grid md:grid-cols-5 md:text-center md:my-auto"
            >
              <span class="font-bold">
                {{ q.stdSubCode
                }}<span class="md:hidden">
                  -
                </span></span
              >
              <span class="md:col-span-3">{{ q.quotaInfo.subName }} </span>
              <span class="">
                <span class=" md:hidden"> (</span>{{ q.quotaInfo.campus
                }}<span class=" md:hidden">)</span>
              </span>
            </div>
            <div
              class="md:col-span-3 md:grid md:grid-cols-3 md:text-center md:my-auto"
            >
              <span>
                <span class="md:hidden">sec </span>{{ q.quotaInfo.secId }}-{{
                  q.quotaInfo.group
                }}</span
              >
              <span class="md:col-span-2">
                <span class=" md:hidden"> (</span>{{ q.quotaInfo.secTime
                }}<span class=" md:hidden"> )</span></span
              >
            </div>

            <div
              class="md:col-span-2  md:text-center md:my-auto"
              v-if="q.quotaInfo.instructors"
            >
              <span
                v-for="instructor in q.quotaInfo.instructors"
                :key="instructor.id"
              >
                {{ instructor.name }}
                <br />
              </span>
            </div>
            <div class="md:col-span-2  md:text-center md:my-auto" v-else>
              <span>{{ q.quotaInfo.instName }}</span>
            </div>
          </div>
          <div
            class="col-span-1 md:col-span-2  md:grid  text-left md:text-center my-auto "
          >
            <div
              class=" mx-auto flex justify-left md:justify-center md:my-auto pb-4 md:pb-0"
            >
              <div class="flex text-yellow-500" v-if="q.status === 'pending'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="font-semibold" title="รอการพิจารณาจากทางคณะฯ">
                  รอพิจารณา
                </span>
              </div>
              <div class="flex text-blue-500 " v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span
                  class="font-semibold"
                  title="โปรดตรวจสอบผลพิจารณาหน้าผลแจ้งความจำนง"
                >
                  พิจารณาแล้ว
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.loadData();
  },
  computed: {
    myPetitions() {
      return this.$store.getters["adminStudents/stdPetitions"];
    },
    studentInfo() {
      return this.$store.getters["adminStudents/studentInfo"];
    },
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("adminStudents/setStdPetition", {
          stdCode: this.studentInfo.stdCode,
        });
      } catch (e) {
        this.error = e.message;
      }
    },
  },
};
</script>
