<template>
  <div class="flex flex-col w-full">
    <div
      class="text-white bg-primary-200 flex flex-shrink-0 flex-col pl-4 py-4"
    >
      <div class="flex relative items-center p-4 h-12">
        <span class="text-2xl tracking-wide font-semibold">FAQ</span>
      </div>
    </div>
  </div>
  <div class="flex justify-center pt-20" v-if="isLoading">
    <base-spinner></base-spinner>
  </div>

  <div class="px-60" v-else>
    <base-card class="flex justify-end">
      <base-button @click="addFaq()"> + เพิ่มคำถาม</base-button>
    </base-card>
    <div class="flex items-center justify-center">
      <table
        class="flex flex-row flex-no-wrap md:block md:bg-white rounded-lg overflow-hidden md:shadow-lg my-5 mx-auto w-full"
      >
        <thead class="text-white">
          <tr
            class="bg-teal-400 flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-2"
          >
            <th class="p-3 text-center">No.</th>
            <th class="p-3 text-left">Question</th>
            <th class="p-3 text-left">Answer</th>
            <th class="p-3 border-b-1 text-left">แก้ไข</th>
          </tr>
        </thead>
        <tbody class="flex-1 md:flex-none">
          <tr
            v-for="(faq, index) in faqs"
            :key="faq"
            class="flex flex-col flex-no wrap md:table-row mb-2 md:mb-0"
          >
            <td
              class="text-center border-grey-light border hover:bg-gray-100 p-3"
            >
              {{ index + 1 }}
            </td>
            <td class="border-grey-light border hover:bg-gray-100 p-3">
              {{ faq.question }}
            </td>
            <td class="border-grey-light border hover:bg-gray-100 p-3">
              {{ faq.ans }}
            </td>
            <td
              class="border-grey-light border hover:bg-gray-100 p-3 text-blue-550 hover:text-blue-600 hover:font-medium cursor-pointer"
              @click="editFaq(faq)"
            >
              แก้ไข
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Edit Dialog -->
  <data-modal
    :show="showDialog"
    title="เพิ่มคำถาม"
    @close="showDialog = false"
    @delete="deleteDialog = true"
    :deleteBtn="mode == 'Edit'"
    @submit="submitFaq()"
  >
    <div class="form-row w-full">
      <label> คำถาม </label>
      <div class="col-span-2">
        <input
          class="form-control"
          type="text"
          v-model="faqQuestion"
          @blur="v$.faqQuestion.$touch"
        />
        <span
          class="errors"
          v-for="error of v$.faqQuestion.$errors"
          :key="error.$uid"
        >
          {{ error.$message }} <br />
        </span>
      </div>
      <div v-if="logVisible">{{ faqQuestion }}</div>
      <label> คำตอบ </label>
      <div class="col-span-2">
        <input
          class="form-control"
          type="text"
          v-model="faqAnswer"
          @blur="v$.faqAnswer.$touch"
        />
        <span
          class="errors"
          v-for="error of v$.faqAnswer.$errors"
          :key="error.$uid"
        >
          {{ error.$message }} <br />
        </span>
      </div>
      <div v-if="logVisible">{{ faqAnswer }}</div>
    </div>
  </data-modal>
  <toast :show="!!error" mode="error" @close="error = null" :message="error">
  </toast>
  <confirm-modal
    :show="deleteDialog"
    title="ยืนยันลบคำถาม ออกจากระบบ"
    @close="deleteDialog = false"
    @confirm="deleteFaq()"
  >
  </confirm-modal>
  <!-- <base-button @click="showTime()"> test</base-button> -->
</template>

<script>
// import { functions } from "../../js/firebaseDb.js";
import LoadDataMixin from "../../mixins/loadData.js";

import DataModal from "../../components/UI/DataModal.vue";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { isLogVisible } from "../../js/debug.js";
import ConfirmModal from "../../components/UI/ConfirmModal.vue";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import BaseButton from "../../components/UI/BaseButton.vue";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mixins: [LoadDataMixin], // loadFaq

  components: { DataModal, ConfirmModal, BaseButton },
  data() {
    return {
      showDialog: false,
      faqQuestion: null,
      faqAns: null,
      faqAnswer: null,
      mode: null,
      faqId: null,
      error: null,
      isLoading: false,
      deleteDialog: false,
    };
  },
  validations() {
    return {
      faqQuestion: { required },
      faqAnswer: { required },
    };
  },
  async created() {
    this.isLoading = true;
    await this.loadFaq();
    // this.showTime2();
    this.isLoading = false;
  },
  computed: {
    faqs() {
      return this.$store.getters["faqs/faqs"];
    },
    logVisible() {
      return isLogVisible();
    },
  },
  methods: {
    // showTime2() {
    //   setInterval(async () => {
    //     const controller = new AbortController();
    //     const timeoutId = setTimeout(() => controller.abort(), 5000);
    //     const url = "https://worldtimeapi.org/api/timezone/Asia/Bangkok";
    //     try {
    //       // throw 500
    //       const res = await fetch(url, {
    //         signal: controller.signal,
    //       });
    //       const data = await res.json();
    //       const ts = data.unixtime * 1000;
    //       console.log("use server time");
    //       console.log(ts);
    //       const getServerTimeFunction = functions.httpsCallable(
    //         "getServerTime"
    //       );
    //       getServerTimeFunction()
    //         .then((result) => {
    //           console.log(result.data.time._seconds * 1000); // This will be the server's current time
    //         })
    //         .catch((error) => {
    //           throw new Error(
    //             `Your Internet connection is not available ${error}`
    //           );
    //         });
    //     } catch (e) {
    //       console.log("use local time");
    //       console.log(new Date().getTime());
    //     } finally {
    //       clearTimeout(timeoutId);
    //     }
    //   }, 2000);
    // },
    // showTime() {
    //   const getServerTimeFunction = functions.httpsCallable("getServerTime");
    //   getServerTimeFunction().then((result) => {
    //     console.log(result.data); // This will be the server's current time
    //   });
    // },
    addFaq() {
      this.showDialog = true;
      this.mode = "Add";
      this.faqQuestion = null;
      this.faqAnswer = null;
    },

    async submitFaq() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }
      if (this.mode == "Add") {
        const submitData = {
          question: this.faqQuestion,
          ans: this.faqAnswer,
        };
        await this.$store.dispatch("faqs/addFaq", submitData);
      } else if (this.mode == "Edit") {
        const submitData = {
          question: this.faqQuestion,
          ans: this.faqAnswer,
          id: this.faqId,
        };
        await this.$store.dispatch("faqs/editFaq", submitData);
      }
      console.log(
        `faqdata : Q ${this.faqQuestion} A ${this.faqAnswer} submit from : ${this.mode} `
      );

      this.showDialog = false;
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "บันทึกสำเร็จ",
      });
    },
    editFaq(faqs) {
      this.showDialog = true;
      this.faqQuestion = faqs.question;
      this.faqAnswer = faqs.ans;
      this.faqId = faqs.id;
      this.mode = "Edit";
      console.log(faqs);
    },
    async deleteFaq() {
      await this.$store.dispatch("faqs/deleteFaq", {
        id: this.faqId,
      });
      this.showDialog = false;
      this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "ลบสำเร็จ",
      });
    },
  },
};
</script>

<style scoped>
.form-row {
  @apply pb-3;
}
.std-info-grid {
  @apply grid grid-cols-4 py-6;
}

.std-info-grid h3 {
  @apply col-span-4 md:col-span-1 pb-4;
}

.std-info-data {
  @apply col-span-4 md:col-span-3;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
