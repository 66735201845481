<template>
  <div class="px-2">
    <h2 class="text-gray-500 font-semibold pt-5">
      ข้อมูลนักศึกษา
    </h2>
    <div class="border-solid my-5 border-b-4 border-primary-250 w-48"></div>
    <div class="pt-5">
      <div class="std-info-grid ">
        <h3 class="text-gray-500 font-semibold  ">
          ข้อมูลรหัสผู้ใช้
        </h3>
        <div class="std-info-data space-y-2">
          <div>Username : {{ userInfo.stdCode }}</div>
          <div class="flex">
            <div>Password : ********</div>
            <base-button
              class="ml-2"
              mode="super-flat"
              @click="showModal = 'resetPassword'"
              >แก้ไข</base-button
            >
          </div>
          <div class="flex">
            <div>E-Mail : {{ userInfo.email }}</div>
            <base-button
              class="ml-2"
              mode="super-flat"
              @click="showModal = 'resetEmail'"
              >แก้ไข</base-button
            >
          </div>
        </div>
      </div>
      <div class="std-info-grid ">
        <h3 class="text-gray-500 font-semibold  ">
          ข้อมูลนักศึกษา
        </h3>
        <div class="std-info-data space-y-2">
          <div>รหัสนักศึกษา : {{ userInfo.stdCode }}</div>
          <div>สาขาวิชาเอก : {{ stdMajorText(userInfo.major) }}</div>
          <div v-if="userInfo.major2">
            สาขาวิชาเอก2 : {{ stdMajorText(userInfo.major2) }}
          </div>
          <div>หลักสูตร : {{ userInfo.program }}</div>
          <div>สถานภาพ : {{ stdStatusText(userInfo.status) }}</div>
          <!-- <div>
            GPAX : -
          </div> -->
          <div>คำนำหน้า : {{ userInfo.nameTitle }}</div>
          <div>ชื่อ : {{ userInfo.firstName }}</div>
          <div>นามสกุล : {{ userInfo.lastName }}</div>
          <div>เพศ: {{ genterText }}</div>
          <div>ที่อยู่ : {{ userInfo.conAddress }}</div>
          <div>ที่อยู่ตามทะเบียนบ้าน : {{ userInfo.homAddress }}</div>
          <!-- <div>
          สาขาวิชาเอก : Management Information System
        </div> -->
          <div>เบอร์โทรศัพท์ : {{ userInfo.tel }}</div>
          <div>เลขประจำตัวประชาชน : {{ userInfo.stdIdCard }}</div>
          <div>
            วันเกิด :
            {{
              userInfo.birthday
                ? userInfo.birthday.toDate().toLocaleDateString()
                : "-"
            }}
          </div>
          <div><b>Emergency Contact</b></div>
          <div>
            เบอร์โทร : {{ userInfo.emergTel ? userInfo.emergTel : "-" }}
          </div>
          <div>
            ชื่อ-นามสกุล : {{ userInfo.emergName ? userInfo.emergName : "-" }}
          </div>
          <div>
            ความสัมพันธ์ :
            {{ userInfo.emergRelation ? userInfo.emergRelation : "-" }}
          </div>
          <div><b>โรงเรียนมัธยมปลาย</b></div>
          <div>โรงเรียนมัธยมปลาย : {{ userInfo.stdHighSchool }}</div>
          <div>หลักสูตร : {{ userInfo.stdHighSchoolSchool }}</div>
          <div>ผลการศึกษา : {{ userInfo.stdHighSchoolGrade }}</div>
          <div>
            <button class="text-blue-550" @click="openModal2">แก้ไข</button>
            <!-- <router-link class="text-blue-550" >แก้ไข</router-link> -->
          </div>
        </div>
      </div>
    </div>
    <div class="std-info-grid col-span-4">
      <h3 class="text-gray-500 font-semibold  ">
        ประวัติการเปลี่ยนแปลงข้อมูล
      </h3>
      <div class="std-info-data space-y-2 col-span-4">
        <div
          class="flex items-center justify-center"
          v-if="studentChangeLog.length === 0"
        >
          <span>ไม่มีข้อมูลที่ต้องแสดง</span>
        </div>
        <div class="grid-table-main" v-else>
          <div
            class="
              grid grid-cols-6
              grid-table-header
            "
          >
            <div class="w-full h-auto">ประเภท</div>
            <div class="w-full h-auto">จาก</div>
            <div class="w-full h-auto">ไปยัง</div>
            <div class="w-full h-auto">เทอม/ปีการศึกษา</div>
            <div class="w-full h-auto">แก้ไขโดย</div>
            <div class="w-full h-auto">เวลา</div>
          </div>

          <div class="grid-table-body">
            <div
              class="grid grid-cols-6 grid-table-row"
              v-for="(changeLog, index) in studentChangeLog"
              :key="index"
            >
              <div class="text-center my-auto ">
                {{ changeLog.type === "major" ? "สาขาวิชาเอก" : "สถานะ" }}
                {{
                  changeLog.type === "major" ? `ที่ ${changeLog.majorNo}` : ""
                }}
              </div>
              <div class="text-center my-auto ">
                {{
                  changeLog.type === "major"
                    ? stdMajorText(changeLog.from)
                    : stdStatusText(changeLog.from)
                }}
              </div>
              <div class="text-center my-auto ">
                {{
                  changeLog.type === "major"
                    ? stdMajorText(changeLog.to)
                    : stdStatusText(changeLog.to)
                }}
              </div>
              <div class="text-center my-auto ">
                {{ changeLog.semester }}/{{ changeLog.year }}
              </div>
              <div class="text-center my-auto ">
                {{ changeLog.stfId }}
              </div>
              <div class="text-center my-auto ">
                {{ changeLog.time.toLocaleString() }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit Dialog 1 -->
  <data-modal
    :show="showModal == 'resetPassword'"
    :deleteBtn="false"
    title="เปลี่ยน Password"
    @close="showModal = null"
    @submit="submit1"
  >
    <form @submit.prevent>
      <!-- <p class="text-sm leading-5 text-gray-500 pt-4 pb-2">
        ข้อมูลนักศึกษา
      </p>
      <p class="text-sm leading-5 text-gray-700">
        ----
      </p> -->

      <div class="form-row flex flex-col">
        <div class="pr-2 pb-3 md:pb-0">
          <label class="text-left">
            Old password
          </label>
          <input
            class="form-control"
            @blur="v$.oldPassword.$touch"
            type="password"
            v-model="oldPassword"
          />
          <span
            class="errors"
            v-for="error of v$.oldPassword.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="pr-2 pb-3 md:pb-0">
          <label class="text-left">
            New password
          </label>
          <input
            class="form-control"
            @blur="v$.newPassword.$touch"
            type="password"
            v-model="newPassword"
          />
          <span
            class="errors"
            v-for="error of v$.newPassword.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>

        <div class="pr-2 pb-3 md:pb-0">
          <label>
            Confirm New Password
          </label>
          <input
            class="form-control"
            @blur="v$.confirmPassword.$touch"
            type="password"
            v-model="confirmPassword"
          />
          <span
            class="errors"
            v-for="error of v$.confirmPassword.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>
    </form>
  </data-modal>

  <!-- Edit Dialog 2 -->
  <data-modal
    :show="showModal === 'editInfo'"
    :deleteBtn="false"
    title="แก้ไขข้อมูลนักศึกษา"
    @close="showModal = null"
    @submit="submit2"
  >
    <form @submit.prevent>
      <div class="form-row flex flex-col md:flex-row">
        <div class="pr-2 pb-3 md:pb-0">
          <label class="text-left">
            คำนำหน้า
          </label>
          <select
            name="select"
            @blur="v$.nameTitle.$touch"
            v-model.trim="nameTitle"
            class="form-control"
            :class="{
              errors: v$.nameTitle.$error,
            }"
          >
            <option value="" selected disabled hidden></option>
            <option value="นาย">นาย</option>
            <option value="นางสาว">นางสาว</option>
            <option value="นาง">นาง</option>
          </select>
          <span
            class="errors"
            v-for="error of v$.nameTitle.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="pr-2 pb-3 md:pb-0">
          <label>
            ชื่อ
          </label>
          <input
            class="form-control"
            @blur="v$.firstName.$touch"
            :class="{
              errors: v$.firstName.$error,
            }"
            type="text"
            v-model="firstName"
          />
          <span
            class="errors"
            v-for="error of v$.firstName.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="pb-2 md:pb-0">
          <label>
            นามสกุล
          </label>
          <input
            class="form-control"
            @blur="v$.lastName.$touch"
            :class="{
              errors: v$.lastName.$error,
            }"
            type="text"
            v-model="lastName"
          />
          <span
            class="errors"
            v-for="error of v$.lastName.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>

      <div class="form-row">
        <label>
          เพศ
        </label>
        <select
          name="select"
          @blur="v$.gender.$touch"
          v-model.trim="gender"
          class="form-control"
          :class="{
            errors: v$.gender.$error,
          }"
        >
          <option value="" selected disabled hidden></option>
          <option value="F">หญิง</option>
          <option value="M">ชาย</option>
        </select>

        <span
          class="errors"
          v-for="error of v$.gender.$errors"
          :key="error.$uid"
          >{{ error.$message }}<br />
        </span>
      </div>

      <div class="form-row">
        <label>
          ที่อยู่
        </label>
        <input
          class="form-control"
          @blur="v$.conAddress.$touch"
          :class="{
            errors: v$.conAddress.$error,
          }"
          type="text"
          v-model="conAddress"
        />
        <span
          class="errors"
          v-for="error of v$.conAddress.$errors"
          :key="error.$uid"
          >{{ error.$message }}<br />
        </span>
      </div>
      <div class="form-row">
        <label>
          ที่อยู่ตามทะเบียนบ้าน
        </label>
        <input
          class="form-control"
          @blur="v$.homAddress.$touch"
          :class="{
            errors: v$.homAddress.$error,
          }"
          type="text"
          v-model="homAddress"
        />
        <span
          class="errors"
          v-for="error of v$.homAddress.$errors"
          :key="error.$uid"
          >{{ error.$message }}<br />
        </span>
      </div>
      <div class="form-row flex ">
        <div class="pr-2 ">
          <label>
            เบอร์โทรศัพท์
          </label>
          <input
            class="form-control"
            @blur="v$.tel.$touch"
            :class="{
              errors: v$.tel.$error,
            }"
            type="text"
            v-model="tel"
          />
          <span class="errors" v-for="error of v$.tel.$errors" :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="">
          <label>
            E-Mail
          </label>
          <input class="form-control " type="text" v-model="email" disabled />
        </div>
      </div>
      <div class="form-row">
        <label>
          วันเกิด
        </label>
        <BaseDatePickerVue
          @blur="v$.birthday.$touch"
          class="w-full"
          :class="{
            errors: v$.birthday.$error,
          }"
          v-model="birthday"
        ></BaseDatePickerVue>
        <span
          class="errors"
          v-for="error of v$.birthday.$errors"
          :key="error.$uid"
          >{{ error.$message }}<br />
        </span>
      </div>

      <div class="form-row">
        <label>
          เลขบัตรประจำตัวประชาชน
        </label>
        <input
          class="form-control"
          @blur="v$.stdIdCard.$touch"
          :class="{
            errors: v$.stdIdCard.$error,
          }"
          type="text"
          v-model="stdIdCard"
        />
        <span
          class="errors"
          v-for="error of v$.stdIdCard.$errors"
          :key="error.$uid"
          >{{ error.$message }}<br />
        </span>
      </div>
      <hr class="pb-4" />
      <b>โรงเรียนมัธยมปลาย</b>
      <div class="form-row">
        <label>
          ชื่อโรงเรียน
        </label>
        <input
          class="form-control"
          @blur="v$.stdHighSchool.$touch"
          :class="{
            errors: v$.stdHighSchool.$error,
          }"
          type="text"
          v-model="stdHighSchool"
        />
        <span
          class="errors"
          v-for="error of v$.stdHighSchool.$errors"
          :key="error.$uid"
          >{{ error.$message }}<br />
        </span>
      </div>

      <div class="form-row flex">
        <div class="pr-2">
          <label>
            หลักสูตร
          </label>
          <input
            class="form-control"
            @blur="v$.stdHighSchoolSchool.$touch"
            :class="{
              errors: v$.stdHighSchoolSchool.$error,
            }"
            type="text"
            v-model="stdHighSchoolSchool"
          />
          <span
            class="errors"
            v-for="error of v$.stdHighSchoolSchool.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="pr-2">
          <label>
            ผลการศึกษา
          </label>
          <input
            class="form-control"
            @blur="v$.stdHighSchoolGrade.$touch"
            :class="{
              errors: v$.stdHighSchoolGrade.$error,
            }"
            type="text"
            v-model="stdHighSchoolGrade"
          />
          <span
            class="errors"
            v-for="error of v$.stdHighSchoolGrade.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>
      <hr class="pb-4" />
      <!-- Emergency contact form -->
      <b>Emergency Contact</b>
      <div class="form-row">
        <label>
          เบอร์
        </label>
        <input
          class="form-control"
          @blur="v$.emergTel.$touch"
          :class="{
            errors: v$.emergTel.$error,
          }"
          type="text"
          v-model="emergTel"
        />
        <span
          class="errors"
          v-for="error of v$.emergTel.$errors"
          :key="error.$uid"
          >{{ error.$message }}<br />
        </span>
      </div>

      <div class="form-row flex ">
        <div class="pr-2">
          <label>
            ชื่อ-นามสกุล
          </label>
          <input
            class="form-control"
            @blur="v$.emergName.$touch"
            :class="{
              errors: v$.emergName.$error,
            }"
            type="text"
            v-model="emergName"
          />
          <span
            class="errors"
            v-for="error of v$.emergName.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
        <div class="pr-2">
          <label>
            ความสัมพันธ์
          </label>
          <input
            class="form-control"
            @blur="v$.emergRelation.$touch"
            :class="{
              errors: v$.emergRelation.$error,
            }"
            type="text"
            v-model="emergRelation"
          />
          <span
            class="errors"
            v-for="error of v$.emergRelation.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>
    </form>
  </data-modal>

  <!-- Edit Dialog 3 -->
  <data-modal
    :show="showModal == 'resetEmail'"
    :deleteBtn="false"
    title="เปลี่ยน Email นักศึกษา"
    @close="showModal = null"
    @submit="confirmEmailDialog = true"
  >
    <form @submit.prevent>
      <div class="form-row flex flex-col">
        <div class="pr-2 pb-3 md:pb-0">
          <label>
            New Email
          </label>
          <input
            class="form-control"
            @blur="v$.newEmail.$touch"
            type="email"
            v-model="newEmail"
          />
          <span
            class="errors"
            v-for="error of v$.newEmail.$errors"
            :key="error.$uid"
            >{{ error.$message }}<br />
          </span>
        </div>
      </div>
    </form>
  </data-modal>

  <toast
    :show="!!error"
    mode="error"
    @close="closeError"
    fixed
    :message="error"
  >
  </toast>
  <confirm-modal
    mode="success"
    :show="confirmEmailDialog"
    title="การเปลี่ยน E-mail ระบบจะทำการ logout อัตโนมัติ ยืนยันเปลี่ยน Email ใช่หรือไม่"
    @close="confirmEmailDialog = false"
    @confirm="submit3()"
  >
  </confirm-modal>
</template>

<script>
import { auth, functions } from "../../js/firebaseDb.js";
import FormValidation from "../../mixins/formValidation.js";
import DataModal from "../UI/DataModal.vue";
import useVuelidate from "@vuelidate/core";
import firebase from "firebase/app";
import BaseDatePickerVue from "../UI/BaseDatePicker.vue";
import ConfirmModal from "../UI/ConfirmModal.vue";
import StudentMixin from "../../mixins/student.js";
import {
  required,
  numeric,
  not,
  minLength,
  maxLength,
  helpers,
  sameAs,
  email,
  integer,
} from "@vuelidate/validators";
export default {
  props: { mode: { type: String, required: false, default: "student" } },
  mixins: [FormValidation, StudentMixin], // isNumber() logoutStudent()
  components: { DataModal, BaseDatePickerVue, ConfirmModal },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      showModal: null,
      error: null,

      // modal1
      newPassword: null,
      oldPassword: null,
      confirmPassword: null,

      // modal2
      nameTitle: null,
      firstName: null,
      lastName: null,
      gender: null,
      conAddress: null,
      homAddress: null,
      tel: null,
      email: null,
      // update 12/21
      birthday: null,
      stdIdCard: null,
      stdHighSchool: null,
      stdHighSchoolSchool: null,
      stdHighSchoolGrade: null,
      // emergency contact
      emergTel: null,
      emergName: null,
      emergRelation: null,

      // modal3
      newEmail: null,
      confirmEmailDialog: false,
    };
  },
  validations() {
    return {
      // form 1
      oldPassword: { required },
      newPassword: { required, minLengthValue: minLength(6) },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(this.newPassword),
        minLengthValue: minLength(6),
      },

      // form 2
      nameTitle: { required },
      firstName: {
        required,
        char: helpers.withMessage("This field should be string", not(numeric)),
        minLength: minLength(2),
      },
      lastName: {
        required,
        char: helpers.withMessage("This field should be string", not(numeric)),
        minLength: minLength(2),
      },
      gender: { required },
      conAddress: { required },
      homAddress: { required },
      tel: {
        required,
        integer,
        minLengthValue: minLength(8),
        maxLengthValue: maxLength(12),
      },

      // update 12/21
      birthday: { required },
      stdIdCard: {
        required,
        integer,
        minLengthValue: minLength(13),
        maxLengthValue: maxLength(13),
      },
      stdHighSchool: { required },
      stdHighSchoolSchool: { required },
      stdHighSchoolGrade: { required },

      // emergency contact
      emergTel: {
        required,
        integer,
        minLengthValue: minLength(8),
        maxLengthValue: maxLength(12),
      },
      emergName: { required },
      emergRelation: { required },

      newEmail: { required, email },
    };
  },
  computed: {
    genterText() {
      if (this.userInfo) {
        if (this.userInfo.gender === "M") {
          return "ชาย";
        } else if (this.userInfo.gender === "F") {
          return "หญิง";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },

    userInfo() {
      return this.$store.getters["userInfo"];
    },
    studentChangeLog() {
      return this.$store.getters["studentChangeLog"];
    },
  },
  methods: {
    stdStatusText(statusId) {
      if (statusId) {
        const selectedStatus = this.$store.getters["students/getStatusById"](
          statusId
        );
        return selectedStatus ? selectedStatus.name : "-";
      } else {
        return "-";
      }
    },
    stdMajorText(majorId) {
      if (majorId) {
        const selectedMajor = this.$store.getters["majors/getMajorById"](
          majorId
        );
        return selectedMajor ? selectedMajor.name : "-";
      } else {
        return "-";
      }
    },
    async submit3() {
      await this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังบันทึกข้อมูล",
      });

      this.v$.newEmail.$touch();

      if (this.v$.newEmail.$error) {
        this.isLoading = false;
        await this.$store.dispatch("clearToast");
        return;
      }

      const resetEmailStudent = functions.httpsCallable("resetEmailStudent");
      await resetEmailStudent({
        uid: this.userInfo.id,
        email: this.newEmail,
      })
        .then(async () => {
          this.$store.dispatch("clearToast");
          this.showModal = null;
          this.newEmail = null;
          this.confirmEmailDialog = false;
          this.v$.newEmail.$reset();
          // await this.$store.dispatch("setUser", { mode: "student" });
          await this.logoutStudent();
          this.$store.dispatch("updateToast", {
            mode: "success",
            show: true,
            message: "อัพเดตข้อมูลสำเร็จ",
          });
        })
        .catch((error) => {
          this.$store.dispatch("clearToast");
          this.error = error.message;
        });
    },
    async submit2() {
      // Form validation
      this.v$.nameTitle.$touch();
      this.v$.firstName.$touch();
      this.v$.lastName.$touch();
      this.v$.gender.$touch();
      this.v$.conAddress.$touch();
      this.v$.homAddress.$touch();
      this.v$.tel.$touch();
      this.v$.stdIdCard.$touch();
      this.v$.birthday.$touch();
      this.v$.stdHighSchool.$touch();
      this.v$.stdHighSchoolSchool.$touch();
      this.v$.stdHighSchoolGrade.$touch();
      this.v$.emergTel.$touch();
      this.v$.emergName.$touch();
      this.v$.emergRelation.$touch();

      if (
        this.v$.nameTitle.$error ||
        this.v$.firstName.$error ||
        this.v$.lastName.$error ||
        this.v$.gender.$error ||
        this.v$.conAddress.$error ||
        this.v$.homAddress.$error ||
        this.v$.tel.$error ||
        this.v$.stdIdCard.$error ||
        this.v$.birthday.$error ||
        this.v$.stdHighSchool.$error ||
        this.v$.stdHighSchoolSchool.$error ||
        this.v$.stdHighSchoolGrade.$error ||
        this.v$.emergTel.$error ||
        this.v$.emergName.$error ||
        this.v$.emergRelation.$error
      ) {
        this.isLoading = false;
        await this.$store.dispatch("clearToast");
        return;
      }

      await this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังบันทึกข้อมูล",
      });

      // get user authen data
      const user = auth.currentUser;
      const submittedData = {
        id: user.uid,
        nameTitle: this.nameTitle,
        firstName: this.firstName,
        lastName: this.lastName,
        gender: this.gender,
        conAddress: this.conAddress,
        homAddress: this.homAddress,
        tel: this.tel,
        // 12/21
        birthday: this.birthday,
        stdIdCard: this.stdIdCard,
        stdHighSchool: this.stdHighSchool,
        stdHighSchoolSchool: this.stdHighSchoolSchool,
        stdHighSchoolGrade: this.stdHighSchoolGrade,
        // emergency contact
        emergTel: this.emergTel,
        emergName: this.emergName,
        emergRelation: this.emergRelation,
      };
      this.showModal = null;
      await this.$store.dispatch("updateUserInfo", {
        mode: "student",
        ...submittedData,
      });
      await this.$store.dispatch("clearToast");
      await this.$store.dispatch("updateToast", {
        mode: "success",
        show: true,
        message: "อัพเดตข้อมูลสำเร็จ",
      });
      this.showModal = null;
    },
    async submit1() {
      await this.$store.dispatch("updateToast", {
        mode: "loading",
        show: true,
        message: "กำลังบันทึกข้อมูล",
      });

      this.v$.oldPassword.$touch();
      this.v$.newPassword.$touch();
      this.v$.confirmPassword.$touch();

      if (
        this.v$.oldPassword.$error ||
        this.v$.newPassword.$error ||
        this.v$.confirmPassword.$error
      ) {
        this.isLoading = false;
        await this.$store.dispatch("clearToast");
        return;
      }

      // get user authen data
      const user = auth.currentUser;

      // create credentail by using oldpassword that reauthenticateWithCredential need only recent authentication
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        this.oldPassword
      );

      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          // User re-authenticated.
          user
            .updatePassword(this.newPassword)
            .then(() => {
              // call update password function and pass new password to firebase
              this.$store.dispatch("updateToast", {
                mode: "success",
                show: true,
                message: "อัพเดตข้อมูลสำเร็จ",
              });
              this.showModal = null;
            })

            .catch((error) => {
              this.error = error;
            });
        })
        .catch((error) => {
          // An error ocurred
          this.error = error;
          this.$store.dispatch("clearToast");
        });
    },
    openModal2() {
      this.nameTitle = this.userInfo.nameTitle;
      this.firstName = this.userInfo.firstName;
      this.lastName = this.userInfo.lastName;
      this.gender = this.userInfo.gender;
      this.conAddress = this.userInfo.conAddress;
      this.homAddress = this.userInfo.homAddress;
      this.tel = this.userInfo.tel;
      this.email = this.userInfo.email;
      // update 12/21
      this.stdIdCard = this.userInfo.stdIdCard;
      this.birthday = this.userInfo.birthday.toDate();
      this.stdHighSchool = this.userInfo.stdHighSchool;
      this.stdHighSchoolSchool = this.userInfo.stdHighSchoolSchool;
      this.stdHighSchoolGrade = this.userInfo.stdHighSchoolGrade;
      this.emergTel = this.userInfo.emergTel;
      this.emergName = this.userInfo.emergName;
      this.emergRelation = this.userInfo.emergRelation;
      this.showModal = "editInfo";
    },
    closeError() {
      this.error = null;
    },
    getDMYDash(seconds) {
      const dt = new Date(seconds * 1000);
      return dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
    },
    getDMY(seconds) {
      const dt = new Date(seconds * 1000);
      return dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate();
    },
  },
};
</script>

<style scoped>
.form-row {
  @apply pb-3;
}
.std-info-grid {
  @apply grid grid-cols-4 py-6;
}

.std-info-grid h3 {
  @apply col-span-4 md:col-span-1 pb-4;
}

.std-info-data {
  @apply col-span-4 md:col-span-3;
}

@media (min-width: 768px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

th {
  @apply bg-brown-300 font-semibold;
}
</style>
