<template>
  <div class="bg-blue-100" style="min-height:70vh">
    <div class="flex justify-end pr-10 pt-5" v-if="hasUserProgram">
      <router-link :to="getPrevRoute">
        <svg
          class="w-8 h-8 hover:text-gray-400 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </router-link>
    </div>

    <div class="flex justify-center">
      <div class="w-1/2 h-1/2 pt-20">
        <h3>โปรดเลือกหลักสูตร</h3>
        <div class="grid gap-0 grid-cols-2">
          <base-card
            :mxAuto="false"
            class="mx-2 h-40 bg-white hover:bg-gray-100 cursor-pointer"
            @click="selectProgram('undergrad')"
          >
            <div>
              <h5 class="py-2">หลักสูตร 4 ปี</h5>
              <p>รังสิต</p>
            </div>
          </base-card>
          <base-card
            :mxAuto="false"
            class="mx-2 h-40 bg-white hover:bg-gray-100 cursor-pointer"
            @click="selectProgram('ibmp')"
          >
            <div>
              <h5 class="py-2">หลักสูตร 5 ปี</h5>
              <p>ท่าพระจันทร์</p>
            </div>
          </base-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formValid: true,
      prevRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((ev) => {
      ev.prevRoute = from;
    });
  },
  computed: {
    getPrevRoute() {
      if (this.prevRoute && this.prevRoute.name === "admin-login") {
        return { name: "quota-list" };
      } else if (this.prevRoute && this.prevRoute.name !== undefined) {
        return {
          name: this.prevRoute.name,
          params: this.prevRoute.params,
        };
      } else {
        return { name: "quota-list" };
      }
    },
    hasUserProgram() {
      return this.$store.getters["hasUserProgram"];
    },
    userProgram() {
      return this.$store.getters["userProgram"];
    },
  },
  methods: {
    async selectProgram(userProgram) {
      const submittedData = {
        userProgram: userProgram,
      };
      try {
        await this.$store.dispatch("updateUserProgram", submittedData);
      } catch (e) {
        this.error = e.message;
      }
      this.$router.replace(this.getPrevRoute);
    },
  },
};
</script>
